import React, { useCallback, useState } from 'react';
import GA4React from "ga-4-react";
import axios from 'axios';
import http from './modules/api';

import '../css/menu_style_v2.css';
import '../css/common_module_style.css';

import $ from 'jquery';

import * as StringUtil from './modules/utils/StringUtil';
import * as StyleUtil from './modules/utils/StyleUtil';

import pc_maison_logo from "../resources/img/pc_maison_logo.jpeg";
import round_loading_bar from '../resources/img/round_loading_bar.gif';
import shop_store_icon from '../resources/img/shop-store-icon.svg';

import { Link, NavLink } from 'react-router-dom';
import ChannelService from './modules/ChannelService';

import Slider from 'react-slick';
import { useDropzone } from 'react-dropzone';

import ToggleButton from './modules/common/ToggleButton';
import ConfirmDialogV2 from './modules/dialogs/ConfirmDialogV2';
import TitleContentDialogV2 from './modules/dialogs/TitleContentDialogV2';
import BasicDialogV2 from './modules/dialogs/BasicDialogV2';
import WarningDialog from './modules/dialogs/WarningDialog';
import { getCookie } from "./modules/utils/CookieUtil";
import { setCookie } from "./modules/utils/CookieUtil";
import { deleteCookie } from "./modules/utils/CookieUtil";

import DownloadManager from './modules/CommonDownloadManager';

/* 공통 모듈 */
import styles from './modules/ModuleGuide.module.css';
import PopupFrameV2 from './modules/popup/PopupFrameV2';
import Button from './modules/common/Button';
import * as TargetViewUtil from './targetView/TargetViewUtil'; // 타겟뷰 유틸

import { margin } from '@mui/system';

import ReactPixel from 'react-facebook-pixel';
export const reactPixel = ReactPixel;
export let ga4react = new GA4React('G-VR4MJ2KSB1');
const domain = document.domain;

if(domain.indexOf("127.0.0.1") == -1
    && domain.indexOf("localhost") == -1
    && domain.indexOf("otdeal.me") == -1) { // 로컬이나 테스트 서버를 제외한 나머지 도메인에서만 GA/페이스북 픽셀을 실행
  if(domain.indexOf("robomdaiimage.com") >= 0) { // 로보이미지 마켓팅용 도메인인 경우
    // ga4react = new GA4React('G-S1KYGY4L2G');
    // reactPixel.init('833859754809649');
    // console.log("##### reactPixel 1 #####", reactPixel)
  } else {
    // ga4react = new GA4React('G-VR4MJ2KSB1');
    // reactPixel.init('438227415050093');
    // console.log("##### reactPixel 2 #####", reactPixel)
  }
}

var user_id = getCookie('user_id');
var userName = sessionStorage.getItem('userName');
var roleType = sessionStorage.getItem('roleType');
var redirectUrl = sessionStorage.getItem('redirectUrl');
var solution_type_cd = getCookie('solution_type_cd');
var serviceType = sessionStorage.getItem("serviceType");
sessionStorage.setItem('user_id', user_id);
sessionStorage.setItem('solution_type_cd', solution_type_cd);

var cookie_value_standby_yn = getCookie('cookie_value_standby_yn');

var isanotherBannerLoaded = true;
var is_reduct_left_menu = sessionStorage.getItem("is_reduct_left_menu");

let window_scrollTop; // 스크롤 위치

// Boot Channel as an anonymous user
ChannelService.boot({
  pluginKey: 'f1c88265-51f4-4bc2-81e8-c3f7a6146cdb', //please fill with your plugin key
});

function MyDropzone(props) {
  //const [uploadFiles, imageArray] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    //props.attachImage(acceptedFiles)
    props.this.attachImage(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const InputProps = {
    ...getInputProps(),
    multiple: true,
    accept: 'image/jpg,image/png,image/jpeg,image/gif,image/bmp,image/tiff',
  };

  const RootProps = {
    ...getRootProps(),
  };

  return (
    <div
      {...getRootProps()}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        cursor: 'pointer',
        backgroundColor: '#F2F2F2',
      }}
    >
      <input type="file" {...InputProps} />
      {isDragActive ? (
        <h5>이제 이미지를 놓아주세요.</h5>
      ) : (
        <h5>
          이곳에 사진 파일 1개를 끌어와 놓거나, 클릭하여 올려주세요.
          <br />
          <br />
          {/* <b>파일명은 꼭 영어</b>로 해주세요.
          <br /> */}
          <b>jpg, jpeg, png, gif, bmp</b>파일만 업로드할 수 있습니다.
        </h5>
      )}
    </div>
  );
}

var this_component;
export function call_checkUser_for_other_component() {
  this_component.checkUser();
}
export function call_onChangeIsAddShopButtonClick_for_other_component(state, kind) {
  if(kind==1){ // 1의 경우 banner
    this_component.onChangeIsAddShopBannerButtonClick(state);
    // 팝업 활성화시 무료체험종료일자 조회하기
    this_component.selectFreeDateEndDateAndEventPeriod();
  } else{ // 2의 경우 exhibition (진열)
    this_component.onChangeIsAddShopExhibitionButtonClick(state);
  }

}
export function call_openChannelTalk() {
  this_component.openChannelTalk();
}
export function openBlankModal(content_html) {
  this_component.setState({
    is_blank_popup_open : true,
    blank_popup_content : content_html
  }, () => {

  });
}
export function call_userGuide(url) {
  this_component.openUserGuide(url);
}

export function expansionLeftMenu(){
  this_component.onClickExpansionButton();
}

export function reductionLeftMenu(){
  this_component.onClickReductionButton();
}

/**
 * 현재 설정된 shop_no 반환
 * @returns shop_no
 */
export function call_shop_no(){
  return this_component.state.shop_no;
}

/* 
* 랜덤 난수 생성기
* min: 최소값,
* max: 최대값,
* preNum: 이전에 생성된 난수 (중복 허용 하고 싶으면 최소~최대 범위 밖의 아무 숫자나 넣으면 됨.)
 */ 
export function randomNum(min, max, preNum){
  var randNum = Math.floor(Math.random()*(max-min+1)) + min;
  while(true) {
      if (preNum == randNum) { // 이전에 생성 된 난수와 같은 값을 생성한 경우.
          randNum = Math.floor(Math.random()*(max-min+1)) + min; // 난수 재생성.
      } else {
          break; // 난수 생성 종료.
      }
  }
  return randNum;
}

let func_title_content_dialog;

export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  state = {
    isOpen: false,
    userName: '',
    sessionType: '',
    roleType: '',
    redirectUrl: '',
    solution_type_cd: '',
    set_token: '',
    logoutText: 'Log out',
    role_cls: '',
    shop_no: '',
    service_type: '',
    shopSyncState: '',
    //menu: [],
    shops: [],
    commonActiveMenuClass: '',
    currentLocationHref: '' /*선택메뉴 url*/,

    is_add_shop_button_click: false,
    is_add_shop_banner_button_click: false, // 배너 쇼핑몰 연동시 true
    is_add_shop_exhibition_button_click: false, // 진열 쇼핑몰 연동시 true

    is_sample_banner_popup_open: false,
    banner_templa_list: [],
    sample_banner_step: '1',
    imageArray: [],

    is_reduct_left_menu : 'F',

    setting_sample_banner_slider: {
      // variableWidth:true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 150,
      autoplaySpeed: 1800,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay : true,
      autoplay: true,
      responsive: [
        // 반응형 웹 구현 옵션
        // {
        // 	breakpoint: 960, //화면 사이즈 960px
        // 	settings: {
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:5
        // 	}
        // },
        // {
        // 	breakpoint: 639, //화면 사이즈 639px
        // 	settings: {
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:4,
        // 		slidesToScroll: 4
        // 	}
        // }
      ],
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="login-slider-custum-dot" />
        </div>
      ),
    },

    setting_info_banner_slider : {
      // variableWidth:true,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay : true,
      autoplay : true,
      autoplaySpeed: 3000,
      responsive: [ // 반응형 웹 구현 옵션
        // {  
        // 	breakpoint: 960, //화면 사이즈 960px
        // 	settings: {
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:5 
        // 	} 
        // },
        // { 
        // 	breakpoint: 639, //화면 사이즈 639px
        // 	settings: {	
        // 		//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
        // 		slidesToShow:4,
        // 		slidesToScroll: 4
        // 	} 
        // }
      ],
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="info_banner-slider-custum-dot" />
        </div>
      )
    },

    end_date_format: '0000년 00월 00일',
    event_period: '3',

    is_blank_popup_open : false,
    blank_popup_content : "",

    /** 큐레이션 설치 여부 / 진열 여부 */
    curation_install_yn: "N",
    curation_display_yn: "N",

    /** 확인,취소 다이얼로그 */
    confirm_dialog_show_state : false,
    confirm_dialog_content : "",
    confirm_dialog_button_list : [],

    /** 타겟뷰 설치 팝업 */
    is_open_target_view_install_popup : false,
    is_open_target_view_install_popup_cafe24 : false,
    /** 사용자 토큰 */
    user_token : "",

    /** 기본 다이얼로그 */
    is_open_basic_dialog : false,
    basic_dialog_content : "",
    /** 경고 다이얼로그 */
    is_open_warning_dialog : false,
    warning_dialog_content : "",
    /** 확인 취소 다이얼로그 */
    is_open_title_content_dialog : false,
    title_content_dialog_title : "",
    title_content_dialog_content : "",
    /** 타겟뷰 스트립트 설치 여부 */
    target_view_script_install_yn: "Y",
    /** 진열 서비스 데이터 분석 여부 */
    standby_yn : "Y"
  };

  componentWillMount() {
    //권한정보 조회
    this.checkUser();
  }

  componentWillUnmount() {}

  // 다른 영역 클릭시 활성화된 메뉴 닫기
  handleClickOutside(event) {
    const commonActiveMenuClass = this.state.commonActiveMenuClass;
    if (commonActiveMenuClass != event.target.className) {
      this.setState({ commonActiveMenuClass: '' });
    }
  }

  // 다른 영역 클릭시 활성화된 메뉴 닫기
  handleClickCommonMenuOutside(event) {
    const commonActiveMenuId = this.state.commonActiveMenuId;
    if (commonActiveMenuId != event.target.id) {
      this.setState({ commonActiveMenuId: '' });
    }
  }
  // 모바일 메뉴 열기
  mobileMenuClick = (e) => {
    this.setState({
      ...this.state,
    });
    $('.mobile_side').toggleClass('active');
    window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
  };
  // 모바일 메뉴 닫기
  mobileMenuCloseClick = (e) => {
    this.setState({
      ...this.state,
    });
    $('.mobile_side').toggleClass('active');
    StyleUtil.clearPopupNoneScroll(window_scrollTop);
  };
  // 모바일 사용자 메뉴 열기
  mobileUserMenuClick = (e) => {
    this.setState({
      ...this.state,
    });
    $('.mobile_side_user').toggleClass('active');
  };

  handleClick = (event) => {
    const commonActiveMenuClass = this.state.commonActiveMenuClass;
    console.log('##### event.target.className : ' + event.target.className);
    console.log(event.target);
    console.log('##### handleClick state : ' + commonActiveMenuClass);

    if (commonActiveMenuClass != '') {
      this.setState({ commonActiveMenuClass: '' });
    } else {
      this.setState({ commonActiveMenuClass: event.target.className });
    }
  };

  handleClickCommonMenu = (event, id) => {
    // const commonActiveMenuId = this.state.commonActiveMenuId;
    // console.log('##### event.target.id : ' + id);
    // console.log(event.target);
    // console.log('##### handleClickCommonMenu state : ' + commonActiveMenuId);

    // if (commonActiveMenuId != '') {
    //   this.setState({ commonActiveMenuId: '' });
    // } else {
    //   this.setState({ commonActiveMenuId: id });
    // }
  };

  componentDidMount(e) {
    this_component = this;
    //this.onClickExpansionButton(e);
    this.setState({
      currentLocationHref: window.location.href,
    });
  }

  componentDidUpdate() {
    // 메뉴 외의 영역 클릭시 이벤트
    if (this.state.commonActiveMenuClass != '') {
      document.addEventListener(
        'mousedown',
        this.handleClickOutside.bind(this),
      );
    } else {
      document.removeEventListener(
        'mousedown',
        this.handleClickOutside.bind(this),
      );
    }
    // 메뉴 외의 영역 클릭시 이벤트
    if (this.state.commonActiveMenuId != '') {
      document.addEventListener(
        'mousedown',
        this.handleClickCommonMenuOutside.bind(this),
      );
    } else {
      document.removeEventListener(
        'mousedown',
        this.handleClickCommonMenuOutside.bind(this),
      );
    }
  }
  // [공통] 사용자 ShopList 조회
  // async selectUserShops() {
  // 	axios.get(`/rest/common/selectUserShops`)
  // 	.then(
  // 		res => {
  // 			this.setState({shops : res.data})
  // 		}
  // 	)
  // }

  // [공통] 사용자 메뉴 + 샵(세션) 조회
  async selectUserMenu() {
    let installBannerCnt = sessionStorage.getItem("isLoadBannerInstallCntForMenu");
    if (installBannerCnt == null) {
      installBannerCnt = "false";
    }
    axios
      .get(`/rest/common/selectUserMenu_V2?roleType=` + this.state.roleType+"&isLoadBannerInstallCntForMenu="+installBannerCnt)
      .then((res) => {
        const { data } = res;
        if (installBannerCnt != null && installBannerCnt == "true") { // 로그인 또는 세션전환을 한 경우 true
            sessionStorage.setItem("installBannerCnt", data.installBannerCnt);
            sessionStorage.setItem("isLoadBannerInstallCntForMenu", false); // 세션 체인지 끝나면 바로 false로 바꿈.
            // alert("installBannerCnt: "+Number(sessionStorage.getItem("installBannerCnt")));
        }
        this.setState({
          role_cls: data.role_cls,
          shop_no: data.shop_no,
          shop_name: data.shop_name,
          service_type: data.service_type,
          service_name: data.service_name,
          shops: data.shopsList,
          //menu: data.resultMenuList,
          topServiceList : data.resultTopServiceList,
          serviceList : data.resultServiceList,
          installBannerCnt: Number(sessionStorage.getItem("installBannerCnt")),
          //service_type_list: res.data.service_type_list,
          shopActiveServiceCnt: res.data.shopActiveServiceCnt
        }, () => {
          if (this.state.service_type === "01007") {
            this.checkCurationInstallAndDisplay();
          }
        });

        if (window.location.href.indexOf('/shop/cafe24') > 0 || window.location.href.indexOf('/shop/rp') > 0 || window.location.href.indexOf('/shop/targetview') > 0) {
          if (this.state.shopActiveServiceCnt == 0
              && (this.state.service_type == "01001"
                  || this.state.service_type == "01002"
                  || this.state.service_type == "01008"
                  || this.state.service_type == "01009")
            ) {
            alert('쇼핑몰 연동 후 이용하실 수 있습니다.');
            //window.location.href = '/shop/serviceShopAdd?service_type='+data.service_type+'&step=1';  
            window.location.href = '/shop/solutionStoreAdd';  
          }
        }

        // console.log(data.shopsList);
        // console.log(this.props.history);
        //if (data.shopsList != null && data.shopsList.length == 0) {
          // 상점 추가 페이지 이동
          // this.props.history.push('/shop/serviceShopAdd');
          // this.props.history.push(
          // 	{
          // 		pathname: `/shop/serviceShopAdd`,
          // 		// search:`?event_seq=`+event_seq,
          // 		// state: {prevPath: window.location.href}
          // 	}
          // )
          //BrowserRouter.props.redirectUrl = `/shop/serviceShopAdd`;
        //}

        if (this.state.service_type == "01009") {
          this.targetViewInstallYn();
        }
      });
  }

  // 큐레이션 버튼 설치여부, 진열여부 조회
  async checkCurationInstallAndDisplay() {
    await http
      .get("/rest/shop/curation/service/display")
      .then((res) => {
        this.setState({
          curation_install_yn: res.data.install_yn,
          curation_display_yn: res.data.display_yn
        })
      });
  }

  changeShowCurationService () {
    this.setState({
        confirm_dialog_show_state: true,
        confirm_dialog_content: 
          this.state.curation_display_yn === "Y" ? "큐레이션을 미노출 하시겠습니까?" : "큐레이션을 노출 하시겠습니까?",
        confirm_dialog_button_list: [
          {
              title: "네",
              type: "1",
              event_method: () => {
                const config = {
                  headers: {
                      'content-type': 'application/json;'
                  }
                };
                const body = {
                  display_yn : this.state.curation_display_yn === "Y" ? "N" : "Y"
                }
                http
                  .post("/rest/shop/curation/service/display/modify", body, config)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.setState({
                        curation_display_yn: this.state.curation_display_yn === "Y" ? "N" : "Y"
                      })
                    }
                  });

                  // 닫기
                  const window_scrollTop = $(window).scrollTop();
                  this.setState({confirm_dialog_show_state: false});
                  // 스크롤 고정 해제
                  StyleUtil.clearPopupNoneScroll(window_scrollTop);
              }
          },
          {
              title: "아니요",
              type: "0",
              event_method: () => {
                const window_scrollTop = $(window).scrollTop();
                this.setState({confirm_dialog_show_state: false});
                // 스크롤 고정 해제
                StyleUtil.clearPopupNoneScroll(window_scrollTop);
              }
          }
      ]
    })
  }

  // [공통] 사용자 Shop 전환
  async changeShop(e) {
    axios
      .get(`/rest/common/changeShop?shop_no=` + e.target.value)
      .then((res) => {
        const { data } = res;
        var code = data.code;
        var msg = '';
        if (code == 200) {
          msg = '멀티샵 전환 성공!';
          sessionStorage.setItem("isLoadBannerInstallCntForMenu", true);
          this.setState({
            shop_no: data.shop_no,
          });
        } else {
          msg =
            '멀티샵 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)';
          //로그인 페이지로 리턴
        }
        //alert(msg);
        //window.location.reload();
        window.location.href = "/";
      });
  }

  // [공통] 사용자 service_type 전환
  async changeServiceType(service_type, redirect_yn) {
    axios
      .get(`/rest/common/changeServiceType?service_type=` + service_type)
      .then((res) => {
        var code = res.data.code;
        var redirectUrl = res.data.redirectUrl;
        if (code == 200) {
          if (redirectUrl != '' && redirect_yn == 'Y') {
            window.location.href = redirectUrl;
          }
          this.setState({
            service_type: service_type,
          });
        } else {
          alert('서비스 전환에 실패하였습니다.\n관리자에게 문의해 주세요. (1599-3518)');
        }
      });
  }

  //사용자 권한 조회
  async checkUser() {
    axios
      .get(`/userSessionType`)
      .then((res) => {
        var roleType = res.data.roleType;
        this.setState({
          isOpen: false,
          userName: res.data.userName,
          corpName: res.data.corpName,
          user_id: res.data.user_id,
          sessionType: res.data.sessionType,
          roleType: res.data.roleType,
          redirectUrl: res.data.redirectUrl,
          standby_yn: res.data.standby_yn,
          solution_type_cd: res.data.solution_type_cd,
        });
        if (
          window.location.href.indexOf('/login') < 0 &&
          window.location.href.indexOf('/demo') > 0
        ) {
          // roleType = 'none';
          this.setState({
            roleType: '2201',
            logoutText: '나가기',
          });
        }
        this.toggle = this.toggle.bind(this);
        if (sessionStorage.getItem('userName') == null) {
          userName = res.data.userName;
        }
        if (sessionStorage.getItem('roleType') == null) {
          roleType = res.data.roleType;
        }

        if (sessionStorage.getItem('redirectUrl') == null) {
          redirectUrl = res.data.redirectUrl;
        }

        sessionStorage.setItem('user_id', res.data.user_id);
        sessionStorage.setItem('userName', res.data.userName);
        sessionStorage.setItem('roleType', res.data.roleType);
        sessionStorage.setItem('serviceType', res.data.service_type);
        sessionStorage.setItem('redirectUrl', res.data.redirectUrl);
        sessionStorage.setItem('solution_type_cd', res.data.solution_type_cd);
        setCookie("user_id", res.data.user_id);
        setCookie("solution_type_cd", res.data.solution_type_cd);
        // this.render();
        if (
          window.location.href.indexOf('/login') < 0 &&
          window.location.href.indexOf('/demo') > 0
        ) {
          sessionStorage.setItem('user_id', 'DemoId');
          sessionStorage.setItem('userName', 'DemoUser');
          sessionStorage.setItem('roleType', '2201');
          sessionStorage.setItem('serviceType', '');
          sessionStorage.setItem('redirectUrl', '/demo/trend/current');
          sessionStorage.setItem('solution_type_cd', "");
          setCookie("user_id", "DemoId");
          setCookie("solution_type_cd", "");
          // this.setState({roleType: null});
        }

        // 로보이미지 마케팅 페이지는 비연동형 세팅 진행
        if (window.location.href.indexOf("/promo_roboimage/result") >= 0 || window.location.href.indexOf("/promo_aibanner/result") >= 0) {
          sessionStorage.setItem('solution_type_cd', "09900");
          setCookie("solution_type_cd", "09900");
        }

        //console.log("##### " + this.state.roleType);
        // NotificationManager.error('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동', '토큰을 입력하세요!', 3600000 * 24, () => {
        // 	alert('어서.');
        // });
        // Notification
        //if (this.state.roleType == '2204') {
          //cafe24 Event Setting 여부 체크
          // this.getSetting();
          // NotificationManager.error('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동', '토큰을 입력하세요!', 3600000 * 24, () => {
          // 	alert('어서.');
          // });
          // NotificationManager.info('배너 호출을 위한 토큰값이 필요합니다. 클릭하여 입력 화면으로 이동');
        //}

        if (res.data.userName != 'anonymousUser') {
          // Boot Channel as a registered user
          ChannelService.boot({
            pluginKey: 'f1c88265-51f4-4bc2-81e8-c3f7a6146cdb', //please fill with your plugin key
            profile: {
              name: res.data.userName, //fill with user name
              mobileNumber: res.data.tel_no, //fill with user phone number
              email: res.data.email,
              memberId: res.data.user_id,
            },
          });
        }

        if(res.data.userName == 'anonymousUser'){
            sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
        }

        if (res.data.chanel_talk == 'N') {
          // Shutdown Channel
          ChannelService.shutdown();
        }
        console.log("##### userSessionType res.data.redirectUrl : " + res.data.redirectUrl)
        // 진열 서비스 접근시 기본 세팅 여부 확인
        if (res.data.service_type == '01002') {
          if (window.location.href.indexOf('shop/rp/') >= 0) {
            if (window.location.href.indexOf(res.data.redirectUrl) == -1)  {
              if ((res.data.solution_type_cd != "09001" && window.location.href.indexOf('/cafe24List') >= 0) || res.data.standby_yn != "Y") {
                // 진입 3단계 (2024.02.27 : 진열 서비스 연동 3단계 화면 진입X)
                //window.location.href = res.data.redirectUrl;
              }
              // if (res.data.standby_yn == "Y" && window.location.href.indexOf('shop/rp/event/') >= 0) {
              //   // 아무동작 안함.
              // } 
              // else if (res.data.standby_yn == null || res.data.standby_yn != "Y" || res.data.standby_yn == "Y")  {
              //   window.location.href = res.data.redirectUrl;
              // }
            }
          }
          else if (window.location.href.indexOf('shop/serviceShopAdd') >= 0) {
            if (window.location.href.indexOf(res.data.redirectUrl) == -1) {
              if (res.data.standby_yn == null || res.data.standby_yn != "Y" || res.data.standby_yn == "Y")  {
                  window.location.href = res.data.redirectUrl;
              }
            }
          }
        }
        //사용자 메뉴 조회
        this.selectUserMenu();
        //사용자 ShopList 조회
        //this.selectUserShops();
      })
      .catch((error) => {
        //console.log("Data가 없습니다.");
        if (!this.props.logged && !window.location.pathname.includes('login')) {
          sessionStorage.removeItem('user_id');
          sessionStorage.removeItem('userName');
          sessionStorage.removeItem('roleType');
          sessionStorage.removeItem('redirectUrl');
          sessionStorage.removeItem('solution_type_cd');
          sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
          deleteCookie("user_id");
          deleteCookie("solution_type_cd");

          if (window.document.domain == "127.0.0.1" || window.document.domain == "localhost") {
            window.location.href = '/login';
          } else {
            window.location.href = '/logout';
          }  
        }
      });

    const res2 = await axios.get(`/rest/common/diff/role`);
    if (
      !res2.data['result'] &&
      window.location.href.indexOf('/login') < 0 &&
      window.location.href.indexOf('/ko') < 0 &&
      window.location.href.indexOf('/demo') < 0 &&
      window.location.href.indexOf('/common') < 0 &&
      window.location.href.indexOf('/ad-trial') < 0 &&
      window.location.href.indexOf(':3000') < 0
    ) {
      //alert("/rest/common/diff/role " + res2.data["result"]);
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('roleType');
      sessionStorage.removeItem('redirectUrl');
      sessionStorage.removeItem('solution_type_cd');
      sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
      deleteCookie("user_id");
      deleteCookie("solution_type_cd");
      window.location.href = '/logout';
    }

    // 3000포트(개발모드 일때)
    if (window.location.href.indexOf(':3000') > 0) {
      if (window.location.href.indexOf('/shop/cafe24') > 0) {
        this.setState({ roleType: 2204 });
      } else if (window.location.href.indexOf('/shop/trend') > 0) {
        this.setState({ roleType: 2203 });
      } else if (window.location.href.indexOf('/admin') > 0) {
        this.setState({ roleType: 2202 });
      } else if (window.location.href.indexOf('/demo') > 0) {
        this.setState({ roleType: 2201 });
      }
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleNext = (e) => {
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('roleType');
    sessionStorage.removeItem('redirectUrl');
    sessionStorage.removeItem('solution_type_cd');
    sessionStorage.removeItem("RP_LIST_OPERATION_BANNER");
    deleteCookie("user_id");
    deleteCookie("solution_type_cd");
    window.location.href = '/logout';
  };

  handleShopChange = (e) => {
    console.log(e);
    this.changeShop(e);
  };

  handleServiceChange(service_type, redirect_yn) {
    //console.log(service_type);
    this.changeServiceType(service_type, redirect_yn);

    // if(service_type === "01001"){
    //     ga4react.event('[상단] 로그인 후 상단 배너 클릭', '로그인 후 상단 배너 클릭 ID=' + user_id, '상단 메뉴');
    //     ReactPixel.trackCustom('[상단] 로그인 후 상단 배너 클릭', {user_id : user_id});

    // }else if(service_type === "01002"){
    //     ga4react.event('[상단] 로그인 후 상단 진열 클릭', '로그인 후 상단 진열 클릭 ID=' + user_id, '상단 메뉴');
    //     ReactPixel.trackCustom('[상단] 로그인 후 상단 진열 클릭', {user_id : user_id});
    // }
  };

  w3_open() {
    $('#mySidenav').css('display', 'block');
  }

  w3_close() {
    $('#mySidenav').css('display', 'none');
  }

  tempStyle = {
    backgroundColor: '#2f4050',
    float: 'left',
    width: '228px',
    height: '100%',
    position: 'fixed',
    overflowY: 'auto',
  };

  onClickExpansionButton = (e, forcedYn) => {
    $('.reduction-side-bar').ready(function() {
      // $('.expansion-side-bar').removeClass('transition_hidden');
      // $('.reduction-side-bar').removeClass('transition_visible');
      // $('.expansion-side-bar').addClass('transition_visible');
      // $('.reduction-side-bar').addClass('transition_hidden');

      // $('.menu_expansion_arrow').css("display","none");
      
      // $('#page-wrapper').css('padding', '72px 0 0 228px');
      // $('#common-page-wrapper').css('padding', '72px 0 0 228px');
      // $('#sosang-page-wrapper').css('padding', '72px 0 0 228px');
      // $('.fixedStepArea').css('left', '228px');

      $('#page-wrapper').css('padding', '111px 0 0 228px');
      $('#common-page-wrapper').css('padding', '72px 0 0 228px');
      $('#sosang-page-wrapper').css('padding', '72px 0 0 228px');
      $('.fixedStepArea').css('left', '220px');
      $(".wrap_file_name_setting").css("margin-left", "260px");

      //$('.mainmenu li ul li a').css('visibility', 'visible');
      //$('.mainmenu li ul li .main_menu_area').show();
    })
    //is_reduct_left_menu = false;
    if (forcedYn != 'Y') {
      sessionStorage.setItem("is_reduct_left_menu", "F");
    }
  };
  onClickReductionButton = (e, forcedYn) => {
    $('.reduction-side-bar').ready(function() {
      // $('.expansion-side-bar').removeClass('transition_visible');
      // $('.reduction-side-bar').removeClass('transition_hidden');
      // $('.expansion-side-bar').addClass('transition_hidden');
      // $('.reduction-side-bar').addClass('transition_visible');

      // $('.menu_expansion_arrow').css("display", "block");
      
      // $('#page-wrapper').css('padding', '72px 0 0 63px');
      // $('#common-page-wrapper').css('padding', '72px 0 0 63px');
      // $('#sosang-page-wrapper').css('padding', '72px 0 0 63px');
      // $('.fixedStepArea').css('left', '63px');

      $('#page-wrapper').css('padding', '111px 0 0 64px');
      $('#common-page-wrapper').css('padding', '72px 0 0 64px');
      $('#sosang-page-wrapper').css('padding', '72px 0 0 64px');
      $('.fixedStepArea').css('left', '60px');
      $(".wrap_file_name_setting").css("margin-left", "96px");

      //$('.mainmenu li ul li a').css('visibility', 'hidden');
      //$('.mainmenu li ul li .main_menu_area').hide();
      // alert($('.reduction-side-bar').length)
    })
    //is_reduct_left_menu = true;
    if (forcedYn != 'Y') {
      sessionStorage.setItem("is_reduct_left_menu", "T");
    }
  };

  onClickShopNoSelect = (e) => {
    this.onClickExpansionButton(e);
    $('#shop_no').trigger('click');
  };

  onClickCommonMenu = (e) => {
    this.mobileMenuClick(e);
  };

  onClickMobileUserMenu = (e) => {
    this.mobileUserMenuClick(e);
  };

  onClickMenu(menu_url) {
    this.props.history.push(menu_url);
  }

  loadBannerTemplateImg() {
    if (this.state.banner_templa_list.length <= 0) {
      http.get('/rest/common/template/img/list').then((resultData) => {
        if (resultData.data.list != null && resultData.data.list.length > 1) {
          this.setState({ banner_templa_list: resultData.data.list });
        }
      });
    }
  }

  onClickSampleBannerNextStep = (e) => {
    var next_sample_banner_step = Number(this.state.sample_banner_step) + 1;
    this.setState({
      sample_banner_step: [next_sample_banner_step],
    });
    console.log('==next_sample_banner_step:' + next_sample_banner_step);
  };

  onClickSampleBannerOpen = (e) => {
    this.loadBannerTemplateImg();
    this.setState({
      is_sample_banner_popup_open: true,
      sample_banner_step: 1,
    });
  };

  onClickSampleBannerClose = (e) => {
    this.setState({
      is_sample_banner_popup_open: false,
    });
  };

  onClickBlankBannerClose = (e) => {
    this.setState({
      is_blank_popup_open: false,
    });
  };

  // 업로드된 이미지 Preview
  attachImage(acceptedFiles) {
    if (acceptedFiles) {
      /* Get files in array form */
      const files = Array.from(acceptedFiles);

      if (files.length > 1) {
        alert('1개의 파일만 첨부해 주세요.');
        return false;
      } else {
        if (
          files[0].name.match(
            '.jpg$|.png$|.jpeg$|.gif$|.bmp$|.JPG$|.PNG$|.JPEG$|.GIF$|.BMP$',
          )
        ) {
          console.log(files[0].name);
        } else {
          alert('이미지 파일만 첨부 해 주세요.');
          return false;
        }
      }

      //const uploadFiles = Array.from(e.target.files);
      //const { getRootProps, getInputProps, isDragActive } = useDropzone({ files });
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsDataURL(file);
          });
        }),
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          console.log(images);
          console.log(files);
          console.log(this);
          this.setState({
            uploadFiles: files,
            imageArray: images,
          });
          //const {uploadFiles, imageArray} = useState(images);
          var fileNames = '';
          files.map((file) => {
            fileNames += file.name + ' ';
          });
          // $("#attachFileName").text(fileNames);

          // $("#sample_banner_image_upload_form").submit();
          this.createSampleEvent();
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }

  // 샘플배너 생성 이벤트
  createSampleEvent() {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const url = '/rest/common/sample/event/create';
    const formData = new FormData();
    const uploadFiles = this.state.uploadFiles;
    uploadFiles.forEach((file) => formData.append('files', file));
    if (this.state.shops.length >= 1) {
      formData.append('is_added_shops', 'Y');
    } else {
      formData.append('is_added_shops', 'N');
    }

    this.onClickSampleBannerNextStep();

    http
      .post(url, formData, config)
      .then((res) => {
        // 조회
        if (res.data != null && res.data.event_seq != null) {
          this.setState({
            sample_banner_event_seq: res.data.event_seq,
          });
          this.onClickSampleBannerNextStep();
        } else if (res.data.code == "401") {
          alert('사용자 세션이 끊어졌습니다.\n다시 로그인해 주세요.');
          window.location.href = "/login";
        } else {
          alert('배너 생성에 실패 하였습니다.\n관리자에게 문의해 주세요.');
          this.onClickSampleBannerClose();
        }
      })
      .catch((error) => {
        alert(error + '\n관리자에게 문의해 주세요.');
      });
  }

  // 새 배너 만들기 (step:2 으로 이동)
  newSampleEvent() {
    this.setState({
      sample_banner_step: 2,
      sample_banner_event_seq: null,
    });
  }

  // 다른 샘플배너 생성 이벤트
  createAnotherSampleEvent() {
    const url = '/rest/common/sample/event/another';
    if (isanotherBannerLoaded == true) {
      isanotherBannerLoaded = false;
      http
        .get(url, {
          params: {
            event_seq: this.state.sample_banner_event_seq,
          },
        })
        .then((res) => {
          // 조회
          if (res.data != null && res.data.event_seq != null) {
            this.setState({
              sample_banner_event_seq: res.data.event_seq,
            });
            // this.onClickSampleBannerNextStep();
          } else if (res.data.code == "401") {
            alert('사용자 세션이 끊어졌습니다.\n다시 로그인해 주세요.');
            window.location.href = "/login";
          } else {
            alert('배너 생성에 실패 하였습니다.\n관리자에게 문의해 주세요.');
          }
          isanotherBannerLoaded = true;
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요.');
          isanotherBannerLoaded = true;
        });
    }
  }

  //  샘플배너 저장 이벤트 : del_yn = "N"
  saveSampleEvent() {
    const url = '/rest/common/sample/event/save';
    if (isanotherBannerLoaded == true) {
      http
        .get(url, {
          params: {
            event_seq: this.state.sample_banner_event_seq,
          },
        })
        .then((res) => {
          // 조회
          if (res.data.result == true || res.data.result == 'true') {
            alert('배너를 저장 하였습니다.');
          } else {
            alert('배너 저장에 실패 하였습니다.');
          }
        })
        .catch((error) => {
          alert(error + '\n관리자에게 문의해 주세요.');
        });
    }
  }

  // 배너 쇼핑몰 연동시
  onChangeIsAddShopBannerButtonClick(state) {
    this.setState({
      is_add_shop_banner_button_click: state,
    });
  }

  // 진열 쇼핑몰 연동시
  onChangeIsAddShopExhibitionButtonClick(state) {
    this.setState({
      is_add_shop_exhibition_button_click: state,
    });
  }

  // 배너 설치 요청 링크
  openChannelTalk() {
    //$('.textLauncherIcon').click();
    //$('.launcherIcon').click();
    // window.ChannelIO('showMessenger');
    window.open("https://docs.google.com/forms/d/1I-Z0tkpHzsqpK76fpKODEX1FzuduWrpyUJ7a_Bv4z8w/edit");
  }

  onClickForceUpdate(path) {
    if (window.location.href.indexOf(path) >= 0) {
      window.location.reload();
    }
  }

  openUserGuide(url) {
    var g_oWindow = window.open('about:blank', '_blank');
    g_oWindow.location = url;
  }

  async selectFreeDateEndDateAndEventPeriod() {
    const res = await http.get("/rest/shop/selectFreeDateEndDateAndEventPeriod");
    // 조회
    if (res.data != null) {
      this.setState({
        end_date_format: res.data.end_date_format,
        event_period: res.data.event_period
      })
    }
  }

  targetViewInstallPopup(state) {
    if (state == false) {
      this.setState({
        is_open_target_view_install_popup : false
      })
    } else {
      // 사용자 토큰 조회
      http.get(`/rest/shop/userToken`).then(res => {
        this.setState({user_token : res.data.token}, () => {
          this.setState({
            is_open_target_view_install_popup : state
          })
        });
      })
      .catch(error => {
        // console.log (error);
      })
    }
  }

  targetViewInstallYn() {
    // 사용자 토큰 조회
    let script_code_cd = "A1003";
    http.get(`/rest/shop/targetViewScriptInstallYn?script_code_cd=${script_code_cd}`).then(res => {
			this.setState({
        target_view_script_install_yn : res.data.install_yn ? res.data.install_yn : "N"
      })
		})
		.catch(error => {
			// console.log (error);
		})
  }

  targetViewInstallPopupCafe24(state) {
    if (state) {
      if (this.state.solution_type_cd == null) {
        this.titleContentDialog(true
          ,`<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 0.5C16.1433 0.5 12.3731 1.64366 9.16639 3.78634C5.95963 5.92903 3.46027 8.97451 1.98436 12.5377C0.508449 16.1008 0.122284 20.0216 0.874696 23.8043C1.62711 27.5869 3.4843 31.0615 6.21143 33.7886C8.93855 36.5157 12.4131 38.3729 16.1957 39.1253C19.9784 39.8777 23.8992 39.4916 27.4623 38.0156C31.0255 36.5397 34.071 34.0404 36.2137 30.8336C38.3564 27.6269 39.5 23.8567 39.5 20C39.495 14.8298 37.439 9.87278 33.7831 6.2169C30.1272 2.56102 25.1702 0.504964 20 0.5ZM18.5 11C18.5 10.6022 18.658 10.2206 18.9393 9.93934C19.2207 9.65804 19.6022 9.5 20 9.5C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11V21.5C21.5 21.8978 21.342 22.2794 21.0607 22.5607C20.7794 22.842 20.3978 23 20 23C19.6022 23 19.2207 22.842 18.9393 22.5607C18.658 22.2794 18.5 21.8978 18.5 21.5V11ZM20 30.5C19.555 30.5 19.12 30.368 18.75 30.1208C18.38 29.8736 18.0916 29.5222 17.9213 29.111C17.751 28.6999 17.7064 28.2475 17.7932 27.811C17.8801 27.3746 18.0944 26.9737 18.409 26.659C18.7237 26.3443 19.1246 26.13 19.5611 26.0432C19.9975 25.9564 20.4499 26.001 20.861 26.1713C21.2722 26.3416 21.6236 26.63 21.8708 27C22.118 27.37 22.25 27.805 22.25 28.25C22.25 28.8467 22.013 29.419 21.591 29.841C21.169 30.2629 20.5967 30.5 20 30.5Z" fill="#ACACAC"></path></svg>`
          , this.state.solution_type_cd == "09001" 
              ? "<p>타겟뷰 서비스 미신청 상태입니다.<br/>타겟뷰 서비스 신청을 하시겠습니까?</p><p>카페24 타겟뷰 앱이 설치되어 있는 경우<br/>타겟뷰 스크립트도 같이 설치됩니다.</p>"
              : "<p>타겟뷰 서비스 미신청 상태입니다.<br/>타겟뷰 서비스 신청을 하시겠습니까?</p>"
          );
          func_title_content_dialog = TargetViewUtil.onClickShopActiveServiceButton;
        return false;
      }
    }

    if ($('.mobile_side').hasClass( "active" )) {
      this.mobileMenuCloseClick();
    }
    this.setState({
      is_open_target_view_install_popup_cafe24 : state,
    })
  }

  basicDialog(state, content) {
    this.setState({
      is_open_basic_dialog : state,
      basic_dialog_content : content,
    })
  }
  
  warningDialog(state, content) {
    this.setState({
      is_open_warning_dialog : state,
      warning_dialog_content : content,
    })
  }
  
  titleContentDialog(state, title, content, is_confirm) {
    this.setState({
      is_open_title_content_dialog : state,
      title_content_dialog_title : title,
      title_content_dialog_content : content,
    });
    if (is_confirm && func_title_content_dialog) {
      func_title_content_dialog(); // 기눙 실행
      func_title_content_dialog = null; // 기능 초기화 
    }
  }

  openCafe24TargetviewApp() {
    window.open("https://store.cafe24.com/kr/apps/22839")
  }
  
  insertProductDetailDurationScriptTag() {
    let data = {
    };
    const config = {
        headers: {
            'content-type': 'application/json;',
        },
    };
    http
    .post('/rest/shop/cafe24/insertProductDetailDurationScriptTag', data, config)
    .then((res) => {
        const { data } = res;
        if (data.code == "0000") {
            this.basicDialog(true,"타겟뷰 스크립트 설치가 완료되었습니다.");
        } else if (data.code == "9004") {	// 9004 : 해당 서비스 유형의 Cafe24 App이 미설치 상태 입니다.
          this.titleContentDialog(true
            ,`<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 0.5C16.1433 0.5 12.3731 1.64366 9.16639 3.78634C5.95963 5.92903 3.46027 8.97451 1.98436 12.5377C0.508449 16.1008 0.122284 20.0216 0.874696 23.8043C1.62711 27.5869 3.4843 31.0615 6.21143 33.7886C8.93855 36.5157 12.4131 38.3729 16.1957 39.1253C19.9784 39.8777 23.8992 39.4916 27.4623 38.0156C31.0255 36.5397 34.071 34.0404 36.2137 30.8336C38.3564 27.6269 39.5 23.8567 39.5 20C39.495 14.8298 37.439 9.87278 33.7831 6.2169C30.1272 2.56102 25.1702 0.504964 20 0.5ZM18.5 11C18.5 10.6022 18.658 10.2206 18.9393 9.93934C19.2207 9.65804 19.6022 9.5 20 9.5C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11V21.5C21.5 21.8978 21.342 22.2794 21.0607 22.5607C20.7794 22.842 20.3978 23 20 23C19.6022 23 19.2207 22.842 18.9393 22.5607C18.658 22.2794 18.5 21.8978 18.5 21.5V11ZM20 30.5C19.555 30.5 19.12 30.368 18.75 30.1208C18.38 29.8736 18.0916 29.5222 17.9213 29.111C17.751 28.6999 17.7064 28.2475 17.7932 27.811C17.8801 27.3746 18.0944 26.9737 18.409 26.659C18.7237 26.3443 19.1246 26.13 19.5611 26.0432C19.9975 25.9564 20.4499 26.001 20.861 26.1713C21.2722 26.3416 21.6236 26.63 21.8708 27C22.118 27.37 22.25 27.805 22.25 28.25C22.25 28.8467 22.013 29.419 21.591 29.841C21.169 30.2629 20.5967 30.5 20 30.5Z" fill="#ACACAC"></path></svg>`
            , "Cafe24 타겟뷰 App이 미설치 상태 입니다.<br/>설치 하시겠습니까?");
            func_title_content_dialog = this.openCafe24TargetviewApp;
				} else if (data.code == "422") {	// 422 : 해당 shop과 screen path에 이미 설치된 스크립트가 존재합니다. (카페24 리턴)
          this.warningDialog(true,"이미 설치된 스크립트가 존재합니다.");
        } else if (data.code == "-999") {	// -999 : 해당 shop과 screen path에 이미 설치된 스크립트가 존재합니다. (내부 리턴)
          this.warningDialog(true,"이미 설치된 스크립트가 존재합니다.");
        } else {
            alert(
                '[code : ' +
                data.code +
                ']' +
                '\n관리자에게 문의해 주세요. (1599-3518)',
            );
        }
        this.targetViewInstallPopupCafe24(false); // 팝업 닫기
        this.targetViewInstallYn(); // 타겟뷰 설치 여부 재조회
    })
    .catch((error) => {
        alert('설치 요청 실패' + '\n관리자에게 문의해 주세요. (1599-3518)');
    }); 
  }

  render() {
    var menuCreateCnt = -1;
    if (
      window.location.href.indexOf('/login') < 0 &&
      window.location.href.indexOf('/ko') < 0 &&
      window.location.href.indexOf('/ad-trial') < 0 &&
      window.location.href.indexOf('/common/curation') < 0 &&
      window.location.href.indexOf('/kdeal/common/product/list') < 0 &&
      window.location.href.indexOf('/kdeal/common/sosanggongin') < 0 &&
      window.location.href.indexOf('/kdeal/demo/sosanggongin') < 0 &&
      window.location.href.indexOf('/common/registUser') < 0 &&
      window.location.href.indexOf('/common/id/search') < 0 &&
      window.location.href.indexOf('/common/pwd/search') < 0 &&
      window.location.href.indexOf('/ko/contact') < 0 &&
      window.location.href.indexOf('/common/FormViewer') < 0 &&
      window.location.href.indexOf('/common/EmailVerify') < 0 && // 이메일 인증 페이지
      window.location.href.indexOf('/common/EmailChangePassword') < 0 && // 이메일 비밀번호 변경 페이지
      window.location.href.indexOf('/common/RegistUser_v3') < 0 && // 신규 회원가입 페이지
      window.location.href.indexOf('/common/Login_v3') < 0 && // 신규 로그인 페이지
      window.location.href.indexOf('/common/RegistConfirm') < 0 && // 이메일 인증 페이지
      window.location.href.indexOf('/common/promo_roboimage') < 0 && // 마케팅 로보 이미지
      window.location.href.indexOf('/common/promo_roboimage/result') < 0 && // 마케팅 로보 이미지 결과
      window.location.href.indexOf('/common/promo_aibanner') < 0 && // 디자인 마케팅 로보 이미지
      window.location.href.indexOf('/common/promo_aibanner/result') < 0 && // 디자인 마케팅 로보 이미지 결과
      // window.location.href.indexOf("/common/ModuleGuide") < 0 &&
      this.state.roleType != null &&
      this.state.roleType != 'undefined' &&
      this.state.roleType != '2299' &&
      this.state.roleType != ''
    ) {
      const { commonActiveMenuClass } = this.state;
      const { commonActiveMenuId } = this.state;
      return (
        <>
          <div>
            {/* PC 헤더 */}
            <div className='pc_header'>
              {/* 로고 */}
              <Link to={this.state.redirectUrl}>
                <div className='logo_robomd' style={{width: '151px', height: '25px', margin: '24px 54px 23px 23px'}}></div>
              </Link>
              {/* 서비스 구분 메뉴 */}
              <div className='pc_service_menu'>
                <ul className='pc_service_menu_list'>
                  {
                    this.state.topServiceList != null && this.state.topServiceList.length > 0
                    ?
                    this.state.topServiceList.map((service, idx) => (
                      <>
                        {/* 서비스 메뉴 1 */}
                        <li id={'pc_menu_'+service.service_type} className={`pc_service_menu_item`} 
                            onClick={(e) => {
                              //this.handleClickCommonMenu(e, 'pc_menu_01001'+service)
                              this.setState({
                                currentLocationHref: window.location.href,
                              });
                              //this.mobileMenuClick(e);
                              window.scrollTo(0, 0);
                              this.handleServiceChange(service.service_type, "Y");
                            }
                        }>
                          <div className={`pc_service_menu_title ${service.service_type == this.state.service_type ? 'active' : ''}`}>{service.service_name}</div>
                          {
                            service.resultMenuList != null && service.resultMenuList.length > 0
                            ?
                              <>
                                {/* data start : service_menu*/}
                                <div className='icon_select_down_arrow' style={{width: '18px', height: '16px', marginLeft: '8px'}}></div>
                                {/* 서비스 상세 메뉴 */}
                                <ul className='pc_service_menu_detail_class'>
                                {
                                  service.resultMenuList.map((menu, idx) => (
                                    <>
                                        {/* 2 depts data start : main_menu*/}
                                        <li className='pc_service_popup_menu_m_class'>
                                          <ul className=''>
                                            <div className='pc_service_popup_menu_m_box'>
                                              <a className='pc_service_popup_menu_m_title'>{menu.menu_nm}</a>
                                            </div>
                                            {/* 2단 : 중메뉴명 */}
                                            {
                                              menu.subMenu.map((sub, sub_idx) => (
                                                <li className={'pc_service_popup_menu_p_box'}>
                                                  <NavLink 
                                                    style={{display: 'flex'}}
                                                    to={sub.menu_url}
                                                    onClick={
                                                    // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                                                    (e) => {
                                                      this.setState({
                                                        currentLocationHref: window.location.href,
                                                      });
                                                      //this.mobileMenuClick(e);
                                                      window.scrollTo(0, 0);
                                                      this.handleServiceChange(service.service_type, "N");
                                                      e.stopPropagation(); // 이벤트 버블링
                                                    }
                                                  }>
                                                  {
                                                    // 메뉴 심볼
                                                    sub.menu_simbol != null && sub.menu_simbol != "" ?
                                                      <div className={sub.menu_simbol} style={{width: '76px', height: '76px', marginRight: '16px'}}></div>
                                                    : ""
                                                  }
                                                  {
                                                    <div className='pc_service_popup_menu_p_detail'>
                                                      {
                                                        <>
                                                          <div className='pc_service_popup_menu_p_title_box'>
                                                            <div className='pc_service_popup_menu_p_title'>{sub.menu_nm}</div>
                                                            <div className='pc_service_popup_menu_p_badge'>
                                                              {
                                                                <>
                                                                  {
                                                                    sub.menu_badge_list != null
                                                                    ?
                                                                      sub.menu_badge_list.split(",").map((badge) => (
                                                                        <div className={badge} style={{marginLeft: '4px'}}></div>
                                                                      ))
                                                                    : ""
                                                                  }
                                                                </>
                                                              }
                                                            </div>
                                                          </div>
                                                          {
                                                            sub.menu_desc1 != null && sub.menu_desc1 != ''
                                                            ?
                                                              <div className='pc_service_popup_menu_p_desc_1'>{sub.menu_desc1}</div>
                                                            :''
                                                          }
                                                          {
                                                            sub.menu_desc2 != null && sub.menu_desc2 != ''
                                                            ?
                                                              <div className='pc_service_popup_menu_p_desc_2'>{sub.menu_desc2}</div>
                                                            :''
                                                          }
                                                        </>
                                                      }
                                                    </div>
                                                  }
                                                  </NavLink>
                                                </li>
                                              ))
                                            }
                                          </ul>
                                        </li>
                                    </>
                                  ))
                                }
                                </ul>
                              </>
                            : ""
                          }
                          {/* data end : service_menu*/}
                        </li>
                      </>
                    ))
                    :""
                  }
                </ul>
              </div>
              <div className='pc_user_info_box'>
                {/* 샘플배너만들기 */}
                {
                  this.state.service_type == '01001' ? (
                    <div className='pc_btn-auto-b' style={{ width: '154px', height: '42px', lineHeight: '42px', cursor: 'pointer', alignItems: 'center', marginRight: '28px' }}
                          onClick={(e) => this.onClickSampleBannerOpen()}> {'무료 배너 체험'}
                    </div>
                  ) : ('')
                }
                <div className='common_menu' onClick={this.handleClick}>
                  {/* 사용자 정보 */}
                  <ul style={{display: 'flex', alignItems: 'center'}}>
                    <li className={`li_commonUserMenu ${commonActiveMenuClass == 'commonUserMenu' ? ' active' : ''}`}>
                      <div className='commonUserMenu'>
                        <div className='icon_user' style={{ width: '32px', height: '32px', marginRight: '8px' }}></div>
                        <div className='pc_user_name' style={{ marginRight: '40px' }}>{this.state.userName}</div>
                      </div>
                      {/* 프로필 메뉴 */}
                      <ul className='pc_user_menu_list'>
                        {/* <li className='pc_user_menu_item active'> */}
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/myInfo'}}>{'나의 정보 변경'}</NavLink>
                        </li>
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/pwdMod'}}>{'비밀번호 변경'}</NavLink>
                        </li>
                        {
                          //(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? (
                          (this.state.service_type == '01001' 
                              || this.state.service_type == '01002' 
                              || this.state.service_type == '01008' 
                              || this.state.service_type == '01009') && this.state.shop_no != -1 ? (
                            <>
                              <li>
                                <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/solutionStoreAdd'}}>{'쇼핑몰 데이터 불러오기'}</NavLink>
                              </li>
                              <li>
                                <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/serviceShopList'}}>{'쇼핑몰 목록'}</NavLink>
                              </li>
                              {/* <li>
                                <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/orderAmountExpect'}}>{'멤버십 관리'}</NavLink>
                              </li> */}
                              <li>
                                <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/order'}}>{'결제수단 관리'}</NavLink>
                              </li>
                              <li>
                                <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/order/list'}}>{'결제 내역'}</NavLink>
                              </li>
                            </>
                          ) : ""
                        }
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/notice/list'}}>{'공지사항'}</NavLink>
                        </li>
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/qna/list'}}>{'나의 문의'}</NavLink>
                        </li>
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/shop/qna/form'}}>{'문의 하기'}</NavLink>
                        </li>
                        <li>
                          <NavLink className='pc_user_menu_item' to={{ pathname: '/logout'}}>{'로그아웃'}</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>      
              </div>
            </div>
            {/* PC 메뉴영역 */}
            {
              this.state.is_reduct_left_menu == "T"
              ?
                <>
                  {/* PC 메뉴영역 축소 */}
                  <div className='pc_left_menu_reduction'>
                    {/* PC 메뉴영역 확대 버튼 */}
                    <div className='icon_arrow_light_right menu_reduction_state'
                      onClick={(e) => {
                        this.onClickExpansionButton(e);
                        this.setState({ is_reduct_left_menu : 'F' });
                      }}>
                    </div>
                    {/* 업체 정보 영역 */}
                    {/* <div className='pc_corp_info_box_reduction' style={(this.state.role_cls == '2204' 
                                                                        || this.state.role_cls == '2207' 
                                                                        || this.state.role_cls == '2211' 
                                                                        || this.state.role_cls == '2214') && this.state.shop_no != -1 ? {} : { display: 'none'}}
                        // onClick={(e) => {
                        // this.onClickExpansionButton(e);
                        // this.setState({ is_reduct_left_menu : 'F' });}}
                    > */}
                    <div className='pc_corp_info_box_reduction' style={(this.state.service_type == '01001' 
                                                                        || this.state.role_cls == '01002' 
                                                                        || this.state.role_cls == '01008' 
                                                                        || this.state.role_cls == '01009') && this.state.shop_no != -1 ? {} : { display: 'none'}}
                        // onClick={(e) => {
                        // this.onClickExpansionButton(e);
                        // this.setState({ is_reduct_left_menu : 'F' });}}
                    >
                      {/* 업체명 */}
                      <div className='pc_corp_name_box_reduction'>
                        <div className='icon_corp'></div>
                      </div>
                    </div>
                    {/* 서비스 메뉴 영역 */}
                    <div className='pc_main_menu_box'>
                      <ul className='pc_main_menu'>
                      {
                        <>
                          {
                            this.state.serviceList != null && this.state.serviceList.length > 0
                            ?
                              this.state.serviceList.map((service, idx) => (
                                <li>
                                  <div className='pc_main_menu_service_box_reduction' style={ service.service_type == '01002' ? {display: 'none'} : {} }>
                                    <div className='pc_main_menu_service_reduction'>
                                      <div className={service.service_icon}></div>
                                    </div>
                                  </div>
                                  <ul>
                                    {
                                      service.resultMenuList != null && service.resultMenuList.length > 0
                                      ?
                                      service.resultMenuList.map((menu, idx) => (
                                        <>
                                          <li>
                                            {
                                              <>
                                                {/* 1단 : 대메뉴명 */}
                                                <ul>
                                                  <>
                                                    {/* 2단 : 중메뉴명 */}
                                                    {
                                                      menu.subMenu.map((sub, sub_idx) => (
                                                        // <div className={`pc_main_menu_p_box_reduction ${sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0 || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ? ' active' : ''}`}>
                                                        <div className={`pc_main_menu_p_box_reduction ${sub.menu_url === new URL(window.location.href).pathname || new URL(window.location.href).pathname === sub.menu_url ? ' active' : ''}`}>
                                                        {
                                                          <NavLink 
                                                            to={sub.menu_url}
                                                            onClick={
                                                            // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                                                            (e) => {
                                                              this.setState({
                                                                currentLocationHref: window.location.href,
                                                              });
                                                              //this.mobileMenuClick(e);
                                                              window.scrollTo(0, 0);
                                                            }
                                                          }>
                                                          {
                                                            // 메뉴 아이콘
                                                            sub.menu_icon != null && sub.menu_icon != "" ?
                                                              <div className={sub.menu_icon + ` ${sub.menu_url === new URL(window.location.href).pathname || new URL(window.location.href).pathname === sub.menu_url ? ' active' : ''}`}></div>
                                                            : ""
                                                          }
                                                          </NavLink>
                                                        }
                                                        </div>
                                                      ))
                                                    }
                                                  </>
                                                </ul>  
                                              </>
                                            }
                                          </li>
                                        </>
                                      ))
                                      : ""
                                    }
                                  </ul>
                                </li>
                              ))
                            : ""
                          }
                        </>
                      }
                      </ul>
                    </div>
                  </div>
                </>
              :
                <>
                {/* ------------------------------------------------------------------------------------------------------------------------------ */}
                  {/* <div className='pc_left_menu expansion-side-bar' style={(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? {} : { paddingTop: '0px' }}> */}
                  <div className='pc_left_menu expansion-side-bar' style={(this.state.service_type == '01001' 
                                                                            || this.state.service_type == '01002' 
                                                                            || this.state.service_type == '01008' 
                                                                            || this.state.service_type == '01009') && this.state.shop_no != -1 ? {} : { paddingTop: '0px' }}>
                    {/* PC 메뉴영역 */}
                    <div className='icon_arrow_light_right menu_unreduction_state'
                      onClick={(e) => {
                        this.onClickReductionButton(e);
                        this.setState({ is_reduct_left_menu : 'T' });
                      }}>
                    </div>
                    {/* 업체 정보 영역 */}
                    {/* <div className='pc_corp_info_box' style={(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? {} : { display: 'none'}}> */}
                    <div className='pc_corp_info_box' style={(this.state.service_type == '01001' 
                                                              || this.state.service_type == '01002' 
                                                              || this.state.service_type == '01008' 
                                                              || this.state.service_type == '01009') && this.state.shop_no != -1 ? {} : { display: 'none'}}>
                      {/* 업체명 */}
                      <div className='pc_corp_name_box'>
                        <div className='icon_corp'></div>
                        <div className='pc_corp_name'>{this.state.shop_name}</div>
                      </div>
                      {/* 멀티샵 선택영역 */}
                      <div className='pc_shop_box'>
                        <div className='pc_shop_title'>{'관리 쇼핑몰'}</div>
                        <div className='pc_shop_select_box'>
                          <div className='pc_shop_icon_box'>
                            {
                              this.state.solution_type_cd != null && this.state.solution_type_cd != "" ?
                                <div className={ "icon_small_solution_" + this.state.solution_type_cd} style={{width: '16px', height: '16px'}}></div>
                              : ""
                            }
                          </div>
                          <select className='pc_shop_select' id="shop_no" name="shop_no" value={this.state.shop_no} onChange={(e) => this.handleShopChange(e)}>
                          { this.state.shops != null && this.state.shops.length > 0 
                            ?
                              this.state.shops.map((option, index) => (
                                <option key={index} value={option.shop_no}>{option.shop_name}</option>
                              ))
                            : <option value="" disabled>쇼핑몰 데이터 불러오기</option>
                          }
                          </select>
                          <div className='icon_select_down_arrow' style={{width: '18px', height: '16px', position: 'fixed', left: '172px'}}></div>
                        </div>
                      </div>
                    </div>
                    {/* 서비스 메뉴 영역 */}
                    <div className='pc_main_menu_box'>
                      <ul className='pc_main_menu'>
                        {/* 서비스명 */}
                        {
                          <>
                            {
                              this.state.serviceList != null && this.state.serviceList.length > 0
                              ?
                                this.state.serviceList.map((service, idx) => (
                                  <li className='pc_main_menu_service_list'>
                                    <div className='pc_main_menu_service_box' style={{}}>
                                      <div className='pc_main_menu_service' style={window.location.href.indexOf("/shop/rp/main") > 0 ? {background: "#DFFAFA"} : {}}>
                                        <div className={service.service_icon}></div>
                                        { service.service_type == '01002' 
                                          ? <NavLink className='pc_main_menu_service_title' to={"/shop/rp/main"}>{"홈"}</NavLink>
                                          : <a className='pc_main_menu_service_title'>{service.service_name}</a>
                                        }
                                      </div>
                                    </div>
                                    <ul>
                                      {
                                        service.resultMenuList != null && service.resultMenuList.length > 0
                                        ?
                                        service.resultMenuList.map((menu, idx) => (
                                          <>
                                            <li>
                                              {
                                                <>
                                                  {/* 1단 : 대메뉴명 */}
                                                  <ul className={ menu.menu_cls == "M" ? 'pc_main_menu_m_class' : 'pc_main_menu_p_class'} >
                                                    <div className='pc_main_menu_m_box'>
                                                      <a className='pc_main_menu_m_title'>{menu.menu_nm}</a>
                                                    </div>
                                                    <>
                                                      {/* 2단 : 중메뉴명 */}
                                                      {
                                                        menu.subMenu.map((sub, sub_idx) => (
                                                          // <div className={`pc_main_menu_p_box ${sub.menu_url.indexOf(new URL(window.location.href).pathname) >= 0 || new URL(window.location.href).pathname.indexOf(sub.menu_url) >= 0 ? ' active' : ''}`}>
                                                          <div className={`pc_main_menu_p_box ${sub.menu_url === new URL(window.location.href).pathname || new URL(window.location.href).pathname === sub.menu_url ? ' active' : ''}`}>
                                                          {
                                                            // 메뉴 아이콘
                                                            sub.menu_icon != null && sub.menu_icon != "" ?
                                                              <div className={sub.menu_icon + ` ${sub.menu_url === new URL(window.location.href).pathname || new URL(window.location.href).pathname === sub.menu_url ? ' active' : ''}`}></div>
                                                            : ""
                                                          }
                                                            {/* <a className='pc_main_menu_p_title'>{sub.menu_nm}</a> */}
                                                            <NavLink 
                                                              exact activeClassName="active"
                                                              className={`pc_main_menu_p_title ${sub.menu_url === new URL(window.location.href).pathname || new URL(window.location.href).pathname === sub.menu_url ? ' active' : ''}`}
                                                              to={sub.menu_url}
                                                              onClick={
                                                              // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                                                              (e) => {
                                                                this.setState({
                                                                  currentLocationHref: window.location.href,
                                                                });
                                                                //this.mobileMenuClick(e);
                                                                window.scrollTo(0, 0);
                                                              }
                                                            }>
                                                              {sub.menu_nm}
                                                            </NavLink>
                                                          </div>
                                                        ))
                                                      }
                                                    </>
                                                  </ul>  
                                                </>
                                              }
                                            </li>
                                          </>
                                        ))
                                        : ""
                                      }
                                    </ul>
                                  </li>
                                ))
                              : ""
                            }
                            {/* 가이드 메뉴 영역 */}
                            {
                              this.state.serviceList != null && this.state.serviceList.length > 0
                              ?
                                this.state.service_type === "01001"
                                ?
                                  <div className='pc_guide_menu'>
                                    {
                                      this.state.solution_type_cd === "09900"
                                      ?
                                        // AI디자인 (비연동형 배너) 가이드
                                        <li>
                                          <a href='https://iclave.notion.site/AI-0e28c3650d9c4784a2ba9f543102be9f'
                                            target='_blank'
                                            style={{ display: "flex", justifyContent: "center" }}>
                                            <button class="hover_dark main_menu_install_link_btn">AI디자인 사용가이드</button>
                                          </a>
                                        </li> 
                                      :
                                        <li>
                                          {/* AI배너 사용가이드 */}
                                          {
                                            // 해당 서비스를 활성화 하지 않는 경우
                                            this.state.shopActiveServiceCnt <= 0
                                            ?
                                              <a href='https://iclave.notion.site/RoboMD-5c0553b3fcc841edae67146f26889a74'
                                                target='_blank'
                                                style={{ display: "flex", justifyContent: "center" }}>
                                                <button class="hover_dark main_menu_install_link_btn">RoboMD 공통 사용가이드</button>
                                              </a>
                                            :
                                              <>
                                                <a href='https://iclave.notion.site/AI-013b37bb75594506874804f76b920b40'
                                                  target='_blank'
                                                  style={{ display: "flex", justifyContent: "center" }}>
                                                  <button class="hover_dark main_menu_install_link_btn">AI배너 사용가이드</button>
                                                </a>
                                                {
                                                  // 설치된 배너가 없는 경우 
                                                  this.state.installBannerCnt <= 0
                                                  ?
                                                      <Link
                                                        to={{
                                                          pathname: `/shop/qna/form`,
                                                            state: { 
                                                              qna_type_cd: "2105"
                                                            }
                                                        }} style={{ display: "flex", justifyContent: "center" }}>
                                                        <button class="hover_dark main_menu_install_link_btn">쇼핑몰에 설치 요청하기</button>
                                                      </Link>
                                                  : ""
                                                }
                                              </>
                                          }
                                        </li>
                                    }
                                  </div>
                                :
                                this.state.service_type === "01002"
                                ?
                                  <>
                                  <div className='pc_guide_menu'>
                                    <li>
                                      {/* 상품자동진열 가이드 */}
                                      {
                                        // 해당 서비스를 활성화 하지 않는 경우
                                        this.state.shopActiveServiceCnt <= 0
                                        ?
                                          <a href='https://iclave.notion.site/RoboMD-5c0553b3fcc841edae67146f26889a74'
                                            target='_blank'
                                            style={{ display: "flex", justifyContent: "center" }}>
                                            <button class="hover_lightmint main_menu_install_link_btn">RoboMD 공통 사용가이드</button>
                                          </a>
                                        :
                                          <>
                                            <a href='https://iclave.notion.site/e70c7030de684de1b64e2a68ca672c30'
                                              target='_blank'
                                              style={{ display: "flex", justifyContent: "center" }}>
                                              <button class="hover_lightmint main_menu_install_link_btn">사용 가이드</button>
                                            </a>
                                            {/* {
                                              this.state.installBannerCnt <= 0
                                              ?
                                                <Link
                                                  to={{
                                                    pathname: `/shop/qna/form`,
                                                      state: {
                                                        qna_type_cd: "2104"
                                                      }
                                                  }} style={{ display: "flex", justifyContent: "center" }}>
                                                  <button class="hover_dark main_menu_install_link_btn">기간 연장하기</button>
                                                </Link>
                                              : ""
                                            } */}
                                          </>
                                      }
                                    </li>
                                    <li>
                                      {/* 상품자동진열 가이드 */}
                                      {
                                          <>
                                            <a href='https://docs.google.com/forms/d/1tb1QSomRUCQpn24azv8yVwaAGC4jbeeYYqu9cF5Y5qQ/edit'
                                              target='_blank'
                                              style={{ display: "flex", justifyContent: "center" }}>
                                              <button class="hover_lightmint main_menu_install_link_btn">설정 요청</button>
                                            </a>
                                          </>
                                      }
                                    </li>
                                  </div>
                                  </>
                                : this.state.service_type === "01005"
                                ?
                                  <div className='pc_guide_menu'>
                                    <Link
                                      to={{
                                        pathname: `/shop/qna/form`,
                                          state: { 
                                            qna_type_cd: "2107"
                                          }
                                      }} style={{ display: "flex", justifyContent: "center" }}>
                                      <button class="hover_dark main_menu_install_link_btn">사이트 수집 요청하기</button>
                                    </Link>
                                  </div>
                                : this.state.service_type === "01010"
                                ?
                                  <div className='pc_guide_menu'>
                                    <Link
                                      to={{
                                        pathname: `/shop/qna/form`,
                                          state: { 
                                            qna_type_cd: "2108"
                                          }
                                      }} style={{ display: "flex", justifyContent: "center" }}>
                                      <button class="hover_dark main_menu_install_link_btn">키워드 수집 요청하기</button>
                                    </Link>
                                  </div>
                                : this.state.service_type === "01009"
                                  ? this.state.target_view_script_install_yn == "N"
                                    ? this.state.solution_type_cd == "09001" || (this.state.solution_type_cd == null && this.state.shop_no < 1000)
                                      ? <div className='pc_guide_menu'>
                                          <a onClick={() => this.targetViewInstallPopupCafe24(true)}
                                            style={{ display: "flex", justifyContent: "center" }}>
                                            <button class="hover_dark main_menu_install_link_btn">타켓뷰 설치하기</button>
                                          </a>
                                        </div>
                                      : <div className='pc_guide_menu'>
                                          <a onClick={() => this.targetViewInstallPopup(true)}
                                            style={{ display: "flex", justifyContent: "center" }}>
                                            <button class="hover_dark main_menu_install_link_btn">타켓뷰 직접 설치하기</button>
                                          </a>
                                          <Link
                                            to={{
                                              pathname: `/shop/qna/form`,
                                                state: { 
                                                  qna_type_cd: "2106"
                                                }
                                            }} style={{ display: "flex", justifyContent: "center" }}>
                                            <button class="hover_dark main_menu_install_link_btn">타겟뷰 설치 요청하기</button>
                                          </Link>
                                        </div>
                                    : ""
                                  : ""
                              : ""
                            }
                          </>
                        }
                      </ul>
                    </div>
                  </div>
                </>
            }
            {/* {
              is_reduct_left_menu
              ? this.onClickReductionButton()
              : null            
            } */}

            {/* Mobile 헤더 -------------------------------------------------------------------------------------------------------------------------------------------- */}
            <div className='mobile_header'>
              {/* 로고 */}
              <Link to={this.state.redirectUrl}>
                <div className='logo_robomd' style={{width: '104px', height: '20px', margin: '20px 16px 20px 16px'}}></div>
              </Link>
              {/* 메뉴 햄버거 버튼 */}
              <div className='icon_hambuger' style={{margin: '18px 16px 18px 16px'}} onClick={this.mobileMenuClick}></div>
            </div>
            {/* Mobile side */}
            <div className='mobile_side'>
              <div className='mobile_side_header'>
                <div className='mobile_side_service'>
                  <div className='icon_arrow_left' style={{width: '24px', height: '24px'}} onClick={this.mobileMenuCloseClick}></div>
                  <a className='mobile_side_service_title'>{this.state.service_name}</a>
                </div>
                <div className='commonUserMenu' onClick={this.onClickMobileUserMenu}>
                  <div className='icon_user' style={{ width: '24px', height: '24px', marginRight: '4px' }}></div>
                  <div className='mobile_user_name' style={{ marginRight: '16px' }}>{this.state.userName}</div>
                </div>
              </div>
              {/* 업체 정보 영역 */}
              {/* </div><div className='mobile_side_corp_info_box' style={(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? {} : { display: 'none'}}> */}
              <div className='mobile_side_corp_info_box' style={(this.state.service_type == '01001' 
                                                                  || this.state.service_type == '01002' 
                                                                  || this.state.service_type == '01008' 
                                                                  || this.state.service_type == '01009') && this.state.shop_no != -1 ? {} : { display: 'none'}}>
                {/* 업체명 */}
                <div className='mobile_side_corp_name_box'>
                  {
                    this.state.solution_type_cd != null && this.state.solution_type_cd != "" ?
                      <div className={ "icon_small_solution_" + this.state.solution_type_cd} style={{ width: '24px', height: '24px', marginLeft: '8px'}}></div>
                    : ""
                  }
                  <div className='mobile_side_corp_name' style={{paddingLeft: '8px'}}>{this.state.shop_name}</div>
                </div>
                {/* 멀티샵 선택영역 */}
                <div className='mobile_side_shop_box'>
                  <div className='mobile_side_shop_select_box'>
                    <select className='mobile_side_shop_select' id="shop_no" name="shop_no" value={this.state.shop_no} onChange={(e) => this.handleShopChange(e)}>
                    { this.state.shops != null && this.state.shops.length > 0 
                      ?
                        this.state.shops.map((option, index) => (
                          <option key={index} value={option.shop_no}>{option.shop_name}</option>
                        ))
                      : <option value="" disabled>쇼핑몰 데이터 불러오기</option>
                    }
                    </select>
                    <div className='icon_select_down_arrow' style={{width: '16px', height: '16px'}}></div>
                  </div>
                </div>
              </div>
              {/* 배너 영역 */}
              <div className='mobile_side_banner_box' style={{margin: '20px 16px 20px 16px', borderRadius: '8px', overflow: 'hidden'}}>
                <Slider {...this.state.setting_info_banner_slider} arrows={false} className="info_banner-slider">
                  <div className="slick-category">
                    <div className='slider_lt'>
                      <NavLink to={{ pathname: '/shop/qna/form'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                      >
                        <div className='mobile_side_banner'>
                          <div className='banner_robomd_info_1' style={{width: '328px', height: '120px'}}></div>
                        </div>
                      </NavLink>
                    </div>
                  </div>	
                  {/* <div className="slick-category" style={{height: '120px'}}>
                    <div className='slider_lt'>
                      <div className='mobile_side_banner'>
                          <div className='banner_robomd_info_1' style={{width: '328px', height: '120px', background: 'red'}}></div>
                      </div>
                    </div>
                  </div>
                  <div className="slick-category" style={{height: '120px'}}>
                    <div className='slider_lt'>
                      <div className='mobile_side_banner'>
                          <div className='banner_robomd_info_1' style={{width: '328px', height: '120px', background: 'blue'}}></div>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
              <div style={{background : '#F7F7F7', paddingTop: '8px'}}>
                {/* 서비스 메뉴 영역 */}
                {/* <div className='mobile_side_main_menu_box' style={(this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? { height: 'calc(100vh - 356px)' } : { height: 'calc(100vh - 230px)' }}> */}
                <div className='mobile_side_main_menu_box' style={(this.state.service_type == '01001' 
                                                                    || this.state.service_type == '01002' 
                                                                    || this.state.service_type == '01008' 
                                                                    || this.state.service_type == '01009') && this.state.shop_no != -1 ? { height: 'calc(100vh - 356px)' } : { height: 'calc(100vh - 230px)' }}>
                  <ul className='mobile_side_main_menu'>
                    {/* 서비스명 */}
                    <li className='mobile_side_main_menu_service_list'>
                      {/* 서비스 상세 메뉴 */}
                      <ul className='mobile_side_service_menu_class'>
                        {
                          this.state.serviceList != null && this.state.serviceList.length > 0
                          ?
                            this.state.serviceList.map((service, idx) => (
                              service.resultMenuList != null && service.resultMenuList.length > 0
                              ?
                                service.resultMenuList.map((menu, idx) => (
                                  <>
                                      {/* 2 depts data start : main_menu*/}
                                      <li className='mobile_side_service_menu_m_class'>
                                        <ul className=''>
                                          <div className='mobile_side_service_menu_m_title'>
                                            {menu.menu_nm}
                                            {/* <a className='mobile_side_service_menu_m_title'>{menu.menu_nm}</a> */}
                                          </div>
                                          {/* 2단 : 중메뉴명 */}
                                          {
                                            menu.subMenu.map((sub, sub_idx) => (
                                              <li className={'mobile_side_service_menu_p_box'}>
                                                <NavLink 
                                                  style={{display: 'flex', alignItems: 'center'}}
                                                  to={sub.menu_url}
                                                  onClick={
                                                  // <a href={props.menu_url} style={{position:"absolute", width:"100%", height:"40px", padding:"8px 45px", borderRadius:"10px", top: "5px", fontWeight: "normal"}} onClick={
                                                  (e) => {
                                                    this.setState({
                                                      currentLocationHref: window.location.href,
                                                    });
                                                    //this.mobileMenuClick(e);
                                                    window.scrollTo(0, 0);
                                                    this.handleServiceChange(service.service_type, "N");
                                                  }
                                                }>
                                                {
                                                  // 메뉴 심볼
                                                  sub.menu_simbol != null && sub.menu_simbol != "" ?
                                                    <div className={sub.menu_simbol} style={{width: '56px', height: '56px'}}></div>
                                                  : ""
                                                }
                                                {
                                                  <div className='mobile_side_service_menu_p_detail'>
                                                    {
                                                      <>
                                                        <div className='mobile_side_service_menu_p_title_box'>
                                                          <div className='mobile_side_service_menu_p_title'>{sub.menu_nm}</div>
                                                          <div className='mobile_side_service_menu_p_badge'>
                                                            {
                                                              <>
                                                                {
                                                                  sub.menu_badge_list != null
                                                                  ?
                                                                    sub.menu_badge_list.split(",").map((badge) => (
                                                                      <div className={badge} style={{marginLeft: '4px'}}></div>
                                                                    ))
                                                                  : ""
                                                                }
                                                              </>
                                                            }
                                                          </div>
                                                        </div>
                                                        {
                                                          sub.menu_desc1 != null && sub.menu_desc1 != ''
                                                          ?
                                                            <div className='mobile_side_service_menu_p_desc_1' style={ sub.menu_desc2 != null && sub.menu_desc2 ? {marginTop : '3px'} : {}}>{sub.menu_desc1}</div>
                                                          :''
                                                        }
                                                        {
                                                          sub.menu_desc2 != null && sub.menu_desc2 != ''
                                                          ?
                                                            <div className='mobile_side_service_menu_p_desc_2'>{sub.menu_desc2}</div>
                                                          :''
                                                        }
                                                      </>
                                                    }
                                                  </div>
                                                }
                                                </NavLink>
                                              </li>
                                            ))
                                          }
                                        </ul>
                                      </li>
                                  </>
                                ))
                              : ""
                            ))
                          : ""
                        }
                        </ul>
                    </li>
                    {/* 가이드 메뉴 영역 */}
                    {
                      this.state.serviceList != null && this.state.serviceList.length > 0
                      ?
                        this.state.service_type === "01001"
                        ?
                          <div className='mobile_guide_menu'>
                            {
                              this.state.solution_type_cd === "09900"
                              ?
                                // AI디자인 (비연동형 배너) 가이드
                                <li>
                                  <a href='https://iclave.notion.site/AI-0e28c3650d9c4784a2ba9f543102be9f'
                                    target='_blank'
                                    style={{ display: "flex", justifyContent: "center" }}>
                                    <button class="hover_dark main_menu_install_link_btn">AI디자인 사용가이드</button>
                                  </a>
                                </li> 
                              :
                                <li>
                                  {/* AI배너 사용가이드 */}
                                  {
                                    // 해당 서비스를 활성화 하지 않는 경우
                                    this.state.shopActiveServiceCnt <= 0
                                    ?
                                      <a href='https://iclave.notion.site/RoboMD-5c0553b3fcc841edae67146f26889a74'
                                        target='_blank'
                                        style={{ display: "flex", justifyContent: "center" }}>
                                        <button class="hover_dark main_menu_install_link_btn">RoboMD 공통 사용가이드</button>
                                      </a>
                                    :
                                      <>
                                        <a href='https://iclave.notion.site/AI-013b37bb75594506874804f76b920b40'
                                          target='_blank'
                                          style={{ display: "flex", justifyContent: "center" }}>
                                          <button class="hover_dark main_menu_install_link_btn">AI배너 사용가이드</button>
                                        </a>
                                        {
                                          // 설치된 배너가 없는 경우 
                                          this.state.installBannerCnt <= 0
                                          ?
                                              <Link
                                                to={{
                                                  pathname: `/shop/qna/form`,
                                                    state: { 
                                                      qna_type_cd: "2105"
                                                    }
                                                }} style={{ display: "flex", justifyContent: "center" }}>
                                                <button class="hover_dark main_menu_install_link_btn">쇼핑몰에 설치 요청하기</button>
                                              </Link>
                                          : ""
                                        }
                                      </>
                                  }
                                </li>
                            }
                          </div>
                        :
                        this.state.service_type === "01002"
                        ?
                          <div className='mobile_guide_menu'>
                            <li>
                              {/* 상품자동진열 가이드 */}
                              {
                                // 해당 서비스를 활성화 하지 않는 경우
                                this.state.shopActiveServiceCnt <= 0
                                ?
                                  <a href='https://iclave.notion.site/RoboMD-5c0553b3fcc841edae67146f26889a74'
                                    target='_blank'
                                    style={{ display: "flex", justifyContent: "center" }}>
                                    <button class="hover_dark main_menu_install_link_btn">RoboMD 공통 사용가이드</button>
                                  </a>
                                :
                                  <>
                                    <a href='https://iclave.notion.site/e70c7030de684de1b64e2a68ca672c30'
                                      target='_blank'
                                      style={{ display: "flex", justifyContent: "center" }}>
                                      <button class="hover_dark main_menu_install_link_btn">상품자동진열 가이드</button>
                                    </a>
                                    {/* {
                                      this.state.installBannerCnt <= 0
                                      ?
                                        <Link
                                          to={{
                                            pathname: `/shop/qna/form`,
                                              state: {
                                                qna_type_cd: "2104"
                                              }
                                          }} style={{ display: "flex", justifyContent: "center" }}>
                                          <button class="hover_dark main_menu_install_link_btn">기간 연장하기</button>
                                        </Link>
                                      : ""
                                    } */}
                                  </>
                              }
                            </li>
                          </div>
                        : this.state.service_type === "01005"
                        ?
                          <div className='mobile_guide_menu'>
                            <Link
                              to={{
                                pathname: `/shop/qna/form`,
                                  state: { 
                                    qna_type_cd: "2107"
                                  }
                              }} style={{ display: "flex", justifyContent: "center" }}>
                              <button class="hover_dark main_menu_install_link_btn">사이트 수집 요청하기</button>
                            </Link>
                          </div>
                        : this.state.service_type === "01010"
                        ?
                          <div className='mobile_guide_menu'>
                            <Link
                              to={{
                                pathname: `/shop/qna/form`,
                                  state: { 
                                    qna_type_cd: "2108"
                                  }
                              }} style={{ display: "flex", justifyContent: "center" }}>
                              <button class="hover_dark main_menu_install_link_btn">키워드 수집 요청하기</button>
                            </Link>
                          </div>
                        : this.state.service_type === "01009"
                          ? this.state.target_view_script_install_yn == "N"
                            ? this.state.solution_type_cd == "09001" || (this.state.solution_type_cd == null && this.state.shop_no < 1000)
                              ? <div className='mobile_guide_menu'>
                                  <a onClick={() => this.targetViewInstallPopupCafe24(true)}
                                    style={{ display: "flex", justifyContent: "center" }}>
                                    <button class="hover_dark main_menu_install_link_btn">타켓뷰 설치하기</button>
                                  </a>
                                </div>
                              : <div className='mobile_guide_menu'>
                                  <a onClick={() => this.targetViewInstallPopup(true)}
                                    style={{ display: "flex", justifyContent: "center" }}>
                                    <button class="hover_dark main_menu_install_link_btn">타켓뷰 직접 설치하기</button>
                                  </a>
                                  <Link
                                    to={{
                                      pathname: `/shop/qna/form`,
                                        state: { 
                                          qna_type_cd: "2106"
                                        }
                                    }} style={{ display: "flex", justifyContent: "center" }}>
                                    <button class="hover_dark main_menu_install_link_btn">타겟뷰 설치 요청하기</button>
                                  </Link>
                                </div>
                            : ""
                          : ""
                      : ""
                    }
                  </ul>
                </div>
                {/* 로그아웃 영역 */}
                {/* <div className='mobile_logout_box'>로그아웃</div> */}
              </div>
            </div>
            {/* Mobile user menu */}
            <div className='mobile_side_user'>
              <div className='mobile_side_user_header'>
                <div className='mobile_side_service_title' style={{marginLeft: '16px'}}>사용자 설정</div>
                <div className='icon_close' style={{width: '24px', height: '24px', marginRight: '12px'}} onClick={this.onClickMobileUserMenu}></div>
              </div>
              {/* 배너 영역 */}
              <div className='mobile_side_banner_box'>
                <Slider {...this.state.setting_info_banner_slider} arrows={false} className="info_banner-slider">
                  <div className="slick-category">
                    <div className='slider_lt'>
                      <NavLink to={{ pathname: '/shop/qna/form'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                      >
                        <div className='mobile_side_banner'>
                          <div className='banner_robomd_info_1' style={{width: '328px', height: '120px'}}></div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </Slider>
              </div>
              <div style={{background : '#F7F7F7', marginTop: '8px', paddingTop: '8px'}}>
                {/* 서비스 메뉴 영역 */}
                <div className='mobile_side_user_menu_box'>
                  <ul className='mobile_side_user_menu'>
                    {/* 서비스명 */}
                    <li>
                      <NavLink to={{ pathname: '/shop/myInfo'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                                  className='mobile_side_user_menu_list'
                                  //onClick={this.onClickMobileUserMenu}
                      >
                        <div className='mobile_side_user_menu_title'>나의 정보 변경</div>
                        <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={{ pathname: '/shop/pwdMod'
                                    //search: '?step=3&finish_yn=y',
                                  }}
                                  className='mobile_side_user_menu_list'
                                  //onClick={this.onClickMobileUserMenu}
                      >
                        <div className='mobile_side_user_menu_title'>비밀번호 변경</div>
                        <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                      </NavLink>
                    </li>
                    {
                      // (this.state.role_cls == '2204' || this.state.role_cls == '2207' || this.state.role_cls == '2211' || this.state.role_cls == '2214') && this.state.shop_no != -1 ? (
                      (this.state.service_type == '01001' 
                        || this.state.service_type == '01002' 
                        || this.state.service_type == '01008' 
                        || this.state.service_type == '01009') && this.state.shop_no != -1 ? (
                        <>
                          <li>
                            <NavLink to={{ pathname: '/shop/solutionStoreAdd'
                                          //search: '?step=3&finish_yn=y',
                                        }} className='mobile_side_user_menu_list'
                                        //onClick={this.onClickMobileUserMenu}
                            >
                              <div className='mobile_side_user_menu_title'>쇼핑몰 데이터 불러오기</div>
                              <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={{ pathname: '/shop/serviceShopList'
                                          //search: '?step=3&finish_yn=y',
                                        }} className='mobile_side_user_menu_list'
                                        //onClick={this.onClickMobileUserMenu}
                            >
                              <div className='mobile_side_user_menu_title'>쇼핑몰 목록</div>
                              <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to={{ pathname: '/shop/orderAmountExpect'
                                          //search: '?step=3&finish_yn=y',
                                        }} className='mobile_side_user_menu_list'
                                        //onClick={this.onClickMobileUserMenu}
                            >
                              <div className='mobile_side_user_menu_title'>멤버십 관리</div>
                              <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink to={{ pathname: '/shop/order'
                                          //search: '?step=3&finish_yn=y',
                                        }} 
                                        className='mobile_side_user_menu_list'
                                        //onClick={this.onClickMobileUserMenu}
                            >
                              <div className='mobile_side_user_menu_title'>결제수단 관리</div>
                              <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={{ pathname: '/shop/order/list'
                                          //search: '?step=3&finish_yn=y',
                                        }} 
                                        className='mobile_side_user_menu_list'
                                        //onClick={this.onClickMobileUserMenu}
                            >
                              <div className='mobile_side_user_menu_title'>결제 내역</div>
                              <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                            </NavLink>
                          </li>
                        </>
                      ) : ""
                    }
                    <li>
                      <NavLink to={{ pathname: '/shop/notice/list'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                                  className='mobile_side_user_menu_list'
                                  //onClick={this.onClickMobileUserMenu}
                      >
                        <div className='mobile_side_user_menu_title'>공지사항</div>
                        <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={{ pathname: '/shop/qna/list'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                                  className='mobile_side_user_menu_list'
                                  //onClick={this.onClickMobileUserMenu}
                      >
                        <div className='mobile_side_user_menu_title'>나의 문의</div>
                        <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={{ pathname: '/shop/qna/form'
                                    //search: '?step=3&finish_yn=y',
                                  }} 
                                  className='mobile_side_user_menu_list'
                                  //onClick={this.onClickMobileUserMenu}
                      >
                        <div className='mobile_side_user_menu_title'>문의 하기</div>
                        <div className='icon_arrow_right' style={{width: '18px', height: '18px'}}></div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* 로그아웃 영역 */}
              <NavLink to={{ pathname: '/logout' }} >
                <div className='mobile_logout_box'>로그아웃</div>
              </NavLink>
            </div>
            {/* Mobile Footer */}
            <div className='mobile_footer'>
              <ul className='mobile_service_menu_list'>
                {
                  this.state.topServiceList != null && this.state.topServiceList.length > 0
                  ?
                  this.state.topServiceList.map((service, idx) => (
                    <>
                      {/* 서비스 메뉴 1 */}
                      <li id={'mobile_menu_'+service.service_type} className={`mobile_service_menu_item ${service.service_type == this.state.service_type ? 'active' : ''}`} onClick={(e) => this.handleServiceChange(service.service_type, "Y")}>
                        <div className='mobile_service_menu_icon'>
                          <div className={service.service_icon + ` ${ service.service_type == this.state.service_type ? 'active' : ''}`} style={{width: '24px', height: '24px'}}></div>
                        </div>
                        <div className={`mobile_service_menu_title ${service.service_type == this.state.service_type ? 'active' : ''}`}>{service.service_name}</div>
                      </li>
                    </>
                  ))
                  : ''
                }
              </ul>
            </div>
          </div>
          
          {this.state.is_blank_popup_open == true ? (
              <div
                className="modal_v3_back"
                id="common_blank_modal"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onClickBlankBannerClose()}
                  ></div>
                  <div className="modal_v3_popup"
                        style={{
                          width: 'auto',
                          maxWidth: '95vw', 
                          height: 'auto',
                          padding: '0px',
                        }}
                  >
                    <div
                            className="modal_v3_content"
                            style={{
                              height: 'auto',
                              marginTop: '0px',
                              marginBottom: "-8px"
                            }}
                            dangerouslySetInnerHTML = {{__html: this.state.blank_popup_content}}
                          >
                    </div>
                  </div>
                </div>
              </div>
            ) : ""
          }
          {/* 팝업 영역 ----------------------------------------------------------------------------------------------------------------------------------------------- */}
          {this.state.is_sample_banner_popup_open == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onClickSampleBannerClose()}
                  ></div>
                  <div className="modal_v3_popup">
                    <div className="modal_v3_title">
                      샘플 배너 만들기
                      {/* ({this.state.shops.length}) */}
                    </div>
                    {Number(this.state.sample_banner_step) == 1 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '80px' }}>
                          로보MD가 자동으로 만들어드리는 배너입니다.
                          <br />
                          고객님의 사진으로 샘플 배너를 만들어 보세요.
                        </div>
                        <div
                          style={{
                            margin: '20px auto',
                            width: '50%',
                            flex: '1',
                          }}
                        >
                          <Slider
                            {...this.state.setting_sample_banner_slider}
                            arrows={false}
                            style={{
                              width: '100%',
                              height: '100%',
                              textAlign: 'center',
                            }}
                            className="sample-banner-slider"
                          >
                            {this.state.banner_templa_list.map((obj, idx) => (
                              <div
                                key={idx}
                                style={{ margin: 'auto', height: '100%' }}
                              >
                                <div
                                  style={{
                                    backgroundImage: 'url(' + obj.val2 + ')',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    width: '100%',
                                    height: '100%',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                ></div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                        <div
                          style={{
                            margin: '30px auto 20px',
                            width: '200px',
                            height: '60px',
                          }}
                          onClick={(e) => this.onClickSampleBannerNextStep()}
                        >
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '100%',
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                          >
                            샘플 배너 만들기
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 2 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '50px' }}>
                          샘플 배너를 만들 사진을 첨부해 주세요.
                        </div>
                        <div
                          style={{
                            margin: '20px auto',
                            width: '50%',
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              flex: '1',
                              border: '1px solid #c4c4c4',
                              borderStyle: 'dotted',
                            }}
                          >
                            <MyDropzone
                              attachImage={this.attachImage}
                              this={this}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 3 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '70px' }}>
                          샘플 배너를 만들고 있습니다.
                          <br />
                          잠시만 기다려 주세요.
                        </div>
                        <div
                          style={{
                            margin: 'auto',
                            width: '50%',
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: 'url(' + round_loading_bar + ')',
                              backgroundSize: '45%',
                              backgroundPositionX: 'center',
                              backgroundPositionY: '30%',
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              height: '100%',
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {Number(this.state.sample_banner_step) == 4 ? (
                      <div
                        className="modal_v3_content"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '95%',
                        }}
                      >
                        <div style={{ height: '70px' }}>
                          마음에 드시는 배너가 있으세요?
                          <br />
                          배너 목록에 저장 후 나중에 수정하실 수 있습니다.
                        </div>
                        <div
                          id="sample_banner_iframe"
                          style={{
                            margin: 'auto',
                            width: '80%',
                            flex: '1',
                            display: 'flex',
                          }}
                        >
                          <object
                            class="robomd_banner_p"
                            type="text/html"
                            data={
                              '/admin_banner.html?event_seq=' +
                              this.state.sample_banner_event_seq +
                              '&platform_type=p&mirror_type=18002'
                            }
                            style={{
                              width: '65%',
                              height: '90%',
                              background: 'white',
                              alignSelf: 'center',
                            }}
                          ></object>
                          <object
                            class="robomd_banner_p"
                            type="text/html"
                            data={
                              '/admin_banner.html?event_seq=' +
                              this.state.sample_banner_event_seq +
                              '&platform_type=m&mirror_type=18002'
                            }
                            style={{
                              width: '35%',
                              height: '90%',
                              background: 'white',
                              alignSelf: 'center',
                              marginLeft: '10px',
                            }}
                          ></object>
                        </div>
                        <div
                          className="sample_banner_btn_area"
                          style={{
                            margin: '30px auto 20px',
                            width: '70%',
                            maxWidth: 'calc(600px + 16%)',
                            height: '60px',
                            display: 'flex',
                          }}
                        >
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '50%',
                              cursor: 'pointer',
                              fontSize: 'min(1vw, 16px)',
                              background: 'white',
                              border: '1px solid #10CFC9',
                              color: '#10CFC9',
                              boxShadow: 'none',
                            }}
                            onClick={(e) => this.newSampleEvent()}
                          >
                            새 배너 만들기
                          </div>
                          <div
                            className="btn-auto-b sample_banner_btn"
                            style={{
                              height: '100%',
                              lineHeight: '60px',
                              width: '50%',
                              cursor: 'pointer',
                              fontSize: 'min(1vw, 16px)',
                              background: 'white',
                              border: '1px solid #10CFC9',
                              color: '#10CFC9',
                              boxShadow: 'none',
                              marginLeft: '8%',
                            }}
                            onClick={(e) => this.createAnotherSampleEvent()}
                          >
                            다른 샘플 보기
                          </div>
                          {this.state.shops.length >= 1 ? (
                            <div
                              className="btn-auto-b sample_banner_btn"
                              style={{
                                height: '100%',
                                lineHeight: '60px',
                                width: '50%',
                                cursor: 'pointer',
                                fontSize: 'min(1vw, 16px)',
                                marginLeft: '8%',
                              }}
                              onClick={(e) => this.saveSampleEvent()}
                            >
                              배너 목록에 저장하기
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            { // 배너 쇼핑몰 연동시 띄워줄 modal
            this.state.is_add_shop_banner_button_click == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onChangeIsAddShopBannerButtonClick(false)}
                  ></div>
                  <div
                    className="modal_v3_popup"
                    style={{
                      width: '760px',
                      minWidth: '760px',
                      height: 'auto',
                      maxHeight: '70vh',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      <img
                        src={shop_store_icon}
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </div>
                    <br />
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      쇼핑몰이 성공적으로 연동되었습니다!
                    </div>
                    <div
                      className="modal_v3_content"
                      style={{
                        height: '95%',
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                    >
                      로보MD와 함께 하는 쇼핑몰 운영 자동화에 한 발 가까워지신 것을 축하드려요. 😀
                      <br />
                      {this.state.end_date_format}까지 14일 동안 배너 서비스를
                      무료로 이용하실 수 있어요.
                      <br />
                      <br />
                      무료체험 기간 동안 날마다 이 시간대에 배너를 1개씩 만들어드리도록 자동 설정까지 도와드렸어요.
                      <br />
                      내일부터 배너가 만들어지면 카카오톡으로 알림 메세지를 보내드릴게요.
                      <br />
                      *자동 배너 설정 메뉴에서 원하시는 대로 설정을 변경하실 수 있습니다.
                      <br />
                      <br />
                      이제 가지고 계신 상품사진으로 샘플 배너를 만들어 보시겠어요?
                      <br />
                      <div
                        className="sample_banner_btn_area"
                        style={{
                          margin: '40px auto 20px',
                          width: '70%',
                          maxWidth: 'calc(600px + 16%)',
                          height: '60px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '50%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            background: 'white',
                            border: '1px solid #10CFC9',
                            color: '#10CFC9',
                            boxShadow: 'none',
                          }}
                          onClick={(e) =>
                            this.onChangeIsAddShopBannerButtonClick(false)
                          }
                        >
                          다음에 할게요.
                        </div>
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '50%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            boxShadow: 'none',
                            marginLeft: '8%',
                          }}
                          onClick={(e) => {
                            this.onChangeIsAddShopBannerButtonClick(false);
                            this.onClickSampleBannerOpen();
                          }}
                        >
                          샘플 배너 만들기
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            { // 진열 쇼핑몰 연동시 보여줄 modal
            this.state.is_add_shop_exhibition_button_click == true ? (
              <div
                className="modal_v3_back"
                style={
                  {
                    // display:"none"
                  }
                }
              >
                <div className="modal_v3_popup_area">
                  <div
                    className="modal_v3_btn_close"
                    onClick={(e) => this.onChangeIsAddShopExhibitionButtonClick(false)}
                  ></div>
                  <div
                    className="modal_v3_popup"
                    style={{
                      width: '760px',
                      minWidth: '760px',
                      height: 'auto',
                      maxHeight: '70vh',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      <img
                        src={shop_store_icon}
                        style={{ width: '100px', height: 'auto' }}
                      />
                    </div>
                    <br />
                    <div
                      className="modal_v3_title"
                      style={{ textAlign: 'center', fontSize: '25px' }}
                    >
                      쇼핑몰이 성공적으로 연동되었습니다!
                    </div>
                    <div
                      className="modal_v3_content"
                      style={{
                        height: '95%',
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                    >
                      로보MD와 함께 하는 쇼핑몰 운영 자동화에 한 발 가까워지신 것을 축하드려요. 😀
                      <br />
                      {this.state.end_date_format}까지 14일 동안 진열 서비스를 무료로 이용하실 수 있어요.
                      <br />
                      <br />
                      진열 서비스는 연동하신 쇼핑몰에서 상품 정보를 불러온 후에 세부 내용을 보실 수 있어요.
                      상품 정보를 불러오는 데에는 5시간 정도 걸려요. 완료되면 카카오톡으로 알림 메시지를 보내드립니다.
                      <br />
                      <br />
                      저희가 무료체험 기간 동안 이틀에 한번씩 베스트 상품을 선정하도록 나열과 롤링을 각각 자동 설정해드렸는데요,
                      상품 정보 불러오기가 완료되면 진열 목록에서 원하시는 대로 설정을 변경해 보세요!
                      <br />
                      <div
                        className="sample_banner_btn_area"
                        style={{
                          margin: '40px auto 20px',
                          width: '70%',
                          maxWidth: 'calc(600px + 16%)',
                          height: '60px',
                          display: 'flex',
                        }}
                      >
                        <div
                          className="btn-auto-b sample_banner_btn"
                          style={{
                            height: '100%',
                            lineHeight: '60px',
                            width: '100%',
                            cursor: 'pointer',
                            fontSize: 'min(1vw, 16px)',
                            boxShadow: 'none',
                            marginLeft: '8%',
                          }}
                          onClick={(e) => {
                            this.onChangeIsAddShopExhibitionButtonClick(false);
                          }}
                        >
                          알겠습니다. 기대돼요!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {/* {
              is_reduct_left_menu
              ? this.onClickReductionButton()
              : null            
            } */}
            {/* 확인 취소 다이얼로그 */
                  (this.state.confirm_dialog_show_state === true)
                  ? <ConfirmDialogV2
                      content={this.state.confirm_dialog_content}
                      button_list={this.state.confirm_dialog_button_list}
                      popup_close={() => {
                          const window_scrollTop = $(window).scrollTop();
                          this.setState({confirm_dialog_show_state: false});
                          // 스크롤 고정 해제
                          StyleUtil.clearPopupNoneScroll(window_scrollTop);
                      }}
                  />
                  : ''
            }
            {
              <DownloadManager></DownloadManager>
            }

        {
          this.state.is_open_target_view_install_popup
          ? 
            <PopupFrameV2
            maxWidth={"600px"}
            padding={"40px"}
            onClose={() => this.targetViewInstallPopup(false)}
            >
                <div className={[styles.title, styles.popup_frame_title].join(' ')}>타켓뷰 직접 설치하기</div>
                <div className={styles.content}>
                  <textarea style={{width:"100%", minHeight:"100px", border: "1px solid #333333"}} readOnly>
                        {`<script src="https://robomd.io/js/robomd_cookie_duration_time.js?token=${this.state.user_token}"></script>`}
                  </textarea>
                </div>
                <div className={styles.content}>
                  <div class="alert_info ">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"></path>
                    </svg>
                    <span style={{fontSize: "12px"}}>코드를 복사 후 쇼핑몰 코드 편집기에 붙여넣기 해주세요.</span>
                  </div>
                </div>
                <div style={{display:"flex", justifyContent: "center", marginTop: "30px"}}>
                    <Button width={"150px"} onClick={() => {
                        window.open("https://iclave.notion.site/RoboMD-ec933dff531e4f818597915aaa0d686c?pvs=25");
                      }
                    }>상세 가이드 보기</Button>
                    <div style={{marginLeft: "20px"}}></div>
                    <Button onClick={() => this.targetViewInstallPopup(false)}>닫기</Button>
                </div>
            </PopupFrameV2>
          : ""
        }
        {
          this.state.is_open_target_view_install_popup_cafe24
          ? 
            <TitleContentDialogV2
                title={"타겟뷰 설치하기"}
                content={"'"+this.state.shop_name+"'에 타겟 분석을 위한 스크립트를 설치 하시겠습니까?"}
                button_list={[{
                    title : "예",
                    event_method : () => { this.insertProductDetailDurationScriptTag() }
                },{
                    title : "아니요",
                    event_method : () => {this.targetViewInstallPopupCafe24(false)}
                }]}
                popup_close={() => this.targetViewInstallPopupCafe24(false)}
            />
          : ""
        }
        {
          this.state.is_open_title_content_dialog
          ? <TitleContentDialogV2
              title={this.state.title_content_dialog_title}
              content={this.state.title_content_dialog_content}
              button_list={[{
                  title : "예",
                  event_method : () => { this.titleContentDialog(false, "", "", true)}
              },{
                  title : "아니요",
                  event_method : () => {this.titleContentDialog(false, "", "")}
              }]}
              popup_close={() => this.titleContentDialog(false, "", "")}
            />
          : ""  
        }  
        {
          this.state.is_open_basic_dialog
          ? <BasicDialogV2
            content={this.state.basic_dialog_content}
            button_list={[{
                title : "확인",
                event_method : () => { this.basicDialog(false,"") }
            }]}
          />
          : ""
        }
        {
          this.state.is_open_warning_dialog
          ? <WarningDialog
                content={this.state.warning_dialog_content}
                button_list={[{
                    title : "확인",
                    event_method : () => { this.warningDialog(false,"") }
                }]}
                popup_close={() => this.warningDialog(false,"")}
            />
          : ""       
        }
        {
          (this.state.standby_yn === "N" 
            && cookie_value_standby_yn !== "Y"
            && window.location.href.indexOf('/shop/rp/') >= 0
            // && window.location.href.indexOf('/shop/rp/main') >= 0
            // && window.location.href.indexOf('/shop/rp/event/cafe24List') >= 0
            // && window.location.href.indexOf('/shop/rp/event/product/cafe24List') >= 0
            // && window.location.href.indexOf('/shop/rp/event/list') >= 0
            // && window.location.href.indexOf('/shop/rp/event/product/list') >= 0
          )
              ? <TitleContentDialogV2
                  title={ "상품 데이터 연동중 입니다." }
                  content={ "연동전에는 상품과 분석데이터 자동화가<br/>정확하지 않습니다.<br/>"
                              + "연동이 끝난 후 자동설정해주세요.<br/>"
                              + "보유하신 데이터에 따라<br/>"
                              + "10분에서 ~ 최대 1일까지 소요될 수 있습니다." }
                  button_list={[
                      {
                          title: "기능 둘러보기",
                          type: "1",
                          event_method: () => {
                              this.setGaEvent("진열 추가 클릭");
                              this.props.history.push(
                                  {
                                      pathname: `/shop/rp/event/product/list`,
                                  }	
                              )
                              //팝업 닫기
                              this.setState({standby_yn: "Y"});
                              StyleUtil.clearPopupNoneScroll(window_scrollTop);
                              setCookie('cookie_value_standby_yn','Y');
                          }
                      },
                      {
                          title: "닫기",
                          type: "0",
                          event_method: () => {
                              //팝업 닫기
                              this.setState({standby_yn: "Y"});
                              StyleUtil.clearPopupNoneScroll(window_scrollTop);
                              setCookie('cookie_value_standby_yn','Y');
                          }
                      }
                  ]}
                  popup_close={() => {
                      this.setState({standby_yn: "Y"});
                      StyleUtil.clearPopupNoneScroll(window_scrollTop);
                      setCookie('cookie_value_standby_yn','Y');
                  }}
              />
              : ''
        }
        </>
        );
    } else {
      if (window.location.href.indexOf('/common/curation') >= 0) {
        return (
          <div id="curation_header" style={{
            display:"flex",
            justifyContent:"center",
            position: "fixed",
            top: 0,
            width: "100vw",
            height: "60px",
            background: "#FFFFFF", 
            boxShadow: "0px 2px 5px 1px rgba(0, 0, 0, 0.06)",
            zIndex: 1,
          }}>
            <div id="user_curation_header" >
              <div id="curation_logo_area">
                <div id="curation_robomd_logo">
                  <a href={"https://maisonys.com/index_sblanc.html"} target="_blank">
                    <img
                      src={pc_maison_logo}
                      alt=""
                    />
                  </a>
                </div>
                <Link to={
                            "/common/curation/list/"+(StringUtil.getUrlParams("service_uid") != null ? StringUtil.getUrlParams("service_uid")  : window.location.pathname.split("/").length >= 5 && window.location.pathname.split("/")[4] != null && window.location.pathname.split("/")[4] != "" ? window.location.pathname.split("/")[4] : "")
                            +"?cookie_robotmd_user="+(StringUtil.getUrlParams("cookie_robotmd_user") != null ? StringUtil.getUrlParams("cookie_robotmd_user")  : "")
                            +"&member_id="+(StringUtil.getUrlParams("member_id") != null ? StringUtil.getUrlParams("member_id")  : "")
                            +"&guest_id="+(StringUtil.getUrlParams("guest_id") != null ? StringUtil.getUrlParams("guest_id")  : "")
                          }>
                  <div id="curation_logo" style={{cursor:"pointer"}}>큐레이션 리스트</div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else {
        return <></>;
      }
    }
  }
}
