import React, { createRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { loadList, saveList } from "./slice/ExcludeProductsSlice";
import axios, { subscribeShopDataSyncState } from '../modules/api';
import {http, setIsErrorAlert} from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import "react-datepicker/dist/react-datepicker.css";
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import SelectBoxV2, {getSelectBoxSelectIdx} from '../modules/common/SelectBoxV2';
import CheckBox from '../modules/common/CheckBox';
import RadioButton from '../modules/common/RadioButton';
import PopupFrame from '../modules/popup/PopupFrame';
import * as StringUtil from '../modules/utils/StringUtil';
import * as CookieUtil from '../modules/utils/CookieUtil';
import Tooltip from '../modules/common/Tooltip'

import plus_img from '../../resources/img/plus.svg';
import default_plus_img from '../../resources/img/default_plus.svg';
import default_minus_img from '../../resources/img/default_minus.svg';
import x_img from '../../resources/img/x.svg';
import arrows_clockwise from '../../resources/img/arrows_clockwise.svg';

import round_loading_bar from '../../resources/img/round_loading_bar.gif';

import { List, arrayMove } from 'react-movable';

import ReactGA, { send } from 'react-ga';
import SearchProductPopup from '../modules/popup/SearchProductPopup';
import TextInput from '../modules/common/TextInput';
import ExcludeProductTableMgmt from './component/ExcludeProductTableMgmt';
import EssentialProductTableMgmt from './component/EssentialProductTableMgmt';

import MultiSelect from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';
import moment from 'moment';

const animatedComponents = makeAnimated();

ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

let is_test_use = "";
if (document.domain.indexOf("127.0.0.1") >= 0) {
    is_test_use = "&is_test_use=Y"
}

let this_location;

// 페이지를 생성하는 경우
let create_rp = false;

// 제목 사용안함 선택 시, 이전 제목값 저장
let display_name_cd = 33098;
let display_name = "";

let sub_display_name_cd = 33098;
let sub_display_name = "";

let user_id = sessionStorage.getItem("user_id"); // 사용자 ID
let expand_timer = {}; // 왼쪽 영역 접기 펼치기 동작 누적 방지
let right_reset = false; // 오른쪽 영역 초기화 상태
let load_upper_num = 0; // upper_code(왼쪽 영역) 선택지 전부 불러왔는지 확인

/*------ 상품 선정수 기본 최대값 ------*/
let nayeol_event_total_item_select_range = 100;
let rolling_event_total_item_select_range = 20;
let detail_page_big_event_total_item_select_range = 10;
let detail_page_event_total_item_select_range = 30;

const widgetListExpandRef = React.createRef();
const widgetListExpandCheckRef = React.createRef();
const rpTitleRef = React.createRef();
const highlightDesign = React.createRef();
const highlightRandom = React.createRef();

class RpProductList_install extends React.Component {
    constructor(props) {
		super(props);
		this.state = {

            syncState : "0",
            syncDateFormat : "",
            refresh_data_show_state : false,
            highlightType: "",

            /*-------------- 왼쪽 영역 시작 --------------*/
            is_left_load_first_setting : false, // 왼쪽영역 로딩여부(update에서 돌리기 위함)
            rolling_event_mobile_view_cnt : "2", // 롤링의 고정 모바일 가로 상품 개수

            install_yn : "", // 값이 없는 경우(진열 추가 페이지에서 저장전인 경우 / 값이 들어오면 저장 후)
            // 저장 값
            user_seq: "",
            shop_no: "",
            iframe_page_id: "", // 위치
            cafe24_main_display_group : "", // 선택된 카페24 메인 진열 카테고리 : 사용 안하는 값
            iframe_html_id: "", // 페이지 번호
            // => 비어있으면 왼쪽 영역 저장 전.. 저장 후 해당 값을 받아와서 적용

            image_type_cd: "", // 이미지 타입 코드
            iframe_html_name: "", // 진열 제목
            display_name_use_yn : "", // 제목 사용 여부
            display_name: "", // 제목
            display_name_cd: "", // 제목 코드
            sub_display_name_use_yn : "", // 제목 사용 여부
            sub_display_name: "", // 제목
            sub_display_name_cd: "", // 소제목 코드
            display_type: "", // 진열 방식
            link_open_type: "48002", // 링크 열기 방식
            event_cycle: "", // 자동 진열 일정관리 주기
            event_time: "", // 자동 진열 일정관리 시간
            event_type_cd: "", // 디자인
            event_item_price_show_type : "", // 가격 타입
            event_category_select_type: "34002", // 상품 카테고리 종류
            event_category_list: "", // 상품 카테고리
            event_view_cnt: "", // pc 가로 상품 개수
            event_mobile_view_cnt: "", // mobile 가로 상품 개수
            platform_type: "", // pc | modile | pc + mobile (P / M / C)
            auto_rolling_yn: "", // 자동 롤링 기능
            transition_mode: "", // 롤링 방식
            transition_speed: "", // 롤링 속도
            transition_pause: "", // 롤링 이동 텀
            product_order_type : "51001", // 진열 순서 방식
            auto_create_yn: "Y", // 자동 진열 일정관리

            list_self_select : [], // 직접 지정 리스트
            item_select_type: "", // 상품 선정 알고리즘 : 구 자동 선정 필드(현재는 해당값이 직점 지정이었던 모습으로만 관리)
            event_item_price_filter_yn : "Y", // 판매가 사용 여부 : 구 자동 선정 필드(현재 Y값 필수)
            event_item_min_price: "", // 판매가 최소값 : 구 자동 선정 필드
            event_item_max_price: "", // 판매가 최대값 : 구 자동 선정 필드
            event_item_created_period : "", // 상품 등록일 기준 : 구 자동 선정 필드
            event_item_analysis_period : "", // 상품 분석기간 : 구 자동 선정 필드
            event_total_item_select_cnt: "", // 상품 선정 수 : 구 자동 선정 필드
            
            max_item_analysis_list: [
                // {value:"1", title:"알고리즘별 자동 합산"},
                {value:"1", title:"사용 안 함"},
                {value:"2", title:"알고리즘별 균등 배분"},
                {value:"3", title:"알고리즘 상위 배분"},
            ],

            max_item_analysis: "1",
            max_item_analysis_value: "",

            /* 강제 자동 상품 설정 관련 */
            force_category_list_1: [],
            force_category_list_2: [],
            force_category_list_3: [],
            force_category_list_4: [],

            force_select_area_checked: true,
            force_use_yn: "N",
            force_logic : "",
            force_category: {},
            force_auto_remove: "",
            force_analysis_period: "",
            force_select_created_yn: "",
            force_created_period_type: "",
            force_created_period: "",
            force_created_start_date: "",
            force_created_end_date: "",
            force_is_except_invisible_category: "",
            
            // upper : 왼쪽 영역 선택지 받아오기 //
            item_select_type_list : [], // 상품 선정 알고리즘 목록
            iframe_page_id_list : [], // 위치 목록
            link_open_type_list : [], // 링크 열기 타입 목록
            image_type_list: [], // 이미지 타입(목록이미지, 상세이미지)
            display_type_list: [], // 진열 방식(나열, 롤링)
            display_name_list : [], // 제목 목록
            event_item_price_show_type_list : [], // 가격 목록
            event_type_list_rolling : [], // 디자인 목록 : 롤링
            event_type_list_nayeol : [], // 디자인 목록 : 나열
            transition_mode_list : [], // 롤링 방식 목록
            transition_pause_list: [], // 롤링 이동 텀 목록
            transition_speed_list: [], // 롤링 속도 목록
            product_order_type_list : [], // 진열 정렬 방식
            event_view_cnt_select : [{value:2, title:"2개"},{value:3, title:"3개"},{value:4, title:"4개"},
            {value:5, title:"5개"},{value:6, title:"6개"},{value:7, title:"7개"}],
            event_mobile_view_cnt_select : [{value:1, title:"1개"},{value:2, title:"2개"},{value:3, title:"3개"}],
            event_item_analysis_period_list : [
                {value:0, title:"날짜 무관"},
                {value:"1/D", title:"최근 1일"},{value:"2/D", title:"최근 2일"},{value:"3/D", title:"최근 3일"},{value:"4/D", title:"최근 4일"},{value:"5/D", title:"최근 5일"},{value:"6/D", title:"최근 6일"},
                {value:1, title:"최근 1주"},{value:2, title:"최근 2주"},{value:3, title:"최근 3주"},{value:4, title:"최근 4주"},
            ],
            event_item_created_period_list : [
                {value:0, title:"날짜 무관"},{value:-1, title:"직접 지정"},
                {value:"1/D", title:"최근 1일"},{value:"2/D", title:"최근 2일"},{value:"3/D", title:"최근 3일"},{value:"4/D", title:"최근 4일"},{value:"5/D", title:"최근 5일"},{value:"6/D", title:"최근 6일"},
                {value:"1/W", title:"최근 1주"},{value:"2/W", title:"최근 2주"},{value:"3/W", title:"최근 3주"},{value:"4/W", title:"최근 4주"},{value:"5/W", title:"최근 5주"},{value:"6/W", title:"최근 6주"},
                {value:"8/W", title:"최근 8주"},{value:"9/W", title:"최근 9주"},{value:"10/W", title:"최근 10주"},{value:"11/W", title:"최근 11주"},
                {value:"12/W", title:"최근 12주"},{value:"24/W", title:"최근 24주"},
                {value:"1/M", title:"최근 1달"},{value:"2/M", title:"최근 2달"},{value:"3/M", title:"최근 3달"},{value:"4/M", title:"최근 4달"},{value:"5/M", title:"최근 5달"},{value:"6/M", title:"최근 6달"},
                {value:"1/Y", title:"최근 1년"},{value:"2/Y", title:"최근 2년"},{value:"3/Y", title:"최근 3년"},{value:"4/Y", title:"최근 4년"}, 
            ],
            event_item_created_period_season_list : [
                {value:"-999", title:"사용 안함"},
                {value:"1/S", title:"모든 봄등록일"},{value:"2/S", title:"모든 초여름등록일"},{value:"3/S", title:"모든 여름등록일"},{value:"4/S", title:"모든 가을등록일 "},{value:"5/S", title:"모든 겨울등록일"},
                {value:"6/S", title:"매년 4계절마다"},{value:"7/S", title:"2년포함 4계절마다"},{value:"8/S", title:"3년포함 4계절마다"},{value:"9/S", title:"4년포함 4계절마다"},{value:"10/S", title:"전체년도 4계절마다"},
                {value:"11/S", title:"매년 여름겨울이 긴 4계절마다"},{value:"12/S", title:"2년포함 여름겨울이 긴 4계절마다 "},{value:"13/S", title:"3년포함 여름겨울이 긴 4계절마다"},{value:"14/S", title:"4년포함 여름겨울이 긴 4계절마다"},{value:"15/S", title:"전체년도 여름겨울이 긴 4계절마다"},
                {value:"16/S", title:"봄가을을 구분없는 매년 계벌별로"},{value:"17/S", title:"간절기를 고려한 장기판매 매년 6계절별로"},{value:"18/S", title:"긴여름을 고려한 장기판매 매년 6계절별로"},{value:"19/S", title:"봄가을을 구분없는 모든 계벌별로"},{value:"20/S", title:"간절기를 고려한 장기판매 모든 6계절별로"},{value:"21/S", title:"긴여름을 고려한 장기판매 모든 6계절별로"},
            ],
            event_cycle_list : [{value:1, title:"1일"},{value:2, title:"2일"},{value:3, title:"3일"},
            {value:5, title:"5일"},{value:7, title:"7일"},{value:14, title:"14일"},{value:30, title:"30일"},],
            use_yn_list : [{text:"사용함", value:"Y"},{text:"사용 안 함", value:"N"}],
            // auto_yn_list : [{text:"자동관리사용", value:"Y"},{text:"수동관리사용", value:"N"}],
            auto_yn_list : [{text:"반복관리", value:"Y"},{text:"수동관리", value:"N"}],
            force_use_yn_list : [{text:"사용함", value:"Y"},{text:"사용 안 함", value:"N"}],
            event_time_list : Array.from({ length: 24 }, (_, i) => ({ value: i, title: `${i}시` })), // (0시 ~ 23시)
            event_time_list_v2 : Array.from({ length: 24 }, (_, i) => ({ value: i + 1, title: `${i + 1}시` })), // (1시 ~ 24시)
            product_color_list : [],

            left_is_modified : false, // 왼쪽 영역 수정 여부
            total_item_select_cnt_range : [], // 상품 선정 수 선택을 위한 리스트
            template_image_url : "", // 디자인 이미지
            template_sub_title_use_yn: "N",

            operateed_yn : "N", // 결제 여부

            show_multi_category_select_popup : false, // 대카테고리 선정 팝업 보이는 상태
            big_category_checked_list : [], // 대 카테고리 선택 리스트
            multi_category_list_is_modified: false, // 대카테고리 선정 팝업에서 변경이 일어났는지

            first_category_list : "", // 처음 받아온 대카테고리
            category_list_for_product_detail : "", // 상품 상세에 사용되는 카테고리 목록


            /* ---------- 선정된 상품목록 팝업 관련 변수 --------*/

            show_event_product_list_popup : false, // 선정된 상품목록 팝업 보이는 상태 
            show_event_product_list_table_loading_bar : false, // 자동 진열된 상품 목록 로딩 바

            event_order_gubun : "",
            event_order_type : "",

            event_rp_product_list : [], // 자동 진열된 상품 목록
            event_product_checked_list : [], // 자동 진열된 상품 목록 체크된 상품 목록
            select_event_order_gubun_type : "org", // 자동 진열된 상품 목록 선택 정렬
            // 자동 진열된 상품 목록 정렬 필터
            select_event_order_gubun_type_list : [{value:"org", title:"선정된 순서대로"},{value:"order_price/desc", title:"총 판매액이 많은 상품이 위로"},
            {value:"order_price/asc", title:"총 판매액이 많은 상품이 아래로"},{value:"price/desc", title:"판매가 높은 상품이 위로"},{value:"price/asc", title:"판매가 높은 상품이 아래로"},
            {value:"order_cnt/desc", title:"판매수 많은 상품이 위로"},{value:"order_cnt/asc", title:"판매수 많은 상품이 아래로"},{value:"hit_cnt/desc", title:"조회 많은 상품이 위로"},
            {value:"hit_cnt/asc", title:"조회 많은 상품이 아래로"},{value:"created_date/desc", title:"최근 등록된 상품이 위로"},{value:"created_date/asc", title:"최근 등록된 상품이 아래로"},
            {value:"updated_date/desc", title:"최근 수정된 상품이 위로"},{value:"updated_date/asc", title:"최근 수정된 상품이 아래로"},{value:"product_name/asc", title:"상품명 가나다순"},
			{value:"product_name/desc", title:"상품명 가나다 역순"}
            ,{value:"cart_cnt/desc", title:"장바구니 많이 담은 상품이 위로"},{value:"cart_cnt/asc", title:"장바구니 많이 담은 상품이 아래로"}
            // {value:"wish_cnt/desc", title:"찜이 많은 상품이 위로"},{value:"wish_cnt/asc", title:"찜이 많은 상품이 아래로"}
            , {value:"quantity/desc", title:"재고 많은 상품이 위로"},
			{value:"quantity/asc", title:"재고 많은 상품이 아래로"},
        ],

            /* ------------ 제외 상품 목록 팝업 관련 변수 ----------- */
            show_exclude_product_list_popup : false, // 제외 상품 목록 팝업 보이는 상태

            /* ------------ 진열 간 상품 중복 제외 설정 팝업 관련 변수 ----------- */

            show_except_setting_popup : false, // 진열 간 상품 중복 제외 설정 팝업 보이는 상태
            rp_event_setting_list: [], // 진열 설정 목록
            except_setting_list: [], // 진열 간 상품 중복 제외 설정 목록
            select_except_rp_setting : "", // 셀렉트박스에 선택된 진열 ex) iframe_page_id+"/"+iframe_html_id
            show_except_product_popup : false, // (제외쪽)진열 상품 목록 보기
            show_except_product_list_table_loading_bar : false, // (제외쪽)진열 상품 목록 로딩바
            except_product_list: [], // 진열 제외 상품 목록 (상품 보기쪽 목록)
            except_products : {}, // 진열 제외 다중 카테고리 상품 목록 (상품 보기쪽 목록)
            except_event_category_list : {}, // 진열 제외 다중 카테고리 목록
            except_select_category : "", // 진열 제외 선택된 다중 카테고리
            except_setting_is_modified : false, // 진열 간 상품 중복 제외 설정 수정 여부

            /*-------------- 왼쪽 영역 종료 --------------*/

            // 왼쪽 영역 단일 카테고리 선택시 받아오는 카테고리 값
            rp_category: [
                {category_no:""},
                {category_no:""},
                {category_no:""},
                {category_no:""}
            ],
            // 왼쪽 영역 세팅 받아온 값
            rp_setting: {
                event_category_select_type: "34002",
            },
            
            /* ---------- 오른쪽 영역 관련 변수 --------*/
            rp_product_list : [], // 단일 카테고리 상품 목록
            rp_check_product_list : [], // 메인 테이블 체크 상품 목록
            modified_date: "", // 메인 테이블 상품 수정일
            is_auto : "N", // 자동="Y", 수동(수정)="N"

            right_is_modified : false, // 메인 테이블 영역 수정 여부

            main_order_idx:[], // 메인 상품 순서 번호

            products : {}, // 다중 카테고리용 상품 목록 모음
            products_is_auto : {}, // 다중 카테고리용 상품 목록의 선정 종류 모음
            products_modified_date : {}, // 다중 카테고리용 상품 목록의 수정일 모음
            multi_category_list : [], // 다중 카테고리 모음
            selected_category_no : "", // 메인에서 선택한 다중 카테고리 번호

            show_rp_product_list_table_loading_bar : true,// 상품 목록 로딩 바

            /* ---------- 상품 추가 팝업 관련 변수 --------*/
            show_add_product_popup : false, // 상품 추가 팝업 보이는 상태

            /* ---------- 다이얼로그 관련 변수 --------*/
            save_dialog_show_state : false, // 저장 다이얼로그
            save_dialog_content: "",
            save_dialog_button_list: [],
            basic_dialog_show_state : false, // 기본 다이얼로그
            basic_dialog_content: "",
            basic_dialog_button_list: [],
            title_content_dialog_show_state : false, // 대카테고리 저장 팝업(제목 및 내용이 있는 다이얼로그)
            title_content_dialog_title: "",
            title_content_dialog_content: "",
            title_content_dialog_button_list: [],
            confirm_dialog_show_state : false,
            paste_confirm_dialog_show_state : false, // 자동 진열된 상품 목록 적용하기
            confirm_dialog_content: "",
            confirm_dialog_button_list: [],

            event_item_analysis_standard_list : [
                {value:"1/D", title:"최근 1일"},{value:"2/D", title:"최근 2일"},{value:"3/D", title:"최근 3일"},{value:"4/D", title:"최근 4일"},{value:"5/D", title:"최근 5일"},{value:"6/D", title:"최근 6일"},
                {value:1, title:"최근 1주"},{value:2, title:"최근 2주"},{value:3, title:"최근 3주"},{value:4, title:"최근 4주"},
            ],
            analysis_standard_value : "4",

                        /* ---------- 실시간, 예약, 요일 ---------- */
            // 이제부터는 API로 코드 테이블을 조회 하는게 아니라 하드코딩으로 적용.

            // auto_create_type : "A7002", // A7001: 반복 관리, A7002: 수동 관리, A7003: 실시간 관리
            auto_create_type_list : [
                {code_cd: "A7001", code_name: "반복관리"}
                , {code_cd: "A7002", code_name: "수동관리"}
                , {code_cd: "A7003", code_name: "실시간관리"}
            ],
            event_cycle_type : "A8001", // A8001: 일별, A8002: 주별, A8003: 실시간
            event_cycle_type_list : [
                {code_cd: "A8001", code_name: "일별"}
                , {code_cd: "A8002", code_name: "요일별"}
                , {code_cd: "A8003", code_name: "실시간"}
            ],
            
            weekday_use_yn : "",
            weekday_cycle : "1", // 1주, 2주, 3주... 12주
            weekday_cycle_selectbox_list : [
                {code_cd: "1", code_name: "1주"},
                {code_cd: "2", code_name: "2주"},
                {code_cd: "3", code_name: "3주"},
                {code_cd: "4", code_name: "4주"},
                {code_cd: "5", code_name: "5주"},
                {code_cd: "6", code_name: "6주"},
                {code_cd: "7", code_name: "7주"},
                {code_cd: "8", code_name: "8주"},
                {code_cd: "9", code_name: "9주"},
                {code_cd: "10", code_name: "10주"},
                {code_cd: "11", code_name: "11주"},
                {code_cd: "12", code_name: "12주"}
            ],
            weekday_list : "-1,0,1,2,3,4,5,6", // 0,1,2,3... (쉼표 구분자로 여러 요일 선택 가능)
            weekday_selectbox_list : [
                {code_cd: "-1", code_name: "전체"},
                {code_cd: "0", code_name: "월"},
                {code_cd: "1", code_name: "화"},
                {code_cd: "2", code_name: "수"},
                {code_cd: "3", code_name: "목"},
                {code_cd: "4", code_name: "금"},
                {code_cd: "5", code_name: "토"},
                {code_cd: "6", code_name: "일"},
            ],

            // real_time_type : "A9003", // A9001: 30분, A9002: 3시간, A9003: 6시간
            // weekday_radio_list : [
            //     {code_cd: "A9001", code_name: "30분"},
            //     {code_cd: "A9002", code_name: "3시간"},
            //     {code_cd: "A9003", code_name: "6시간"},
            // ],
            real_time_cycle : "720",
            real_time_cycle_selectbox_list_by_basic : [
                {code_cd: "720", code_name: "12시간"},
                {code_cd: "360", code_name: "6시간"}, 
                {code_cd: "180", code_name: "3시간"},
                {code_cd: "120", code_name: "2시간"}, 
            ],
            real_time_cycle_selectbox_list_by_premium : [
                {code_cd: "720", code_name: "12시간"},
                {code_cd: "360", code_name: "6시간"}, 
                {code_cd: "180", code_name: "3시간"}, 
                {code_cd: "120", code_name: "2시간"}, 
                {code_cd: "60", code_name: "1시간"}, 
                {code_cd: "30", code_name: "30분"}, 
            ],

            reservation_use_yn : "N",
            reservation_date : new Date().setDate(new Date().getDate() + 0),
            // reservation_hour : "7",

            event_start_date : new Date().setDate(new Date().getDate() + 0),
            // event_start_hour : "7",

            real_time_service_regist_show_state: false,

            real_time_txt_use_yn: "N",

            real_time_start_hour: "12",
            real_time_end_hour: "24",
        }

        this.hasFetchedSyncData = createRef()
        this.hasFetchedSyncData.current = false;
    }

    componentWillMount() {
        // 무료 체험 기간이 종료되었다면 진열 목록 화면으로 보내기
        this.loadFreeDatePeriod();

        // 새로운 페이지라면, 로딩 프로그레스 안 보이게 처리
        var page_id = StringUtil.getUrlParams("page_id");
        var html_id = StringUtil.getUrlParams("html_id");
        this.setState({
            iframe_page_id : page_id,
            iframe_html_id : html_id,
        }, () => {
            console.log("#### componentWillMount", this.state.iframe_page_id)
            console.log("#### componentWillMount", page_id)
        });
        if(page_id == null || html_id == null || page_id == "" || html_id == ""){
            create_rp = true;
            this.setState({
                show_rp_product_list_table_loading_bar : false,
            });
        }

        // 페이지 주소값 보관
        this_location = window.location.href;
    }

	componentDidMount() {
        this.loadUpperCodeList();
        this.loadProductCategory("", "", "first_category_list");
        this.loadProductCategory("", 1, "force_category_list_");
        this.loadProductCategoryHierarchy();
        this.loadShopSyncStateAndSyncDate();
        /** 부가서비스 신청 여부 조회 */
        this.loadRegistedService("0100201001"); // operate_yn_0100201001 (3시간 서비스 - 베이직)
        this.loadRegistedService("0100201002"); // operate_yn_0100201002 (30분 서비스 - 프리미엄)

        const solution_type_cd = CookieUtil.getCookie("solution_type_cd");
        if(solution_type_cd == "09001"){
            this.loadProductColorList();
        }
        this.setState({ solution_type_cd : solution_type_cd });

        if(this.props.location.state && this.props.location.state.highlightType){
            this.setState({highlightType : this.props.location.state.highlightType});
        }

        // 사이드바 확장/축소 버튼 클릭 시 화면 비율 조정
        $('html').click(function(e) {
            if($(e.target).parents(".pc_left_menu").length > 0 || $(e.target).parents(".pc_left_menu_reduction").length > 0) {
                setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
            }
        });

        $(document).ready(function(){
            $(window).resize(function(){
                list_main_menu_width_change();
            });
            
            // 페이지 로드시 사이드메뉴 펼친상태 / 접힌상태 설정
            function list_expand(){
                if($(window).width() <= 800){
                    //넓이 720이하일 때 사이드메뉴 줄이기
                    $(".list_expand_check").prop("checked",false);
                    $(".list_expand").each(function(){
                        
                        if($(this).hasClass("list_category_expand")){
                            $(this).nextAll("#list_category_radio_wrap_expand").first().css({height :"0", overflow:"hidden"});
                        } else{
                            let list_expand_m = $(this).outerHeight();
                            
                            $(this).parent(".list_expand_wrap").css({
                                "height":list_expand_m
                                , "overflow":"hidden"
                            });
                        }

                    });
                }
                else{
                    //넓이 720초과일 때 사이드메뉴 펼치기
                    $(".list_expand_check").prop("checked",true);
                    $(".list_expand").each(function(){
                        $(this).parent(".list_expand_wrap").css({"height":"auto", "overflow":"visible"});
                    });
                }
            }
            setTimeout(list_expand,0);
        
            function list_main_menu_width_change(){
                let window_width= $(window).width();
                let window_height = $(window).height();
                
                let list_expand_outerWidth = $(".list_expand").outerWidth();
                let list_expand_innerWidth = $(".list_expand").innerWidth();
                let list_expand_width = $(".list_expand").width();
                let list_expand_width_padding_all = list_expand_width - list_expand_innerWidth;
                let list_expand_width_padding = list_expand_width_padding_all / 2;

                // window 높이에 따라서 메인테이블의 max-height가 달라지도록 적용(드래그 스크롤을 위한 작업)
                if($("#list_category_radio_a").is(":checked")){
                    let multi_category_select_div= $("#multi_category_select_div").height();
                    $("#list_admin_main_board").css({"max-height":window_height-330-multi_category_select_div});
                    $("#main_table_tbody").css({"max-height":window_height-400-multi_category_select_div});
                } else{
                    $("#list_admin_main_board").css({"max-height":window_height-330});
                    $("#main_table_tbody").css({"max-height":window_height-400});
                }
                
                // 오른쪽 영역 메인테이블 크기 지정하는 부분
                if(window_width < 800){
                    //상품 카테고리의 '직접 지정' 선택 시 하단 선택창 넓이 변경
                    $(".list_side_2_radio_self_select_wrap").css({
                        "width":list_expand_outerWidth,
                        "transform":"translateX("+list_expand_width_padding+"px)"
                    });
                }
                else{
                    // 상품 카테고리의 '직접 지정' 선택 시 하단 선택창 넓이 변경
                    $(".list_side_2_radio_self_select_wrap").css({
                        "width":list_expand_outerWidth,
                        "transform":"translateX("+list_expand_width_padding+"px)"
                    });
                }
            }
            setTimeout(list_main_menu_width_change,0);
        
            // 왼쪽 영역 접기/펼치기 클릭 이벤트
            var list_title_height;
        
            $(".list_expand").on('click',function(e){
                list_title_height = $(this).outerHeight();

                // expand_timer의 동작을 취소한다 (시간이 300 만큼 지나기 전에 클릭이 또 있었다면, 이전의 timeout동작은 실행되지 않을것이다)
                let object = this;
                let object_id = this.id;
                // 버튼을 연속으로 누르기 전에 열려있었는지 닫혀있었는지에 대한 정보를 담는다
                if(expand_timer[object_id] == null){
                    expand_timer[object_id+"_yn"] = $(object).find(".list_expand_check").is(":checked")? "Y" : "N";
                }
                // 700만큼의 시간이 지나기 전에 여닫기를 다시 눌렀다면 이전에 걸어놨던 setTimeout 동작은 사라진다(연속으로 누른경우 마지막 동작만 실행)
                clearTimeout(expand_timer[object_id]);
                if($(object).find(".list_expand_check").is(":checked")){
                    $(object).find(".list_expand_check").prop('checked',false);
                    expand_timer[object_id] = setTimeout(() => {
                        if($(object).hasClass("list_category_expand")){
                            $(object).nextAll("#list_category_radio_wrap_expand").first().css({ overflow:"hidden"});
                            $(object).nextAll("#list_category_radio_wrap_expand").first().animate({
                                height :"0"
                            },700, () => {
                                $(object).nextAll("#list_category_radio_wrap_expand").first().css({ overflow:"hidden"});
                            });
                        } else{
                            $(object).parent(".list_expand_wrap").css({overflow:"hidden"});
                            $(object).parent(".list_expand_wrap").animate({
                                height:list_title_height,
                            },700, () => {
                                $(object).parent(".list_expand_wrap").css({overflow:"hidden"});
                            });
                        }
                        expand_timer[object_id] = null;
                    },300);
                }
                else{
                    $(object).find(".list_expand_check").prop('checked',true);
                    expand_timer[object_id] = setTimeout(() => {
                        // 버튼을 연속으로 누르기전에 닫혀있는 상태일 경우에만 영역을 연다
                        // 이전에 열려있던 상태였는데도 여는 animate를 실행하면 길이가 뚝 잘렸다가 다시 늘어나는 모션을 취하기 때문에 방지한다.
                        if(expand_timer[object_id+"_yn"]!="Y"){
                            if($(object).hasClass("list_category_expand")){
                                // height auto값에 animate를 주는 작업
                                var height = $(object).nextAll("#list_category_radio_wrap_expand").first().css('height','auto').height();
                                $(object).nextAll("#list_category_radio_wrap_expand").first().css({height :"0"}); // 크기가 변하기 전 값
                                $(object).nextAll("#list_category_radio_wrap_expand").first().animate({height: height+'px'}, 700, () => {
                                    $(object).nextAll("#list_category_radio_wrap_expand").first().css({height: 'auto', overflow:"visible"});
                                });
                            } else{
                                // height auto값에 animate를 주는 작업
                                var height = $(object).parent(".list_expand_wrap").css('height','auto').height();
                                $(object).parent(".list_expand_wrap").css('height',list_title_height+'px'); // 크기가 변하기 전 값
                                $(object).parent(".list_expand_wrap").animate({height: height+'px'}, 700, () => {
                                    $(object).parent(".list_expand_wrap").css({height: 'auto', overflow:"visible"});
                                });
                            }
                        }
                        expand_timer[object_id] = null;
                    },300);
                }
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {

        // 화면 스크롤 고정 : 여러개의 팝업 중 하나라도 보여지는 상태라면 스크롤 고정
        if(this.state.save_dialog_show_state
            || this.state.basic_dialog_show_state
            || this.state.title_content_dialog_show_state
            || this.state.confirm_dialog_show_state
            || this.state.paste_confirm_dialog_show_state
            || this.state.show_event_product_list_popup
            || this.state.show_exclude_product_list_popup
            || this.state.show_except_setting_popup
            || this.state.show_except_product_popup
            || this.state.show_add_product_popup
            || this.state.show_multi_category_select_popup) {
            document.body.style.overflow = 'hidden'; // 스크롤 고정
        } else {
            document.body.style.overflow = 'auto'; // 스크롤 고정 해제
        }

        /*------------------------- 왼쪽 영역 : 초기 설정 부분 ------------------------*/
        if (this.state.is_left_load_first_setting) {
            // 진열 방식으로 인한 설정
            this.listTypeStyle();
            this.countTotalItemSelectCntRange(); // 상품 선정수 계산
            // 디자인으로 인한 설정
            this.loadEventTemplateImage(); // 디자인 이미지 불러오기
            // 상품 선정수 합계 구하기
            this.SumEventTotalItemSelectCnt();
            // 알고리즘 설정 체크 상태에 맞게 영역 높이(여닫기) 설정
            for(var i=0;i<this.state.list_self_select.length; i++){
                $("#list_select_arrow_"+i).prop("checked",this.state.list_self_select[i].self_select_area_checked);
                this.onClickEventFolderNoneAni(i); // none 애니메이션으로 변경
            }
            
            $("#list_select_arrow_force").prop("checked", true);
            this.onClickEventFolderNoneAni("force");
            
            this.setState({
                left_is_modified : false,
                is_left_load_first_setting : false
            }, () => {
                // css설정이 끝난 후에 오른쪽 영역의 크기를 제대로 돌리기 위해 resize를 한번 실행한다.
                window.dispatchEvent(new Event('resize'));
            });
        }

        /** cafe24_shops sync_state 1(연동중) 일 경우 최초 렌더링시 다시 sync 호출 */
        if (prevState.syncState !== this.state.syncState){
            if (this.state.syncState === "1" && !this.hasFetchedSyncData.current) {
                try {
                    this.hasFetchedSyncData.current = true
                    subscribeShopDataSyncState(new Date().getTime())
                        .then(res => this.callBackSubscribeShopDataSyncState(res))
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    componentWillUnmount(e) {
    }

    // 무료 체험 기간 조회 : 무료체험 기간이 끝났다면 위에 링크를 쳐서 들어오는 것을 막는다
    async loadFreeDatePeriod(){
        await http.get("/rest/shop/rp/freeDatePeriod")
		.then(res => {
			if (res.data != null && res.data != '') {
                if(res.data["is_finish"]!=0){
                    alert("사용 기간이 종료 되었습니다");
                    this.props.history.replace(
                        {
                            pathname: `/shop/rp/event/list`,
                        }
                    )
                }
			}
		})
		.catch(error => {
		 });
    }

    setGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 ("+ this.state.rp_setting.iframe_html_name +" 상품 목록)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
     }

     setPopupGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 ("+this.state.rp_setting.iframe_html_name +" 상품 추가 팝업)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
     }

    // 사용자 가이드
    onClickUserGuide(){
        window.open("https://iclave.notion.site/MD-41912c6f69084ba19fab96b903b08999");
    }

    // 기간 연장하기
    onClicExtendPeriod(){
        this.props.history.push(
            {
                pathname: `/shop/qna/form`,
                state: {qna_type_cd: "2104"}
            }	
        )
    }

    // 서비스 상담 안내 문의
    goConsultingPage = () => {
        window.open('/shop/qna/form?qna_type_cd=2109', '_blank');
    }

    // 진열 설치 문의
    goInstallQnaPage = () => {
        window.open('/shop/qna/form?qna_type_cd=2111', '_blank');
    }

    /** 왼쪽 영역 기존 설정 불러오기
     * @param {*} reload 다시 로드하는 경우 (초기화, 첫 저장)
     * @param {*} first_save 첫 저장인 경우
    */
    async loadRpEventSetting(reload, first_save) {
        await http.get("/rest/shop/rp/event/setting/v5?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
		.then(res => {
			if (res.data.code == "200") {
				this.setState({
                    rp_setting : res.data["setting"],
                }, () => {
                    /*------- 왼쪽 영역 값 초기화 ------*/
                    const {rp_setting, product_color_list} = this.state;

                    // 판매가 최대값, 최소값
                    let event_item_min_price = "";
                    if (rp_setting.event_item_min_price != null) {
                        event_item_min_price = rp_setting.event_item_min_price;
                    }
                    let event_item_max_price = "";
                    if (rp_setting.event_item_max_price != null) {
                        event_item_max_price = rp_setting.event_item_max_price;
                    }
                    
                    // 제목 사용 여부
                    const display_name_use_yn = rp_setting.display_name_cd == "33099"? "N" : "Y";
                    // 제목 type이 없는 경우 직접지정으로 변경
                    const {display_name_list} = this.state;
                    let display_name_cd = "33098"
                    for(var i=0;i<display_name_list.length;i++){
                        if(display_name_list[i].code_cd == rp_setting.display_name_cd){
                            display_name_cd = rp_setting.display_name_cd;
                        }
                    }
                    // 소제목 사용 여부
                    const sub_display_name_use_yn = rp_setting.sub_display_name_cd == "33099"? "N" : "Y";
                    // 제목 type이 없는 경우 직접지정으로 변경
                    let sub_display_name_cd = "33098"
                    for(var i=0;i<display_name_list.length;i++){
                        if(display_name_list[i].code_cd == rp_setting.sub_display_name_cd){
                            sub_display_name_cd = rp_setting.sub_display_name_cd;
                        }
                    }
                    // 롤링 모바일 고정 개수 적용
                    let rolling_event_mobile_view_cnt = "2";
                    if(rp_setting.display_type == "31002"){
                        rolling_event_mobile_view_cnt = rp_setting.event_mobile_view_cnt;
                    }
                    // 상품 등록일 기준 : 새로 생긴 속성이라 값이 없는것에 대응
                    let event_item_created_period = "0";
                    if(rp_setting.event_item_created_period != null && rp_setting.event_item_created_period != ""){
                        event_item_created_period = rp_setting.event_item_created_period;
                    }
                    // 알고리즘 분석 기간 : 새로 생긴 속성이라 값이 없는것에 대응
                    let event_item_analysis_period = "4";
                    if(rp_setting.event_item_analysis_period != null && rp_setting.event_item_analysis_period != ""){
                        event_item_analysis_period = rp_setting.event_item_analysis_period;
                    }
                    // 직접 지정 영역 : 고도화 이후 새로 생긴 속성이라 값이 없는것에 대응
                    let force_use_yn = "N";
                    let force_logic = "22001";
                    let force_category_list_1 = JSON.parse(JSON.stringify(this.state.first_category_list));
                    let force_category_list_2 = [];
                    let force_category_list_3 = [];
                    let force_category_list_4 = [];
                    let force_category = {
                        "product_rp_category_no_1": "전체상품",
                    };
                    let force_auto_remove = "";
                    let force_analysis_period = 0;
                    let force_select_created_yn = "N";
                    let force_created_period_type = "";
                    let force_created_period = 0;
                    let force_created_start_date = "";
                    let force_created_end_date = "";
                    let force_is_except_invisible_category = "N";

                    // let auto_create_type = "";
                    // if (rp_setting.auto_create_type) {
                    //     auto_create_type = rp_setting.auto_create_type;
                    // } else if (rp_setting.auto_create_yn == "Y") {
                    //     auto_create_type = "A7001"
                    // } else if (rp_setting.auto_create_yn == "N") {
                    //     auto_create_type = "A7002"
                    // }

                    let event_cycle_type = "A8001";
                    if (rp_setting.event_cycle_type) {
                        event_cycle_type = rp_setting.event_cycle_type;
                    }

                    let weekday_use_yn = "";
                    if (rp_setting.weekday_use_yn) {
                        weekday_use_yn = rp_setting.weekday_use_yn;
                    }

                    let weekday_cycle = "";
                    if (rp_setting.weekday_cycle) {
                        weekday_cycle = rp_setting.weekday_cycle;
                    }

                    let weekday_list = "-1,0,1,2,3,4,5,6";
                    if (rp_setting.weekday_list) {
                        weekday_list = rp_setting.weekday_list;
                    }

                    let real_time_cycle = "720";
                    if (rp_setting.real_time_cycle) {
                        real_time_cycle = rp_setting.real_time_cycle;
                    }

                    let real_time_txt_use_yn = "N";
                    if (rp_setting.real_time_txt_use_yn) {
                        real_time_txt_use_yn = rp_setting.real_time_txt_use_yn;
                    }

                    let reservation_use_yn = "N";
                    if (rp_setting.reservation_use_yn) {
                        reservation_use_yn = rp_setting.reservation_use_yn;
                    }

                    let reservation_date = "";
                    if (rp_setting.reservation_date) {
                        reservation_date = rp_setting.reservation_date;
                    }

                    // let reservation_hour = "7";
                    // if (rp_setting.reservation_hour) {
                    //     reservation_hour = rp_setting.reservation_hour;
                    // }

                    let event_start_date = new Date(rp_setting.reg_date);
                    if (rp_setting.event_start_date) {
                        event_start_date = rp_setting.event_start_date;
                    }

                    // let event_start_hour = "7";
                    // if (rp_setting.event_start_hour) {
                    //     event_start_hour = rp_setting.event_start_hour;
                    // }

                    let real_time_start_hour = "12";
                    if (rp_setting.real_time_start_hour) {
                        real_time_start_hour = rp_setting.real_time_start_hour;
                    }
                    
                    let real_time_end_hour = "24";
                    if (rp_setting.real_time_end_hour) {
                        real_time_end_hour = rp_setting.real_time_end_hour;
                    }

                    // 진열 최대 개수
                    let max_item_analysis = this.state.max_item_analysis;
                    let max_item_analysis_value = this.state.max_item_analysis_value;

                    let list_self_select = this.state.list_self_select;
                    if(res.data["list_self_select"] != null && res.data["list_self_select"] != ""){
                        list_self_select = res.data["list_self_select"];
                        const {first_category_list} = this.state;
                        for(var idx=0;idx<list_self_select.length;idx++){
                            // uuid 부여
                            if (list_self_select[idx].uuid == null) {
                                list_self_select[idx].uuid = StringUtil.generateUUID();
                            }

                            // null이라는 문자값으로 들어오는것을 방지
                            if(list_self_select[idx].min_hit_cnt == null){ list_self_select[idx].min_hit_cnt = ""; }
                            if(list_self_select[idx].max_hit_cnt == null){ list_self_select[idx].max_hit_cnt = ""; }

                            if(list_self_select[idx].min_cart_cnt == null){ list_self_select[idx].min_cart_cnt = ""; }
                            if(list_self_select[idx].max_cart_cnt == null){ list_self_select[idx].max_cart_cnt = ""; }

                            if(list_self_select[idx].min_price == null){ list_self_select[idx].min_price = ""; }
                            if(list_self_select[idx].max_price == null){ list_self_select[idx].max_price = ""; }

                            if(list_self_select[idx].min_order_cnt == null){ list_self_select[idx].min_order_cnt = ""; }
                            if(list_self_select[idx].max_order_cnt == null){ list_self_select[idx].max_order_cnt = ""; }

                            if(list_self_select[idx].min_order_price == null){ list_self_select[idx].min_order_price = ""; }
                            if(list_self_select[idx].max_order_price == null){ list_self_select[idx].max_order_price = ""; }
                            
                            if(list_self_select[idx].min_review_cnt == null){ list_self_select[idx].min_review_cnt = ""; }
                            if(list_self_select[idx].max_review_cnt == null){ list_self_select[idx].max_review_cnt = ""; }

                            if(list_self_select[idx].min_review_point == null){ list_self_select[idx].min_review_point = ""; }
                            if(list_self_select[idx].max_review_point == null){ list_self_select[idx].max_review_point = ""; }
                        
                            if(list_self_select[idx].min_quantity == null){ list_self_select[idx].min_quantity = ""; }
                            if(list_self_select[idx].max_quantity == null){ list_self_select[idx].max_quantity = ""; }

                            // 상품 선정 수가 빈문자로 들어오는것을 방지 (select 영역)
                            if(list_self_select[idx].cnt == null || list_self_select[idx].cnt == ""){ list_self_select[idx].cnt = 0; }

                            // 카테고리 목록을 불러올 수 없다면 전체선택으로 변경 (전체선택 or 기존 대카테고리 목록값 들어오는 경우[a,b,c] or 오류)
                            if(list_self_select[idx].category == null || list_self_select[idx].category.length == 0){
                                list_self_select[idx].event_category_list = "";
                            }

                            // 알고리즘 분석 기간이 null이라면
                            if(!list_self_select[idx].event_item_analysis_period && list_self_select[idx].event_item_analysis_period != 0){
                                list_self_select[idx].event_item_analysis_period = '4';
                            }

                            // 상품 선정일 관련 작업
                            if(list_self_select[idx].event_item_created_period != null && list_self_select[idx]["self_select_created_yn"] == 'Y'){
                                list_self_select[idx].event_item_created_start_date = list_self_select[idx].event_item_created_start_date != null ? new Date(StringUtil.dateFormater(list_self_select[idx].event_item_created_start_date)) : null;
                                list_self_select[idx].event_item_created_end_date = list_self_select[idx].event_item_created_end_date != null ? new Date(StringUtil.dateFormater(list_self_select[idx].event_item_created_end_date)) : null;
                            } else{
                                list_self_select[idx].event_item_created_period = (list_self_select[idx].event_item_created_period != null ? list_self_select[idx].event_item_created_period : "0");
                                list_self_select[idx].event_item_created_period_type = list_self_select[idx].event_item_created_period_type ? list_self_select[idx].event_item_created_period_type : "W";
                                list_self_select[idx].event_item_created_start_date = null;
                                list_self_select[idx].event_item_created_end_date = null;
                            }

                            // 강제설정 날짜 포맷 맞추기
                            list_self_select[idx].force_created_start_date = list_self_select[idx].force_created_start_date != null && list_self_select[idx].force_created_start_date != "" ? new Date(StringUtil.dateFormater(list_self_select[idx].force_created_start_date)) : null;
                            list_self_select[idx].force_created_end_date = list_self_select[idx].force_created_end_date != null && list_self_select[idx].force_created_end_date != "" ? new Date(StringUtil.dateFormater(list_self_select[idx].force_created_end_date)) : null;

                            // 카테고리 목록 및 값
                            list_self_select[idx]["product_rp_category_list_1"] = JSON.parse(JSON.stringify(first_category_list));
                            list_self_select[idx]["product_rp_category_list_2"] = [];
                            list_self_select[idx]["product_rp_category_list_3"] = [];
                            list_self_select[idx]["product_rp_category_list_4"] = [];

                            list_self_select[idx]["product_rp_category_no_1"] = list_self_select[idx]["category"].length > 0 ? list_self_select[idx]["category"][0].category_no : "";
                            list_self_select[idx]["product_rp_category_no_2"] = list_self_select[idx]["category"].length > 1 ? list_self_select[idx]["category"][1].category_no : "";
                            list_self_select[idx]["product_rp_category_no_3"] = list_self_select[idx]["category"].length > 2 ? list_self_select[idx]["category"][2].category_no : "";
                            list_self_select[idx]["product_rp_category_no_4"] = list_self_select[idx]["category"].length > 3 ? list_self_select[idx]["category"][3].category_no : "";

                            // 관련 상품명
                            list_self_select[idx]["product_keyword_list"] = list_self_select[idx]["product_keyword_list"] ? list_self_select[idx]["product_keyword_list"] : [""];
                            // 제외 키워드
                            list_self_select[idx]["product_except_keyword_list"] = list_self_select[idx]["product_except_keyword_list"] ? list_self_select[idx]["product_except_keyword_list"] : [""];

                            // 여닫기 체크 상태
                            if (!first_save) {
                                list_self_select[idx]["self_select_area_checked"] = (idx == 0 ? true : false);
                            }

                            if (!list_self_select[idx]["event_item_condition_list"] 
                                    || (list_self_select[idx]["event_item_condition_list"] && list_self_select[idx]["event_item_condition_list"].length <= 0)) {
                                // 알고리즘(~중에)) 초기화
                                list_self_select[idx]["event_item_condition_list"] = [
                                    "A2999",
                                    "A2999"
                                ]                                            
                            }
                            // 컬러 목록
                            if(list_self_select[idx].color_seq_list){
                                let color_seq_list = list_self_select[idx].color_seq_list;
                                const color_list = product_color_list.filter(obj => color_seq_list.indexOf(obj.color_seq) != -1);
                                list_self_select[idx].color_list = color_list;
                                delete list_self_select[idx].color_seq_list;
                            } else{
                                list_self_select[idx].color_list = [];
                            }

                            // 강제 알고리즘
                            if( list_self_select[idx].force_use_yn ) force_use_yn = list_self_select[idx].force_use_yn;
                            if( list_self_select[idx].force_logic ) force_logic = list_self_select[idx].force_logic;
                            if( list_self_select[idx].force_category ) force_category = list_self_select[idx].force_category;
                            if( list_self_select[idx].force_auto_remove ) force_auto_remove = list_self_select[idx].force_auto_remove;
                            if( list_self_select[idx].force_analysis_period ) force_analysis_period = list_self_select[idx].force_analysis_period;
                            if( list_self_select[idx].force_select_created_yn ) force_select_created_yn = list_self_select[idx].force_select_created_yn;
                            if( list_self_select[idx].force_created_period_type ) force_created_period_type = list_self_select[idx].force_created_period_type;
                            if( list_self_select[idx].force_created_period ) force_created_period = list_self_select[idx].force_created_period;
                            if( list_self_select[idx].force_created_start_date ) force_created_start_date = list_self_select[idx].force_created_start_date;
                            if( list_self_select[idx].force_created_end_date ) force_created_end_date = list_self_select[idx].force_created_end_date;
                            if( list_self_select[idx].force_is_except_invisible_category ) force_is_except_invisible_category = list_self_select[idx].force_is_except_invisible_category;
                            if( list_self_select[idx].max_item_analysis ) max_item_analysis = list_self_select[idx].max_item_analysis;
                            if( list_self_select[idx].max_item_analysis_value ) max_item_analysis_value = list_self_select[idx].max_item_analysis_value;
                        }
                    }
                    this.setState({
                        user_seq : rp_setting.user_seq,
                        shop_no : rp_setting.shop_no,
                        iframe_html_id : rp_setting.iframe_html_id,
                        iframe_page_id : rp_setting.iframe_page_id,
                        cafe24_main_display_group : '',
                        image_type_cd: rp_setting.image_type_cd,
                        link_open_type : rp_setting.link_open_type,
                        display_type : rp_setting.display_type,
                        display_name_use_yn : display_name_use_yn,
                        display_name : rp_setting.display_name,
                        display_name_cd : display_name_cd,
                        sub_display_name_use_yn : sub_display_name_use_yn,
                        sub_display_name : rp_setting.sub_display_name,
                        sub_display_name_cd : sub_display_name_cd,
                        platform_type : rp_setting.platform_type,
                        item_select_type : rp_setting.item_select_type,
                        auto_create_yn: rp_setting.auto_create_yn,
                        auto_rolling_yn: rp_setting.auto_rolling_yn,
                        event_category_list: rp_setting.event_category_list,
                        event_category_select_type: rp_setting.event_category_select_type,
                        event_cycle: rp_setting.event_cycle,
                        event_item_created_period : event_item_created_period,
                        event_item_analysis_period : event_item_analysis_period,
                        list_self_select : list_self_select,
                        event_item_price_filter_yn :"Y",
                        event_item_min_price : event_item_min_price,
                        event_item_max_price : event_item_max_price,
                        event_mobile_view_cnt: rp_setting.event_mobile_view_cnt,
                        event_time: rp_setting.event_time,
                        event_total_item_select_cnt: rp_setting.event_total_item_select_cnt,
                        event_type_cd: rp_setting.event_type_cd,
                        event_item_price_show_type : rp_setting.event_item_price_show_type,
                        event_view_cnt: rp_setting.event_view_cnt,
                        iframe_html_name: rp_setting.iframe_html_name,
                        install_yn: rp_setting.install_yn,
                        reg_date: rp_setting.reg_date,
                        transition_mode: rp_setting.transition_mode,
                        transition_pause: rp_setting.transition_pause,
                        transition_speed: rp_setting.transition_speed,
                        product_order_type : rp_setting.product_order_type,
                        operateed_yn : rp_setting.operateed_yn,
                        force_use_yn: force_use_yn,
                        force_logic: force_logic,
                        force_category: force_category,
                        force_category_list_1: force_category_list_1,
                        force_category_list_2: force_category_list_2,
                        force_category_list_3: force_category_list_3,
                        force_category_list_4: force_category_list_4,
                        force_auto_remove: force_auto_remove,
                        force_analysis_period: force_analysis_period,
                        force_select_created_yn: force_select_created_yn,
                        force_created_period_type: force_created_period_type,
                        force_created_period: force_created_period,
                        force_created_start_date: force_created_start_date,
                        force_created_end_date: force_created_end_date,
                        force_is_except_invisible_category: force_is_except_invisible_category,
                        max_item_analysis: max_item_analysis,
                        max_item_analysis_value: max_item_analysis_value,
                        // 롤링 모바일 고정 개수
                        rolling_event_mobile_view_cnt : rolling_event_mobile_view_cnt,
                        
                        // auto_create_type: auto_create_type,
                        event_cycle_type: event_cycle_type,
                        weekday_use_yn: weekday_use_yn,
                        weekday_cycle: weekday_cycle,
                        weekday_list: weekday_list,
                        real_time_cycle: real_time_cycle,
                        real_time_txt_use_yn: real_time_txt_use_yn,
                        reservation_use_yn: reservation_use_yn,
                        reservation_date: reservation_date,
                        // reservation_hour: reservation_hour,
                        event_start_date: event_start_date,
                        // event_start_hour: event_start_hour,
                        real_time_start_hour: real_time_start_hour,
                        real_time_end_hour: real_time_end_hour,
                    },()=>{
                        if(!create_rp && this.state.operateed_yn != "Y") {
                            this.props.history.replace(
                                {
                                    pathname: `/shop/rp/event/list`,
                                }
                            )
                        }
                        // 이미 설치 되었다면
                        if(this.state.install_yn=="Y"){
                            // 설치값 메뉴 닫기
                            $("#list_side_title_arrow").prop("checked",false);

                            let list_expand_m = $("#install_list_expand").outerHeight();
                            $("#install_list_expand").parent(".list_expand_wrap").css({
                                "height":list_expand_m,
                                "overflow":"hidden"
                            });
                        }
                        if(reload == null || !reload){
                            // 제외 상품 목록 불러오기
                            this.loadExcludeProductList();
                            this.loadRpEventSettingList();
                            this.loadExceptSettingList();

                            // 카테고리 종류에 따라 카테고리 번호 및 상품 목록 받아오기
                            if(this.state.event_category_select_type=="34002"){
                                // 단일 카테고리 상품 목록을 불러옴
                                this.loadRpEventProductList();
                            } else{
                                const category_list = this.state.event_category_list.split(',');
                                const big_category_checked_list = [];
                                for(var i=0;i<category_list.length;i++){
                                    big_category_checked_list.push(category_list[i]);
                                }
                                this.setState({
                                    big_category_checked_list : big_category_checked_list,
                                    multi_category_list : [...big_category_checked_list],
                                }, () => {
                                    // 단일 카테고리와 다중 카테고리 상품목록을 단일에서 같이 불러옴(단일은 추후에 따로 불러올 일이 없어서 같이 불러옴)
                                    this.loadRpEventProductList(true);
                                });
                            }
                        } else{
                            if(this.state.event_category_select_type=="34001"){
                                const category_list = this.state.event_category_list.split(',');
                                const big_category_checked_list = [];
                                for(var i=0;i<category_list.length;i++){
                                    big_category_checked_list.push(category_list[i]);
                                }
                                this.setState({
                                    big_category_checked_list : big_category_checked_list,
                                    multi_category_list : [...big_category_checked_list],
                                    show_rp_product_list_table_loading_bar : true
                                }, () => {
                                    // 다중 카테고리 상품목록 불러오기
                                    this.loadBigCategoryProductList();
                                });
                            } else{
                                this.orderIdxReset();
                            }
                        }
                        
                        // if(!first_save){
                            let {list_self_select} = this.state;
                            for(let idx=0;idx<list_self_select.length;idx++){
                                // 상품 선정 영역 카테고리 리스트 선택되어 있도록 하는 작업
                                if(list_self_select[idx].category != null && list_self_select[idx].category.length!=0){
                                    for (var idx2 in list_self_select[idx].category) {
                                        const name = "product_rp_category_no_"+(Number(idx2)+1);
                                        this.setState({
                                            [name]: list_self_select[idx].category[idx2].category_no,
                                        });
                                        this.loadProductCategory(list_self_select[idx].category[Number(idx2)].parent_category_no, Number(idx2)+1, "product_rp_category_list_",idx)
                                    }
            
                                    const category_length = list_self_select[idx].category.length;
                                    // 불러온 카테고리의 가장 하위 카테고리는, 다음 카테고리가 전체선택인 채로 저장되었을 수도 있으니 불러온다. (아니면 안불러옴)
                                    if(category_length > 0){
                                        this.loadProductCategory(list_self_select[idx].category[category_length-1].category_no, category_length+1, "product_rp_category_list_",idx);
                                    }
                                }
                            }

                            // 상품 선정 영역 카테고리 리스트 선택되어 있도록 하는 작업
                            let {force_category} = this.state;
                            if(force_category != null && force_category.length!=0){
                                idx = 1;
                                for (var key in force_category) {
                                    if( key === 'event_category_list') continue;
                                    if( force_category[key] == "") continue;
                                    this.loadProductCategory(force_category[key], Number(idx)+1, "force_category_list_")
                                    idx++;
                                }
                            }
                        // }

                        this.setState({is_left_load_first_setting : true});
                    });

                });
			} else if(res.data.code == "400" || res.data.code == "404" || res.data.code == "500") {
                alert("접근할 수 없는 진열입니다.")
                window.location.href = "/shop/rp/event/list";
            } else{
                alert("진열 설정을 불러오는데 실패하였습니다.");
                window.history.back();
            }
		})
		.catch(error => {
            alert("접근할 수 없는 진열입니다.")
            window.location.href = "/shop/rp/event/list";
		 });
    }

    /** 단일 카테고리(오른쪽 영역) : 상품 목록 불러오기
     * @param {*} and_multi_product_load 다중 카테고리별 상품목록도 함께 불러오는지에 대한 여부
     * @param {*} save_right_area 저장인 경우 (상품목록 새로고침 X, 수정시간/노출여부 갱신)
    */
    async loadRpEventProductList(and_multi_product_load, save_right_area) {
        await http.get("/rest/shop/rp/event/select/v2?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
		.then(res => {
			if (res.data.code == "200") {
                // 저장이 아닐때만 상품 목록을 새로고침 한다
                if(!save_right_area){
                    this.setState({
                        rp_product_list : res.data["list"],
                    }, ()=>{
                        // 상품 이미지 byte로 변환
                        // const {rp_product_list} = this.state;
                        // for(let i=0;i<rp_product_list.length;i++){
                        //     // 이미지가 재로딩 되지 않은 경우에만 byte변환 api를 타도록 한다
                        //     if(rp_product_list[i]["image_reload_yn"]!="Y"){
                        //         this.changeImageToByte(rp_product_list[i]["image_url"],i,"rp_product_list");
                        //         this.changeImageToByte(rp_product_list[i]["detail_image_url"],i,"rp_product_list", "detail_image_url");
                        //     }
                        // }
                    });
                }
				this.setState({
                    modified_date : res.data["last_mod_date"],
                    right_is_modified : false,
                    is_auto : res.data["is_auto"],
                }, ()=> {
                    // 다중 카테고리 상품목록을 같이 불러오는 경우
                    if(and_multi_product_load != null && and_multi_product_load){
                        if(save_right_area){ // 저장의 경우(선정 종류와 수정 시간을 불러온다)
                            this.loadBigCategoryProductList(true);
                        } else{ // 초기화 또는 첫 로드의 경우
                            this.setState({
                                products : {},
                                products_is_auto : {},
                                products_modified_date : {},
                                selected_category_no : "",
                                show_rp_product_list_table_loading_bar : true,
                                analysis_standard_value : 4, // 최근 4주로 초기화
                            }, () => {
                                this.loadBigCategoryProductList();
                                this.orderIdxReset(); // 순번 초기화
                            });
                        }
                    } else{
                        this.setState({
                            show_rp_product_list_table_loading_bar : false,
                            analysis_standard_value : 4, // 최근 4주로 초기화
                        }, () => {
                            this.orderIdxReset(); // 순번 초기화
                        });
                    }
                });
			} else {
                alert("상품 목록을 불러오는데 실패하였습니다.");
                this.setState({
                    rp_product_list : [],
                    modified_date : "",
                    right_is_modified : false,
                    is_auto : res.data["is_auto"],
                }, ()=> {
                    // 다중 카테고리 상품목록을 같이 불러오는 경우
                    if(and_multi_product_load != null && and_multi_product_load){
                        if(save_right_area){ // 저장의 경우(선정 종류와 수정 시간을 불러온다)
                            this.loadBigCategoryProductList(true);
                        } else{ // 초기화 또는 첫 로드의 경우
                            this.setState({
                                products : {},
                                products_is_auto : {},
                                products_modified_date : {},
                                selected_category_no : "",
                                show_rp_product_list_table_loading_bar : true,
                            });
                            this.loadBigCategoryProductList();
                            this.orderIdxReset(); // 순번 초기화
                        }
                    } else{
                        this.setState({
                            show_rp_product_list_table_loading_bar : false,
                        }, () => {
                            this.orderIdxReset(); // 순번 초기화
                        });
                    }
                });
            }
		})
		.catch(error => {
		 });
    }

    /** 카테고리 리스트를 불러오는 함수
     * @param {*} parentCategory 목록이 바로 포함하는 상위 카테고리 번호
     * @param {*} depth 카테고리 깊이 순서
     * @param {*} key_name 리스트 값이 저장될 이름
     * @param {*} list_self_select_index 직접 지정속 카테고리인 경우 : 선정 기준 순서 번호
    */
    async loadProductCategory(parentCategory, depth, key_name, list_self_select_index) {

        const sendForm = {
            user_seq: this.state.user_seq, 
            shop_no: this.state.shop_no,
            parent_category_no: parentCategory
        }

        // var name = "product_rp_category_list_"+depth;
        var name = key_name+depth;
		await http.get("/rest/common/cafe24/category", {params:sendForm})
		.then(res => {
            if(res.data.list.length > 0){
                res.data.list.splice(0,0,{category_name : "전체상품", category_name_no_format: "전체상품", category_no : "전체상품"})
            }
            if(list_self_select_index != null){
                let {list_self_select} = this.state;
                list_self_select[list_self_select_index][name] = res.data.list;
                this.setState({list_self_select : list_self_select});
            } else{
                this.setState({[name]: res.data.list});
            }
		})
		.catch(error => {
		 });
    }
    
   /**
    * 전체 카테고리 리스트를 한번에 불러오는 함수
    */
    async loadProductCategoryHierarchy() {
        const sendForm = {
            user_seq: this.state.user_seq, 
            shop_no: this.state.shop_no
        }

		await http.get("/rest/common/cafe24/hierarchy/category", {params:sendForm})
		.then(res => {
            this.setState({category_list_for_product_detail: res.data.list});
		})
		.catch(error => {
		 });
    }

     /** 다중 카테고리 상품 저장 */
    saveRpMultiEventList() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/multi/save';
        const body = {
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            event_group_category_no_list : this.state.big_category_checked_list,
            products : this.state.products
        }
        http
        .post(url, body, config)
        .then((res) => {
            if (res.data.code == "200") {
                // 단일|다중 카테고리 상품 목록 선정 종류, 수정 시간 재로딩
                this.loadRpEventProductList(true,true);
                this.setState({
                    rp_check_product_list: [],
                    right_is_modified: false,
                    save_dialog_show_state: true,
                    save_dialog_content: "저장됐습니다.",
                    save_dialog_button_list: [
                        {
                            title: "확인",
                            type: "1",
                            event_method: () => {
                                //팝업 닫기
                                this.setState({ save_dialog_show_state: false });
                            }
                        }
                    ]
                });
            } else {
                alert('상품 목록 저장에 실패하였습니다.');
                //팝업 닫기
                this.setState({ save_dialog_show_state: false });
            }
        })
        .catch((error) => {
            alert('상품 목록 저장에 실패하였습니다.');
            //팝업 닫기
            this.setState({ save_dialog_show_state: false });
        });
    }

    /** 카페24 아이콘 수집 (비동기 호출을 위해 api로 호출)
     * 수집 시간이 오래 걸리기 때문에 비동기로 호출
     */
    saveCafe24ProductIcons() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/icons';
        const body = {
            list : this.state.rp_product_list
        }
        axios
        .post(url, body, config)
        .then((res) => {
            console.log("아이콘 수집 완료!!", res.data);
        })
        .catch((error) => {
            console.log("아이콘 수집 실패!!", error);
        });
    }

    /** 카페24 아이콘 수집 - 상품상세 멀티 카테고리용 (비동기 호출을 위해 api로 호출)
     * 수집 시간이 오래 걸리기 때문에 비동기로 호출
     */
    saveCafe24MultiProductIcons() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/multi/icons';
        const body = {
            products : this.state.products
        }
        axios
        .post(url, body, config)
        .then((res) => {
            console.log("아이콘 수집 완료!!", res.data);
        })
        .catch((error) => {
            console.log("아이콘 수집 실패!!", error);
        });
    }

    /** 단일 카테고리 상품 저장
     * @param {*} none_dialog 다이얼로그 안내 없이 상품목록을 저장하는 경우
    */
    saveRpEventList(none_dialog) {
        let list_self_select = this.getListSelfSelectSend();

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const url = '/rest/shop/rp/event/save';
        const body = {
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            list : this.state.rp_product_list,
            manual_json: list_self_select,
        }

        if(none_dialog){
            http
            .post(url, body, config)
            .then((res) => {
                if (res.data.code == "200") {
                    // 선택된 다중 카테고리가 있다면
                    if(this.state.big_category_checked_list.length > 0){
                        // 다중 카테고리 상품 목록 저장
                        this.saveRpMultiEventList();
                        // 카페24 아이콘 수집
                        if (this.state.solution_type_cd == "09001") {
                            this.saveCafe24MultiProductIcons();
                        }
                    } else{
                        // 단일 카테고리 상품 목록 선정 종류, 수정 시간 재로딩
                        this.loadRpEventProductList(null,true);
                        this.setState({
                            rp_check_product_list: [],
                            right_is_modified: false,
                            save_dialog_show_state: true,
                            save_dialog_content: "저장됐습니다.",
                            save_dialog_button_list: [
                                {
                                    title: "확인",
                                    type: "1",
                                    event_method: () => {
                                        //팝업 닫기
                                        this.setState({ save_dialog_show_state: false });
                                    }
                                }
                            ]
                        });
                        // 카페24 아이콘 수집
                        if (this.state.solution_type_cd == "09001") {
                            this.saveCafe24ProductIcons();
                        }
                    }
                } else {
                    alert('상품 목록 저장에 실패하였습니다.');
                    //팝업 닫기
                    this.setState({ save_dialog_show_state: false });
                }
            })
            .catch((error) => {
                alert('상품 목록 저장에 실패하였습니다.');
                //팝업 닫기
                this.setState({ save_dialog_show_state: false });
            });

            this.setGaEvent("저장");
        } else{
            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: "변경하신 목록을 쇼핑몰에 바로<br/>적용하시겠습니까?",
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.setState({
                                confirm_dialog_show_state: false,
                                save_dialog_show_state: true,
                                save_dialog_content: "저장 중 입니다.",
                                save_dialog_button_list: [
                                    {
                                        title: "확인",
                                        type: "1",
                                        display_yn:"N",
                                        event_method: () => {
                                            //팝업 닫기
                                            this.setState({ save_dialog_show_state: false });
                                        }
                                    }
                                ]
                            });

                            http
                            .post(url, body, config)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    // 선택된 다중 카테고리가 있다면
                                    if(this.state.big_category_checked_list.length > 0){
                                        // 다중 카테고리 상품 목록 저장
                                        this.saveRpMultiEventList();
                                        // 카페24 아이콘 수집
                                        if (this.state.solution_type_cd == "09001") {
                                            this.saveCafe24MultiProductIcons();
                                        }
                                    } else{
                                        // 단일 카테고리 상품 목록 선정 종류, 수정 시간 재로딩
                                        this.loadRpEventProductList(null,true);
                                        this.setState({
                                            rp_check_product_list: [],
                                            right_is_modified: false,
                                            save_dialog_show_state: true,
                                            save_dialog_content: "저장됐습니다.",
                                            save_dialog_button_list: [
                                                {
                                                    title: "확인",
                                                    type: "1",
                                                    event_method: () => {
                                                        //팝업 닫기
                                                        this.setState({ save_dialog_show_state: false });
                                                    }
                                                }
                                            ]
                                        });
                                        // 카페24 아이콘 수집
                                        if (this.state.solution_type_cd == "09001") {
                                            this.saveCafe24ProductIcons();
                                        }
                                    }
                                } else {
                                    alert('상품 목록 저장에 실패하였습니다.');
                                    //팝업 닫기
                                    this.setState({ save_dialog_show_state: false });
                                }
                            })
                            .catch((error) => {
                                alert('상품 목록 저장에 실패하였습니다.');
                                //팝업 닫기
                                this.setState({ save_dialog_show_state: false });
                            });
    
                            this.setGaEvent("저장");
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                confirm_dialog_show_state: false,
                            });
                        }
                    }
                ]
            });
        }

    }

    /** 오른쪽 영역 초기화*/
    onClickResetRightSetting(){
        this.setState({
            title_content_dialog_show_state: true,
            title_content_dialog_title: "상품목록을 변경하지 않고 기존<br/>목록을 보시겠습니까?",
            title_content_dialog_content: "초기화하시면 변경하신 값은<br/>따로 저장되지 않습니다.",
            title_content_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        right_reset = true;
                        this.setState({
                            show_rp_product_list_table_loading_bar : true,
                            title_content_dialog_show_state : false,
                            right_is_modified : false,
                            rp_check_product_list : [],
                        });
                        this.loadRpEventProductList(true); // 단일|다중 카테고리 상품 목록 불러오기
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            title_content_dialog_show_state: false,
                        });
                    }
                }
            ],
        });
    }

    /** 왼쪽 영역 저장
     * @param {*} save_right 왼쪽 영역 저장시 오른쪽 영역도 함께 저장하는 경우
    */
    saveListSetting(save_right) {
        const firstSave = !this.state.iframe_html_id;
        // list_self_select는 저장시에 json 형태로 통으로 저장되기 때문에 프론트에서 사용하기 위해 독자적으로 넣은 데이터는 삭제하여 전송
        let list_self_select_send = this.getListSelfSelectSend();

        let {weekday_list} = this.state;
        if (weekday_list && weekday_list.length > 0) {
            weekday_list = weekday_list.replace("-1,", "");
        }

        // 기본 저장 멘트
        let confirm_dialog_content = firstSave ? "진열제목, 진열위치, 자동화설정 정보를<br/>모두 저장하시겠습니까?<br/><br/>! 저장하셔도 내 쇼핑몰에 바로 반영되지 않습니다." : this.state.install_yn == "Y" ? "현재 변경된 설정정보를 저장하시겠습니까?" : "저장하셔도 쇼핑몰에 바로 반영되지 않습니다.<br/>현재 변경된 설정정보를 저장하시겠습니까?";

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/setting/v5';
        const body = {
            user_seq: this.state.user_seq,
            shop_no: this.state.shop_no,
            iframe_page_id: this.state.iframe_page_id,
            iframe_html_id: this.state.iframe_html_id,
            iframe_html_name: this.state.iframe_html_name,
            cafe24_main_display_group: '',
            image_type_cd: this.state.image_type_cd,
            link_open_type: this.state.link_open_type,
            display_name: this.state.display_name,
            display_name_cd: this.state.display_name_cd,
            sub_display_name: this.state.sub_display_name,
            sub_display_name_cd: this.state.sub_display_name_cd,
            display_type: this.state.display_type,
            event_cycle: this.state.event_cycle,
            event_time: this.state.event_time,
            event_type_cd: this.state.event_type_cd,
            event_item_price_show_type : this.state.event_item_price_show_type,
            event_category_select_type: this.state.event_category_select_type,
            event_category_list: this.state.event_category_list,
            event_view_cnt: this.state.event_view_cnt,
            event_mobile_view_cnt: this.state.event_mobile_view_cnt,
            platform_type: this.state.platform_type,
            auto_rolling_yn: this.state.auto_rolling_yn,
            transition_mode: this.state.transition_mode,
            transition_speed: this.state.transition_speed,
            transition_pause: this.state.transition_pause,
            auto_create_yn: this.state.auto_create_yn,
            event_item_created_period : this.state.event_item_created_period,
            event_item_analysis_period : this.state.event_item_analysis_period,
            product_order_type : this.state.product_order_type,

            item_select_type: this.state.item_select_type,
            list_self_select : list_self_select_send,
            event_item_price_filter_yn: "Y",
            event_item_min_price: this.state.event_item_min_price != "" ? this.state.event_item_min_price : null,
            event_item_max_price: this.state.event_item_max_price != "" ? this.state.event_item_max_price : null,
            event_total_item_select_cnt: this.state.event_total_item_select_cnt,

            // auto_create_type : this.state.auto_create_type,
            event_cycle_type : this.state.event_cycle_type,

            weekday_use_yn : this.state.weekday_use_yn,
            weekday_cycle : this.state.weekday_cycle,
            weekday_list : weekday_list,

            real_time_cycle : this.state.real_time_cycle,
            real_time_txt_use_yn : this.state.real_time_txt_use_yn,

            reservation_use_yn : this.state.reservation_use_yn,
            reservation_date : StringUtil.dateFormater(this.state.reservation_date),
            // reservation_hour : this.state.reservation_hour,

            event_start_date : StringUtil.dateFormater(this.state.event_start_date),
            // event_start_hour : this.state.event_start_hour,
        }

        if(save_right){
            this.setState({
                confirm_dialog_show_state: false,
                save_dialog_show_state: true,
                save_dialog_content: "저장 중 입니다.",
                save_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        display_yn:"N",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ save_dialog_show_state: false });
                        }
                    }
                ]
            });

            http
            .post(url, body, config)
            .then((res) => {
                if (res.data.insertCnt > 0) {
                    // 진열 추가로 페이지를 처음 저장한 경우에는 iframe_html_id를 받아서 저장한다
                    console.log("saveListSetting_max_item_analysis", this.state.max_item_analysis);
                    if(firstSave){
                        this.setState({
                            iframe_html_id : res.data.iframe_html_id,
                            iframe_page_id : res.data.iframe_page_id,
                        }, () => {
                            this.onClickSaveRightSetting(true);
                            this.loadRpEventSettingList(true);
                            this.saveExcludeProductList();
                            this.saveExceptSettingList(true);

                            // 왼쪽 영역 저장값 불러오기 (초기 저장시 : 위치는 저장후에 변경 불가능 하기 때문에 다시 불러와서 해당 설정을 적용시킨다)
                            this.loadRpEventSetting(true,true);
                            // 링크 주소에 param값으로 iframe_html_id와 iframe_page_id를 붙인 형태로 변경한다.
                            window.history.replaceState("","",this_location+"?page_id="+this.state.iframe_page_id+"&html_id="+this.state.iframe_html_id);
                            this_location = this_location+"?page_id="+this.state.iframe_page_id+"&html_id="+this.state.iframe_html_id;
                        });
                    }
                } else {
                    this.setState({
                        save_dialog_show_state: false,
                    });
                    alert('진열 설정 저장에 실패하였습니다.');
                }
            })
            .catch((error) => {
                this.setState({
                    save_dialog_show_state: false,
                });
                alert('진열 설정 저장에 실패하였습니다.');
            });

            this.setGaEvent("저장");

        } else{
            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: firstSave ? "진열위치 및 설정정보저장" : "설정정보저장",
                confirm_dialog_content2 : confirm_dialog_content,
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            http
                            .post(url, body, config)
                            .then((res) => {
                                if (res.data.insertCnt > 0) {
                                    // 진열 추가로 페이지를 처음 저장한 경우에는 iframe_html_id를 받아서 저장한다
                                    if(this.state.iframe_html_id == "" || this.state.iframe_html_id == null){
                                        this.setState({
                                            iframe_html_id : res.data.iframe_html_id,
                                            iframe_page_id : res.data.iframe_page_id,
                                        }, () => {
                                            // 왼쪽 영역 저장값 불러오기 (초기 저장시 : 위치는 저장후에 변경 불가능 하기 때문에 다시 불러와서 해당 설정을 적용시킨다)
                                            this.loadRpEventSetting(true,true);
                                            this.loadRpEventSettingList(true);
                                            // 링크 주소에 param값으로 iframe_html_id와 iframe_page_id를 붙인 형태로 변경한다.
                                            window.history.replaceState("","",this_location+"?page_id="+this.state.iframe_page_id+"&html_id="+this.state.iframe_html_id);
                                            this_location = this_location+"?page_id="+this.state.iframe_page_id+"&html_id="+this.state.iframe_html_id;
                                        });
                                    }
                                    this.saveExcludeProductList();
                                    this.saveExceptSettingList(true);

                                    if(firstSave){
                                        this.setState({
                                            left_is_modified : false,
                                            confirm_dialog_show_state: true,
                                            confirm_dialog_content: "진열 자동화 설치하시겠습니까?",
                                            confirm_dialog_content2 : "이제 설정된 내 쇼핑몰 상품 진열을<br/>로보MD 자동 진열로 바꾸시려면<br/>설치를 요청주셔야 합니다.<br/><br/>설치를 요청하시겠습니까?",
                                            confirm_dialog_button_list: [
                                                {
                                                    title: "네",
                                                    type: "1",
                                                    event_method: () => {
                                                        //팝업 닫기
                                                        this.setState({
                                                            confirm_dialog_show_state: false,
                                                            confirm_dialog_content2: null,
                                                            save_dialog_show_state: true,
                                                            save_dialog_content: "저장 중 입니다.",
                                                            save_dialog_button_list: [
                                                                {
                                                                    title: "확인",
                                                                    type: "1",
                                                                    display_yn:"N",
                                                                    event_method: () => {}
                                                                }
                                                            ]
                                                        });
                                                        this.onClickSaveRightSetting(true);
                                                        this.goInstallQnaPage();
                                                    }
                                                },
                                                {
                                                    title: "아니요",
                                                    type: "0",
                                                    event_method: () => {
                                                        //팝업 닫기
                                                        this.setState({
                                                            confirm_dialog_content: "상담예약",
                                                            confirm_dialog_content2 : "좀 더 상세하게 서비스 상담 및<br/>사용법을 안내해드릴까요?",
                                                            confirm_dialog_button_list: [
                                                                {
                                                                    title: "네",
                                                                    type: "1",
                                                                    event_method: () => {
                                                                        //팝업 닫기
                                                                        this.setState({
                                                                            confirm_dialog_show_state: false,
                                                                            confirm_dialog_content2 : null,
                                                                        });
                                                                        this.goConsultingPage();
                                                                    }
                                                                },
                                                                {
                                                                    title: "아니요",
                                                                    type: "0",
                                                                    event_method: () => {
                                                                        //팝업 닫기
                                                                        this.setState({
                                                                            confirm_dialog_show_state: false,
                                                                            confirm_dialog_content2 : null
                                                                        });
                                                                    }
                                                                }
                                                            ]
                                                        });
                                                    }
                                                }
                                            ]
                                        });
                                    } else{
                                        this.setState({
                                            left_is_modified : false,
                                            confirm_dialog_show_state: false,
                                            confirm_dialog_content: null,
                                            confirm_dialog_content2 : null,
                                            save_dialog_show_state: true,
                                            save_dialog_content: "저장됐습니다.",
                                            save_dialog_button_list: [
                                                {
                                                    title: "확인",
                                                    type: "1",
                                                    event_method: () => {
                                                        //팝업 닫기
                                                        this.setState({ save_dialog_show_state: false });
                                                    }
                                                }
                                            ]
                                        });
                                    }
                                } else {
                                    this.setState({
                                        confirm_dialog_show_state: false,
                                        confirm_dialog_content: null,
                                        confirm_dialog_content2 : null,
                                    });
                                    alert('진열 설정 저장에 실패하였습니다.');
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    confirm_dialog_show_state: false,
                                    confirm_dialog_content: null,
                                    confirm_dialog_content2 : null,
                                });
                                alert('진열 설정 저장에 실패하였습니다.');
                            });

                            this.setGaEvent("저장");
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                confirm_dialog_show_state: false,
                                confirm_dialog_content: null,
                                confirm_dialog_content2 : null,
                            });
                        }
                    }
                ]
            }, () => {
                if(!widgetListExpandCheckRef.current.checked){
                    widgetListExpandRef.current.click();
                }
                rpTitleRef.current.scrollIntoView({block:"start"});
            });
        }
    }

    /** 왼쪽 영역 취소 버튼 클릭(초기화) */
    onClickResetLeftSetting(){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else{
            // 자동 진열된 상품 목록 관련 팝업 닫기
            // this.allCloseEventListPopup();
            // this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            // this.setState({
            //     show_add_product_popup : false, // 상품 추가 팝업 닫기
            // });

            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: "설정 값 변경을 취소하시겠습니까?",
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.setState({
                                left_is_modified : false, // 왼쪽 영역 수정 여부
                                total_item_select_cnt_range : [], // 상품 선정 수 선택을 위한 리스트
                                big_category_checked_list : [], // 대 카테고리 선택 리스트
                                selected_category_no : "",
                                multi_category_list : [],

                                rp_check_product_list : [], // 오른쪽 영역 선택된 상품목록
                                // 상품 카테고리 목록
                                product_rp_category_list_1:[],
                                product_rp_category_list_2:[],
                                product_rp_category_list_3:[],
                                product_rp_category_list_4:[],

                                force_category_list_1: [],
                                force_category_list_2: [],
                                force_category_list_3: [],
                                force_category_list_4: [],
                                force_category: {},

                                product_rp_category_no_1: "",
                                product_rp_category_no_2: "",
                                product_rp_category_no_3: "",
                                product_rp_category_no_4: "",
                            }, () => {
                                this.loadProductCategory("", 1, "product_rp_category_list_");
                                this.loadProductCategory("", 1, "force_category_list_");
                                this.loadExcludeProductList();
                                this.loadExceptSettingList();
                                // 진열 추가 페이지일 경우
                                if(this.state.iframe_html_id == null || this.state.iframe_html_id==""){
                                    this.loadDefaultSetting();
                                } else{ // 저장한 기록이 있는 페이지일 경우
                                    this.loadRpEventSetting(true);
                                }
                                this.setState({
                                    confirm_dialog_show_state : false,
                                    basic_dialog_show_state: true,
                                    basic_dialog_content: "변경이 취소되었습니다.",
                                    basic_dialog_button_list: [
                                        {
                                            title: "확인",
                                            type: "1",
                                            event_method: () => {
                                                //팝업 닫기
                                                this.setState({ basic_dialog_show_state: false });
                                            }
                                        }
                                    ]
                                });
                            });
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                confirm_dialog_show_state: false,
                            });
                        }
                    }
                ]
            });
        }
    }

    /* ---------------------------------- 왼쪽 영역 관련 함수 --------------------------------------- */

    /** 진열 추가시 로드되는 왼쪽영역 기본세팅값 */
    async loadDefaultSetting(){
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=36000")
		.then(res => {
			if (res.data != null && res.data != '') {

                display_name = res.data[3].val1;
                display_name_cd = res.data[4].val1;

				this.setState({
                    /* upper_code_cd=37000을 사용하는 경우 */
                    // display_type : res.data[0].val1,
                    // platform_type : res.data[1].val1,
                    // iframe_page_id : res.data[2].val1,
                    // display_name : res.data[3].val1,
                    // display_name_cd : res.data[4].val1,
                    // event_view_cnt : res.data[5].val1,
                    // event_mobile_view_cnt : res.data[6].val1,
                    // transition_mode : res.data[7].val1,
                    // transition_pause : res.data[8].val1,
                    // transition_speed : res.data[9].val1,
                    // auto_rolling_yn : res.data[10].val1,
                    // item_select_type : res.data[11].val1,
                    // event_total_item_select_cnt : res.data[12].val1,
                    // auto_create_yn : res.data[13].val1,
                    // event_cycle : res.data[14].val1,
                    // event_time : res.data[15].val1,
                    // event_type_cd : res.data[16].val1,
                    // event_category_select_type : res.data[17].val1,
                    // event_item_price_show_type : res.data[18].val1,
                    // iframe_html_name : res.data[19].val1,
                    // event_item_created_period : res.data[20].val1,
                    // event_item_analysis_period : res.data[21].val1,

                    display_name_use_yn : res.data[4].val1 == "33099"? "N" : "Y",
                    sub_display_name_use_yn : res.data[4].val2 == "33099"? "N" : "Y",
                    display_type : res.data[0].val1,
                    platform_type : res.data[1].val1,
                    iframe_page_id : res.data[2].val1,
                    display_name : "추천 진열제목 및 직접입력", // res.data[3].val1,
                    display_name_cd : null, // res.data[4].val1,
                    sub_display_name : "추천 진열제목 및 직접입력", // res.data[3].val1,
                    sub_display_name_cd : null, // res.data[4].val1,
                    event_view_cnt : res.data[5].val1,
                    event_mobile_view_cnt : res.data[6].val1,
                    event_category_list : res.data[7].val1,
                    item_select_type : res.data[8].val1,
                    event_total_item_select_cnt : res.data[9].val1,
                    auto_create_yn : res.data[10].val1,
                    event_cycle : res.data[11].val1,
                    event_time : res.data[12].val1,
                    event_type_cd : res.data[13].val1,
                    event_category_select_type : res.data[14].val1,
                    event_item_price_show_type : res.data[15].val1,
                    transition_mode : res.data[16].val1,
                    transition_pause : res.data[17].val1,
                    transition_speed : res.data[18].val1,
                    auto_rolling_yn : res.data[19].val1,
                    iframe_html_name : res.data[20].val1,
                    event_item_created_period : res.data[21].val1,
                    event_item_analysis_period : res.data[22].val1,
                    image_type_cd : res.data[23].val1,
                    link_open_type : res.data[24].val1,
                    show_rp_product_list_table_loading_bar : false,
                    force_logic: '22001',
                    force_is_except_invisible_category: 'N',
                    force_analysis_period: 0,
                    force_select_created_yn: 'N',
                    force_created_period: 0,
                    force_category: {
                        "product_rp_category_no_1": "",
                        "product_rp_category_no_2": "",
                        "product_rp_category_no_3": "",
                        "product_rp_category_no_4": "",
                    },
                }, () => {
                        // 카테고리 종류에 따라 카테고리 번호를 받아오기
                        if(this.state.event_category_select_type=="34001"){
                            const category_list = this.state.event_category_list.split(',');
                            const big_category_checked_list = [];
                            for(var i=0;i<category_list.length;i++){
                                big_category_checked_list.push(category_list[i]);
                            }
                            this.setState({
                                big_category_checked_list : big_category_checked_list,
                                multi_category_list : [...big_category_checked_list],
                                show_rp_product_list_table_loading_bar : true,
                            }, () => {
                                this.loadBigCategoryProductList();
                            });
                        }

                        this.setState({is_left_load_first_setting : true});

                        if(this.state.highlightType == 'design'){
                            highlightDesign.current.scrollIntoView({block:"center"});
                        } else if(this.state.highlightType == 'random'){
                            highlightRandom.current.scrollIntoView({block:"center"});
                        }

                        let this_component = this;
                        document.body.addEventListener('click', () => {
                            this_component.setState({highlightType : ""});
                        });
                });
			}
		})
		.catch(error => {
		});
    }

    onClickEventFolderForce() {
        const target = "#list_select_expand_force";
        let list_select_height = $(target).outerHeight();
        let list_select_1_height = $(target).next(".list_select_expand_1").outerHeight();
        let {force_select_area_checked} = this.state;

        if($(target).find(".list_select_expand_check").is(":checked")){ // 펼치기
            $(target).parent(".list_select_expand_wrap").css({height :list_select_height+list_select_1_height});
            setTimeout(() => {
                if($(target).find(".list_select_expand_check").is(":checked")){
                    $(target).parent(".list_select_expand_wrap").css("overflow","visible");
                    $(target).parent(".list_select_expand_wrap").css({height :"auto"});
                }
            },200);
            force_select_area_checked = false;
        }
        else{ // 접기
            $(target).parent(".list_select_expand_wrap").css("overflow","hidden");
            $(target).parent(".list_select_expand_wrap").css({height :list_select_height});
            force_select_area_checked = true;
        }
        this.setState({
            force_select_area_checked: force_select_area_checked,
        })

    }

    /** 알고리즘 설정 여닫기
     * @param {*} idx 알고리즘 순서
     * @param {*} cnt_focus 포커싱할 상품 선정수 id값, null이 아닐때만 포커싱
    */
    onClickEventFolder(idx, cnt_focus) {
        const target = "#list_select_expand_" + idx;

        let list_select = $(target).outerHeight();
        let list_select_1 = $(target).next(".list_select_expand_1").outerHeight();
        let list_select_total = list_select + list_select_1;
        let { list_self_select } = this.state;
        const parentWrap = $(target).parent(".list_select_expand_wrap");
        const expandContent = parentWrap.children(".list_select_expand_1");
    
        if ($(target).find(".list_select_expand_check").is(":checked")) { // 펼치기
            if (cnt_focus != null) {
                expandContent.removeClass("hidden_algorithm").addClass("visible_algorithm");
                setTimeout(() => {
                    parentWrap.css({ overflow: "visible", height: "auto" });
                    $(cnt_focus).focus();
                }, 20) 
            } else {
                parentWrap.css({ height: list_select_total });
                setTimeout(() => {
                    if ($(target).find(".list_select_expand_check").is(":checked")) {
                        parentWrap.css({ overflow: "visible", height: "auto" });
                        expandContent.removeClass("hidden_algorithm").addClass("visible_algorithm");
                    }
                }, 20);
            }
            list_self_select[idx].self_select_area_checked = true;
        } else { // 접기
            parentWrap.css({ overflow: "visible"})
            expandContent.removeClass("visible_algorithm").addClass("hidden_algorithm");
            list_self_select[idx].self_select_area_checked = false;
            setTimeout(() => {
                parentWrap.css({ height: "auto" });
            }, 20);
        }
        this.setState({
            list_self_select: list_self_select,
        });
    }

    /**
     * 알고리즘 조작 : 인터렉션 후 UI 접기
     */
    foldAlgorithm(idx) {
        const target = "#list_select_expand_" + idx;
        let { list_self_select } = this.state;
        const parentWrap = $(target).parent(".list_select_expand_wrap");
        const expandContent = parentWrap.children(".list_select_expand_1");
        parentWrap.css({ overflow: "visible"})
        expandContent.removeClass("visible_algorithm").addClass("hidden_algorithm");
        list_self_select[idx].self_select_area_checked = false;
        $("#list_select_arrow_"+idx).prop("checked",list_self_select[idx].self_select_area_checked);
        this.setState({
            list_self_select: list_self_select,
        });
        setTimeout(() => {
            parentWrap.css({ height: "auto" });
        }, 20);
    }

    /** 알고리즘 설정 여닫기 : 애니메이션 없음
     * @param {*} idx 알고리즘 순서
    */
     onClickEventFolderNoneAni(idx){
        const target = "#list_select_expand_"+idx;
        let list_select = $(target).outerHeight();

        if($(target).find(".list_select_expand_check").is(":checked")){ // 펼치기
            $(target).parent(".list_select_expand_wrap").css("overflow","visible");
            $(target).parent(".list_select_expand_wrap").css({height :"auto"});
        }
        else{ // 접기
            $(target).parent(".list_select_expand_wrap").css("overflow","hidden");
            $(target).parent(".list_select_expand_wrap").css({height :list_select});
        }
    }

     /** '대 카테고리 사용 설정' 팝업 높이 구하기 (최대 5개까지 보이고 그 이후 스크롤) */
    category_list_setting_table(){
        let category_list_thead_height = $(".category_list_setting_table thead td").height();
        let category_list_td_height = $(".category_list_setting_table tbody td").height();
        let category_list_td_max_height = category_list_td_height * 5;
        let category_list_total_height = category_list_thead_height + category_list_td_max_height;
        $(".category_list_setting_table_wrap").css({
            "height":category_list_total_height
        });
    }

    /** 진열 방식 관련 스타일 */
    listTypeStyle(){
        if(this.state.display_type == "31001"){
            $(".list_admin_side_rolling").css({
                "display":"none",
                "height":"0"
            });
        }
        else {
            // 첫 로드 시, 모바일 화면일 것을 대비하여, 로드중에는 열리지 않도록 방지한다
            if(!this.state.is_left_load_first_setting){
                $(".list_admin_side_rolling").css({
                    "display":"block",
                    "height":"auto"
                });
                $("#list_side_title_arrow_rolling").prop("checked", true);
            }
        }
    }

    /** 왼쪽 영역 선택지들 불러오기 */
    async loadUpperCodeList() {

        // 이미지 타입 (목록이미지, 상세이미지)
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=45000")
        .then(res => {
            if (res.data != null && res.data != '') {
                this.setState({
                    image_type_list : res.data,
                });
            }
            load_upper_num += 1;
        })
        .catch(error => {
            load_upper_num += 1;
        });        
        
        // 진열 방식(나열, 롤링)
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=31000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    display_type_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 위치
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=16000"+is_test_use)
		.then(res => {
			if (res.data != null && res.data != '') {
                let iframe_page_id_list = res.data;
                // 헤더페이지는 마켓비와 테스트 계정에서만 보이도록 한다.
                if(user_id != "test_rp" && user_id != "marketb"){
                    for(var i=0;i<iframe_page_id_list.length;i++){
                        for(var i=0;i<iframe_page_id_list.length;i++){
                            if(iframe_page_id_list[i].code_cd == "16002"){
                                iframe_page_id_list.splice(i, 1);
                                i--;
                                continue;
                            }
                            if(iframe_page_id_list[i].val2 == "cafe24"){
                                iframe_page_id_list.splice(i, 1);
                                i--;
                            }
                        }
                    }
                }
                this.setState({
                    iframe_page_id_list : iframe_page_id_list,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 링크 열기 타입
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=48000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    link_open_type_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 제목
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=33000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    display_name_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 가격
        await http.get("/rest/shop/rp/event/price/option")
		.then(res => {
            if (res.data != null && res.data != '') {
				this.setState({
                    event_item_price_show_type_list : res.data["list"],
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		 });

        // 디자인 : 롤링
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=40000"+is_test_use)
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    event_type_list_rolling : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 디자인 : 나열
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=42000"+is_test_use)
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState({
                    event_type_list_nayeol : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 롤링 방식
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=38000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    transition_mode_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 롤링 이동 텀
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=29000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    transition_pause_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 롤링 속도
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=28000")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState( {
                    transition_speed_list : res.data,
                });
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

         // 상품 선정 알고리즘 (~중에)
         await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=A2000"+is_test_use)
         .then(res => {
             if (res.data != null && res.data != '') {
                 this.setState({
                     item_condition_list : res.data,
                 });
             }
             load_upper_num += 1;
         })
         .catch(error => {
             load_upper_num += 1;
         });

        // 상품 선정 알고리즘
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=22000"+is_test_use)
		.then(res => {
			if (res.data != null && res.data != '') {
				// 테스트 및 마켓비 계정의 경우 로보MD 베스트 추천이 아닌 마켓비 베스트 추천으로 보이도록 함
	            if(user_id == "test_rp" || user_id == "marketb"){
	                let item_select_type_list = res.data;
	                for(var i=0;i<item_select_type_list.length;i++){
	                    if(item_select_type_list[i].code_cd == "22001"){
	                        item_select_type_list[i].val1 = "마켓비 베스트 추천"
	                    }
	                }
	                this.setState({
	                    item_select_type_list : item_select_type_list
	                }, () => {
	                    // 직접 지정 영역 기본값 1개
	                    this.addListSelfSelect(true);
	                });
	            } else{
	            	let item_select_type_list = res.data;
	                this.setState({
	                    item_select_type_list : item_select_type_list,
	                }, () => {
	                    // 직접 지정 영역 기본값 1개
	                    this.addListSelfSelect(true);
	                });
	            }
			}
            load_upper_num += 1;
		})
		.catch(error => {
            load_upper_num += 1;
		});

        // 상품 정렬 타입(순서대로, 랜덤)
        await http.get("/rest/common/cafe24RpEventCode?upper_code_cd=51000")
        .then(res => {
            if (res.data != null && res.data != '') {
                this.setState({
                    product_order_type_list : res.data,
                });
            }
            load_upper_num += 1;
        })
        .catch(error => {
            load_upper_num += 1;
        });

        // 새로운 페이지가 아니라면, 기존 진열설정 및 상품 목록을 불러온다
        var page_id = this.state.iframe_page_id;
        var html_id = this.state.iframe_html_id;
        if(page_id != null && html_id != null && page_id != "" && html_id != ""){
            this.loadRpEventSetting();
        } else{
            this.loadDefaultSetting();
        }
        
        // 카페24 API 사용자 권한 조회
        await http.get("/rest/shop/cafe24/api/userScopes")
		.then(res => {
			if (res.data != null && res.data != '') {
				this.setState({ cafe24api_userScopes : res.data.cafe24ApiUserScopesMap.scopes }, () => 
				{
					const arr_cafe24api_userScopes = this.state.cafe24api_userScopes.split(',').map(s => s.trim());
					let item_select_type_list = this.state.item_select_type_list;
					//개인정보 읽기권한	(찜)
		            if (!arr_cafe24api_userScopes.includes("mall.read_privacy")) {
		            	item_select_type_list = item_select_type_list.filter(item => (item.code_cd != "22015" && item.code_cd != "22016"));
		            }
		            //개인화정보 읽기권한 (장바구니)
		            if (!arr_cafe24api_userScopes.includes("mall.read_personal")) {
		            	item_select_type_list = item_select_type_list.filter(item => (item.code_cd != "22017" && item.code_cd != "22018"));
		            }
		            this.setState({ item_select_type_list : item_select_type_list });
				});
			}
		}).catch(error => {
			console.log("## userScopes error ##");
		});
    }

    /** 진열 제목 변경 */
    onChangeListName(e){
        this.setState({
            iframe_html_name:  e.target.value,
            left_is_modified : true,
        });
    }

    /** 진열 방식 변경 */
    onChangeListType(e){
        this.setState({
            display_type : e.target.value,
            left_is_modified : true,
        }, () => {
            if(this.state.display_type == "31001"){
                this.setState({
                    event_type_cd : this.state.event_type_list_nayeol[0].code_cd
                }, () => {
                    this.loadEventTemplateImage();
                    this.listTypeStyle();
                });
            } else{
                // 롤링의 경우 모바일 상품 개수 고정
                this.setState({
                    event_mobile_view_cnt : this.state.rolling_event_mobile_view_cnt,
                    event_type_cd : this.state.event_type_list_rolling[0].code_cd
                }, () => {
                    this.loadEventTemplateImage();
                    this.listTypeStyle();
                });
            }
            this.countTotalItemSelectCntRange();
        });
    }

    /** 위치 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeListPosition(text, value, id){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            return false;
        }

        this.setState({
            show_add_product_popup : false, // 상품 추가 팝업 닫기
            show_exclude_product_list_popup : false, // 제외 상품 팝업 닫기
            show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
            show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
            show_except_product_popup: false,
            show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
        }, () => {
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        });
        
        this.setState({
            iframe_page_id : value,
            left_is_modified : true,
        },() => {
            this.countTotalItemSelectCntRange();
            this.orderIdxReset(); // 순번 초기화
        });

        // 상세보기 페이지가 아니라면 카테고리 타입을 34002로 고정
        if(value!="robomd_product_detail_page"){
            if(this.state.event_category_select_type != "34002"){
                this.setState({
                    event_category_select_type: "34002",
                    event_category_list : "",
                });
                // 카테고리 종류가 변경되면서 상품목록이 변경되기 때문에 기존 체크도 풀려야 한다
                this.setState({
                    rp_check_product_list: [],
                });
            }
        }
    }

    /** 링크 열기 타입 변경 */
    onChangeLinkOpenType(e){
        this.setState({
            link_open_type : e.target.value,
            left_is_modified : true,
        });
    }

    /** 상품 선정수 가능 범위 계산 */
    countTotalItemSelectCntRange(){
        // 상세 페이지
        if(this.state.iframe_page_id == 'robomd_product_detail_page'){
            // 기존 상품 선정수가 변경될 상품 선정수 가능 범위보다 클 경우를 고려하여 변경
            this.totalItemSelectCntRangeCheck(this.state.event_category_select_type=="34001" ? detail_page_big_event_total_item_select_range : detail_page_event_total_item_select_range);
        } else{
            let total_item_select_cnt_range = [];
            if( this.state.max_item_analysis !== '1' ) total_item_select_cnt_range.push('자동');
            // 나열 또는 메인진열인 경우
            if(this.state.display_type == "31001"){
                for(var i=0; i<=nayeol_event_total_item_select_range; i++){
                    total_item_select_cnt_range.push(i);
                }
                this.setState({
                    total_item_select_cnt_range : total_item_select_cnt_range,
                });
            } else{
                // 롤링 선택시
                // 기존 상품 선정수가 변경될 상품 선정수 가능 범위보다 클 경우를 고려하여 변경
                this.totalItemSelectCntRangeCheck(rolling_event_total_item_select_range);
            }
        }
    }

    /** 기존 상품 선정수 초과 대응
     * @param {*} range 상품 선정수 최대 범위로 설정할 값
    */
    totalItemSelectCntRangeCheck(range){
        let {event_total_item_select_cnt} = this.state;
        let total_item_select_cnt_range = [];
        total_item_select_cnt_range.push('자동');
        if( this.state.max_item_analysis !== '1' ) total_item_select_cnt_range.push('자동');
        for(var i=0; i<=range; i++){
            total_item_select_cnt_range.push(i);
        }
        // 기존 상품 선정수가 변경될 상품 선정수 범위를 초과했을 경우
        if(event_total_item_select_cnt>range){
            this.setState({
                total_item_select_cnt_range : total_item_select_cnt_range,
            }, () =>{
                this.totalCntDialog();
            });
            return false;
        }
        this.setState({
            total_item_select_cnt_range : total_item_select_cnt_range,
        });
    }

    /** 위치 변경 : pc / mobile / pc+mobile
     * @param {*} e P or M or C
    */
    onChangeListPositionPM(e){
        this.setState({
            platform_type : e.target.value,
            left_is_modified : true,
        });
    }

    /** 제목 사용 여부 변경
     * @param {*} e Y or N
    */
    onChangeDisplayNameUseYn(e){
        // 사용안함을 누르면 제목도 사용안함 필드로 변경
        if(e.target.value == "N"){
            // 사용 안함을 누르기 전의 제목 정보를 담아둔다
            display_name_cd = this.state.display_name_cd;
            display_name = this.state.display_name;

            const {display_name_list} = this.state;
            for(var i=0;i<display_name_list.length;i++){
                if (display_name_list[i].code_cd == "33099"){
                    this.setState({
                        display_name_cd : "33099",
                        display_name : display_name_list[i].code_name,
                    });
                }
            }
        } else{
            // 사용함을 누르면 이전 제목 나타내기
            this.setState({
                display_name_cd : display_name_cd,
                display_name : display_name,
            });
        }
        this.setState({
            display_name_use_yn : e.target.value,
            left_is_modified : true,
        }, () => {
            this.loadEventTemplateImage();
        });
    }
    onChangeSubDisplayNameUseYn(e){
        // 사용안함을 누르면 제목도 사용안함 필드로 변경
        if(e.target.value == "N"){
            // 사용 안함을 누르기 전의 제목 정보를 담아둔다
            sub_display_name_cd = this.state.sub_display_name_cd;
            sub_display_name = this.state.sub_display_name;

            const {display_name_list} = this.state;
            for(var i=0;i<display_name_list.length;i++){
                if (display_name_list[i].code_cd == "33099"){
                    this.setState({
                        sub_display_name_cd : "33099",
                        sub_display_name : display_name_list[i].code_name,
                    });
                }
            }
        } else{
            // 사용함을 누르면 이전 제목 나타내기
            this.setState({
                sub_display_name_cd : sub_display_name_cd,
                sub_display_name : sub_display_name,
            });
        }
        this.setState({
            sub_display_name_use_yn : e.target.value,
            left_is_modified : true,
        }, () => {
            this.loadEventTemplateImage();
        });
    }

    /** 제목 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeDisplayName(text, value, id){
        // 사용 안함인 경우
        if(value == "33099"){
            // 사용 안함을 누르기 전의 제목 정보를 담아둔다
            display_name_cd = this.state.display_name_cd;
            display_name = this.state.display_name;
            this.setState({display_name_use_yn : "N"}, () => {
                this.loadEventTemplateImage();
            });
        }

        this.setState({
            display_name_cd : value,
            left_is_modified : true,
        });
        // 선택 사항 텍스트 적용
        // 직접 지정인 경우
        if(value == "33098"){
            this.setState({
                display_name : "",
            });
        } else{
            const {display_name_list} = this.state;
            for(var i=0;i<display_name_list.length;i++){
                if (display_name_list[i].code_cd == value){
                    this.setState({
                        display_name : display_name_list[i].code_name,
                    });
                }
            }
        }
    }
    onChangeSubDisplayName(text, value, id){
        // 사용 안함인 경우
        if(value == "33099"){
            // 사용 안함을 누르기 전의 제목 정보를 담아둔다
            sub_display_name_cd = this.state.sub_display_name_cd;
            sub_display_name = this.state.sub_display_name;
            this.setState({sub_display_name_use_yn : "N"}, () => {
                this.loadEventTemplateImage();
            });
        }

        this.setState({
            sub_display_name_cd : value,
            left_is_modified : true,
        });
        // 선택 사항 텍스트 적용
        // 직접 지정인 경우
        if(value == "33098"){
            this.setState({
                sub_display_name : "",
            });
        } else{
            const {display_name_list} = this.state;
            for(var i=0;i<display_name_list.length;i++){
                if (display_name_list[i].code_cd == value){
                    this.setState({
                        sub_display_name : display_name_list[i].code_name,
                    });
                }
            }
        }
    }

    /** 제목 : 직접 기입 input값 변경 */
    onChangeDisplayNameDirectly(e){
        const value = e.target.value;
        // 이모티콘이나 특수문자도 각각 하나로 숫자를 세어 28자가 넘는지 확인한다
        if(value != "" && value.match(/./gu).length > 28){

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "제목은 28자 이내로 작성해주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            $("#self_title_input").focus();
                        }
                    }
                ]
            });
        } else{
            this.setState({
                display_name : value,
                left_is_modified : true,
            });
        }
    }
    onChangeSubDisplayNameDirectly(e){
        const value = e.target.value;
        // 이모티콘이나 특수문자도 각각 하나로 숫자를 세어 28자가 넘는지 확인한다
        if(value != "" && value.match(/./gu).length > 28){

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "제목은 28자 이내로 작성해주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            $("#self_title_input").focus();
                        }
                    }
                ]
            });
        } else{
            this.setState({
                sub_display_name : value,
                left_is_modified : true,
            });
        }
    }

    /**
     * 이미지 타입 변경
     * @param {*} text 
     * @param {*} value 
     * @param {*} id 
     */
    onChangeImageType(text, value, id){
        this.setState({
            image_type_cd : value,
            left_is_modified : true,
        });
    }    

    /**
     * 알고리즘 조작 : 해당 알고리즘 복사 붙여넣기
     * @param {} idx : 복사 요청 대상 알고리즘.
     */
    copyPasteAlgorithm = (e, idx) => {
        e.stopPropagation();
        const {item_select_type_list ,list_self_select} = this.state
        /** 관련 상품명 <- 추가 */
        if(list_self_select.length > 99){
            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "알고리즘 추가는<br/>최대 100개까지만 가능합니다.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // $("#self_title_input").focus();
                        }
                    }
                ]
            });

            return false;
        }
        item_select_type_list.every((ele) => {
            if(ele.val1 != null && ele.val1 !== ""){ 
                list_self_select.push({
                    ...list_self_select[idx],
                    self_select_area_checked: false,
                    uuid: StringUtil.generateUUID(),
                })
            } 
        })


        this.setState({
            list_self_select,
            left_is_modified : true
        }, () => {
            this.SumEventTotalItemSelectCnt();
            /** 모든 알고리즘 expand 줄이기 */
            for(var i=0;i<this.state.list_self_select.length; i++){
                $("#list_select_arrow_"+i).prop("checked",this.state.list_self_select[i].self_select_area_checked);
                this.foldAlgorithm(i);
            }
        });
    }

    /**
     * 알고리즘 조작 : 해당 알고리즘 삭제
     */
    onDeleteAlgorithm = (e, idx) => {
        e.stopPropagation();
        if(this.state.list_self_select.length < 2) {
            this.basicDialogSimpleInform("최소 한개의 알고리즘이 필요합니다.")
            return
        }
        this.titleContentDialogForm(
            "선택하신 제품 알고리즘을 삭제하시겠습니까?",
            "",
            {
                title: "네",
                type: "1",
                event_method: () => {
                    const {list_self_select} = this.state;

                    list_self_select.splice(idx,1);

                    this.setState({
                        list_self_select : list_self_select,
                        left_is_modified : true,
                        title_content_dialog_show_state: false,
                    }, () => {
                        this.SumEventTotalItemSelectCnt();
                        // 삭제 후에 체크값을 유지하기 위한 작업
                        for(var i=0;i<this.state.list_self_select.length; i++){
                            $("#list_select_arrow_"+i).prop("checked",this.state.list_self_select[i].self_select_area_checked);
                            this.foldAlgorithm(i);
                        }
                    });
                }
            }
        )
    }

    /**
     * 알고리즘 조작 : 해당 알고리즘 순서 위로 변경
     */
    onChangeUpSequenceAlgorithm = (e, idx) => {
        e.stopPropagation();
        if (idx <= 0) {
            this.basicDialogSimpleInform("이미 가장 처음 위치입니다.")
            return
        }
        this.setState(prevState => {
            const list = [...prevState.list_self_select]
            const temp = list[idx - 1];
            list[idx - 1] = list[idx];
            list[idx] = temp;

            return {list_self_select: list}
        })
    }
    /**
     * 알고리즘 조작 : 해당 알고리즘 순서 아래로 변경
     */
    onChangeDownSequenceAlogorithm = (e, idx) => {
        e.stopPropagation();
        const {list_self_select} = this.state
        if (idx === list_self_select.length - 1) {
            this.basicDialogSimpleInform("이미 가장 마지막 위치입니다.")
            return
        }
        this.setState(prevState => {
            const list = [...prevState.list_self_select]
            const temp = list[idx + 1];
            list[idx + 1] = list[idx];
            list[idx] = temp;

            return {list_self_select: list}
        })
    }

    onDragStartAlgorithm = (e, idx) => {
        e.dataTransfer.setData("draggedItemIndex", idx);
    }

    onDragOverAlgorithm = (e) => {
        e.preventDefault()
    }

    onDropAlgorithm = (e, dropIndex) => {
        const draggedItemIndex = e.dataTransfer.getData("draggedItemIndex");
        if (draggedItemIndex !== dropIndex) {
            let list = [...this.state.list_self_select];
            const draggedItem = list[draggedItemIndex];
            list.splice(draggedItemIndex, 1);
            list.splice(dropIndex, 0, draggedItem);
            this.setState({ list_self_select: list });
          }
    }


    /** 가격 타입 변경 */
    onChangePriceShowType(e){
        this.setState({
            event_item_price_show_type : e.target.value,
            left_is_modified : true,
        }, () => {
            this.loadEventTemplateImage();
        });
    }

    /** 디자인 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventTypeList(text, value, id){
        this.setState({
            event_type_cd : value,
            left_is_modified : true,
        }, () => {
            this.loadEventTemplateImage();
        });
    }

    /** 디자인 이미지를 불러오는 함수 */
    async loadEventTemplateImage() {

        let sendForm = {
            code_cd: this.state.event_type_cd,
        }
        /* 템플릿 서브타이틀 사용 유무 조회 */
        await http.get("/rest/shop/rp/event/template/subTitleUseYn", {params:sendForm})
        .then(res => {
            if(res.data != null && res.data != ''){
                this.setState({
                    template_sub_title_use_yn: res.data.sub_title_use_yn,
                }, () => {

                    sendForm = {
                        code_cd: this.state.event_type_cd,
                        display_name_use_yn: this.state.display_name_use_yn,
                        sub_display_name_use_yn: this.state.template_sub_title_use_yn == null || this.state.template_sub_title_use_yn == "N" || this.state.template_sub_title_use_yn == ""
                            ? "N" // 템플릿이 서브타이틀을 사용하지 않는 경우
                            : this.state.sub_display_name_use_yn, // 템플릿이 서브타이틀을 사용하는 경우
                        event_item_price_show_type: this.state.event_item_price_show_type,
                    }
                    /* 템플릿 이미지 조회 */
                    http.get("/rest/shop/rp/event/template", {params:sendForm})
                    .then(res => {
                        if(res.data != null && res.data != ''){
                            this.setState({
                                template_image_url: res.data.template_image_url,
                            });
                        }
                    })
                    .catch(error => {
                    });
                });
            }
        })
    }

    /** 가로줄당 상품 개수 변경 : PC (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventViewCnt(text, value, id){
        this.setState({
            event_view_cnt : value,
            left_is_modified : true,
        });
    }

    /** 가로줄당 상품 개수 변경 : Mobile (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventMobileViewCnt(text, value, id){
        this.setState({
            event_mobile_view_cnt : value,
            left_is_modified : true,
        });
    }

    /** 롤링 방식 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeTransitionMode(text, value, id){
        this.setState({
            transition_mode : value,
            left_is_modified : true,
        });
    }

    /** 롤링 이동 텀 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeTransitionPause(text, value, id){
        this.setState({
            transition_pause : value,
            left_is_modified : true,
        });
    }

    /** 롤링 속도 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeTransitionSpeed(text, value, id){
        this.setState({
            transition_speed : value,
            left_is_modified : true,
        });
    }

    /** 자동 롤링 기능 변경
     * @param {*} e Y or N
    */
    onChangeAutoRollingYn(e){
        this.setState({
            auto_rolling_yn : e.target.value,
            left_is_modified : true,
        });
    }

    /** 상품 카테고리 종류 변경
     * @param {*} e 34001(다중카테고리) or 34002(단일카테고리)
    */
    onChangeEventCategorySelectType(e){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else{
            this.setState({
                show_add_product_popup : false, // 상품 추가 팝업 닫기
                show_exclude_product_list_popup : false, // 제외 상품 팝업 닫기
                show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
                show_except_product_popup: false,
                show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기

                event_category_select_type : e.target.value,
                left_is_modified : true,
            }, ()=>{
                this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
                if(this.state.event_category_select_type=="34002"){
                    this.setState({event_category_list: ""});
                } else{
                    const {big_category_checked_list} = this.state;
                    let event_category_list = "";
        
                    for(var i=0;i<big_category_checked_list.length;i++){
                        if(i == big_category_checked_list.length-1){
                            event_category_list += big_category_checked_list[i];
                        } else{
                            event_category_list += big_category_checked_list[i]+",";
                        }
                    }
                    this.setState({event_category_list: event_category_list,});

                    // 대카테고리 목록 리스트가 비어있다면, 대카테고리 선정 팝업을 자동으로 연다
                    if(big_category_checked_list.length <= 0){
                        this.onClickEventCategorySelectPopup();
                    }
                }
                this.setState({
                    rp_check_product_list: [],
                });
                this.orderIdxReset(); // 순번 초기화
                this.countTotalItemSelectCntRange(); // 상품 선정수 가능 범위 계산
                // 오른쪽 영역 : 테이블 max-height 변경을 위한 부분
                setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
            });
        }
    }

    /** 강제 상품 설정 카테고리 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
     onChangeForceCategory = (text, value, id) => {
        const current_category = id.split('/')[1];
        const depth = Number(current_category) + 1; // Number(current_category.split('_')[4])+1;
        var category_name = "product_rp_category_no_"+(Number(depth)-1);
        let {force_category} = this.state;
        var event_category_list;
            
        // 전체상품을 선택한 경우, 대카테고리가 아니면 상위 카테고리 번호를 대표 카테고리 번호로 설정
        if((value==="전체상품" || value==="")){
            if(Number(depth)>2){
                const parent_category_no = force_category["product_rp_category_no_"+(Number(depth)-2)];
                event_category_list = parent_category_no;
            } else{ // 대카테고리인 경우 ""로 변경
                event_category_list = "";
            }
        }else{
            event_category_list = value;
        }
        force_category[category_name] = value;
        force_category["event_category_list"] = event_category_list;
        this.setState({
            force_category : force_category,
            left_is_modified : true,
        })

        // 세분류가 아니라면 하위카테고리 관련 작업을 진행
        if(depth < 5){

            // 선택한 카테고리의 자식 카테고리 목록을 불러온다
            if (value !=="전체상품" && value !=="") {
                this.loadProductCategory(value, depth, "force_category_list_", null);
            } else{
                var category_list = "force_category_list_"+depth;
                this.setState({ 
                    [category_list] : [],
                    force_category : force_category,
                    left_is_modified : true,
                });
            }

            // 선택한 카테고리의 하위 카테고리 리스트를 초기화 시킨다
            for(var idx = depth+1; idx <=4; idx++) {
                var category_list = "force_category_list_"+idx;
                this.setState({ 
                    [category_list] : [],
                    force_category : force_category,
                    left_is_modified : true,
                });
            }

            // 선택한 카테고리 이후의 자식 카테고리 넘버를 초기화 시킨다
            for(var idx = depth; idx <=4; idx++) {
                var category_name = "product_rp_category_no_"+idx;
                force_category[category_name] = "";
                this.setState({
                    force_category : force_category,
                    left_is_modified : true,
                });
            }
        }
    }

    /** 상품 카테고리 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeRpCategory = (text, value, id) => {
        const current_category = id.split('/')[0];
        const index = id.split('/')[1];
        const depth = Number(current_category.split('_')[4])+1;
        var category_name = "product_rp_category_no_"+(Number(depth)-1);
        let {list_self_select} = this.state;
        let list = JSON.parse(JSON.stringify(list_self_select));
        var event_category_list;
            
        // 전체상품을 선택한 경우, 대카테고리가 아니면 상위 카테고리 번호를 대표 카테고리 번호로 설정
        if((value=="전체상품" || value == "")){
            if(Number(depth)>2){
                const parent_category_no = list[index]["product_rp_category_no_"+(Number(depth)-2)];
                event_category_list = parent_category_no;
            } else{ // 대카테고리인 경우 ""로 변경
                event_category_list = "";
            }
        }else{
            event_category_list = value;
        }
        list[index][category_name] = value;
        list[index]["event_category_list"] = event_category_list;
        if(this.state.event_category_select_type=="34001"){
            this.setState({
                list_self_select : list,
                left_is_modified : true,
            })
        } else{
            this.setState({
                event_category_list : event_category_list,
                list_self_select : list,
                left_is_modified : true,
            })
        }

        // 세분류가 아니라면 하위카테고리 관련 작업을 진행
        if(depth < 5){

            // 선택한 카테고리의 자식 카테고리 목록을 불러온다
            if (value !="전체상품" && value !="") {
                this.loadProductCategory(value, depth, "product_rp_category_list_", index);
            } else{
                var category_list = "product_rp_category_list_"+depth;
                list[index][category_list] = [];
                this.setState({ list_self_select : list });
            }

            // 선택한 카테고리의 하위 카테고리 리스트를 초기화 시킨다
            for(var idx = depth+1; idx <=4; idx++) {
                var category_list = "product_rp_category_list_"+idx;
                list[index][category_list] = [];
                this.setState({ list_self_select : list});
            }

            // 선택한 카테고리 이후의 자식 카테고리 넘버를 초기화 시킨다
            for(var idx = depth; idx <=4; idx++) {
                var category_name = "product_rp_category_no_"+idx;
                list[index][category_name] = "";
                this.setState({
                    list_self_select : list,
                });
            }
        }
    }

    /** 직접 지정 : 선택지 추가
     * @param {*} none_modified default로 추가되는 경우(수정이 아닌 경우)
    */
    addListSelfSelect(none_modified){
        const {item_select_type_list, list_self_select, first_category_list} = this.state;

        const list = [...list_self_select]

        if(list.length > 99){

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "자동 상품 진열 추가는<br/>최대 100개까지만 가능합니다.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            $("#self_title_input").focus();
                        }
                    }
                ]
            });

            return false;
        }
        // 사용 가능한 첫번째 알고리즘으로 생성
        item_select_type_list.every(function(ele){
            if(ele.val1 != null && ele.val1 != ""){
                list.push({
                    cd : ele.code_cd,
                    cnt : '10',
                    event_category_list : '',
                    event_item_price_filter_yn: "Y",
                    min_hit_cnt:'',
                    max_hit_cnt:'',
                    min_cart_cnt:'',
                    max_cart_cnt:'',
                    min_price :'',
                    max_price:'',
                    min_order_cnt:'',
                    max_order_cnt:'',
                    min_order_price:'',
                    max_order_price:'',
                    min_review_cnt:'',
                    max_review_cnt:'',
                    min_review_point:'',
                    max_review_point:'',
                    min_quantity:'',
                    max_quantity:'',
                    event_item_created_period: "0",
                    event_item_created_period_type: "W",
                    // 상품 등록일 기준이 직접지정인지에 대한 여부
                    // 직접 지정이면서 값이 없는경우 상품 선정이 안되기 때문에 날짜무관으로 저장하고 프론트에서 다시 직접 지정으로 관리하기 위해 존재
                    self_select_created_yn : "N",
                    event_item_created_start_date: null,
                    event_item_created_end_date: null,
					event_item_analysis_period: "4",

                    product_rp_category_list_1: [...first_category_list],
                    product_rp_category_list_2:[],
                    product_rp_category_list_3:[],
                    product_rp_category_list_4:[],

                    product_rp_category_no_1: "",
                    product_rp_category_no_2: "",
                    product_rp_category_no_3: "",
                    product_rp_category_no_4: "",

                    product_keyword_list:[""],
                    product_except_keyword_list:[""],
                    color_list: [],

                    self_select_area_checked : none_modified ? true : false,

                    // 숨긴카테고리 등록상품 자동제외여부
                    is_except_invisible_category : "N",

                    event_item_condition_list : [
                        "A2999",
                        "A2999"
                    ],

                    // uuid 부여
                    uuid: StringUtil.generateUUID()
                });

                return false;
            }
        });
        if(none_modified){
            this.setState({
                list_self_select : list,
            }, () => {
                this.SumEventTotalItemSelectCnt();
            });
        } else{
            this.setState({
                list_self_select : list,
                left_is_modified : true,
            }, () => {
                this.SumEventTotalItemSelectCnt();
                this.onClickEventFolder(this.state.list_self_select.length-1);
            });
        }
    }

    /** 직접 지정 : 알고리즘 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelfSelectListCd(text, value, id) {
        let idx = id.split("_")[4];

        let {list_self_select} = this.state;
        let list = JSON.parse(JSON.stringify(list_self_select));
        list[idx]["cd"] = value;

        this.setState({
            list_self_select : list,
            left_is_modified : true,
        });
    }

    /** 직접 지정 : 알고리즘 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelfSelectListCdForce(text, value, id) {
        this.setState({
            force_logic : value,
            left_is_modified : true,
        });
    }

    /** 상품 선정 수 합계 구하기 */
    SumEventTotalItemSelectCnt(){
        let {list_self_select} = this.state;
        let event_total_item_select_cnt = 0;

        if( this.state.max_item_analysis == '1' ) {
        // Y값인 직접지정 영역을 활성화 시켜준다
            let showAlert = false;
            list_self_select.forEach(function(ele, idx){
                if( ele.cnt == '자동' ) {
                    showAlert = true;
                    ele.cnt = '0';
                }
                event_total_item_select_cnt += Number(ele.cnt);
            });
            if( showAlert == true ) {
                this.basicDialogSimpleInform("숫자는 자동에서 0개로 변경되었습니다.");
            }
        } else if( this.state.max_item_analysis == '2' ) {
            let total_ele_cnt = 0;
            let is_auto_cnt = false;
            list_self_select.forEach(function(ele, idx){
                if (ele.cnt == '자동') {
                    is_auto_cnt = true;
                } else {
                    total_ele_cnt+= Number(ele.cnt);
                }
            });
            if (total_ele_cnt > Number(this.state.max_item_analysis_value)) {
                event_total_item_select_cnt = Number(this.state.max_item_analysis_value);
            } else {
                if (is_auto_cnt == true) {
                    event_total_item_select_cnt = Number(this.state.max_item_analysis_value);
                } else {
                    event_total_item_select_cnt = total_ele_cnt;
                }
            }
        } else {
            let total_ele_cnt = 0;
            list_self_select.forEach(function(ele, idx){
                total_ele_cnt+= Number(ele.cnt);
            });
            if (this.state.force_use_yn != "Y") {
                if (Number(this.state.max_item_analysis_value) > total_ele_cnt) {
                    event_total_item_select_cnt = total_ele_cnt;
                } else {
                    event_total_item_select_cnt = Number(this.state.max_item_analysis_value);
                }
            } else {
                event_total_item_select_cnt = Number(this.state.max_item_analysis_value);
            }
        }
        
        this.setState({
            event_total_item_select_cnt : event_total_item_select_cnt,
        });
    }

    /** 상품 선정수 범위를 넘었을 경우 다이얼로그 */
    totalCntDialog(){
        let basic_dialog_content = "상품 선정 수 합계가 <br/> "+(this.state.total_item_select_cnt_range.indexOf("자동") >= 0 ? this.state.total_item_select_cnt_range.length-2 : this.state.total_item_select_cnt_range.length-1)+"이 넘지 않도록 입력해 주세요.";

        this.setState({
            basic_dialog_show_state: true,
            basic_dialog_content: basic_dialog_content,
            basic_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    event_method: () => {
                        //팝업 닫기
                        this.setState({ basic_dialog_show_state: false })
                        // 자동 진열 선정 영역 열기
                        if(!$("#list_side_title_arrow_2").is(":checked")){
                            $("#listCategoryExpand").trigger('click');
                        }

                        // 상품선정수 input focus 작업
                        const self_select_idx = this.state.list_self_select.length-1;
                        $("#list_select_arrow_"+self_select_idx).prop("checked",true);
                        this.onClickEventFolder(self_select_idx, "#EventTotalItemSelectCntSelectInput_"+self_select_idx);
                    }
                }
            ]
        });
    }

    /** 관련 상품명 변경
     * @param {*} e
     * @param {*} idx 영역 idx 번호
     * @param {*} keyword_list 영역 키워드 리스트 idx 번호 product_keyword_list
    */
    onChangeEventItemProductKeyword = (e, idx, keyword_idx) => {
        let input_value = e.target.value;
        let {list_self_select} = this.state;
        list_self_select[idx]["product_keyword_list"][keyword_idx] = input_value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        });
    }

    addEventItemProductKeyword = (idx,keyword_idx) => {
        let {list_self_select} = this.state;
        let keyword_list = list_self_select[idx]["product_keyword_list"];
        keyword_list = [...keyword_list.slice(0, keyword_idx+1), "", ...keyword_list.slice(keyword_idx+1)];
        list_self_select[idx]["product_keyword_list"] = [...keyword_list];
        this.setState({list_self_select : list_self_select});
    }

    delEventItemProductKeyword = (idx,keyword_idx) => {
        let {list_self_select} = this.state;
        let keyword_list = list_self_select[idx]["product_keyword_list"];
        keyword_list.splice(keyword_idx,1);
        list_self_select[idx]["product_keyword_list"] = [...keyword_list];
        this.setState({list_self_select : list_self_select});
    }

    /** 제외 키워드 변경
     * @param {*} e
     * @param {*} idx 영역 idx 번호
     * @param {*} keyword_list 영역 키워드 리스트 idx 번호 product_except_keyword_list
    */
    onChangeEventItemProductExceptKeyword = (e, idx, keyword_idx) => {
        let input_value = e.target.value;
        let {list_self_select} = this.state;
        list_self_select[idx]["product_except_keyword_list"][keyword_idx] = input_value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        });
    }

    addEventItemProductExceptKeyword = (idx,keyword_idx) => {
        let {list_self_select} = this.state;
        let keyword_list = list_self_select[idx]["product_except_keyword_list"];
        keyword_list = [...keyword_list.slice(0, keyword_idx+1), "", ...keyword_list.slice(keyword_idx+1)];
        list_self_select[idx]["product_except_keyword_list"] = [...keyword_list];
        this.setState({list_self_select : list_self_select});
    }

    delEventItemProductExceptKeyword = (idx,keyword_idx) => {
        let {list_self_select} = this.state;
        let keyword_list = list_self_select[idx]["product_except_keyword_list"];
        keyword_list.splice(keyword_idx,1);
        list_self_select[idx]["product_except_keyword_list"] = [...keyword_list];
        this.setState({list_self_select : list_self_select});
    }

    /** 판매가 변경
     * @param {*} e <e.target.name : min_price or max_price>
     * @param {*} idx 영역 idx 번호
    */
    onChangeEventItemPrice(e, idx, max_value){
        let input_value = StringUtil.inputOnlyNumberFormat(e.target.value, max_value);
        if(input_value.length > 9){input_value = input_value.slice(0, 9);}
        let {list_self_select} = this.state;
        list_self_select[idx][e.target.name] = input_value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        });
    }

    /** 알고리즘 분석 기간 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id
    */
    onChangeEventItemAnalysisPeried(text, value, id){
        const idx = id.split('_')[1];
        let {list_self_select} = this.state;
        list_self_select[idx]["event_item_analysis_period"] = value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        });
    }

    /** 데이터 분석 기간 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id
    */
    onChangeEventItemAnalysisPeriedForce(text, value, id){
        this.setState({
            force_analysis_period : value,
        });
    }

    /** 진열 최대 개수 설정 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id
    */
    onChangeMaxItemAnalysis(text, value, id){
        this.setState({
            max_item_analysis : value,
        }, () => {
            this.SumEventTotalItemSelectCnt();
            this.countTotalItemSelectCntRange();
        });
    }

    /** 진열 최대 개수 변경 (onClickOption)
     * @param {*} e <e.target.name : min_price or max_price>
    */
    onChangeMaxItemAnalysisValue(e){
        let input_value = StringUtil.inputOnlyNumberFormat(e.target.value);
        if(input_value.length > 4){input_value = input_value.slice(0, 4);}
        this.setState({
            max_item_analysis_value : input_value,
        }, () => {
            this.SumEventTotalItemSelectCnt();
        });
    }

    /** 상품등록일 기준 변경 변경 (onClickOption)
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} idx 셀렉트 박스 idx
    */
    onChangeEventItemCreatedPeriod(value, idx){
        let {list_self_select} = this.state;
        // 변경전 값이 직접 지정이라면, 날짜값을 비우고 변경한다
        if(value != '-1' && list_self_select[idx]["self_select_created_yn"] == 'Y'){
            list_self_select[idx].event_item_created_start_date = null;
            list_self_select[idx].event_item_created_end_date = null;
        }
        // 변경할 값에 따라 직접지정 여부 업데이트
        list_self_select[idx].self_select_created_yn = (value == '-1' ? "Y" : "N");

        // 값과 타입의 구분자인 '/'가 없는 경우는 0으로 취급 (-1 : 직접 지정, 0 : 날짜 무관)
        let [period_value, period_type] = value == "-999" // -999를 고르면, 무조건 날짜 무관(0) 값으로 전환
                                        ? [0, '']
                                        : String(value).includes('/') ? value.split('/') : [0,'w'];
        
        list_self_select[idx]["event_item_created_period"] = period_value;
        list_self_select[idx]["event_item_created_period_type"] = period_type;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        });
    }

    /** 상품등록일 기준 변경 변경 (onClickOption)
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} idx 셀렉트 박스 idx
    */
    onChangeEventItemCreatedPeriodForce(value){
        // 변경전 값이 직접 지정이라면, 날짜값을 비우고 변경한다
        if( value != '-1' && this.state.force_select_created_yn === 'Y' ){
            this.setState({
                force_created_start_date: null,
                force_created_end_date: null,
                left_is_modified : true,
            })
        }

        // 값과 타입의 구분자인 '/'가 없는 경우는 0으로 취급 (-1 : 직접 지정, 0 : 날짜 무관)
        let [period_value, period_type] = String(value).includes('/') ? value.split('/') : [0,'w'];

        // 변경할 값에 따라 직접지정 여부 업데이트
        this.setState({
            force_select_created_yn: (value == '-1' ? "Y" : "N"),
            force_created_period: period_value,
            force_created_period_type: period_type,
            left_is_modified : true,
        })
    }

    /** 상품등록일 기준 시작 날짜 변경
     * @param {*} date 선택한 날짜
     * @param {*} idx 리스트 내 idx 번호
    */
     onChangeSelectStartDate = (date, idx) => {
        let {list_self_select} = this.state;
        list_self_select[idx].event_item_created_start_date = date;
        this.setState({
            list_self_select: list_self_select,
            left_is_modified : true,
        }, () => {
            let {list_self_select} = this.state;
            // 변경된 시작 날짜가 종료날짜보다 크면, 시작날짜 값을 종료날짜에도 넣어준다
            if(list_self_select[idx].event_item_created_end_date!= null && list_self_select[idx].event_item_created_end_date !='' && new Date(list_self_select[idx].event_item_created_start_date) > new Date(list_self_select[idx].event_item_created_end_date)){
                list_self_select[idx].event_item_created_end_date = list_self_select[idx].event_item_created_start_date;
                this.setState({
                    list_self_select: list_self_select,
                });
            }
        });
    };

    /** 상품등록일 기준 시작 날짜 변경
     * @param {*} date 선택한 날짜
     * @param {*} idx 리스트 내 idx 번호
    */
    onChangeSelectStartDateForce = (date) => {
        this.setState({
            force_created_start_date: date,
        }, () => {
            let {list_self_select} = this.state;
            // 변경된 시작 날짜가 종료날짜보다 크면, 시작날짜 값을 종료날짜에도 넣어준다
            if( this.state.force_created_end_date !== null && this.state.force_created_end_date !=='' && new Date(this.state.force_created_start_date) > new Date(this.state.force_created_end_date) ){
                this.setState({
                    force_created_end_date: this.state.force_created_start_date,
                    left_is_modified : true,
                });
            }
        });
    };

    /** 상품등록일 기준 종료 날짜 변경
     * @param {*} date 선택한 날짜
     * @param {*} idx 리스트 내 idx 번호
    */
    onChangeSelectEndDate = (date, idx) => {
        let {list_self_select} = this.state;
        list_self_select[idx].event_item_created_end_date = date;
        this.setState({
            list_self_select: list_self_select,
            left_is_modified : true,
        });
    };

    /** 상품등록일 기준 종료 날짜 변경
     * @param {*} date 선택한 날짜
     * @param {*} idx 리스트 내 idx 번호
    */
    onChangeSelectEndDateForce = (date) => {
        this.setState({
            force_created_end_date: date,
            left_is_modified : true,
        });
    };

    /** 상품 선정수 값 변경 : input
     * @param {*} e <e.target.value : 상품 선정수 값>
     * @param {*} idx 영역 idx 번호
     * @param {*} onBlur blur가 된 경우
    */
    onChangeEventTotalItemSelectCntInput(e, idx, onBlur){
        const {list_self_select} = this.state;

        // 값이 없는데 blur가 실행되면 0으로 설정
        if(onBlur && (e.target.value == null || e.target.value=="")){
            list_self_select[idx]["cnt"] = "0";
            this.setState({
                list_self_select : list_self_select
            }, () => {
                this.SumEventTotalItemSelectCnt();
            });
            return false;
        }

        let input_value = StringUtil.inputOnlyNumberFormat(e.target.value);
        list_self_select[idx]["cnt"] = input_value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        }, () => {
            this.SumEventTotalItemSelectCnt();
        });
    }

    /** 상품 선정수 값 변경 : select (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventTotalItemSelectCntSelect(text, value, id){
        const idx = id.split('_')[1];
        const {list_self_select} = this.state;
        list_self_select[idx]["cnt"] = value;
        this.setState({
            list_self_select : list_self_select,
            left_is_modified : true,
        }, () => {
            this.SumEventTotalItemSelectCnt();
        });
    }

    /** 상품 진열 방식 변경 */
    onChangeProductOrderType(e){
        this.setState({
            product_order_type : e.target.value,
            left_is_modified : true,
        });
    }

    /** 자동 진열 일정관리 사용 여부 변경 */
    onChangeAutoCreateYn(e){
        this.setState({
            auto_create_yn : e.target.value,
            left_is_modified : true,
        });
    }

    /** 자동 진열 일정관리 사용 여부 변경 */
    onChangeForceUseYn(e){
        this.setState({
            force_use_yn : e.target.value,
            left_is_modified : true,
        }, () => {
            this.SumEventTotalItemSelectCnt();
        });
    }

    /** 자동 진열 일정관리 날짜 변경(주기) (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventCycle(text, value, id){
        this.setState({
            event_cycle : value,
            left_is_modified : true,
        });
    }

    /** 자동 진열 일정관리 시간 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventTime(text, value, id){
        this.setState({
            event_time : value,
            left_is_modified : true,
        });
    }

    /** 단순 정보 Guide 용 Basic dialog */
    basicDialogSimpleInform = (
        basic_dialog_content, 
        button = {
            title: "확인",
            type: "1",
            event_method: () => {this.setState({basic_dialog_show_state:false})}
        }) => {
        this.setState({
            basic_dialog_show_state: true,
            basic_dialog_content,
            basic_dialog_button_list: [
                {...button}
            ]
        })
    }

    /** 제목 내용 다이얼로그 */
    titleContentDialogForm = (
        title_content_dialog_title = "",
        title_content_dialog_content = "",
        title_content_dialog_ok = {
            title: "확인",
            type: "1",
            event_method: () => {this.setState({title_content_dialog_show_state: false})}
        },
        title_content_dialog_cancel = {
            title: "아니요",
            type: "0",
            event_method: () => {this.setState({title_content_dialog_show_state: false})}
        },
        callbackFunc = () => {},
        title_content_dialog_check_box_text = ""
    ) => {
        this.setState({
            title_content_dialog_show_state: true,
            title_content_dialog_title, 
            title_content_dialog_content,
            title_content_dialog_check_box_text,
            title_content_dialog_button_list: [
                {...title_content_dialog_ok},
                {...title_content_dialog_cancel}
            ]
        } , callbackFunc)
    }

    /** 왼쪽 영역 저장 버튼 클릭 */
    onClickSaveLeftSetting(save_right){

        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            return false;
        }

        // 자동 진열된 상품 목록 관련 팝업 닫기
        // this.allCloseEventListPopup();
        // this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        // this.setState({
        //     show_add_product_popup : false, // 상품 추가 팝업 닫기
        // });

        const {event_total_item_select_cnt, total_item_select_cnt_range, display_name, iframe_html_name, max_item_analysis_value} = this.state;
        const range = total_item_select_cnt_range.indexOf("자동") >= 0 ? total_item_select_cnt_range.length-2 : total_item_select_cnt_range.length-1;
        const display_name_check = display_name.replaceAll(' ','').length;
        const iframe_html_name_check = iframe_html_name.replaceAll(' ','').length;
        // 상품 선정수가 범위를 넘었다면 저장하지말고, 다이얼로그를 띄운다.
        if (event_total_item_select_cnt > range || max_item_analysis_value > 100 ){
            this.totalCntDialog();
        } else if(this.state.iframe_page_id != "robomd_cafe24_main_page" && (this.state.display_name_cd == null || this.state.display_name_cd == "")){

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "쇼핑몰에 노출될 위치 설정의<br/>위젯 진열 디자인 제목을 선택해 주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });

                            // 설치값 영역 열기
                            if(!$("#list_side_title_arrow").is(":checked")){
                                rpTitleRef.current.scrollIntoView({block:"start"});
                                $("#install_list_expand").trigger('click');
                                // setTimeout(() => {$("#selectDisplayName").focus()},400);
                            } else{
                                $("#selectDisplayName").focus();
                            }
                        }
                    }
                ]
            });
        } else if(this.state.display_name_use_yn== "Y" && display_name_check <= 0){
            // 디자인 제목을 사용하는데, 제목이 공백이라면 작성해달라고 한다.
            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "쇼핑몰에 노출될 위치 설정의 위젯<br/>진열 디자인 제목을 입력해 주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });

                            // 설치값 영역 열기
                            if(!$("#list_side_title_arrow").is(":checked")){
                                rpTitleRef.current.scrollIntoView({block:"start"});
                                $("#install_list_expand").trigger('click');
                                // setTimeout(() => {$("#self_title_input").focus()},400);
                            } else{
                                $("#self_title_input").focus();
                            }
                        }
                    }
                ]
            });
        } else if(this.state.display_name_use_yn== "N" && iframe_html_name_check <= 0){
            // 디자인 제목을 사용하지 않고, 진열 제목이 공백이라면 진열 제목을 작성해달라고 한다.
            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "진열 제목을 입력해주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            $("#iframe_html_name_input").focus();
                        }
                    }
                ]
            });
        } else if(this.state.event_category_select_type == "34001" && this.state.big_category_checked_list.length <= 0){
            // 카테고리 종류를 다중 카테고리를 선택했는데, 선택된 카테고리가 하나도 없다면 선택하도록 한다.

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "대카테고리를 하나 이상 선택해주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            // 다중 카테고리 사용 설정 팝업 열기
                            this.onClickEventCategorySelectPopup();
                        }
                    }
                ]
            });
        }
        else if (!this.validateCheckForautoCreateY()) {
            /** 반복관리 필수값 체크 */
        } 
        else{
            this.saveListSetting(save_right);
        }
    }

    validateCheckForautoCreateY() {
        if (this.state.auto_create_yn == "Y") {
            const firstSave = !this.state.iframe_html_id;
            if (this.state.event_cycle_type == "A8001") {
                // if (firstSave) {
                    // 시작시간 입력 여부 체크
                    if (!this.state.event_start_date) {
                        this.basicDialogNeedStartDate();
                        return false;
                    }
                // }
            }
            if (this.state.event_cycle_type == "A8002") {
                // if (firstSave) {
                    // 시작시간 입력 여부 체크
                    if (!this.state.event_start_date) {
                        this.basicDialogNeedStartDate();
                        return false;
                    }
                // }

                /* 요일 */
                const {weekday_list} = this.state;
                const weekdat_list_2 = weekday_list.replace("-1,", "");
                if (weekday_list != null) {
                    let array_weekday_list = weekdat_list_2 ? weekdat_list_2.split(",") : [];
                    if (array_weekday_list.length == 0) {
                        this.basicDialogNeedWeekDay();
                        return false;
                    }
                } else {
                    this.basicDialogNeedWeekDay();
                    return false;
                }

            }
            if (this.state.event_cycle_type == "A8003") {
                // if (firstSave) {
                    // 시작시간 입력 여부 체크
                    if (!this.state.event_start_date) {
                        this.basicDialogNeedStartDate();
                        return false;
                    }
                // }

                /* 요일 */
                const {weekday_list} = this.state;
                const weekdat_list_2 = weekday_list.replace("-1,", "");
                if (weekday_list != null) {
                    let array_weekday_list = weekdat_list_2 ? weekdat_list_2.split(",") : [];
                    if (array_weekday_list.length == 0) {
                        this.basicDialogNeedWeekDay();
                        return false;
                    }
                } else {
                    this.basicDialogNeedWeekDay();
                    return false;
                }
            }
        }
        return true;
    }

    basicDialogNeedStartDate() {
        this.setState({
            basic_dialog_show_state: true,
            basic_dialog_content : "시작시점을 입력해주세요.",
            basic_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    event_method: () => {
                        this.setState({basic_dialog_show_state:false});
                        $("#event_start_date").focus();
                    }
                }
            ],        
        })
    }
    basicDialogNeedWeekDay() {
        this.setState({
            basic_dialog_show_state: true,
            basic_dialog_content : "반복요일을 선택해주세요.",
            basic_dialog_button_list: [
                {
                    title: "확인",
                    type: "1",
                    event_method: () => {
                        this.setState({basic_dialog_show_state:false});
                        $("#rp_weekday").scrollTop();
                    }
                }
            ],            
        })
    }


    /* ---------------------------------- 대 카테고리 사용 설정 팝업 관련 함수 --------------------------------------- */

    /** 대카테고리 사용 설정 팝업 열기 */
    onClickEventCategorySelectPopup(){
        // 이미 열려있다면 동작하지 않는다
        if(this.state.show_multi_category_select_popup){
            return false;
        }
        this.setState({
            show_multi_category_select_popup : true, // 팝업 열기
            show_add_product_popup : false, // 다른 팝업 닫기
            show_exclude_product_list_popup : false, // 제외 상품 팝업 닫기
            show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
            show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
            show_except_product_popup: false,
            show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
            multi_category_list_is_modified: false,
        }, () => {
            
            this.selectMultiCategoryUseBtnStyle();
            this.category_list_setting_table();
        });
    }

    /** 대카테고리 리스트 수정시 버튼 길이 변경 */
    selectMultiCategoryUseBtnStyle(){
        if(this.state.multi_category_list_is_modified){
            $('#selectMultiCategoryUseBtn').css({"width":"55%"});
        } else{
            $('#selectMultiCategoryUseBtn').css({"width":"100%"});
            $('#selectMultiCategoryUseBtn').css({"border":"none"});
        }
    }

    /** 대 카테고리 체크 : 전체 */
    onChangeBigCategoryCheckAll(e){
        const big_category_checked_list = [];
        //'카테고리 사용 설정' 팝업 체크박스 전체선택 이벤트
        if(e.target.checked == true){
            const {category_list_for_product_detail} = this.state;
            for(var i=0;i<category_list_for_product_detail.length;i++){
                if(category_list_for_product_detail[i].category_no!="전체상품"){
                    big_category_checked_list.push(String(category_list_for_product_detail[i].category_no));
                }
            }
        }

        this.setState({
            big_category_checked_list : big_category_checked_list,
            multi_category_list_is_modified: true,
        },()=>{
            this.selectMultiCategoryUseBtnStyle();
        });
    }
    
    /** 대 카테고리 체크 */
    onChangeBigCategoryCheck(e){
        let {big_category_checked_list} = this.state;
        if(e.target.checked){
            big_category_checked_list.push(e.target.value);
        } else{
            big_category_checked_list = big_category_checked_list.filter(
                (element) => element !== e.target.value);
        }
        this.setState({
            big_category_checked_list : big_category_checked_list,
            multi_category_list_is_modified: true,
        }, () => {
            this.selectMultiCategoryUseBtnStyle();
        });
    }

    /** 선택한 카테고리 사용 버튼 클릭 : 선택된 대 카테고리 목록 반영 */
    onClickSelectBigCategoryUse(){

        this.setState({
            title_content_dialog_show_state: true,
            title_content_dialog_title: "변경하신 카테고리로 <br/> 사용 설정하시겠습니까?",
            title_content_dialog_content: "사용 설정하지 않으시면 변경하신 값은 <br/> 따로 저장되지 않습니다.",
            title_content_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        // 다중 카테고리는 20개 까지만 선택 가능해야한다
                        if(this.state.big_category_checked_list.length > 20){
                            this.setState({
                                title_content_dialog_show_state: false,
                                basic_dialog_show_state: true,
                                basic_dialog_content: "대 카테고리를 최대 20개까지 <br/> 지정할 수 있습니다.",
                                basic_dialog_button_list: [
                                    {
                                        title: "확인",
                                        type: "1",
                                        event_method: () => {
                                            //팝업 닫기
                                            this.setState({ basic_dialog_show_state: false});
                                        }
                                    }
                                ]
                            });
                        } else{
                            const {big_category_checked_list} = this.state;
                            big_category_checked_list.sort();
                            this.setState({
                                multi_category_list : [...big_category_checked_list], // 메인 다중카테고리 셀렉트 박스에 적용
                                big_category_checked_list : big_category_checked_list,
                                // 대카테고리를 하나도 선택하지않았다면, 메인 테이블에서 띄울 선택된 카테고리도 비운다.
                                selected_category_no : big_category_checked_list.length <=0 ? "" : this.state.selected_category_no,
                                show_rp_product_list_table_loading_bar : true,
                            }, () =>{
                                // 상품 목록 불러오기
                                this.loadBigCategoryProductList();

                                let event_category_list = "";
                                for(var i=0;i<big_category_checked_list.length;i++){
                                    // 왼쪽 영역 카테고리 리스트에 문자열로 ','를 찍어서 보내기 위함.
                                    if(i == big_category_checked_list.length-1){
                                        // 마지막 ','는 붙이지 않음
                                        event_category_list += big_category_checked_list[i];
                                    } else{
                                        event_category_list += big_category_checked_list[i]+",";
                                    }
                                }
                                
                                this.setState({
                                    show_multi_category_select_popup : false, // 팝업 닫기
                                    title_content_dialog_show_state: false,
                                    event_category_list: event_category_list,
                                    left_is_modified : true,
                                    multi_category_list_is_modified: false,
                                }, () => {
                                    this.setState({
                                        basic_dialog_show_state: true,
                                        basic_dialog_content: "변경하신 목록으로 설정됐습니다.",
                                        basic_dialog_button_list: [
                                            {
                                                title: "확인",
                                                type: "1",
                                                event_method: () => {
                                                    this.setState({ basic_dialog_show_state: false })
                                                }
                                            }
                                        ]
                                    });
                                });
                            });
                        }
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            title_content_dialog_show_state: false,
                        });
                    }
                }
            ]
        });
    }

    /** 대 카테고리 선정 팝업 : x버튼 클릭 */
    onCloseBigCategoryListPopup(){
        if(this.state.multi_category_list_is_modified){

            this.setState({
                title_content_dialog_show_state: true,
                title_content_dialog_title: "변경하신 대(大) 카테고리로 <br/> 사용 설정하시겠습니까?",
                title_content_dialog_content: "사용 설정하지 않으시면 변경하신 값은 <br/> 따로 저장되지 않습니다.",
                title_content_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {// 다중 카테고리는 20개 까지만 선택 가능해야한다
                            if(this.state.big_category_checked_list.length > 20){
                                this.setState({
                                    title_content_dialog_show_state: false,
                                    basic_dialog_show_state: true,
                                    basic_dialog_content: "대 카테고리를 최대 20개까지 <br/> 지정할 수 있습니다.",
                                    basic_dialog_button_list: [
                                        {
                                            title: "확인",
                                            type: "1",
                                            event_method: () => {
                                                //팝업 닫기
                                                this.setState({ basic_dialog_show_state: false});
                                            }
                                        }
                                    ]
                                });
                            } else{
                                const {big_category_checked_list} = this.state;
                                big_category_checked_list.sort();
                                this.setState({
                                    multi_category_list : [...big_category_checked_list], // 메인 다중카테고리 셀렉트 박스에 적용
                                    big_category_checked_list : big_category_checked_list,
                                    // 대카테고리를 하나도 선택하지않았다면, 메인 테이블에서 띄울 선택된 카테고리도 비운다.
                                    selected_category_no : big_category_checked_list.length <=0 ? "" : this.state.selected_category_no,
                                    show_rp_product_list_table_loading_bar : true,
                                }, () =>{
                                    // 상품 목록 불러오기
                                    this.loadBigCategoryProductList();

                                    let event_category_list = "";
                                    for(var i=0;i<big_category_checked_list.length;i++){
                                        // 왼쪽 영역 카테고리 리스트에 문자열로 ','를 찍어서 보내기 위함.
                                        if(i == big_category_checked_list.length-1){
                                            // 마지막 ','는 붙이지 않음
                                            event_category_list += big_category_checked_list[i];
                                        } else{
                                            event_category_list += big_category_checked_list[i]+",";
                                        }
                                    }

                                    this.setState({
                                        show_multi_category_select_popup : false, // 팝업 닫기
                                        title_content_dialog_show_state: false,
                                        event_category_list: event_category_list,
                                        left_is_modified : true,
                                        multi_category_list_is_modified: false,
                                    }, () => {
                                        this.selectMultiCategoryUseBtnStyle();

                                        this.setState({
                                            basic_dialog_show_state: true,
                                            basic_dialog_content: "변경하신 목록으로 설정됐습니다.",
                                            basic_dialog_button_list: [
                                                {
                                                    title: "확인",
                                                    type: "1",
                                                    event_method: () => {
                                                        this.setState({ basic_dialog_show_state: false });
                                                    }
                                                }
                                            ]
                                        });
                                    });
                                });
                            }
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                show_multi_category_select_popup : false, // 팝업 닫기
                                title_content_dialog_show_state: false,
                            }, () => {
                                // 팝업속 체크리스트 초기화
                                let {big_category_checked_list} = this.state;
                                // 기존에 선택한 카테고리가 없었다면, ""값이 배열로 들어가는것을 방지
                                if(this.state.event_category_list != null && this.state.event_category_list != ""){
                                    big_category_checked_list = this.state.event_category_list.split(',');
                                } else{
                                    big_category_checked_list = [];
                                }

                                // 전체 선택 여부 변경
                                this.setState({
                                    big_category_checked_list : big_category_checked_list,
                                    multi_category_list : [...big_category_checked_list], // 메인 다중카테고리 셀렉트 박스에 적용
                                    multi_category_list_is_modified: false,
                                }, () => {
                                    this.selectMultiCategoryUseBtnStyle();
                                });
                            });
                        }
                    }
                ]
            });
        } else{
            this.setState({
                show_multi_category_select_popup : false, // 팝업 닫기
            });
        }    
    }

    /** 대 카테고리 선정 팝업 : 초기화 버튼 클릭 */
    onResetBigCategoryList(){

        this.setState({
            title_content_dialog_show_state: true,
            title_content_dialog_title: "대(大) 카테고리 사용 설정을 <br/> 변경하지 않으시겠습니까?",
            title_content_dialog_content: "초기화하시면 변경하신 값은 <br/> 따로 저장되지 않습니다.",
            title_content_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        let {big_category_checked_list} = this.state;
                        // 기존에 선택한 카테고리가 없었다면, ""값이 배열로 들어가는것을 방지
                        if(this.state.event_category_list != null && this.state.event_category_list != ""){
                            big_category_checked_list = this.state.event_category_list.split(',');
                        } else{
                            big_category_checked_list = [];
                        }
                        // 전체 선택 여부 변경

                        this.setState({
                            big_category_checked_list : big_category_checked_list,
                            title_content_dialog_show_state: false,
                            multi_category_list_is_modified: false,
                        }, () => {
                            this.selectMultiCategoryUseBtnStyle();

                            this.setState({
                                basic_dialog_show_state: true,
                                basic_dialog_content: "대(大) 카테고리 사용 목록이 초기화되었습니다.",
                                basic_dialog_button_list: [
                                    {
                                        title: "확인",
                                        type: "1",
                                        event_method: () => {
                                            this.setState({ basic_dialog_show_state: false });
                                        }
                                    }
                                ]
                            });
                        });
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            title_content_dialog_show_state: false,
                        });
                    }
                }
            ]
        });
    }

    /* ---------------------------------- 자동 진열된 상품 목록 팝업 관련 함수 --------------------------------------- */

    /** 상품 선정수가  필수 상품의 개수보다 큰 알고리즘이 있는지 체크 */
    checkEssentialProductCnt() {
        const {list_self_select} = this.state
        for (let list_self_select_index in list_self_select) {
            if ((list_self_select[list_self_select_index]["essential_product_no_list"] 
                ? list_self_select[list_self_select_index]["essential_product_no_list"].length  
                : 0) > list_self_select[list_self_select_index]["cnt"]) {
                    this.warningDialogEssentialProductCnt();
                    return false;
                }
        }
        return true;
    }

    /** 등록된 필수 상품이 있는데, 상품 선정수가 '자동'인 경우  */
    checkEssentialProductAutoCnt() {
        const {list_self_select} = this.state
        for (let list_self_select_index in list_self_select) {
            if (list_self_select[list_self_select_index]["essential_product_no_list"] 
                && list_self_select[list_self_select_index]["essential_product_no_list"].length > 0
                && list_self_select[list_self_select_index]["cnt"] == '자동') {
                return false;
            }
        }
        return true;
    }
    
    /** 선정된 상품목록 보기 버튼 클릭 */
    onClickEventProductListPopup(){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else{
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            this.setState({
                show_add_product_popup : false, // 다른 팝업 닫기
                show_exclude_product_list_popup : false, // 제외 상품 팝업 닫기
                show_except_setting_popup : false,
                show_except_product_popup : false,
                show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
                except_product_list : [],

                event_product_checked_list : [],
            }, () => {
                // 팝업이 열려있으면 그냥 불러오기
                if(this.state.show_event_product_list_popup){
                    this.loadSelfEventProductList();
                } else{
                    this.setState({
                        select_event_order_gubun_type : "org",
                        event_order_gubun : "",
                        event_order_type : "",
                        show_event_product_list_popup : true, // 팝업 열기
                    }, () => {
                        $("#select_event_order_gubun_type").val("");
                        this.loadSelfEventProductList();
                    });
                }
            });
        }
    }

    /** 선정된 상품목록 이벤트 생성 : 직접 지정 */
    loadSelfEventProductList() {
        this.setState({
            show_event_product_list_table_loading_bar : true,
            event_rp_product_list : [],
        });

        let list_self_select = JSON.parse(JSON.stringify(this.state.list_self_select));
        for(var i=0;i<list_self_select.length;i++){
            if(list_self_select[i].event_item_created_start_date != null && list_self_select[i].event_item_created_start_date != ""){
                list_self_select[i].event_item_created_start_date = StringUtil.dateFormater(list_self_select[i].event_item_created_start_date);
            }
            if(list_self_select[i].event_item_created_end_date != null && list_self_select[i].event_item_created_end_date != ""){
                list_self_select[i].event_item_created_end_date = StringUtil.dateFormater(list_self_select[i].event_item_created_end_date);
            }
            if( list_self_select[i].cnt == '자동' ) {
                list_self_select[i].cnt = -1;
            }

            let color_list = list_self_select[i].color_list.map(obj => obj.color_seq);
            list_self_select[i].color_seq_list = color_list;
            delete list_self_select[i].color_list;

            delete list_self_select[i].force_use_yn;
            delete list_self_select[i].force_logic;
            delete list_self_select[i].force_category;
            delete list_self_select[i].force_auto_remove;
            delete list_self_select[i].force_analysis_period;
            delete list_self_select[i].force_select_created_yn;
            delete list_self_select[i].force_created_period_type;
            delete list_self_select[i].force_created_period;
            delete list_self_select[i].force_created_start_date;
            delete list_self_select[i].force_created_end_date;
            delete list_self_select[i].force_is_except_invisible_category;
            delete list_self_select[i].max_item_analysis;
            delete list_self_select[i].max_item_analysis_value;
        }

        let last_select_index = list_self_select.length-1;
        list_self_select[last_select_index].force_use_yn = this.state.force_use_yn;
        list_self_select[last_select_index].force_logic = this.state.force_logic;
        list_self_select[last_select_index].force_category = this.state.force_category;
        list_self_select[last_select_index].force_auto_remove = this.state.force_auto_remove;
        list_self_select[last_select_index].force_analysis_period = this.state.force_analysis_period;
        list_self_select[last_select_index].force_select_created_yn = this.state.force_select_created_yn;
        list_self_select[last_select_index].force_created_period_type = this.state.force_created_period_type;
        list_self_select[last_select_index].force_created_period = this.state.force_created_period;
        list_self_select[last_select_index].force_created_start_date = StringUtil.dateFormater(this.state.force_created_start_date);
        list_self_select[last_select_index].force_created_end_date = StringUtil.dateFormater(this.state.force_created_end_date);
        list_self_select[last_select_index].force_is_except_invisible_category = this.state.force_is_except_invisible_category;
        list_self_select[last_select_index].max_item_analysis = this.state.max_item_analysis;
        list_self_select[last_select_index].max_item_analysis_value = this.state.max_item_analysis_value;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/create/manual/v5';
        const body = {
            user_seq: this.state.user_seq, 
            shop_no: this.state.shop_no,
            create_type_cd : "22998",
            row_cnt : this.state.event_total_item_select_cnt,
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            event_category_list : this.state.event_category_list,
            list_self_select : list_self_select,
            event_item_price_filter_yn : "Y",
            order_gubun : this.state.event_order_gubun,
            order_type : this.state.event_order_type,
            analysis_standard_value: this.state.analysis_standard_value,
        }
        http
        .post(url, body, config)
		.then(res => {
			if (res.data.code == "200") {
                //console.log(res.data["list"]);
				this.setState( {
                    event_rp_product_list : res.data["list"],
                    show_event_product_list_table_loading_bar : false, // 로딩바
                }, () => {
                    // 상품 이미지 byte로 변환
                    // const {event_rp_product_list} = this.state;
                    // for(let i=0;i<event_rp_product_list.length;i++){
                    //     // 이미지가 재로딩 되지 않은 경우에만 byte변환 api를 타도록 한다
                    //     if(event_rp_product_list[i]["image_reload_yn"]!="Y"){
                    //         this.changeImageToByte(event_rp_product_list[i]["image_url"],i,"event_rp_product_list");
                    //         this.changeImageToByte(event_rp_product_list[i]["detail_image_url"],i,"event_rp_product_list", "detail_image_url");
                    //     }
                    // }

                    const {rp_product_list, products, selected_category_no} = this.state;
                    let product_list = [];
                    // 단일 || 다중 카테고리 목록 불러오기
                    if(this.state.event_category_select_type == "34001"){
                        if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                            this.closeEventListPopup();
                            return true;
                        } else{
                            product_list = [...products[selected_category_no]];
                        }
                    } else if(this.state.event_category_select_type == "34002"){
                        product_list = [...rp_product_list];
                    }

                    let product_no_list = new Set(product_list.map(obj => obj.product_no));
                    let event_product_checked_list = this.state.event_rp_product_list.filter(obj => product_no_list.has(obj.product_no));
                    this.setState({
                        event_product_checked_list : event_product_checked_list,
                    });
                });
			} else{
                alert("상품 선정에 실패하였습니다.")
                this.setState( {
                    event_rp_product_list : [],
                    show_event_product_list_table_loading_bar : false, // 로딩바
                });
            }
		})
		.catch(error => {
		 });
    }

    /** 상품 새로 선정하기 버튼 클릭 */
    onClickReLoadEventProductList(){
        this.setState({
            event_product_checked_list : [],
        }, () => {
            this.loadSelfEventProductList();
        });
    }

    /** 선정 자동 진열 적용 */
    onClickEventProductCopy(){
        if(this.state.show_event_product_list_popup){
            this.onClickRpProductListPaste();
        } else{
            this.onClickRpProductListPaste(true);
        }
    }

    /** 정렬 기준이 변경될때 실행되는 함수 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeEventOrderGubun(text, value, id){
        const org_value = value;

        if(value == "org"){
            value = "";
        }
        const order_data = value.split("/");

        this.setState({
            select_event_order_gubun_type : org_value,
            event_order_gubun : order_data[0],
            event_order_type : order_data[1],
        }, () => {
            this.loadSelfEventProductList();
        })
    }

    /** 선정된 상품목록 팝업 전체 체크
     * @param {*} e 전체 체크 상태
    */
    onChangeEventProductListCheckAll(e){
        if(e.target.checked == true){
            this.setState({
                event_product_checked_list: JSON.parse(JSON.stringify(this.state.event_rp_product_list)),
            })
        }
        else{
            const {rp_product_list, products, selected_category_no} = this.state;
            let product_list = [];
            // 단일 || 다중 카테고리 목록 불러오기
            if(this.state.event_category_select_type == "34001"){
                if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                    this.closeEventListPopup();
                    return true;
                } else{
                    product_list = [...products[selected_category_no]];
                }
            } else if(this.state.event_category_select_type == "34002"){
                product_list = [...rp_product_list];
            }

            let product_no_list = new Set(product_list.map(obj => obj.product_no));
            let event_product_checked_list = this.state.event_rp_product_list.filter(obj => product_no_list.has(obj.product_no));
            this.setState({
                event_product_checked_list : event_product_checked_list,
            });
        }
    }

    /** 선정된 상품목록 팝업 체크
     * @param {*} e 체크 상태
     * @param {*} idx 체크된 idx 번호
    */
    onChangeEventProductListCheck(e){
        let idx = e.target.value;
        if (!e.currentTarget.checked) {
            const {event_rp_product_list, event_product_checked_list} = this.state;
            
            for(var idx2 in event_product_checked_list) {
                if (event_product_checked_list[idx2].product_no == event_rp_product_list[idx].product_no) {
                    idx = idx2;
                    break;
                }
            }

            var event_product_checked_list_copy = JSON.parse(JSON.stringify(event_product_checked_list));
            event_product_checked_list_copy.splice(idx, 1);
            
            this.setState({
                event_product_checked_list: event_product_checked_list_copy,
            })
        } else {
            const {event_rp_product_list, event_product_checked_list} = this.state;
            event_product_checked_list.push(event_rp_product_list[idx])
            this.setState({
                event_product_checked_list: event_product_checked_list,
            })
        }
    }

    /** 선정된 상품목록 적용하기 버튼 클릭 */
    onClickRpProductListPaste(copy){
        let pasteAction = function(this_component){
            const {products, selected_category_no, event_rp_product_list} = this_component.state;

            let product_list = [];
            for (var idx in event_rp_product_list) {
                var obj = event_rp_product_list[idx];
                var result_obj = {
                    category_no: obj.category_no,
                    create_type_cd: obj.create_type_cd,
                    created_date : obj.created_date,
                    hit_cnt :obj.hit_cnt,
					cart_cnt :obj.cart_cnt,
                    wish_cnt :obj.wish_cnt,
                    quantity :obj.quantity,
                    mod_user_seq: obj.mod_user_seq,
                    review_cnt : obj.review_cnt,
                    sale_percent : obj.sale_percent,
                    order_price : obj.order_price,
                    retail_price: obj.retail_price,
                    del_yn: obj.del_yn,
                    order_cnt : obj.order_cnt,
                    review_point : obj.review_point,
                    display_yn: obj.display_yn,
                    iframe_html_id: this_component.state.iframe_html_id,
                    iframe_page_id: this_component.state.iframe_page_id,
                    image_url: obj.image_url,
                    detail_image_url: obj.detail_image_url,
                    price: obj.price,
                    pc_discount_price: obj.pc_discount_price,
                    product_name: obj.product_name,
                    product_no: obj.product_no,
                }
                product_list.push(result_obj) // 실제 추가 할 상품
            }
            
            // 단일 || 다중 카테고리 상태에 따라 상품 추가
            if(this_component.state.event_category_select_type == "34001"){
                if(this_component.state.selected_category_no == null || this_component.state.selected_category_no == ""){
                    this_component.closeEventListPopup();
                    this_component.setState({confirm_dialog_show_state : false,});
                    return true;
                } else{
                    products[selected_category_no]  = [...product_list];
                    this_component.setState({products: products});
                }
            } else if(this_component.state.event_category_select_type == "34002"){
                this_component.setState({rp_product_list : product_list,});
            }
            this_component.setState({
                show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                show_rp_product_list_table_loading_bar : false,
                rp_check_product_list : [],
                event_rp_product_list : [],
                confirm_dialog_show_state: false,
                right_is_modified: true,
            }, () => {
                this_component.orderIdxReset();
            });
        };

        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: "미리보기에 덮어쓰기 됩니다.<br/>적용 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        if(copy){
                            this.setState({
                                show_rp_product_list_table_loading_bar : true,
                                confirm_dialog_show_state: false,
                            });
                            let list_self_select = JSON.parse(JSON.stringify(this.state.list_self_select));
                            for(var i=0;i<list_self_select.length;i++){
                                if(list_self_select[i].event_item_created_start_date != null && list_self_select[i].event_item_created_start_date != ""){
                                    list_self_select[i].event_item_created_start_date = StringUtil.dateFormater(list_self_select[i].event_item_created_start_date);
                                }
                                if(list_self_select[i].event_item_created_end_date != null && list_self_select[i].event_item_created_end_date != ""){
                                    list_self_select[i].event_item_created_end_date = StringUtil.dateFormater(list_self_select[i].event_item_created_end_date);
                                }
                                if( list_self_select[i].cnt == '자동' ) {
                                    list_self_select[i].cnt = -1;
                                }

                                let color_list = list_self_select[i].color_list.map(obj => obj.color_seq);
                                list_self_select[i].color_seq_list = color_list;
                                delete list_self_select[i].color_list;

                                delete list_self_select[i].force_use_yn;
                                delete list_self_select[i].force_logic;
                                delete list_self_select[i].force_category;
                                delete list_self_select[i].force_auto_remove;
                                delete list_self_select[i].force_analysis_period;
                                delete list_self_select[i].force_select_created_yn;
                                delete list_self_select[i].force_created_period_type;
                                delete list_self_select[i].force_created_period;
                                delete list_self_select[i].force_created_start_date;
                                delete list_self_select[i].force_created_end_date;
                                delete list_self_select[i].force_is_except_invisible_category;
                                delete list_self_select[i].max_item_analysis;
                                delete list_self_select[i].max_item_analysis_value;
                            }

                            let last_select_index = list_self_select.length-1;
                            list_self_select[last_select_index].force_use_yn = this.state.force_use_yn;
                            list_self_select[last_select_index].force_category = this.state.force_category;
                            list_self_select[last_select_index].force_auto_remove = this.state.force_auto_remove;
                            list_self_select[last_select_index].force_analysis_period = this.state.force_analysis_period;
                            list_self_select[last_select_index].force_select_created_yn = this.state.force_select_created_yn;
                            list_self_select[last_select_index].force_created_period_type = this.state.force_created_period_type;
                            list_self_select[last_select_index].force_created_period = this.state.force_created_period;
                            list_self_select[last_select_index].force_created_start_date = StringUtil.dateFormater(this.state.force_created_start_date);
                            list_self_select[last_select_index].force_created_end_date = StringUtil.dateFormater(this.state.force_created_end_date);
                            list_self_select[last_select_index].force_is_except_invisible_category = this.state.force_is_except_invisible_category;
                            list_self_select[last_select_index].max_item_analysis = this.state.max_item_analysis;
                            list_self_select[last_select_index].max_item_analysis_value = this.state.max_item_analysis_value;

                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    },
                            };
                            const url = '/rest/shop/rp/event/create/manual/v5';
                            const body = {
                                user_seq: this.state.user_seq, 
                                shop_no: this.state.shop_no,
                                create_type_cd : "22998",
                                row_cnt : this.state.event_total_item_select_cnt,
                                iframe_page_id : this.state.iframe_page_id,
                                iframe_html_id : this.state.iframe_html_id,
                                event_category_list : this.state.event_category_list,
                                list_self_select : list_self_select,
                                event_item_price_filter_yn : "Y",
                                order_gubun : this.state.event_order_gubun,
                                order_type : this.state.event_order_type,
                            }
                            http
                            .post(url, body, config)
                            .then(res => {
                                if (res.data.code == "200") {
                                    //console.log(res.data["list"]);
                                    this.setState( {
                                        event_rp_product_list : res.data["list"],
                                    }, () => {
                                        // 상품 이미지 byte로 변환
                                        const {event_rp_product_list} = this.state;
                                        // for(let i=0;i<event_rp_product_list.length;i++){
                                        //     // 이미지가 재로딩 되지 않은 경우에만 byte변환 api를 타도록 한다
                                        //     if(event_rp_product_list[i]["image_reload_yn"]!="Y"){
                                        //         this.changeImageToByte(event_rp_product_list[i]["image_url"],i,"event_rp_product_list");
                                        //         this.changeImageToByte(event_rp_product_list[i]["detail_image_url"],i,"event_rp_product_list", "detail_image_url");
                                        //     }
                                        // }
                                        pasteAction(this);
                                    });
                                } else{
                                    alert("상품 선정에 실패하였습니다.")
                                    this.setState( {
                                        event_rp_product_list : [],
                                    });
                                }
                            })
                            .catch(error => {
                                alert("상품 선정에 실패하였습니다.")
                                this.setState( {
                                    event_rp_product_list : [],
                                });
                            });
                        } else{
                            pasteAction(this);
                        }
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            confirm_dialog_show_state: false,
                        });
                    }
                }
            ]
        });
    }

    /** 선정된 상품목록 선택한 상품 추가 버튼 클릭 */
    onClickAddSelectEventProductList(){
        const {rp_product_list, products, selected_category_no, event_product_checked_list} = this.state;

        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                this.closeEventListPopup();
                return true;
            } else{
                product_list = [...products[selected_category_no]];
            }
        } else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }

        // var product_list_copy = JSON.parse(JSON.stringify(product_list));
        var add_product_list = [];

        add_product_list = event_product_checked_list.filter(function(obj) {
            return !this.has(obj.product_no);
        }, new Set(product_list.map(obj => obj.product_no))); // 상품 중복 체크

        if(add_product_list.length == 0){

            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "상품을 선택해 주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            // 다이얼로그 닫기
                            this.setState({ basic_dialog_show_state: false });
                        }
                    }
                ]
            });

            return false;
        }
        
        let ga_product_list = [];
        for (var idx in add_product_list) {
            var obj = add_product_list[idx];
            var result_obj = {
                category_no: obj.category_no,
                create_type_cd: obj.create_type_cd,
                created_date : obj.created_date,
                hit_cnt :obj.hit_cnt,
				cart_cnt :obj.cart_cnt,
                wish_cnt :obj.wish_cnt,
                quantity :obj.quantity,
                mod_user_seq: obj.mod_user_seq,
                review_cnt : obj.review_cnt,
                sale_percent : obj.sale_percent,
                order_price : obj.order_price,
                retail_price: obj.retail_price,
                del_yn: obj.del_yn,
                order_cnt : obj.order_cnt,
                review_point : obj.review_point,
                display_yn: obj.display_yn,
                iframe_html_id: this.state.iframe_html_id,
                iframe_page_id: this.state.iframe_page_id,
                image_url: obj.image_url,
                detail_image_url: obj.detail_image_url,
                price: obj.price,
                pc_discount_price: obj.pc_discount_price,
                product_name: obj.product_name,
                product_no: obj.product_no,
            }
            product_list.push(result_obj) // 실제 추가 할 상품
            var ga_result_obj = {
                product_name: obj.product_name,
                product_no: obj.product_no,
            }
            ga_product_list.push(ga_result_obj) // GA 이벤트 표기용 상품
        }

        // 단일 || 다중 카테고리 상태에 따라 상품 추가
        if(this.state.event_category_select_type == "34001"){
            products[selected_category_no]  = [...product_list];
            this.setState({products: products});
        } else if(this.state.event_category_select_type == "34002"){
            this.setState({rp_product_list: product_list});
        }
        this.setState({
            show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
            event_product_checked_list: [],
            event_rp_product_list : [],
            right_is_modified: true,
        }, () => {
            this.orderIdxReset();

            if (ga_product_list != null && ga_product_list.length > 0) {
                // alert("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list) +" ("+ this.state.ga_rp_category_name + ")")
                this.setPopupGaEvent("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list));
            }
        })
    }

    /** 선정된 상품목록 팝업 닫기 */
    closeEventListPopup(){
        this.setState({
            show_event_product_list_popup : false,
            show_except_setting_popup : false,
            show_except_product_popup : false,
            show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
            except_product_list : [],
            except_products: [],
            event_product_checked_list : [],
        });
    }

     /* ---------------------------------- 필수 상품 목록 팝업 관련 함수 --------------------------------------- */
    
    /** 필수 상품 목록 보기 버튼 클릭 */
    onClickEssentialProductListPopup(uuid){
        console.log("uuid",uuid);
        this.setState({
            essential_list_self_select_uuid : uuid,
        }, () => {
            // if(!this.state.show_essential_product_list_popup){
                this.setState({
                    show_add_product_popup : false, // 다른 팝업 닫기
                    show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                    show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
                    show_except_product_popup: false,
                    show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
                }, () => {
                    this.setState({
                        show_essential_product_list_popup : true, // 팝업 열기
                    });
                });
            // }
        })
    }

    /** 필수 상품 목록 팝업 닫기 */
    closeEssentialListPopup(){
        this.setState({
            show_essential_product_list_popup : false, // 팝업 닫기
        });
    }

    /* ---------------------------------- 제외 상품 목록 팝업 관련 함수 --------------------------------------- */

    /** 제외 상품 목록 보기 버튼 클릭 */
    onClickExcludeProductListPopup(){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else if(!this.state.show_exclude_product_list_popup){
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            this.setState({
                show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
                show_except_product_popup: false,
                show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
                show_add_product_popup : false, // 다른 팝업 닫기
            }, () => {
                this.setState({
                    show_exclude_product_list_popup : true, // 팝업 열기
                });
            });
        }
    }

    /** 제외 상품 목록 팝업 닫기 */
    closeExcludeListPopup(){
        this.setState({
            show_exclude_product_list_popup : false, // 팝업 닫기
        });
    }

    /** 제외 상품 목록 불러오기 */
    async loadExcludeProductList(){
        this.props.excludeProductLoad(this.state.iframe_page_id, this.state.iframe_html_id)
    }

    /** 제외 상품 목록 저장 */
    saveExcludeProductList(){
        if(!this.props.excludeProductModified){
            return;
        }

        this.props.excludeProductSave({
            list: this.props.excludeProductList,
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
        });
    }

    /* ---------------------------------- 진열 간 상품 중복 제외 설정 팝업 관련 함수 --------------------------------------- */

    /** 진열 간 상품 중복 제외 설정 팝업 열기 버튼 클릭 */
    onClickExceptSettingPopup(){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else if(!this.state.show_except_setting_popup){
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            this.setState({
                show_add_product_popup : false, // 다른 팝업 닫기
                show_exclude_product_list_popup : false,
                show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                show_except_product_popup: false,
                show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
            }, () => {
                const {rp_event_setting_list} = this.state;
                let {select_except_rp_setting} = this.state;
                if(select_except_rp_setting == ""){
                    for(var i=0;i<rp_event_setting_list.length;i++){
                        if(this.state.except_setting_list.map(obj => obj.id).indexOf(rp_event_setting_list[i].id) < 0){
                            select_except_rp_setting = rp_event_setting_list[i].id;
                            break;
                        }
                    }
                }
                
                this.setState({
                    select_except_rp_setting : select_except_rp_setting,
                    show_except_setting_popup : true, // 팝업 열기
                });
            });
        }
    }

    /** 진열 설정 목록 불러오기 (셀렉트 박스)*/
    async loadRpEventSettingList(add_page){
        await http.get("/rest/shop/rp/event/all/exceptSetting/list?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
		.then(res => {
			if (res.data.code == "200") {
                let rp_event_setting_list = res.data["list"];
                if (rp_event_setting_list != null && rp_event_setting_list.length > 0) {
                    for(var i=0;i<rp_event_setting_list.length;i++){
                        rp_event_setting_list[i].id = rp_event_setting_list[i].iframe_page_id+"/"+rp_event_setting_list[i].iframe_html_id;
                        rp_event_setting_list[i].name = "["+rp_event_setting_list[i].iframe_page_name+"] "+rp_event_setting_list[i].display_name;
                    }
                }

                this.setState({
                    rp_event_setting_list: rp_event_setting_list,
                }, () =>{
                    if(add_page){
                        if (rp_event_setting_list != null && rp_event_setting_list.length > 0) {
                            let select_except_rp_setting = rp_event_setting_list[0].id;
                            this.setState({select_except_rp_setting: select_except_rp_setting,})
                        }
                    }
                });

			} else{
                alert("진열 설정 목록을 불러오는데 실패하였습니다.");
            }
		})
		.catch(error => {
            alert("진열 설정 목록을 불러오는데 실패하였습니다.");
		 });
    }

    /** 진열 간 상품 중복 제외 설정 목록 불러오기 (테이블)*/
    async loadExceptSettingList(){
        await http.get("/rest/shop/rp/event/target/exceptSetting/list?iframe_page_id="+this.state.iframe_page_id+"&iframe_html_id="+this.state.iframe_html_id)
		.then(res => {
			if (res.data.code == "200") {
                let except_setting_list = res.data["list"];
                for(var i=0;i<except_setting_list.length;i++){
                    except_setting_list[i].id = except_setting_list[i].iframe_page_id+"/"+except_setting_list[i].iframe_html_id;
                    except_setting_list[i].name = "["+except_setting_list[i].iframe_page_name+"] "+except_setting_list[i].display_name;
                }
                this.setState({
                    except_setting_list: except_setting_list,
                    except_setting_is_modified : false,
                });
			} else{
                alert("진열 간 상품 중복 제외 설정 목록을 불러오는데 실패하였습니다.");
            }
		})
		.catch(error => {
            alert("진열 간 상품 중복 제외 설정 목록을 불러오는데 실패하였습니다.");
		 });
    }

    /** 진열 간 상품 중복 제외 설정의 셀렉트박스가 변경될때 실행되는 함수 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelectExceptRpSetting(text, value, id){

        this.setState({select_except_rp_setting : value,});
    }

    /** 진열 간 상품 중복 제외 설정의 셀렉트박스가 변경될때 실행되는 함수 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeSelectExceptProductCategory(text, value, id){
        this.setState({
            except_product_list: this.state.except_products[value].list,
            except_select_category : value,
        });
    }

    /** 진열 간 상품 중복 제외 설정 상품 목록 보기
     * @param {*} obj 진열 정보
    */
    async showExceptSettingProductList(obj){
        this.setState({
            show_except_product_popup: true,
            except_select_category : "",
        }, () => {
            this.setState({show_except_product_list_table_loading_bar : true,});
        });
        let api; // iframe_page_id 값에 따라 변경될 api

        if(obj.iframe_page_id == "robomd_cafe24_main_page"){
            api = "/rest/shop/cafe24/main/product?display_group="+obj.cafe24_main_display_group;
        }
        else if(obj.iframe_page_id == "robomd_cafe24_category_page"){
            api = "/rest/shop/cafe24/category/products?category_no="+obj.cafe24_category_no_list.split(',')[obj.cafe24_category_no_list.length-1]+"&display_group="+obj.cafe24_category_display_group
        }
        else if(obj.iframe_page_id == "robomd_product_detail_page" && obj.event_category_select_type == "34001"){
            api = "/rest/shop/rp/category/event/select/v3?iframe_page_id="+obj.iframe_page_id+"&iframe_html_id="+obj.iframe_html_id;
        } else{
            api = "/rest/shop/rp/event/select/v2?iframe_page_id="+obj.iframe_page_id+"&iframe_html_id="+obj.iframe_html_id;
        }
        await http.get(api)
		.then(res => {
			if (res.data.code == "200") {
                let list = [];
                let products = {};
                let except_event_category_list = [];
                let select_category = "";

                if(obj.iframe_page_id == "robomd_cafe24_main_page"){
                    list = res.data["main_product_list"];
                }
                else if(obj.iframe_page_id == "robomd_cafe24_category_page"){
                    list = res.data["category_product_list"];
                }
                else if(obj.iframe_page_id == "robomd_product_detail_page" && obj.event_category_select_type == "34001"){
                    let category_list = res.data["event_category_array"];
                    select_category = category_list[0];
                    list = res.data[category_list[0]].list;
                    for(var i=0;i<category_list.length;i++){
                        products[category_list[i]] = res.data[category_list[i]];
                        except_event_category_list.push({
                            category_name : res.data[category_list[i]].category_name,
                            category_name_no_format : res.data[category_list[i]].category_name_no_format,
                            category_no : category_list[i],
                        });
                    }
                } else{
                    list = res.data["list"];
                }
                this.setState({
                    except_product_list: list,
                    except_products: products,
                    except_event_category_list : except_event_category_list,
                    except_select_category : select_category,
                    show_except_product_list_table_loading_bar : false,
                });

			} else {
                this.setState({show_except_product_list_table_loading_bar : false,});
                alert("상품 목록을 불러오는데 실패하였습니다.");
            }
		})
		.catch(error => {
            this.setState({show_except_product_list_table_loading_bar : false,});
		 });
    }

    /** 진열 간 상품 중복 제외 상품 목록 팝업 닫기 */
    closeExceptSettingProductList(){
        this.setState({
            except_product_list : [],
            except_products: [],
            show_except_product_popup : false,
        });
    }

    /** 진열 간 상품 중복 제외 설정 추가 */
    addExceptSettingList(){
        let {except_setting_list} = this.state;

        for(var i=0;i<except_setting_list.length;i++){
            if(except_setting_list[i].id == this.state.select_except_rp_setting){
                alert("이미 추가된 진열 설정입니다.");
                return false;
            }
        }

        const iframe_page_id = this.state.select_except_rp_setting.split("/")[0];
        const iframe_html_id = this.state.select_except_rp_setting.split("/")[1];
        
        // 선택한 진열을 찾아서 진열 간 상품 중복 제외 설정 목록에 추가
        for(var i=0;i<this.state.rp_event_setting_list.length;i++){
            if(this.state.rp_event_setting_list[i].iframe_page_id == iframe_page_id && this.state.rp_event_setting_list[i].iframe_html_id == iframe_html_id){
                except_setting_list.push(this.state.rp_event_setting_list[i]);
                break;
            }
        }

        this.setState({
            except_setting_list : except_setting_list,
            except_setting_is_modified : true,
        });
    }

    /** 진열 간 상품 중복 제외 설정 삭제 (x버튼 클릭) */
    delExceptSetting(idx){
        let {except_setting_list} = this.state;

        except_setting_list.splice(idx,1);
        this.setState({
            except_setting_list: except_setting_list,
            except_setting_is_modified : true,
        });
    }

    /** 진열 간 상품 중복 제외 설정 취소 버튼 클릭 */
    clickCancelExceptSettingList(){
        this.setState({
            paste_confirm_dialog_show_state: true,
            confirm_dialog_content: "변경사항을 취소 하시겠습니까?",
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        this.setState({
                            paste_confirm_dialog_show_state: false,
                        });

                        if(this.state.iframe_html_id == null || this.state.iframe_html_id == ""){
                            this.setState({
                                except_setting_list : [],
                                except_setting_is_modified : false,
                            });
                        } else{
                            this.loadExceptSettingList();
                        }
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            paste_confirm_dialog_show_state: false,
                        });
                    }
                }
            ]
        });
    }

    /** 진열 간 상품 중복 제외 설정 저장 */
    saveExceptSettingList(save_left){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/exceptSetting/save';
        const body = {
            list : this.state.except_setting_list,
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
        }

        if(save_left){
            if(this.state.except_setting_is_modified){
                http
                .post(url, body, config)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.setState({
                            except_setting_is_modified : false,
                        });
                    } else {
                        alert('진열 간 상품 중복 제외 설정 저장에 실패하였습니다.');
                    }
                })
                .catch((error) => {
                    alert('진열 간 상품 중복 제외 설정 저장에 실패하였습니다.');
                });
    
                this.setGaEvent("진열 간 상품 중복 제외 설정 저장");
            }
        } else{
            this.setState({
                paste_confirm_dialog_show_state: true,
                confirm_dialog_content: "변경된 설정을 저장 하시겠습니까?",
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.setState({
                                paste_confirm_dialog_show_state: false,
                                save_dialog_show_state: true,
                                save_dialog_content: "저장 중 입니다.",
                                save_dialog_button_list: [
                                    {
                                        title: "확인",
                                        type: "1",
                                        display_yn:"N",
                                        event_method: () => {
                                            //팝업 닫기
                                            this.setState({ save_dialog_show_state: false });
                                        }
                                    }
                                ]
                            });
    
                            http
                            .post(url, body, config)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    this.setState({
                                        save_dialog_show_state: true,
                                        save_dialog_content: "저장됐습니다.",
                                        save_dialog_button_list: [
                                            {
                                                title: "확인",
                                                type: "1",
                                                event_method: () => {
                                                    //팝업 닫기
                                                    this.setState({
                                                        save_dialog_show_state: false,
                                                        except_setting_is_modified : false,
                                                    });
                                                }
                                            }
                                        ]
                                    });
                                } else {
                                    alert('진열 간 상품 중복 제외 설정 저장에 실패하였습니다.');
                                    //팝업 닫기
                                    this.setState({ save_dialog_show_state: false });
                                }
                            })
                            .catch((error) => {
                                alert('진열 간 상품 중복 제외 설정 저장에 실패하였습니다.');
                                //팝업 닫기
                                this.setState({ save_dialog_show_state: false });
                            });
    
                            this.setGaEvent("진열 간 상품 중복 제외 설정 저장");
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                paste_confirm_dialog_show_state: false,
                            });
                        }
                    }
                ]
            });
        }
    }

    /** 진열 간 상품 중복 제외 설정 팝업 닫기 */
    closeExceptSettingPopup(){
        this.setState({
            show_except_setting_popup : false, // 팝업 닫기
        });
    }

    /* ------------------------------------------ 메인 테이블 관련 함수 ---------------------------------------------- */

    /** 메인 테이블이 로드 될떄 드래그 스크롤을 위한 max_height 설정 */
    onLoadMainTable(){
        let window_height = $(window).height();
        // window 높이에 따라서 메인테이블의 max-height가 달라지도록 적용(드래그 스크롤을 위한 작업)
        if(this.state.event_category_select_type=="34001"){
            let multi_category_select_div= $("#multi_category_select_div").height();
            $("#list_admin_main_board").css({"max-height":window_height-330-multi_category_select_div});
            $("#main_table_tbody").css({"max-height":window_height-400-multi_category_select_div});
        } else{
            $("#list_admin_main_board").css({"max-height":window_height-330});
            $("#main_table_tbody").css({"max-height":window_height-400});
        }
    }

    /** 대 카테고리 상품 목록 불러오기
     * @param {*} // event_category_list 입력된 카테고리의 상품목록 조회, 값이 없는 경우 해당 진열 설정의 모든 카테고리 상품 목록 조회
    */
    async loadBigCategoryProductList(only_setting_load){
        const {big_category_checked_list} = this.state;
        let event_category_list = "";
        
        if(big_category_checked_list.length == 0){
            // 대 카테고리 상품 목록 불러오기 완료 작업
            this.loadBigCategoryProductListFinish();
            this.setState({show_rp_product_list_table_loading_bar : false,});
            return false;
        }

        for(var i=0;i<big_category_checked_list.length;i++){
            if(i == big_category_checked_list.length-1){
                event_category_list += big_category_checked_list[i];
            } else{
                event_category_list += big_category_checked_list[i]+",";
            }
        }

        const sendForm = {
            iframe_page_id : this.state.iframe_page_id,
            iframe_html_id : this.state.iframe_html_id,
            event_category_list : event_category_list,
        }
        await http.get("/rest/shop/rp/category/event/select/v3?", {params:sendForm})
        .then(res => {
            if (res.data.code == "200") {
                let {products, products_is_auto, products_modified_date} = this.state;
                let category_list = res.data["event_category_array"];
                let is_init_analysis_standard_value = false;
                for(var i=0;i<category_list.length;i++){
                    // 설정값만 적용하는 경우 (상품목록 저장 등)
                    if(only_setting_load){
                        products_is_auto[category_list[i]] = res.data[category_list[i]].is_auto;
                        products_modified_date[category_list[i]] = res.data[category_list[i]].last_mod_date;
                    } else{
                        // 상품 목록이 이미 존재하는 경우 받아온 값으로 교체하지 않는다.
                        if(!products[category_list[i]]){
                            products[category_list[i]] = res.data[category_list[i]].list;
                            products_is_auto[category_list[i]] = res.data[category_list[i]].is_auto;
                            products_modified_date[category_list[i]] = res.data[category_list[i]].last_mod_date;
                        }
                    }
                }

                this.setState({
                    show_rp_product_list_table_loading_bar : false,
                    products : products,
                    products_is_auto : products_is_auto,
                    products_modified_date : products_modified_date,
                }, () => {
                    // 대 카테고리 상품 목록 불러오기 완료 작업
                    this.loadBigCategoryProductListFinish();
                });
            } else{
                alert('상품 목록을 불러오는데 실패하였습니다.');
            }

        })
        .catch(error => {
         });
    }

    /** 대 카테고리 상품 목록 불러오기 완료 작업 */
    async loadBigCategoryProductListFinish(){
        // 상품 이미지 byte로 변환
        // const {products, big_category_checked_list} = this.state;
        // for(let i=0;i<big_category_checked_list.length;i++){
        //     for(let idx=0;idx<products[big_category_checked_list[i]].length;idx++){
        //         // 이미지가 재로딩 되지 않은 경우에만 byte변환 api를 타도록 한다
        //         if(products[big_category_checked_list[i]][idx]["image_reload_yn"]!="Y"){
        //             this.changeImageToByte(products[big_category_checked_list[i]][idx]["image_url"],idx,"products", null ,big_category_checked_list[i]);
        //             this.changeImageToByte(products[big_category_checked_list[i]][idx]["detail_image_url"],idx,"products", "detail_image_url" ,big_category_checked_list[i]);
        //         }
        //     }
        // }
        // 기존에 오른쪽 영역에서 선택되어있던 카테고리가 선택한 대카테고리 목록에 없을 경우,
        // 선택된 카테고리중 가장 첫번째 대카테고리를 오른쪽 영역에 선택된 카테고리로 변경한다.
        if(this.state.big_category_checked_list.indexOf(String(this.state.selected_category_no)) < 0){
            // 상품 목록이 불러와지기 전에 selected_category_no에 값을 주면, render 시작에
            // products에서 불러오는 상품목록에서 에러가 생김. 그래서 여기서 설정하는 것.
            this.setState({
                selected_category_no : this.state.big_category_checked_list[0], // 해당 대 카테고리를 선택된 카테고리로 설정
            }, () => {
                this.orderIdxReset(); // 순번 초기화
                // 기존 체크 해제
                this.setState({
                    rp_check_product_list: [],
                });

                // 데이터 분석 표기 기준에 따라 재조회
                this.reloadProductByAnalysisStandard("products", this.state.products[this.state.selected_category_no], this.state.analysis_standard_value);
            });
        } else{
            this.orderIdxReset(); // 순번 초기화
        }

        // 오른쪽 영역을 초기화 시키는 중이었다면, 다이얼로그 출력
        if(right_reset){
            right_reset = false;
            this.setState({
                show_rp_product_list_table_loading_bar : false,
                confirm_dialog_show_state : false,
                basic_dialog_show_state: true,
                basic_dialog_content: "상품목록이 초기화되었습니다.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                        }
                    }
                ]
            });
        }
    }

    /** 메인 테이블쪽 선택된 다중 카테고리 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id(question_idx, answer_idx 포함)
    */
    onChangeMultiCategorySelect(text, value, id){
        this.setState({selected_category_no : value,}, () => {
            this.orderIdxReset(); // 순번 초기화
            // 기존 체크 해제
            this.setState({
                rp_check_product_list: [],
            });
            // 데이터 분석 표기 기준에 따라 재조회
            this.reloadProductByAnalysisStandard("products", this.state.products[this.state.selected_category_no], this.state.analysis_standard_value);
        });
    }

    /** 상품의 순서를 나타내는 input값 타이핑 가능하도록 하는 함수
     * @param {*} e < e.target.name : idx 번호, e.target.value : 입력 값>
    */
    onChangeInput(e) {
        // main_order_idx = [1,2,3,4,5,6,7,8,9,10]
        const {main_order_idx} = this.state;
        main_order_idx[e.target.name] =  StringUtil.inputOnlyNumberFormat(e.target.value); // 배열의 해당 위치값을 이벤트로 받아온 값으로 변경
        this.setState({
            main_order_idx:  main_order_idx, // 적용
        }, ()=>{
            // input width가 숫자 길이에 따라 변환되도록 조절
            $("#"+e.target.id).css({width: Number(((this.state.main_order_idx[e.target.name].length-1)*5)+24)+"px"});
        })
    }

    /** 상품의 순번을 순차적으로 초기화하는 함수 */
    orderIdxReset() {
        // 번호가 순차적으로 보일 수 있도록 main_order_idx를 초기화
        let main_order_idx = [];
        const {rp_product_list, products, selected_category_no} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            if(selected_category_no != "" && selected_category_no != null){
                product_list = [...products[selected_category_no]];
            }
        } else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }
        for(var i=0;i<product_list.length;i++){
            main_order_idx[i]=i+1;
        }
        // 반영
        this.setState({
            main_order_idx:  main_order_idx,
        }, ()=>{
            // input width가 숫자 길이에 따라 변환되도록 조절
            for(var i=0;i<this.state.main_order_idx.length;i++){
                let str_length = String(i+1).length;
                $("#main_order_input_"+i).css({width: Number(((str_length-1)*5)+24)+"px"});
            }
        })
    }

    /** 상품의 순서를 input 입력 값으로 변경하는 함수
     * @param {*} e < e.target.name : idx 번호, e.target.value : 입력 값>
     * @param {*} onBlur blur 된 경우
    */
    onChangeOrderIdx(e,onBlur) {
        // 상품 목록
        const {rp_product_list, products, selected_category_no} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            product_list = JSON.parse(JSON.stringify(products[selected_category_no]));
        } else if(this.state.event_category_select_type == "34002"){
            product_list = JSON.parse(JSON.stringify(rp_product_list));
        }
        // 엔터키를 눌렀을때 해당 순서 범위가 유효한 범위라면 순서 변경
        if(e.key=='Enter' || onBlur){
            if(e.target.value>0 && e.target.value<=product_list.length){
                // 이전과 값이 달라진 경우
                if(e.target.name != e.target.value-1){
                    const change_idx = Number(e.target.value)-1;
                    const change_product = product_list[e.target.name]; // 순서를 변경할 상품의 임시 저장소
                    change_product.create_type_cd = "22999"; // 수동 선정으로 변경
                    product_list.splice(e.target.name,1); // 위치를 변경할 상품을 리스트에서 제거 후
                    product_list.splice(e.target.value-1,0,change_product); // 변경된 위치에 추가
                    this.orderIdxReset(); // 순번 초기화
                    
                    // 단일 || 다중 카테고리 상태에 따라 상품 순서 변경
                    if(this.state.event_category_select_type == "34001"){
                        products[selected_category_no]  = JSON.parse(JSON.stringify(product_list));
                        this.setState({products: products}, () => {
                            $("#main_order_input_"+change_idx).focus();
                        });
                    } else if(this.state.event_category_select_type == "34002"){
                        this.setState({rp_product_list: product_list}, () => {
                            $("#main_order_input_"+change_idx).focus();
                        });
                    }
                    this.setState({right_is_modified: true,});
                }
            } else{
                this.orderIdxReset(); // 순번 초기화
            }
        }
    }

    /** 체크된 상품을 이동시키는 함수
     * @param {*} to 이동할 계산 값 product_list.length or 1 or -1 or 0
    */
    onChangeProductOrder(to) {
        const {rp_product_list, products, selected_category_no, rp_check_product_list} = this.state;
        let product_list = [];
        let check_product_no_list = [];
        let main_checked_idx = [];
        
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            product_list = JSON.parse(JSON.stringify(products[selected_category_no]));
        } else if(this.state.event_category_select_type == "34002"){
            product_list = JSON.parse(JSON.stringify(rp_product_list));
        }

        for(var i=0;i<rp_check_product_list.length;i++){
            check_product_no_list.push(rp_check_product_list[i].product_no);
        }

        for(var i=0;i<product_list.length;i++){
            if(check_product_no_list.indexOf(product_list[i].product_no) != -1){
                main_checked_idx.push(i);
            }
        }

        // 체크된 상품이 없다면 종료
        if(main_checked_idx.length === 0){
            return false;
        }
        
        const checked_idx_length = main_checked_idx.length;
        main_checked_idx = main_checked_idx.sort(function(a,b){
            return a - b;
        }); // 체크된 idx를 정렬(숫자 정렬을 사용)

        // 모든 상품이 선택된 경우에는 동작하지 않는다.
        if (checked_idx_length == product_list.length) {
            return false;
        }

        var update_checked_idx = []; // 체크 상품이 변경된 위치들
        if(to == 0){
            // 체크 상품 순서대로 위치 이동
            for(var idx=0;idx<main_checked_idx.length;idx++){
                let update_idx = 0; // 이동할 위치 값
                // 앞서 변경된 체크 상품 위치들 중, 현재의 update_idx와 같은것이 있다면,
                // 그 자리는 이미 차지된 자리이니 한칸 더 아래로 간다.
                for(var idx2=0;idx2<update_checked_idx.length;idx2++){
                    if(update_checked_idx[idx2] == update_idx){
                        update_idx += 1;
                    }
                }
                // update_idx값으로 상품을 옮길것이니, 해당 자리가 사용되었음을 나타내기 위해
                // 체크 상품이 변경된 위치들에 추가한다.
                update_checked_idx.push(update_idx);

                // 상품을 새로운 위치에 넣기
                const checked_product = product_list.splice(Number(main_checked_idx[idx]),1);
                checked_product[0].create_type_cd = "22999"; // 수동 선정으로 변경
                product_list.splice(update_idx, 0, checked_product[0]);
            }
        } else if(to == -1){
            for(var idx=0;idx<main_checked_idx.length;idx++){
                // 상품을 한칸 위로 올리는 위치
                let update_idx = Number(main_checked_idx[idx])-1;
                // 만약 한칸 위로 올렸을때 0보다 작아진다면, 0으로 설정한다.
                if(update_idx<0){
                    update_idx = 0;
                }
                for(var idx2=0;idx2<update_checked_idx.length;idx2++){
                    if(update_checked_idx[idx2] == update_idx){
                        update_idx += 1;
                    }
                }
                update_checked_idx.push(update_idx);

                // 상품을 새로운 위치에 넣기
                const checked_product = product_list.splice(Number(main_checked_idx[idx]),1);
                checked_product[0].create_type_cd = "22999"; // 수동 선정으로 변경
                product_list.splice(update_idx, 0, checked_product[0]);
            }
        } else if(to == 1){
            for(var idx=main_checked_idx.length-1;idx>=0;idx--){
                // 상품을 한칸 아래로 내리는 위치
                let update_idx = Number(main_checked_idx[idx])+1;
                // 만약 한칸 아래로 내렸을 경우 상품 배열 idx를 넘어갈 경우, 마지막 idx값으로 설정한다.
                if(update_idx>product_list.length-1){
                    update_idx = product_list.length-1;
                }
                for(var idx2=0;idx2<update_checked_idx.length;idx2++){
                    if(update_checked_idx[idx2] == update_idx){
                        update_idx -= 1;
                    }
                }
                update_checked_idx.push(update_idx);

                // 상품을 새로운 위치에 넣기
                const checked_product = product_list.splice(Number(main_checked_idx[idx]),1);
                checked_product[0].create_type_cd = "22999"; // 수동 선정으로 변경
                product_list.splice(update_idx, 0, checked_product[0]);
            }
        } else {
            for(var idx=main_checked_idx.length-1;idx>=0;idx--){
                let update_idx = product_list.length-1;
                for(var idx2=0;idx2<update_checked_idx.length;idx2++){
                    if(update_checked_idx[idx2] == update_idx){
                        update_idx -= 1;
                    }
                }
                update_checked_idx.push(update_idx);

                // 상품을 새로운 위치에 넣기
                const checked_product = product_list.splice(Number(main_checked_idx[idx]),1);
                checked_product[0].create_type_cd = "22999"; // 수동 선정으로 변경
                product_list.splice(update_idx, 0, checked_product[0]);
            }
        }

        // 단일 || 다중 카테고리 상태에 따라 상품 순서 변경
        if(this.state.event_category_select_type == "34001"){
            products[selected_category_no]  = [...product_list];
            this.setState({
                products: products,
                right_is_modified: true,
            }, () => {this.orderIdxReset()});
        } else if(this.state.event_category_select_type == "34002"){
            this.setState({
                rp_product_list: product_list,
                right_is_modified: true,
            }, () => {this.orderIdxReset()});
        }
    }

    /** 체크 상태 변경 :전체 */
    onChangeRpProductCheckAll(e) {
        const {rp_product_list, products, selected_category_no} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                return true;
            } else{
                product_list = [...products[selected_category_no]];
            }
        } else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }
        var rp_check_product_list = [];

        if (e.currentTarget.checked) {
            for(var idx in product_list) {
                rp_check_product_list.push(product_list[idx])
            }
        }
        this.setState({
            rp_check_product_list: rp_check_product_list,
        });
    }

    /** 체크 상태 변경
     * @param {*} e <e.target.value : 상품 목록 idx 번호>
    */
    onChangeRpProductCheck(e) {
        var idx = e.target.value;
        const {rp_product_list, products, selected_category_no, rp_check_product_list} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            product_list = [...products[selected_category_no]];
        } else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }
        
        // 체크를 한 경우와 풀은 경우
        if (!e.currentTarget.checked) {
            for(var idx2 in rp_check_product_list) {
                if (rp_check_product_list[idx2]["product_no"] == product_list[idx]["product_no"]) {
                    rp_check_product_list.splice(idx2, 1);
                    break;
                }
            }
            this.setState({
                rp_check_product_list: rp_check_product_list,
            })

        } else {
            rp_check_product_list.push(product_list[idx])
            this.setState({
                rp_check_product_list: rp_check_product_list,
            })
        }
    }

    /** 체크된 상품을 삭제하는 함수 */
    onClickCheckedRpProductDelete() {
        const {rp_product_list, products, selected_category_no, rp_check_product_list} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                return true;
            } else{
                product_list = [...products[selected_category_no]];
            }
        } else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }
        if (rp_check_product_list.length != 0) {
            this.setState({
                confirm_dialog_show_state: true,
                confirm_dialog_content: "선택하신 상품을 목록에서 삭제하시겠습니까?",
                confirm_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            var product_list_copy = JSON.parse(JSON.stringify(product_list));

                            product_list_copy = product_list_copy.filter(function(obj) {
                                return !this.has(obj.product_no);
                            }, new Set(rp_check_product_list.map(obj => obj.product_no)));

                            // 단일 || 다중 카테고리 상태에 따라, 삭제후 상품 목록 적용
                            if(this.state.event_category_select_type == "34001"){
                                products[selected_category_no]  = [...product_list_copy];
                                this.setState({products: products}, () => {this.orderIdxReset()});
                            } else if(this.state.event_category_select_type == "34002"){
                                this.setState({rp_product_list: product_list_copy}, () => {this.orderIdxReset()});
                            }

                            this.setState({
                                rp_check_product_list: [],
                                right_is_modified: true,
                            });

                            var ga_check_product_list = [];
                            for (var idx in rp_check_product_list) {
                                var obj = rp_check_product_list[idx];
                                ga_check_product_list.push({
                                    product_name: obj.product_name,
                                    product_no: obj.product_no,
                                })
                            }
                            if (product_list.length == rp_check_product_list.length) {
                                // alert("전체 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list) + " ("+this.state.ga_rp_category_name+")")
                                this.setGaEvent("전체 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list) + " ("+this.state.ga_rp_category_name+")")
                            } else {
                                // alert("선택 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list)+ " ("+this.state.ga_rp_category_name+")")
                                this.setGaEvent("선택 삭제 - "+rp_check_product_list.length+"개 "+JSON.stringify(ga_check_product_list)+ " ("+this.state.ga_rp_category_name+")")
                            }
                            
                            this.setState({
                                confirm_dialog_show_state: false,
                            });
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                confirm_dialog_show_state: false,
                            });
                        }
                    }
                ]
            });
        }
    }

    /** 오른쪽 영역 저장 */
    onClickSaveRightSetting(none_dialog){
        // 진열 추가시 왼쪽 영역을 저장하지 않았다면
        if(this.state.iframe_html_id == null || this.state.iframe_html_id == ""){

            this.setState({
                title_content_dialog_show_state: true,
                title_content_dialog_title: "위젯 자동진열 설정을 저장하신 후에<br/>상품 목록을 저장하실 수 있습니다.",
                title_content_dialog_content: "모두 저장하시겠습니까?",
                title_content_dialog_button_list: [
                    {
                        title: "네",
                        type: "1",
                        event_method: () => {
                            this.setState({
                                title_content_dialog_show_state : false,
                            });
                            this.onClickSaveLeftSetting(true);
                        }
                    },
                    {
                        title: "아니요",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                title_content_dialog_show_state: false,
                            });
                        }
                    }
                ]
            });
        } else {
            // 단일과 다중 카테고리 상품 목록 모두 저장
            this.saveRpEventList(none_dialog);
        }
    }

    /* ---------------------------------- 상품 추가 팝업 관련 함수 --------------------------------------- */

    /** 상품 추가 팝업 : 선택 상품 추가하기 버튼 클릭 */
    onClickPopupCheckedProductApply(popup_check_product_list) {
        const {rp_product_list, products, selected_category_no} = this.state;
        let product_list = [];
        // 단일 || 다중 카테고리 목록 불러오기
        if(this.state.event_category_select_type == "34001"){
            if(this.state.selected_category_no == null || this.state.selected_category_no == ""){
                this.closeAddProductPopup();
                return false;
            } else{
                product_list = [...products[selected_category_no]];
            }
        }
        else if(this.state.event_category_select_type == "34002"){
            product_list = [...rp_product_list];
        }
        
        var add_product_list = [];
        
        var page_id = this.state.iframe_page_id;
        var html_id = this.state.iframe_html_id;

        add_product_list = popup_check_product_list.filter(function(obj) {
            return !this.has(obj.product_no);
        }, new Set(product_list.map(obj => obj.product_no))); // 상품 중복 체크

        if(add_product_list.length == 0){
            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "상품을 선택해 주세요.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            // 다이얼로그 닫기
                            this.setState({ basic_dialog_show_state: false });
                        }
                    }
                ]
            });

            return false;
        }
        
        let ga_product_list = [];
        for (var idx in add_product_list) {
            // alert(idx);
            var obj = add_product_list[idx];
            var result_obj = {
                category_no: obj.category_no,
                created_date : obj.created_date,
                hit_cnt :obj.hit_cnt,
				cart_cnt :obj.cart_cnt,
                wish_cnt :obj.wish_cnt,
                quantity :obj.quantity,
                mod_user_seq: obj.mod_user_seq,
                review_cnt : obj.review_cnt,
                sale_percent : obj.sale_percent,
                order_price : obj.order_price,
                pc_discount_price: obj.pc_discount_price,
                del_yn: obj.del_yn,
                order_cnt : obj.order_cnt,
                review_point : obj.review_point,
                display_yn: obj.display_yn,
                iframe_html_id: html_id,
                iframe_page_id: page_id,
                image_url: obj.image_url,
                detail_image_url: obj.detail_image_url,
                price: obj.price,
                product_name: obj.product_name,
                product_no: obj.product_no,
                create_type_cd: "22999", // 수동 선정
            }
            product_list.push(result_obj) // 실제 추가 할 상품
            var ga_result_obj = {
                product_name: obj.product_name,
                product_no: obj.product_no,
            }
            ga_product_list.push(ga_result_obj) // GA 이벤트 표기용 상품
        }
        // 단일 || 다중 카테고리 상태에 따라 상품 추가
        if(this.state.event_category_select_type == "34001"){
            products[selected_category_no]  = [...product_list];
            this.setState({products: products}, () => {this.orderIdxReset()});
        }
        else if(this.state.event_category_select_type == "34002"){
            this.setState({rp_product_list: product_list}, () => {this.orderIdxReset()});
        }
        this.setState({right_is_modified: true,});

        // 상품 추가 팝업 닫기
        this.closeAddProductPopup();

        if (ga_product_list != null && ga_product_list.length > 0) {
            // alert("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list) +" ("+ this.state.ga_rp_category_name + ")")
            this.setPopupGaEvent("선택 상품 추가하기 - "+ga_product_list.length+"개 "+ JSON.stringify(ga_product_list));
        }
    }

    /** 상품 추가 팝업 열기 */
    onClickAddProductPopup(){
        // 대 카테고리 선정 팝업의 내용이 수정된 상태라면
        if(this.state.multi_category_list_is_modified){
            // 저장하고 닫을것인지 물어본다.
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
        } else{
            this.setPopupGaEvent("상품 추가 팝업 열기")
            this.onCloseBigCategoryListPopup(); // 대카테고리 선정 팝업 닫기
            this.setState({
                show_event_product_list_popup : false, // 선정된 상품목록 팝업 닫기
                show_except_setting_popup : false, // 진열 간 상품 중복 제외 팝업 닫기
                show_except_product_popup: false,
                show_essential_product_list_popup: false, // 필수 상품 설정 팝업 닫기
                show_add_product_popup : true, // 팝업 열기
            }, () => {
                window.dispatchEvent(new Event('resize'));
            });
        }
    }

    /** 상품 추가 팝업 닫기 */
    closeAddProductPopup(){
        this.setState({
            show_add_product_popup : false, // 팝업 닫기
        });
    }

    /** 이미지 백엔드에서 byte로 변환
     * @param {*} url 이미지 url
     * @param {*} idx 상품 위치 idx
     * @param {*} product_list_name 상품 목록 이름
     * @param {*} product_image_url_name 이미지 종류 (메인/상세)
     * @param {*} category_no 카테고리 번호 : 다중 카테고리 전용
    */
    async changeImageToByte(url,idx,product_list_name,product_image_url_name,category_no){
        const sendForm = {
            img_url : url,
        };
        await http.get("/rest/common/v2/backImageLoad", {params:sendForm})
        .then(res => {
            if (res.data != null && res.data != '') {
                if(product_list_name == "products"){
                    let product_list = this.state[product_list_name];
                    product_list[category_no][idx][product_image_url_name == null ? "image_url" : product_image_url_name] = "data:image/png;base64," + window.btoa(String.fromCharCode.apply(null, new Uint8Array(res.data)));
                    product_list[category_no][idx]["image_reload_yn"] = "Y"; // 이미 재로딩 되었던 상품은 다시 api를 타지 않도록 방지하는 부분
                    this.setState({[product_list_name] : product_list});
                } else{
                    let product_list = this.state[product_list_name];
                    product_list[idx][product_image_url_name == null ? "image_url" : product_image_url_name] = "data:image/png;base64," + window.btoa(String.fromCharCode.apply(null, new Uint8Array(res.data)));
                    product_list[idx]["image_reload_yn"] = "Y";
                    this.setState({[product_list_name] : product_list});
                }
            }
        })
        .catch(error => {
        });
    }

    onChangeItemConditionListCd(text, value, id) {
        let idx = id.split("_")[3];
        let inner_idx = id.split("_")[4];

        let {list_self_select} = this.state;
        let list = JSON.parse(JSON.stringify(list_self_select));
        let event_item_condition_list = list[idx]["event_item_condition_list"];
        event_item_condition_list[inner_idx] = value;
        list[idx]["event_item_condition_list"] = event_item_condition_list;

        this.setState({
            list_self_select : list,
            left_is_modified : true,
        });
    }

    /** 플랫폼 API 연동 상태 조회 */
    loadShopSyncStateAndSyncDate = (is_sync_date_check_and_sync_start) => {
        http.get("/rest/shop/shopSyncStateAndSyncDate")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {

                this.setState({
                    syncState : res.data.sync_state,
                    syncDateFormat : res.data.sync_date_full_format
                });

                if (is_sync_date_check_and_sync_start == true) {
                    if (res.data.sync_state == "1") {
                        alert("상품 목록을 불러오는 중입니다. 완료될 때까지 기다려 주세요.\n"+
                                "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.");
                    } else {
                        this.setState({refresh_data_show_state : true});
                    }
                }
			}
		})
		.catch(error => {
		 });
    }

    /** 데이터 동기화 */
    fn_refreshDataSync = () => {
        try {
            this.hasFetchedSyncData.current = true
            this.setState({syncState : "1"});            
            axios.post('/rest/shop/refreshDataSyncShop', {}, {
                headers: {
                    'Connection' : 'keep-alive',
                    'Keep-Alive' : 'timeout=40000',
                    'X-requested-With' : 'XMLHttpRequest',
                    'Content-type': 'application/json;',
                }
            })
            .then(res => {
                /** 최초 호출시 cafe24API 호출 connection timeout 외 예외 발생시 */
                if (!res || res.data.code !== 200) {
                    if (this.hasFetchedSyncData.current) {
                        console.log("일반 서버 에러")
                        throw new Error('ERR_EMPTY_RESPONSE')
                    }
                } else {
                    /** 200/OK */
                    this.loadShopSyncStateAndSyncDate()
                    this.loadRpEventProductList()
                }
            })
            .catch(error => {
                /** 서버 타임아웃 */
                    subscribeShopDataSyncState(new Date().getTime())
                        .then(res => this.callBackSubscribeShopDataSyncState(res))
            })
        } catch (err) {
            console.log(err)
        }
    }

    callBackSubscribeShopDataSyncState = (res) => {
        this.hasFetchedSyncData.current = false
        if (res === 0) return;
        if (res === true) {
            this.setState({syncState: "0"})
            /** 데이터 연동 성공 후 , 해당 세팅의 상품 목록 재호출 */
            this.loadRpEventProductList()
        }
        else if (typeof res === "string"){
            /** 시간초과 || 서버 메세지*/
            console.log(res)
        } else {
            /** response.data 없을 경우 */
            console.err(res)
        }
    }

    loadProductColorList = () => {
        http.get("/rest/shop/product/colorList")
        .then(res => {
            if (res.data && res.data.color_list) {
                this.setState({product_color_list : res.data.color_list});
            }
        })
        .catch(error => {
         });
    }

    onChangeIsExceptInvisibleCategory = (idx, value) => {
        let list_self_select = [...this.state.list_self_select];
        let new_list_self_select = list_self_select.map((obj,index) => index == idx ? {...obj, is_except_invisible_category : value} : {...obj});
        this.setState({list_self_select : new_list_self_select});
    }
    
    onChangeIsExceptInvisibleCategoryForce = (value) => {
        this.setState({
            force_is_except_invisible_category: value,
        });
    }
    
    onChangeIsExceptOptionSoldout = (idx, value) => {
        let list_self_select = [...this.state.list_self_select];
        let new_list_self_select = list_self_select.map((obj,index) => index == idx ? {...obj, is_except_option_soldout : value} : {...obj});
        this.setState({list_self_select : new_list_self_select});
    }

    selectProductColor = (idx, value) => {
        let list_self_select = [...this.state.list_self_select];
        let new_list_self_select = list_self_select.map((obj,index) => index == idx ? {...obj, color_list : value} : {...obj});
        this.setState({list_self_select : new_list_self_select});
    }

    /** 알고리즘 분석 표기 기준 변경 (onClickOption)
     * @param {*} text 셀렉트 박스 선택된 option 이름
     * @param {*} value 셀렉트 박스 선택된 option 값
     * @param {*} id 셀렉트 박스 id
    */
    onChangeEventItemAnalysisStandard(text, value, id){
        this.setState({
            analysis_standard_value : value,
        }, () => {
            // 여기서 상품번호에 따른 데이터를 재조회 하여 프론트에서 가공함.
            // 상품번호는 this.state.rp_product_list에서 추출함.
            if(this.state.event_category_select_type == "34001"){
                this.reloadProductByAnalysisStandard("products", this.state.products[this.state.selected_category_no], this.state.analysis_standard_value);
            } else {
                this.reloadProductByAnalysisStandard("rp_product_list", this.state.rp_product_list, this.state.analysis_standard_value);
            }
        });
    }

    reloadProductByAnalysisStandard(org_product_list_name, org_product_list, analysis_standard_value) {
        // let rp_product_nos = org_product_list.map(item => item.product_no);
        let rp_product_nos = [];
        for(let idx in org_product_list) {
            rp_product_nos[idx] = org_product_list[idx].product_no;
        }
        let startidx = 0;
        let rowcnt = rp_product_nos.length;
        const body = {
            product_no_list : rp_product_nos,
            analysis_standard_value : analysis_standard_value,
            startidx : startidx,
            rowcnt : rowcnt,
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        }; 
        http.post("/rest/shop/rp/product/byAnalysisStandard",body, config)
        .then(res => {
            const list = res.data.list;
            let copy_product_list = JSON.parse(JSON.stringify(org_product_list));
            for (let idx1 in org_product_list) {
                for (let idx2 in list) {
                    if (org_product_list[idx1].product_no == list[idx2].product_no) {
                        /* analysis_standard_value에 의해 조회된 값으로 대체 */
                        copy_product_list[idx1].hit_cnt = list[idx2].hit_cnt
                        copy_product_list[idx1].cart_cnt = list[idx2].cart_cnt
                        copy_product_list[idx1].wish_cnt = list[idx2].wish_cnt
                        copy_product_list[idx1].review_point = list[idx2].review_point
                        copy_product_list[idx1].review_cnt = list[idx2].review_cnt
                        copy_product_list[idx1].order_cnt = list[idx2].order_cnt
                        copy_product_list[idx1].order_price = list[idx2].order_price
                    }
                }
            }

            if(this.state.event_category_select_type == "34001"){
                const {products, selected_category_no} = this.state;
                products[selected_category_no]  = copy_product_list;
                this.setState({
                    [org_product_list_name] : products
                })
            } else {
                this.setState({
                    [org_product_list_name] : copy_product_list
                })
            }
        })
        .catch(error => {
            console.log("reloadProductByAnalysisStandardError", error);
        })
    }

    getListSelfSelectSend() {
        let list_self_select_send = JSON.parse(JSON.stringify(this.state.list_self_select));
        for(var i=0;i<list_self_select_send.length;i++){
            list_self_select_send[i].event_item_created_start_date = StringUtil.dateFormater(list_self_select_send[i].event_item_created_start_date);
            list_self_select_send[i].event_item_created_end_date = StringUtil.dateFormater(list_self_select_send[i].event_item_created_end_date);
            delete list_self_select_send[i].category;

            delete list_self_select_send[i].product_rp_category_list_1;
            delete list_self_select_send[i].product_rp_category_list_2;
            delete list_self_select_send[i].product_rp_category_list_3;
            delete list_self_select_send[i].product_rp_category_list_4;

            delete list_self_select_send[i].product_rp_category_no_1;
            delete list_self_select_send[i].product_rp_category_no_2;
            delete list_self_select_send[i].product_rp_category_no_3;
            delete list_self_select_send[i].product_rp_category_no_4;

            delete list_self_select_send[i].self_select_area_checked;

            let color_list = list_self_select_send[i].color_list ? list_self_select_send[i].color_list.map(obj => obj.color_seq) : [];
            list_self_select_send[i].color_seq_list = color_list;
            delete list_self_select_send[i].color_list;

            delete list_self_select_send[i].force_use_yn;
            delete list_self_select_send[i].force_logic;
            delete list_self_select_send[i].force_category;
            delete list_self_select_send[i].force_auto_remove;
            delete list_self_select_send[i].force_analysis_period;
            delete list_self_select_send[i].force_select_created_yn;
            delete list_self_select_send[i].force_created_period_type;
            delete list_self_select_send[i].force_created_period;
            delete list_self_select_send[i].force_created_start_date;
            delete list_self_select_send[i].force_created_end_date;
            delete list_self_select_send[i].force_is_except_invisible_category;
            delete list_self_select_send[i].max_item_analysis;
            delete list_self_select_send[i].max_item_analysis_value;
        }

        let last_select_index = list_self_select_send.length-1;
        list_self_select_send[last_select_index].force_use_yn = this.state.force_use_yn;
        list_self_select_send[last_select_index].force_logic = this.state.force_logic;
        list_self_select_send[last_select_index].force_category = this.state.force_category;
        list_self_select_send[last_select_index].force_auto_remove = this.state.force_auto_remove;
        list_self_select_send[last_select_index].force_analysis_period = this.state.force_analysis_period;
        list_self_select_send[last_select_index].force_select_created_yn = this.state.force_select_created_yn;
        list_self_select_send[last_select_index].force_created_period_type = this.state.force_created_period_type;
        list_self_select_send[last_select_index].force_created_period = this.state.force_created_period;
        list_self_select_send[last_select_index].force_created_start_date = StringUtil.dateFormater(this.state.force_created_start_date);
        list_self_select_send[last_select_index].force_created_end_date = StringUtil.dateFormater(this.state.force_created_end_date);
        list_self_select_send[last_select_index].force_is_except_invisible_category = this.state.force_is_except_invisible_category;
        list_self_select_send[last_select_index].max_item_analysis = this.state.max_item_analysis;
        list_self_select_send[last_select_index].max_item_analysis_value = this.state.max_item_analysis_value;

        return list_self_select_send;
    }

    addEssentialProduct = (list_self_select_uuid, newProductNoList) => {
        let {list_self_select} = this.state;
        let obj = {}
        for (let idx in list_self_select) {
            if (list_self_select[idx]["uuid"] == list_self_select_uuid) {
                obj = {...list_self_select[idx]};
                obj["essential_product_no_list"] = newProductNoList;
                list_self_select[idx] = obj;
                this.setState({
                    list_self_select: list_self_select
                })
                break;
            }
        }

    }

    /** 총 필수 상품 개수 추출 */
    totalEssentialProductCnt() {
        let totalEssentialProductCnt = 0;
        const {list_self_select} = this.state
        for(let idx in list_self_select) {
            totalEssentialProductCnt+= list_self_select[idx]["essential_product_no_list"] ? list_self_select[idx]["essential_product_no_list"].length : 0;
        }
        // console.log("totalEssentialProductCnt", totalEssentialProductCnt);
        return totalEssentialProductCnt;
    }

    /**
    * 실시간 서비스 신청 여부 & 이용 여부
    */
    loadRegistedService(service_type) {
        http.get("/rest/shop/get/additionFunction?function_cd="+service_type)
        .then(res => {
            let regist_state_name = "regist_yn_"+service_type;
            let operate_state_name = "operate_yn_"+service_type;
            this.setState({
                [regist_state_name]: res.data.regist_cnt > 0 ? "Y" : "N",
                [operate_state_name]: res.data.operate_cnt > 0 ? "Y" : "N",
            })
        }).catch(error => {
            console.log(error);
        });
    }
    
        /** 시작 날짜 변경 */
        handleChangeStartDate = date => {
            console.log("###### date", date);
            // if (date != null) {
                this.setState({
                    event_start_date: date,
                    left_is_modified : true,
                });
            // } 
        };
        onChangeEventStartHour(text, value, id){
            this.setState({
                event_start_hour : value,
                left_is_modified : true,
            });
        }
    
        onChangeWeekdayCycle(text, value, id){
            this.setState({
                weekday_cycle : value,
                left_is_modified : true,
            });
        }
    
        onChangeRealTimeCycle(text, value, id){
            this.setState({
                real_time_cycle : value,
                left_is_modified : true, // event_start_hour
            });
        }
    
        onChangeAutoCreateType(e){
            let event_cycle_type = this.state.event_cycle_type;
            let event_cycle = this.state.event_cycle;
            let event_time = this.state.event_time;
            if (e.target.value == 'A7003') {
                if (this.state.operate_yn_0100201001 == "Y" || this.state.operate_yn_0100201002 == "Y") {
                    event_cycle_type = 'A8003';
                } else {
                    this.setState({
                        real_time_service_regist_show_state : true
                    })
                    return false;
                }
            } else if (e.target.value == 'A7001') {
                if (this.state.event_cycle_type == 'A8003') {
                    event_cycle_type = 'A8001';
                }
    
                if (event_cycle == null || event_cycle == "") {
                    event_cycle = "2";
                    event_time = "7";
                } 
                this.setState({
                    event_cycle: event_cycle,
                    event_time: event_time,
                })
            }
    
            this.setState({
                auto_create_type : e.target.value,
                event_cycle_type : event_cycle_type,
                left_is_modified : true,
            });
        }
    
        onChangeReservationUseYn(e) {
            this.setState({
                reservation_use_yn: e.target.value,
            })
        }
    
        handleChangeReservationDate = date => {
            console.log("###### date", date);
            // if (date != null) {
                this.setState({
                    reservation_date: date,
                    left_is_modified : true,
                });
            // } 
        };
        onChangeReservationHour(text, value, id){
            this.setState({
                reservation_hour : value,
                left_is_modified : true,
            });
        }

        onClickWeeklyButton(obj) {
            const {weekday_list} = this.state;
            if (weekday_list != null) {
                let array_weekday_list = weekday_list ? weekday_list.split(",") : [];
                if (obj.code_cd == "-1") {
                    if (array_weekday_list.filter(day => day.trim() != "-1").length < 7) {
                        array_weekday_list = [];
                        for (let idx = -1; idx <=6; idx++) {
                            array_weekday_list.push(idx);
                        }
                    } else {
                        array_weekday_list = [];
                    }
                } else if (array_weekday_list.indexOf(obj.code_cd) >= 0) {
                    array_weekday_list = array_weekday_list.filter(weekday => weekday != obj.code_cd);
                    if (array_weekday_list.filter(day => day.trim() != "-1").length < 7) {
                        array_weekday_list = array_weekday_list.filter(day => day.trim() != "-1");
                    }
                } else {
                    array_weekday_list.push(obj.code_cd);
                    if (array_weekday_list.filter(day => day.trim() != "-1").length >= 7) {
                        array_weekday_list = array_weekday_list.filter(day => day.trim() != "-1");
                        array_weekday_list.push("-1");
                    } else {
                        array_weekday_list = array_weekday_list.filter(day => day.trim() != "-1");
                    }
                }
                this.setState({
                    weekday_list : array_weekday_list.toString().replace("[").replace("]")
                })
            }
        }
    
        renderDailySetting () {
            return (
                <>
                    {/* "일별" */}
                    <div className='text_small list_admin_side_design_title m-b-8' style={{marginTop:"18px", marginBottom: "8px"}}>반복주기</div>
                    <div class="list_admin_side_row_5_select">
                        <div>
                            <div class="list_admin_side_select_area">
                                {/* {this.state.auto_create_yn=="Y"
                                ? */}
                                <SelectBoxV2
                                    id={"event_cycle_select"}
                                    tabIndex={0}
                                    width="100%"
                                    height="36px"
                                    padding="14px 36px 14px 16px"
                                    list= {this.state.event_cycle_list}
                                    optionTexttName = "title"
                                    optionValuetName = "value"
                                    selectAreaMaxHeight = "120px"
                                    selectedValue = {this.state.event_cycle}
                                    onClickOption={this.onChangeEventCycle.bind(this)}
                                />
                            </div>
                            <span class="text_14" style={{marginLeft:"4px", paddingBottom: 0}}>마다</span>
                        </div>
                        <div class="list_auto_selected_last_div">
                            <div class="list_admin_side_select_area">
                            {
                                // 자동 진열 시각
                                // this.state.auto_create_yn === "Y"
                                // ?
                                <SelectBoxV2
                                    id={"event_time_select"}
                                    tabIndex={0}
                                    width="100%"
                                    height="36px"
                                    padding="14px 36px 14px 16px"
                                    list= {this.state.event_time_list}
                                    optionTexttName = "title"
                                    optionValuetName = "value"
                                    selectAreaMaxHeight = "120px"
                                    selectedValue = {this.state.event_time}
                                    onClickOption={this.onChangeEventTime.bind(this)}
                                />
                            }
                            </div>
                            <span class="text_14" style={{marginLeft:"4px", paddingBottom: 0}}>에</span>
                        </div>
                    </div>
                    <div>
                    <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>시작시점  설정</div>
                        <div className='flexBox' style={{justifyContent:'space-between', }}>
                            <div className='flexBox'>
                                <div className="style_guide_v2 rp_event_start_date_wrapper" style={{width:"170px"}}>
                                    <DatePicker name="event_start_date" id="event_start_date" className="rp_event_start_date border_form_radius_b_h_42"
                                        selected={this.state.event_start_date}
                                        // onSelect={this.handleSelect} //when day is clicked
                                        onChange={this.handleChangeStartDate} //only when value has changed
                                        placeholderText="마지막 쇼핑몰적용"
                                        // showTimeSelect
                                        // minDate={moment().add(1,'days').toDate()}
                                        maxDate={new Date("9999-12-31")}
                                        dateFormat="yyyy-MM-dd"
                                        locale={ko}
                                        todayButton="오늘"
                                        autoComplete="off"
                                        showYearDropdown
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                    />
                                    <div className="icn_calendar"/>
                                </div>
                                <span className="text_14 m-l-5" style={{alignContent: "center"}}>{"부터 진열함"}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        renderWeeklySetting () {
            return (
                <>
                    {/* "요일별" */}
                    <div className='text_small list_admin_side_design_title m-b-8' style={{marginTop:"18px", marginBottom: "8px"}}>반복요일</div>
                    <div class="list_admin_side_row_5_select rp_weekday">
                        {this.state.weekday_selectbox_list.map((obj, idx) => (
                            <button style={{fontWeight: 400, border: "1px solid #d1d1d1", flex: obj.code_name.length >=2 ? 1.5 : 1, justifyContent: "center", backgroundColor: (this.state.weekday_list.indexOf(obj.code_cd) >= 0 || (obj.code_cd == -1 && this.state.weekday_list && this.state.weekday_list.split(",").filter(day => day.trim() != "-1").length >= 7)) ? "#C4F6FD" : null}}
                            onClick={ () => {
                                    this.onClickWeeklyButton(obj);
                                }
                            }
                            >
                                {obj.code_name}
                            </button>
                        ))}
                        {/* <div className='m-l-5'> 추천함</div> */}
                    </div>
                    <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>반복시간</div>
                    <div class="list_admin_side_row_5_select">
                        <div className='list_auto_selected_last_div'>
                            <div class="list_admin_side_select_area">
                                <SelectBoxV2
                                    id={"weekday_event_time"}
                                    tabIndex={0}
                                    width="100%"
                                    height="36px"
                                    padding="14px 36px 14px 16px"
                                    list= {this.state.event_time_list}
                                    optionTexttName = "title"
                                    optionValuetName = "value"
                                    selectAreaMaxHeight = "120px"
                                    selectedValue = {this.state.event_time}
                                    onClickOption={this.onChangeEventTime.bind(this)}
                                />
                            </div>
                            <span class="text_14" style={{marginLeft:"4px", paddingBottom: 0}}>에</span>
                        </div>
                    </div>
                    <div>
                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>시작시점 설정</div>
                        <div className='flexBox' style={{justifyContent:'space-between', }}>
                            <div className='flexBox'>
                                <div className="style_guide_v2 rp_event_start_date_wrapper" style={{width:"170px"}}>
                                    <DatePicker name="event_start_date" id="event_start_date" className="rp_event_start_date border_form_radius_b_h_42"
                                        selected={this.state.event_start_date}
                                        // onSelect={this.handleSelect} //when day is clicked
                                        onChange={this.handleChangeStartDate} //only when value has changed
                                        placeholderText="마지막 쇼핑몰적용"
                                        // showTimeSelect
                                        // minDate={moment().add(1,'days').toDate()}
                                        maxDate={new Date("9999-12-31")}
                                        dateFormat="yyyy-MM-dd"
                                        locale={ko}
                                        todayButton="오늘"
                                        autoComplete="off"
                                        showYearDropdown
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                    />
                                    <div className="icn_calendar"/>
                                </div>
                                <span className="text_14 m-l-5" style={{alignContent: "center"}}>{"부터 진열함"}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        renderRealTimeSetting () {
            return (
                <>
                    {/* "실시간" */}
                    <div className='real_time_waraper'>
                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginTop:"18px", marginBottom: "8px"}}>반복주기</div>
                        <div class="flexBox">
                            {/* <span class="text_14" style={{paddingBottom: 0}}>매일</span> */}
                            <div>
                                <div className="list_admin_side_select_area">
                                <SelectBoxV2
                                    id={"weekday_event_time"}
                                    tabIndex={0}
                                    width="100%"
                                    height="36px"
                                    padding="14px 36px 14px 16px"
                                    list= {this.state.operate_yn_0100201002 ? this.state.real_time_cycle_selectbox_list_by_premium : this.state.real_time_cycle_selectbox_list_by_basic}
                                    optionTexttName = "code_name"
                                    optionValuetName = "code_cd"
                                    selectAreaMaxHeight = "120px"
                                    selectedValue = {this.state.real_time_cycle}
                                    onClickOption={this.onChangeRealTimeCycle.bind(this)}
                                />
                                </div>
                            </div>
                            <span class="text_14 m-l-5" style={{paddingBottom: 0}}>마다</span>
                        </div>
                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>반복일정 요일</div>
                        <div class="list_admin_side_row_5_select rp_weekday">
                            {this.state.weekday_selectbox_list.map((obj, idx) => (
                                <button style={{fontWeight: 400, border: "1px solid #d1d1d1", flex: obj.code_name.length >=2 ? 1.5 : 1, justifyContent: "center", backgroundColor: (this.state.weekday_list.indexOf(obj.code_cd) >= 0 || (obj.code_cd == -1 && this.state.weekday_list && this.state.weekday_list.split(",").filter(day => day.trim() != "-1").length >= 7)) ? "#C4F6FD" : null}}
                                onClick={ () => {
                                        this.onClickWeeklyButton(obj);
                                    }
                                }
                                >
                                    {obj.code_name}
                                </button>
                            ))}
                            {/* <div className='m-l-5'> 추천함</div> */}
                        </div>
                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>반복 적용 시간</div>
                        <div className="flexBox">
                            <div className='flexBox'>
                                <div class="list_admin_side_select_area">
                                {
                                    <SelectBoxV2
                                        id={"event_time_select"}
                                        tabIndex={0}
                                        width="100%"
                                        height="36px"
                                        padding="14px 36px 14px 16px"
                                        list= {this.state.event_time_list}
                                        optionTexttName = "title"
                                        optionValuetName = "value"
                                        selectAreaMaxHeight = "120px"
                                        selectedValue = {this.state.real_time_start_hour}
                                        onClickOption={this.onChangeEventTime.bind(this)}
                                    />
                                }
                                </div>
                                <span class="text_14" style={{marginLeft:"4px", paddingBottom: 0}}>부터</span>
                            </div>
                            <div class="flexBox m-l-10">
                                <div class="list_admin_side_select_area">
                                {
                                    <SelectBoxV2
                                        id={"event_time_select"}
                                        tabIndex={0}
                                        width="100%"
                                        height="36px"
                                        padding="14px 36px 14px 16px"
                                        list= {this.state.event_time_list_v2}
                                        optionTexttName = "title"
                                        optionValuetName = "value"
                                        selectAreaMaxHeight = "120px"
                                        selectedValue = {this.state.real_time_end_hour}
                                        onClickOption={this.onChangeEventTime.bind(this)}
                                    />
                                }
                                </div>
                                <span class="text_14" style={{marginLeft:"4px", paddingBottom: 0}}>까지</span>
                            </div>
                        </div>  

                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>시작시점  설정</div>
                        <div className='flexBox' style={{justifyContent:'space-between', }}>
                            <div className='flexBox'>
                                <div className="style_guide_v2 rp_event_start_date_wrapper" style={{width:"170px"}}>
                                        <DatePicker name="event_start_date" id="event_start_date" className="rp_event_start_date border_form_radius_b_h_42"
                                            selected={this.state.event_start_date}
                                            // onSelect={this.handleSelect} //when day is clicked
                                            onChange={this.handleChangeStartDate} //only when value has changed
                                            placeholderText="마지막 쇼핑몰적용"
                                            // showTimeSelect
                                            // minDate={moment().add(1,'days').toDate()}
                                            maxDate={new Date("9999-12-31")}
                                            dateFormat="yyyy-MM-dd"
                                            locale={ko}
                                            todayButton="오늘"
                                            autoComplete="off"
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                        />
                                        <div className="icn_calendar"/>
                                </div>
                                <span className="text_14 m-l-5" style={{alignContent: "center"}}>{"부터 진열함"}</span>
                            </div>
                        </div>
                        <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>리얼타임 랭킹위젯</div>
                        <div className='real_time_txt_use_yn flexBox'>
                        {
                            this.state.use_yn_list.map((obj, idx) => (
                                <RadioButton
                                    id={"real_time_txt_use"+obj.value}
                                    name={"real_time_txt_use_yn"}
                                    value={obj.value}
                                    text={obj.text}
                                    checked={this.state.real_time_txt_use_yn==obj.value}
                                    onChangeCheck={e => {
                                        this.setState({
                                            real_time_txt_use_yn: obj.value
                                        })
                                    }}
                                />
                            ))
                        }
                        </div>
                    </div>
                </>
            )
        }

    render() {
        const {right_is_modified, left_is_modified, multi_category_list_is_modified} = this.state;
        const{rp_product_list, is_auto, modified_date, products, products_is_auto, products_modified_date, selected_category_no, event_rp_product_list} = this.state;

        let product_list = [];
        let product_no_list = [];
        let event_rp_product_no_list = [];

        let product_is_auto = "Y";
        let product_modified_date = "";

        // 테이블에 보일 상품 목록을 카테고리 카테고리 종류에 맞게 선택한다.
        if(this.state.event_category_select_type=="34002"){
            product_list = JSON.parse(JSON.stringify(rp_product_list));
            product_is_auto = is_auto;
            product_modified_date = modified_date;
        } else if(this.state.selected_category_no && this.state.event_category_select_type=="34001"){
            product_list = JSON.parse(JSON.stringify(products[selected_category_no]));
            product_is_auto = products_is_auto[selected_category_no];
            product_modified_date = products_modified_date[selected_category_no];
        }

        // product_no_list 값 초기화
        for(var i=0;i<product_list.length;i++){
            product_no_list.push(product_list[i].product_no);
        }
        for(var i=0;i<event_rp_product_list.length;i++){
            if(product_no_list.indexOf(event_rp_product_list[i].product_no) == -1){
                event_rp_product_no_list.push(event_rp_product_list[i].product_no);
            }
        }

        // 왼쪽 영역 선택지 모두 불러오기 전에는 진열 수정이 불가능 하도록 클릭을 막는다
        if(load_upper_num < 13){
            $("#common-page-wrapper").css({pointerEvents: "none"});
        } else{
            $("#common-page-wrapper").css({pointerEvents: ""});
        }

        // 선택된 다중카테고리 목록 (상품목록 카테고리에서 사용)
        let show_mulit_category_list = [];
        for(var i=0;i<this.state.category_list_for_product_detail.length;i++){
            if(this.state.multi_category_list.indexOf(String(this.state.category_list_for_product_detail[i].category_no)) >= 0){
                show_mulit_category_list.push(this.state.category_list_for_product_detail[i]);
            }
        }

        // 상품추가 정렬 기준 목록
        let select_order_gubun_type_list = [...this.state.select_event_order_gubun_type_list];
        select_order_gubun_type_list.splice(0,1);

        // 직접 지정 영역 알고리즘 목록
        let item_select_type_self_list = [...this.state.item_select_type_list];
        for(var i=0;i<this.state.item_select_type_list.length;i++){
            if(item_select_type_self_list[i].code_cd == "22998"){
                item_select_type_self_list.splice(i,1);
            }
        }


        return (
            <>
                <div id='common-page-wrapper'>
                    <div id="list_wrap" class="style_guide_v2 rp_setting_page">
                        <section class="list_admin_wrap">
                            <div class="list_admin_side_menu">
                                <div class="list_admin_side_menu_wrap">
                                    <div class="list_admin_side_row_1">
                                        <h2 class="text_regular">위젯 자동진열 설정</h2>
                                        <div>
                                            <button class="list_btn_1 hover_lightmint" onClick={() => {this.onClickUserGuide()}}>사용 가이드</button>
                                            {this.state.install_yn == "N"
                                            ?<button class="list_btn_1 hover_dark" onClick={()=> this.onClicExtendPeriod()}>기간 연장하기</button>
                                            :""}
                                        </div>
                                    </div>
                                    <div class="list_admin_side_row_2">
                                        <h3 ref={rpTitleRef} class="text_16">진열 제목</h3>
                                        <input type="text" maxlength="100" placeholder="Best Page" id="iframe_html_name_input" value={this.state.iframe_html_name} onChange={e => this.onChangeListName(e)} autocomplete="off"/>
                                    </div>
                                    <div class="list_admin_side_row_3 list_expand_wrap">
                                        <div class="list_admin_side_title_1 list_admin_side_design_expand list_expand widget_info_list_expand" id="install_list_expand">
                                            <h3 class="text_16">위치 설정</h3>
                                            <input ref={widgetListExpandCheckRef} class="list_expand_check widget_info_list_expand_check" type="checkbox" id="list_side_title_arrow" name="list_side_title_arrow"/>
                                            <label ref={widgetListExpandRef} for="list_side_title_arrow">
                                                <svg class="list_arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0001 14.3751C9.83444 14.3728 9.67561 14.3088 9.55479 14.1954L3.30479 7.94541C3.20512 7.82398 3.15419 7.66981 3.1619 7.5129C3.1696 7.356 3.2354 7.20756 3.34648 7.09648C3.45756 6.9854 3.606 6.9196 3.7629 6.9119C3.91981 6.90419 4.07398 6.95512 4.19541 7.05479L10.0001 12.8673L15.8048 7.05479C15.9262 6.95512 16.0804 6.90419 16.2373 6.9119C16.3942 6.9196 16.5426 6.9854 16.6537 7.09648C16.7648 7.20756 16.8306 7.356 16.8383 7.5129C16.846 7.66981 16.7951 7.82398 16.6954 7.94541L10.4454 14.1954C10.3246 14.3088 10.1658 14.3728 10.0001 14.3751V14.3751Z" fill="#333333"/>
                                                </svg>
                                            </label>
                                        </div>
                                        <div class="list_admin_side_row_3_1 list_expand_1">
                                            <div class="list_admin_side_row_3_1a">
                                                <span class="i_notice">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                    </svg>
                                                </span>
                                                <h3 class="text_12">
                                                    설치 후에는 직접 변경하실 수 없습니다.<br/>
                                                    로보MD 운영팀에 요청해 주세요.
                                                </h3>
                                            </div>
                                            <div class="list_admin_side_row_3_1b">
                                                <h3 class="text_small">위젯 진열 방식</h3>
                                                <div class="list_admin_side_row_3_1b_radio">
                                                    {this.state.display_type_list.map((obj, idx) => (
                                                        <RadioButton
                                                            id={"list_side_1_radio_"+obj.code_cd}
                                                            name={"list_side_1_radio"}
                                                            value={obj.code_cd}
                                                            text={obj.code_name}
                                                            checked={this.state.display_type==obj.code_cd}
                                                            disabled={this.state.install_yn=="Y"}
                                                            onChangeCheck={this.state.install_yn=="Y" ? null : this.onChangeListType.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div class="list_admin_side_row_3_1c">
                                                <h3 class="text_small">
                                                    위젯 위치
                                                    <span class="i_notice">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>
                                                    </span>
                                                    <span class="text_12">저장 후에는 직접 변경하실 수 없습니다.</span>
                                                </h3>
                                                {this.state.iframe_page_id != null && this.state.iframe_html_id != null && this.state.iframe_page_id != "" && this.state.iframe_html_id != ""
                                                ?
                                                    <div class="select_list_position_disabled">
                                                        <SelectBoxV2
                                                            id={"selectListPosition"}
                                                            width="100%"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.iframe_page_id_list}
                                                            disabled={true}
                                                            optionTexttName = "val1"
                                                            optionValuetName = "code_name"
                                                            selectedValue = {this.state.iframe_page_id}
                                                        />
                                                    </div>
                                                :
                                                    <div class="select_list_position_area">
                                                        <SelectBoxV2
                                                            id={"selectListPosition"}
                                                            tabIndex={0}
                                                            width="100%"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.iframe_page_id_list}
                                                            optionTexttName = "val1"
                                                            optionValuetName = "code_name"
                                                            selectedValue = {this.state.iframe_page_id}
                                                            onClickOption={this.onChangeListPosition.bind(this)}
                                                        />
                                                    </div>
                                                }
                                                <div class="list_admin_side_row_3_1c_radio">
                                                    {[{text:"PC", value:"P"},{text:"모바일", value:"M"},{text:"PC/모바일", value:"C"}].map((obj, idx) => (
                                                        <RadioButton
                                                            id={"platform_type_"+obj.value}
                                                            name={"list_side_2_radio"}
                                                            value={obj.value}
                                                            text={obj.text}
                                                            checked={this.state.platform_type==obj.value}
                                                            disabled={this.state.install_yn=="Y"}
                                                            onChangeCheck={this.state.install_yn=="Y" ? null : this.onChangeListPositionPM.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div class="list_admin_side_row_3_1b" name="none_cafe24_main_list">
                                                <h3 class="text_small">위젯 링크 열기 타입</h3>
                                                <div class="list_admin_side_row_3_1b_radio">
                                                    {this.state.link_open_type_list.map((obj, idx) => (
                                                        <RadioButton
                                                            id={"link_open_type_input_"+obj.code_cd}
                                                            name={"link_open_type_input"}
                                                            value={obj.code_cd}
                                                            text={obj.code_name}
                                                            checked={this.state.link_open_type==obj.code_cd}
                                                            onChangeCheck={this.onChangeLinkOpenType.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div class="list_admin_side_row_4_1">
                                                {
                                                    this.state.template_sub_title_use_yn == "Y"
                                                    ?   <h3 class="text_small list_admin_side_design_title">위젯 진열 디자인 대제목</h3>
                                                    :   <h3 class="text_small list_admin_side_design_title">위젯 진열 디자인 제목</h3>
                                                }
                                                <div class="list_side_1_title_radio">
                                                    {this.state.use_yn_list.map((obj, idx) => (
                                                        <RadioButton
                                                            id={"display_name_use_"+obj.value}
                                                            name={"radio_display_name_use_yn"}
                                                            value={obj.value}
                                                            text={obj.text}
                                                            checked={this.state.display_name_use_yn==obj.value}
                                                            disabled={this.state.install_yn=="Y"}
                                                            onChangeCheck={this.state.install_yn=="Y" ? null : this.onChangeDisplayNameUseYn.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                                {this.state.install_yn=="Y" || this.state.display_name_use_yn != "Y"
                                                ?<>
                                                    <SelectBoxV2
                                                        id={"selectDisplayName"}
                                                        tabIndex={0}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {this.state.display_name_list}
                                                        disabled={true}
                                                        optionTexttName = "code_name"
                                                        optionValuetName = "code_cd"
                                                        selectedValue = {this.state.display_name_cd}
                                                    />
                                                    {this.state.display_name_cd == "33098"
                                                    ? <input class="list_input_disabled" type="text" id="self_title_input" value={this.state.display_name} style={{color: "#3a3a3a"}} disabled autocomplete="off"/>
                                                    : ""}
                                                </>
                                                :<>
                                                    <SelectBoxV2
                                                        id={"selectDisplayName"}
                                                        tabIndex={0}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {this.state.display_name_list}
                                                        optionTexttName = "code_name"
                                                        optionValuetName = "code_cd"
                                                        selectedValue = {this.state.display_name_cd}
                                                        selectedText={(this.state.display_name_cd == null || this.state.display_name_cd == "")
                                                                        ? this.state.display_name
                                                                        : null}
                                                        onClickOption={this.onChangeDisplayName.bind(this)}
                                                    />
                                                    {this.state.display_name_cd == "33098"
                                                    ? <input class="list_input_disabled" type="text" id="self_title_input" value={this.state.display_name} style={{color: "#3a3a3a"}} onChange={e => this.onChangeDisplayNameDirectly(e)} autocomplete="off"/>
                                                    : ""}
                                                </>}
                                            </div>
                                            {
                                                this.state.template_sub_title_use_yn == "Y"
                                                ?
                                                <div class="list_admin_side_row_4_1">
                                                    <h3 class="text_small list_admin_side_design_title">위젯 진열 디자인 소제목</h3>
                                                    <div class="list_side_1_title_radio">
                                                        {this.state.use_yn_list.map((obj, idx) => (
                                                            <RadioButton
                                                                id={"sub_display_name_use_"+obj.value}
                                                                name={"radio_sub_display_name_use_yn"}
                                                                value={obj.value}
                                                                text={obj.text}
                                                                checked={this.state.sub_display_name_use_yn==obj.value}
                                                                disabled={this.state.install_yn=="Y"}
                                                                onChangeCheck={this.state.install_yn=="Y" ? null : this.onChangeSubDisplayNameUseYn.bind(this)}
                                                            />
                                                        ))}
                                                    </div>
                                                    {this.state.install_yn=="Y" || this.state.sub_display_name_use_yn != "Y"
                                                    ?<>
                                                        <SelectBoxV2
                                                            id={"selectSubDisplayName"}
                                                            tabIndex={0}
                                                            width="100%"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.display_name_list}
                                                            disabled={true}
                                                            optionTexttName = "code_name"
                                                            optionValuetName = "code_cd"
                                                            selectedValue = {this.state.sub_display_name_cd}
                                                        />
                                                        {this.state.sub_display_name_cd == "33098"
                                                        ? <input class="list_input_disabled" type="text" id="self_title_input" value={this.state.sub_display_name} style={{color: "#3a3a3a"}} disabled autocomplete="off"/>
                                                        : ""}
                                                    </>
                                                    :<>
                                                        <SelectBoxV2
                                                            id={"selectSubDisplayName"}
                                                            tabIndex={0}
                                                            width="100%"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.display_name_list}
                                                            optionTexttName = "code_name"
                                                            optionValuetName = "code_cd"
                                                            selectedValue = {this.state.sub_display_name_cd}
                                                            selectedText={(this.state.sub_display_name_cd == null || this.state.sub_display_name_cd == "")
                                                                            ? this.state.sub_display_name
                                                                            : null}
                                                            onClickOption={this.onChangeSubDisplayName.bind(this)}
                                                        />
                                                        {this.state.sub_display_name_cd == "33098"
                                                        ? <input class="list_input_disabled" type="text" id="self_title_input" value={this.state.sub_display_name} style={{color: "#3a3a3a"}} onChange={e => this.onChangeSubDisplayNameDirectly(e)} autocomplete="off"/>
                                                        : ""}
                                                    </>}
                                                </div>
                                                : ""
                                            }
                                            <div class="list_admin_side_rolling_1">
                                                <h3 class="text_small list_admin_side_design_title">위젯 상품 썸네일</h3>
                                                <SelectBoxV2
                                                    id={"selectImageType"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="36px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {this.state.image_type_list}
                                                    optionTexttName = "code_name"
                                                    optionValuetName = "code_cd"
                                                    selectedValue = {this.state.image_type_cd}
                                                    onClickOption={this.onChangeImageType.bind(this)}
                                                />
                                                {
                                                    this.state.iframe_page_id != null
                                                    ?
                                                        <div class="list_admin_side_row_3_1a">
                                                            <span class="i_notice">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                                </svg>
                                                            </span>
                                                            <span class="text_12">우측 상품 진열에서 보여질 상품 이미지를 선택해 주세요.</span>
                                                        </div>
                                                    :
                                                        <div class="list_admin_side_row_3_1a">
                                                            <span class="i_notice">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                                </svg>
                                                            </span>
                                                            <span class="text_12">우측 상품 진열과 쇼핑몰 페이지의 상품 목록에 사용 할 이미지를 선택해 주세요.</span>
                                                        </div>
                                                }
                                            </div>
                                            <div class="list_admin_side_row_4_1">
                                                <h3 class="text_small">위젯 가격 형태</h3>
                                                <div class="list_admin_side_price">
                                                    {this.state.event_item_price_show_type_list.map((obj, idx) => (
                                                        <RadioButton
                                                            id={"radio_price_show_type_"+obj.code_cd}
                                                            name={"list_side_1_price_radio"}
                                                            value={obj.code_cd}
                                                            text={obj.code_name}
                                                            checked={this.state.event_item_price_show_type==obj.code_cd}
                                                            disabled={this.state.install_yn=="Y" || obj.disabled_yn == "Y"}
                                                            onChangeCheck={(this.state.install_yn=="Y" || obj.disabled_yn == "Y") ? null : this.onChangePriceShowType.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={this.state.highlightType == "design" ? "list_admin_side_row_3_1d rp_border_padding_highlight" : "list_admin_side_row_3_1d"} ref={highlightDesign}>
                                                <h3 class="text_small">
                                                    위젯 디자인 형태
                                                </h3>
                                                <div class="list_admin_side_row_3_1a" style={{marginLeft:"0px", marginTop:"8px"}}>
                                                    <span class="i_notice">
                                                        <svg style={{marginLeft:"0px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="text_12">디자인은 추후 추가될 예정입니다.</span>
                                                </div>
                                                {this.state.install_yn=="Y"
                                                ?
                                                <div style={{marginTop:"8px"}}>
                                                    <SelectBoxV2
                                                        id={"eventTypeSelect"}
                                                        tabIndex={0}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {this.state.display_type == "31001" ? this.state.event_type_list_nayeol : this.state.event_type_list_rolling}
                                                        disabled={true}
                                                        optionTexttName = "val1"
                                                        optionValuetName = "code_cd"
                                                        selectedValue = {this.state.event_type_cd}
                                                    />
                                                </div>
                                                :
                                                    <div style={{marginTop:"8px"}}>
                                                        <SelectBoxV2
                                                            id={"eventTypeSelect"}
                                                            tabIndex={0}
                                                            width="96px"
                                                            height="36px"
                                                            padding="14px 36px 14px 16px"
                                                            list= {this.state.display_type == "31001" ? this.state.event_type_list_nayeol : this.state.event_type_list_rolling}
                                                            optionTexttName = "val1"
                                                            optionValuetName = "code_cd"
                                                            selectedValue = {this.state.event_type_cd}
                                                            onClickOption={this.onChangeEventTypeList.bind(this)}
                                                        />
                                                    </div>
                                                }
                                                <div class="event_list_design_area">
                                                    <img style={this.state.install_yn=="Y"?{opacity : "0.5"}:{}} src={this.state.template_image_url}/>
                                                </div>
                                            </div>
                                            <div class="list_admin_side_row_4_1">
                                                <h3 class="text_small list_admin_side_product_count">위젯 가로줄 상품 개수</h3>
                                                <div class="list_admin_side_row_4_1_a">
                                                    <div class="list_admin_side_row_4_1_a_div">
                                                        <h4 class="text_14">PC</h4>
                                                        {this.state.install_yn=="Y"
                                                        ?
                                                        <div class="event_view_cnt_select">
                                                            <SelectBoxV2
                                                                id={"event_view_cnt_select"}
                                                                tabIndex={0}
                                                                width="100%"
                                                                height="36px"
                                                                padding="14px 36px 14px 16px"
                                                                list= {this.state.event_view_cnt_select}
                                                                disabled={true}
                                                                optionTexttName = "title"
                                                                optionValuetName = "value"
                                                                selectedValue = {this.state.event_view_cnt}
                                                            />
                                                        </div>
                                                        :
                                                        <div class="event_view_cnt_select">
                                                            <SelectBoxV2
                                                                id={"event_view_cnt_select"}
                                                                tabIndex={0}
                                                                width="100%"
                                                                height="36px"
                                                                padding="14px 36px 14px 16px"
                                                                list= {this.state.event_view_cnt_select}
                                                                optionTexttName = "title"
                                                                optionValuetName = "value"
                                                                selectedValue = {this.state.event_view_cnt}
                                                                onClickOption={this.onChangeEventViewCnt.bind(this)}
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                    <div class="list_admin_side_row_4_1_a_div">
                                                        <h4 class="text_14">모바일</h4>
                                                        {this.state.install_yn == "Y" || this.state.display_type == "31002"
                                                        ?
                                                            <div class="event_view_cnt_select">
                                                                <SelectBoxV2
                                                                    id={"event_mobile_view_cnt_select"}
                                                                    tabIndex={0}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="14px 36px 14px 16px"
                                                                    list= {this.state.event_mobile_view_cnt_select}
                                                                    disabled={true}
                                                                    optionTexttName = "title"
                                                                    optionValuetName = "value"
                                                                    selectedValue = {this.state.event_mobile_view_cnt}
                                                                />
                                                            </div>
                                                            :
                                                            <div class="event_view_cnt_select">
                                                                <SelectBoxV2
                                                                    id={"event_mobile_view_cnt_select"}
                                                                    tabIndex={0}
                                                                    width="100%"
                                                                    height="36px"
                                                                    padding="14px 36px 14px 16px"
                                                                    list= {this.state.event_mobile_view_cnt_select}
                                                                    optionTexttName = "title"
                                                                    optionValuetName = "value"
                                                                    selectedValue = {this.state.event_mobile_view_cnt}
                                                                    onClickOption={this.onChangeEventMobileViewCnt.bind(this)}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list_admin_side_rolling list_expand_wrap">
                                        <div class="list_expand">
                                            <h3 class="text_16">롤링 설정</h3>
                                            <input class="list_expand_check" type="checkbox" id="list_side_title_arrow_rolling" name="list_side_title_arrow_rolling"/>
                                            <label for="list_side_title_arrow_rolling">
                                                <svg class="list_arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0001 14.3751C9.83444 14.3728 9.67561 14.3088 9.55479 14.1954L3.30479 7.94541C3.20512 7.82398 3.15419 7.66981 3.1619 7.5129C3.1696 7.356 3.2354 7.20756 3.34648 7.09648C3.45756 6.9854 3.606 6.9196 3.7629 6.9119C3.91981 6.90419 4.07398 6.95512 4.19541 7.05479L10.0001 12.8673L15.8048 7.05479C15.9262 6.95512 16.0804 6.90419 16.2373 6.9119C16.3942 6.9196 16.5426 6.9854 16.6537 7.09648C16.7648 7.20756 16.8306 7.356 16.8383 7.5129C16.846 7.66981 16.7951 7.82398 16.6954 7.94541L10.4454 14.1954C10.3246 14.3088 10.1658 14.3728 10.0001 14.3751V14.3751Z" fill="#333333"/>
                                                </svg>
                                            </label>
                                        </div>
                                        <div class="list_expand_1">
                                            <div class="list_admin_side_rolling_1">
                                                <h4 class="text_small">롤링 방식</h4>
                                                <SelectBoxV2
                                                    id={"transition_mode_select"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="36px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {this.state.transition_mode_list}
                                                    optionTexttName = "code_name"
                                                    optionValuetName = "code_cd"
                                                    selectedValue = {this.state.transition_mode}
                                                    onClickOption={this.onChangeTransitionMode.bind(this)}
                                                />
                                                <div class="list_admin_side_row_3_1a">
                                                    <span class="i_notice">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>
                                                    </span>
                                                    <h3 class="text_12">
                                                        선정된 상품이 열 개수보다 많은 경우, 
                                                        적용하실 롤링 방식을 골라 주세요.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="list_admin_side_rolling_1">
                                                <h4 class="text_small">롤링 이동 텀</h4>
                                                <SelectBoxV2
                                                    id={"transition_pause_select"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="36px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {this.state.transition_pause_list}
                                                    optionTexttName = "code_name"
                                                    optionValuetName = "code_cd"
                                                    selectedValue = {this.state.transition_pause}
                                                    onClickOption={this.onChangeTransitionPause.bind(this)}
                                                />
                                                <div class="list_admin_side_row_3_1a">
                                                    <span class="i_notice">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>
                                                    </span>
                                                    <h3 class="text_12">
                                                        선정된 상품이 열 개수보다 많은 경우, 몇 초 동안 
                                                        머무른 뒤 다음 화면으로 이동할지 골라 주세요.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="list_admin_side_rolling_1">
                                                <h4 class="text_small">롤링 속도</h4>
                                                <SelectBoxV2
                                                    id={"transition_speed_select"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="36px"
                                                    padding="14px 36px 14px 16px"
                                                    list= {this.state.transition_speed_list}
                                                    optionTexttName = "code_name"
                                                    optionValuetName = "code_cd"
                                                    selectedValue = {this.state.transition_speed}
                                                    onClickOption={this.onChangeTransitionSpeed.bind(this)}
                                                />
                                                <div class="list_admin_side_row_3_1a">
                                                    <span class="i_notice">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>
                                                    </span>
                                                    <h3 class="text_12">
                                                        선정된 상품이 열 개수보다 많은 경우, 
                                                        다음 화면으로 얼마나 빠르게 이동할지 골라 주세요.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="list_admin_side_rolling_radio">
                                                <h3 class="text_small">자동 롤링 기능</h3>
                                                <div class="list_side_1_title_radio">
                                                    {this.state.use_yn_list.map((obj, idx) => (
                                                        <RadioButton
                                                            id={"auto_rolling_radio_"+obj.value}
                                                            name={"list_side_rolling_radio"}
                                                            value={obj.value}
                                                            text={obj.text}
                                                            checked={this.state.auto_rolling_yn==obj.value}
                                                            onChangeCheck={this.onChangeAutoRollingYn.bind(this)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list_admin_side_row_5 list_expand_wrap">
                                        <div class="list_expand" id="auto_product_select_list_expand">
                                            {/* <h3 class="text_16">자동관리 사용 설정</h3> */}
                                            <h3 class="text_16">자동관리 일정 설정</h3>
                                            <input class="list_expand_check" type="checkbox" id="list_side_title_arrow_3" name="list_side_title_arrow_3"/>
                                            <label for="list_side_title_arrow_3">
                                                <svg class="list_arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0001 14.3751C9.83444 14.3728 9.67561 14.3088 9.55479 14.1954L3.30479 7.94541C3.20512 7.82398 3.15419 7.66981 3.1619 7.5129C3.1696 7.356 3.2354 7.20756 3.34648 7.09648C3.45756 6.9854 3.606 6.9196 3.7629 6.9119C3.91981 6.90419 4.07398 6.95512 4.19541 7.05479L10.0001 12.8673L15.8048 7.05479C15.9262 6.95512 16.0804 6.90419 16.2373 6.9119C16.3942 6.9196 16.5426 6.9854 16.6537 7.09648C16.7648 7.20756 16.8306 7.356 16.8383 7.5129C16.846 7.66981 16.7951 7.82398 16.6954 7.94541L10.4454 14.1954C10.3246 14.3088 10.1658 14.3728 10.0001 14.3751V14.3751Z" fill="#333333"/>
                                                </svg>
                                            </label>
                                        </div>
                                        <div class="list_expand_1" style={{paddingTop: 0}}>
                                            <div class="list_admin_side_row_5_radio">
                                                {/* {this.state.auto_create_type_list.map((obj, idx) => (
                                                    <RadioButton
                                                        id={"auto_create_type_"+obj.code_cd}
                                                        name={"auto_create_type_radio"}
                                                        value={obj.code_cd}
                                                        text={obj.code_name}
                                                        checked={
                                                            (
                                                                this.state.auto_create_type == obj.code_cd 
                                                                // || (this.state.auto_create_type == null && this.state.auto_create_yn == "Y" && obj.code_cd == "A7001")
                                                                // || (this.state.auto_create_type == null && this.state.auto_create_yn == "N" && obj.code_cd == "A7002")
                                                            )
                                                            }
                                                        onChangeCheck={
                                                            this.onChangeAutoCreateType.bind(this)
                                                        }
                                                    />
                                                ))} */}
                                                 {this.state.auto_yn_list.map((obj, idx) => (
                                                    <RadioButton
                                                        id={"auto_create_"+obj.value}
                                                        name={"list_side_3_radio"}
                                                        value={obj.value}
                                                        text={obj.text}
                                                        // 이전에 N값이 0으로 저장되게 해버려서 0관련 처리 진행
                                                        checked={
                                                            (this.state.auto_create_yn == obj.value || (obj.value == "N" && this.state.auto_create_yn == "0"))
                                                            }
                                                        onChangeCheck={
                                                            this.onChangeAutoCreateYn.bind(this)
                                                        }
                                                    />
                                                ))}
                                            </div>
                                            
                                            { this.state.auto_create_yn == "Y"
                                                ?   <>
                                                        <div className="realTimeServiceHeader m-t-20" style={{width:"calc(100% + 32px)", marginLeft: "-16px", borderBottom:"2px solid rgba(0, 0, 0, 0.08)"}}>
                                                            <div className='flexBox' style={{justifyContent:"space-between", padding: "0 32px"}}>
                                                                {this.state.event_cycle_type_list.map((obj, idx) => (
                                                                    <button className={"tab_width_50 " + (this.state.event_cycle_type == obj.code_cd ? "active" : "")} 
                                                                    style={{
                                                                        color: (
                                                                            (obj.code_cd == "A8003" && !(this.state.operate_yn_0100201001 == "Y" || this.state.operate_yn_0100201002 == "Y")) 
                                                                            ? 'lightgrey' 
                                                                            // : (obj.code_cd == "A8003" && this.state.auto_create_type != 'A7003')
                                                                            //     ? 'lightgrey' 
                                                                            //     : (obj.code_cd != "A8003" && this.state.auto_create_type == 'A7003')
                                                                            //         ? 'lightgrey' 
                                                                                    : '#333'
                                                                        ),
                                                                        paddingBottom: "8px"
                                                                    }}
                                                                    onClick={e => {
                                                                        if (obj.code_cd == "A8003") {
                                                                            if (this.state.operate_yn_0100201001 == "Y" || this.state.operate_yn_0100201002 == "Y") {
                                                                                this.setState({
                                                                                    event_cycle_type: obj.code_cd
                                                                                })
                                                                            } else {
                                                                                this.setState({
                                                                                    real_time_service_regist_show_state : true
                                                                                })
                                                                                return false;
                                                                            }
                                                                        } else {
                                                                            // if (obj.code_cd != "A8003" && this.state.auto_create_type == 'A7003') {
                                                                            //     return false;
                                                                            // }
                                                                            this.setState({
                                                                                event_cycle_type: obj.code_cd
                                                                            })
                                                                        }
                                                                    }}>
                                                                        {obj.code_name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.event_cycle_type == "A8001"
                                                            ?   this.renderDailySetting()
                                                            :   this.state.event_cycle_type == "A8002"
                                                                ?   this.renderWeeklySetting()
                                                                :   this.state.event_cycle_type == "A8003"
                                                                    ?   this.renderRealTimeSetting()
                                                                    :   this.renderDailySetting()
                                                        }
                                                    </>
                                                :   <>
                                                        {/* <div className="realTimeServiceHeader m-t-20" style={{width:"calc(100% + 32px)", marginLeft: "-16px", borderBottom:"2px solid rgba(0, 0, 0, 0.08)"}}>
                                                            <div className='flexBox' style={{justifyContent:"space-between", padding: "0 32px"}}>
                                                                <button className={"active"} 
                                                                style={{
                                                                    color: '#333',
                                                                    paddingBottom: "8px"
                                                                }}                                                                >
                                                                    {"수동 일정"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='m-t-20'>
                                                            <div className="text_small list_admin_side_design_title m-b-8" style={{marginTop:"18px", marginBottom: "8px"}}>예약 시간 설정</div>
                                                            <div class="list_admin_side_row_5_radio">
                                                                {this.state.use_yn_list.map((obj, idx) => (
                                                                    <RadioButton
                                                                        id={"reservation_"+obj.value}
                                                                        name={"reservation_radio"}
                                                                        value={obj.value}
                                                                        text={obj.text}
                                                                        checked={
                                                                            (this.state.reservation_use_yn == obj.value)
                                                                        }
                                                                        onChangeCheck={
                                                                            this.onChangeReservationUseYn.bind(this)
                                                                        }
                                                                    />
                                                                ))}
                                                            </div>
                                                            {
                                                                this.state.reservation_use_yn == "Y"
                                                                ?
                                                                <div>
                                                                    <div className='text_small list_admin_side_design_title m-b-8' style={{marginBottom: "8px"}}>예약 시간</div>
                                                                    <div className='flexBox' style={{justifyContent:'space-between', }}>
                                                                        <div className='flexBox'>
                                                                            <div className="style_guide_v2 rp_event_start_date_wrapper" style={{width:"140px"}}>
                                                                                <DatePicker name="reservation_date" id="reservation_date" className="rp_event_start_date border_form_radius_b_h_42"
                                                                                    selected={this.state.reservation_date}
                                                                                    // onSelect={this.handleSelect} //when day is clicked
                                                                                    onChange={this.handleChangeReservationDate} //only when value has changed
                                                                                    placeholderText="예약일"
                                                                                    minDate={moment().add(0,'days').toDate()}
                                                                                    maxDate={new Date("9999-12-31")}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    locale={ko}
                                                                                    todayButton="오늘"
                                                                                    autoComplete="off"
                                                                                    showYearDropdown
                                                                                    yearDropdownItemNumber={15}
                                                                                    scrollableYearDropdown
                                                                                />
                                                                                <div className="icn_calendar"/>
                                                                            </div>
                                                                            <span className="text_14 m-l-5" style={{alignContent: "center"}}>{"의"}</span>
                                                                        </div>
                                                                        <div className='flexBox'>
                                                                            <SelectBoxV2
                                                                                id={"reservation_hour"}
                                                                                placeHolder={"예약시간"}
                                                                                tabIndex={0}
                                                                                width="80px"
                                                                                height="36px"
                                                                                padding="14px 36px 14px 16px"
                                                                                list= {this.state.event_time_list}
                                                                                // disabled={this.state.event_total_item_select_cnt > flagCafe24BulkConversionProductCount ? true : false}
                                                                                optionTexttName = "title"
                                                                                optionValuetName = "value"
                                                                                selectAreaMaxHeight = "120px"
                                                                                selectedValue = {this.state.reservation_hour}
                                                                                onClickOption={this.onChangeReservationHour.bind(this)}
                                                                            />
                                                                            <span className="text_14 m-l-5" style={{alignContent: "center"}}>{" 에 적용함"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div> */}
                                                    </>
                                            }
                                            {/* <div className='m-t-15'>
                                                <div class="list_admin_row_4_1a">
                                                    <span class="i_notice">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                        </svg>
                                                    </span>
                                                    <span class="text_12">반복예약 일정 설정을 사용하시면 쇼핑몰 설치 후에는 자동 진열된 상품 목록이 쇼핑몰에 바로 노출도 됩니다.</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="list_admin_side_row_4 list_expand_wrap list_category_expand_wrap" id="listCategory" style={{overflow:"visible"}}>
                                        <div class="list_expand list_category_expand" id="listCategoryExpand">
                                            <h3 class="text_16">규칙 설정</h3>
                                            <div class="setting_exclude_product_btn list_expand_select_num">{"상품 "+this.state.event_total_item_select_cnt+"개 | 제외 "+this.props.excludeProductList.length+"개"}</div>
                                            <input class="list_expand_check" type="checkbox" id="list_side_title_arrow_2" name="list_side_title_arrow_2"/>
                                            <label for="list_side_title_arrow_2">
                                                <svg class="list_arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0001 14.3751C9.83444 14.3728 9.67561 14.3088 9.55479 14.1954L3.30479 7.94541C3.20512 7.82398 3.15419 7.66981 3.1619 7.5129C3.1696 7.356 3.2354 7.20756 3.34648 7.09648C3.45756 6.9854 3.606 6.9196 3.7629 6.9119C3.91981 6.90419 4.07398 6.95512 4.19541 7.05479L10.0001 12.8673L15.8048 7.05479C15.9262 6.95512 16.0804 6.90419 16.2373 6.9119C16.3942 6.9196 16.5426 6.9854 16.6537 7.09648C16.7648 7.20756 16.8306 7.356 16.8383 7.5129C16.846 7.66981 16.7951 7.82398 16.6954 7.94541L10.4454 14.1954C10.3246 14.3088 10.1658 14.3728 10.0001 14.3751V14.3751Z" fill="#333333"/>
                                                </svg>
                                            </label>
                                        </div>

                                        <div class="list_side_select_btn_wrap">
                                            <h3 class="text_16" style={{marginBottom: "8px"}}>알고리즘 규칙상품 미리보기</h3>
                                            <button class="selected_list_btn text_extrasmall hover_white"  onClick={e => this.onClickEventProductListPopup()} tabIndex={-1}>팝업 미리보기</button>
                                            <button class="selected_list_btn text_extrasmall hover_dark" onClick={e => this.onClickEventProductCopy()} tabIndex={-1}>자동 상품 진열 미리보기</button>
                                        </div>

                                        <div id="list_category_radio_wrap_expand">
                                            {this.state.iframe_page_id == "robomd_product_detail_page"
                                            ?<div class="list_category_radio_wrap">
                                                <RadioButton
                                                    id={"list_category_radio_a"}
                                                    name={"list_category_radio"}
                                                    value={"34001"}
                                                    text={"상품이 속해 있는 <br/>카테고리에서 선정"}
                                                    checked={this.state.event_category_select_type=="34001"}
                                                    onChangeCheck={this.onChangeEventCategorySelectType.bind(this)}
                                                />
                                                {this.state.event_category_select_type=="34001"
                                                ?<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#333333" class="category_btn_enabled" viewBox="0 0 16 16" onClick={() => {this.onClickEventCategorySelectPopup()}}>
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                                                </svg>
                                                :<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#333333" class="category_btn_disabled" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                                                </svg>}
                                                <RadioButton
                                                    id={"list_category_radio_b"}
                                                    name={"list_category_radio"}
                                                    value={"34002"}
                                                    text={"지정한 카테고리에서 선정<br/>(모든 페이지에 동일한 상품 노출)"}
                                                    checked={this.state.event_category_select_type=="34002"}
                                                    onChangeCheck={this.onChangeEventCategorySelectType.bind(this)}
                                                />
                                            </div> : ""}


                                            <div class="list_admin_side_select list_expand_1" style={{overflow:"visible"}}>
                                                <div class="list_select_content">
                                                    <div class="list_select_wrap">
                                                        <div class="list_select_expand_wrap" style={{overflow:'visible'}}>
                                                            <div class="list_select_expand_1">
                                                                <div class="list_select_set">
                                                                    <h4>진열 최대 개수</h4>
                                                                    <div class="list_select_margin" style={{marginBottom:this.state.max_item_analysis == "1" ? "0px" : null}}>
                                                                        <SelectBoxV2
                                                                            id={"maxItemAnalysis"}
                                                                            width="100%"
                                                                            height="36px"
                                                                            padding="14px 36px 14px 16px"
                                                                            list= {this.state.max_item_analysis_list}
                                                                            optionTexttName = "title"
                                                                            optionValuetName = "value"
                                                                            selectedValue = {this.state.max_item_analysis}
                                                                            onClickOption={this.onChangeMaxItemAnalysis.bind(this)}
                                                                        />
                                                                    </div>

                                                                    <div class="list_select_margin">
                                                                    {this.state.max_item_analysis !== '1'
                                                                    ?   <>
                                                                            <h4>상품 최대 개수</h4>
                                                                            <input style={{width: "100%"}} name={"max_item_analysis_value"} type="text" placeholder="0" value={StringUtil.numberComma(this.state.max_item_analysis_value)} onChange={e => this.onChangeMaxItemAnalysisValue(e)} autocomplete="off"/>
                                                                        </>
                                                                    // :   <input style={{width: "100%"}} type="text" placeholder="0" value={StringUtil.numberComma(this.state.event_total_item_select_cnt)} autocomplete="off" disabled/>
                                                                    :   null
                                                                    }
                                                                    </div>
                                                                    {this.state.max_item_analysis_value > 1000 && this.state.max_item_analysis !== "1"
                                                                    ?
                                                                    <div class="alert_warning list_select_margin">
                                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/></svg>                
                                                                        <span>최대 상품 선정수는 알고리즘 총합 100개 입니다.</span>
                                                                    </div>
                                                                    :""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)", margin: "10px 0"}}></div> 
                                            {/* </div>

                                                <div class="list_admin_side_select list_expand_1" style={{overflow:"visible"}}> */}
                                                    {this.state.list_self_select != null && this.state.list_self_select.map((obj, idx) => (
                                                        <div class="list_select_content"
                                                            key={idx}
                                                            onDragOver={(e) => this.onDragOverAlgorithm(e)}
                                                            onDrop={(e) => this.onDropAlgorithm(e, idx)}>
                                                            <div class="list_select_wrap">
                                                                <div class="list_select_expand_wrap">
                                                                <label class="list_select_expand" style={{display: "flex",flexDirection: "column"}} id={"list_select_expand_"+idx} for={"list_select_arrow_"+idx} onClick={e => {this.onClickEventFolder(idx)}} 
                                                                draggable
                                                                onDragStart={(e) => this.onDragStartAlgorithm(e, idx)}>
                                                                    <div class="label-content" style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                                                                        <h3 class="text_16">
                                                                            {(idx+1)+". "+getSelectBoxSelectIdx(item_select_type_self_list, "val1","code_cd",obj.cd)}
                                                                        </h3>
                                                                        <div class="list_self_select_number_text">{"상품 "+obj.cnt+"개"}</div>
                                                                        <input class="list_select_expand_check" type="checkbox" id={"list_select_arrow_"+idx} name="list_select_arrow" />
                                                                        <label for={"list_select_arrow_"+idx} style={{cursor:"pointer",display: "flex", alignItems: "center", justifyContent: "center", width:"16px",height:"16px"}}>                                                                            <svg style={{background:"none",width:"16px",height:"16px", marginBottom: "3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#333" class="arrow-down" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                            </svg>
                                                                        </label>
                                                                    </div>
                                                                    <div class="list_select_action_set" 
                                                                style={{display: "flex", justifyContent: "space-between", alignItems: "center", boxSizing: "border-box", width: "50%" , marginTop: "5px"}}>
                                                                    <button class="list_select_paste" alt="알고리즘 복제" onClick={e => {this.copyPasteAlgorithm(e, idx)}} tabIndex={10}> 
                                                                        <Tooltip
                                                                        className={"algorithm_tooltip"}
                                                                        content={"복제"}
                                                                        iconWidth={30}
                                                                        iconHeight={30}
                                                                        zIndex={10}
                                                                        hoverSvg={(
                                                                            <svg class="hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7475 9.4149 20.7195 6.93639 18.8916 5.10845C17.0636 3.28051 14.5851 2.25248 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75H12.75V15.75C12.75 15.9489 12.671 16.1397 12.5303 16.2803C12.3897 16.421 12.1989 16.5 12 16.5C11.8011 16.5 11.6103 16.421 11.4697 16.2803C11.329 16.1397 11.25 15.9489 11.25 15.75V12.75H8.25C8.05109 12.75 7.86033 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86033 11.329 8.05109 11.25 8.25 11.25H11.25V8.25C11.25 8.05109 11.329 7.86032 11.4697 7.71967C11.6103 7.57902 11.8011 7.5 12 7.5C12.1989 7.5 12.3897 7.57902 12.5303 7.71967C12.671 7.86032 12.75 8.05109 12.75 8.25V11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        nonHoverSvg={(
                                                                            <svg class="non_hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7475 9.4149 20.7195 6.93639 18.8916 5.10845C17.0636 3.28051 14.5851 2.25248 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75H12.75V15.75C12.75 15.9489 12.671 16.1397 12.5303 16.2803C12.3897 16.421 12.1989 16.5 12 16.5C11.8011 16.5 11.6103 16.421 11.4697 16.2803C11.329 16.1397 11.25 15.9489 11.25 15.75V12.75H8.25C8.05109 12.75 7.86033 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86033 11.329 8.05109 11.25 8.25 11.25H11.25V8.25C11.25 8.05109 11.329 7.86032 11.4697 7.71967C11.6103 7.57902 11.8011 7.5 12 7.5C12.1989 7.5 12.3897 7.57902 12.5303 7.71967C12.671 7.86032 12.75 8.05109 12.75 8.25V11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        ></Tooltip>
                                                                    </button>
                                                                    <button class="list_select_delete" alt="알고리즘 삭제" onClick={e => {this.onDeleteAlgorithm(e, idx)}} tabIndex={-1}> 
                                                                    <Tooltip
                                                                        className={"algorithm_tooltip"}
                                                                        content={"삭제"}
                                                                        iconWidth={30}
                                                                        iconHeight={30}
                                                                        hoverSvg={(
                                                                            <svg class="hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7475 9.4149 20.7195 6.93639 18.8916 5.10845C17.0636 3.28051 14.5851 2.25248 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75H8.25C8.05109 12.75 7.86033 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86033 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        nonHoverSvg={(
                                                                            <svg class="non_hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7475 9.4149 20.7195 6.93639 18.8916 5.10845C17.0636 3.28051 14.5851 2.25248 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75H8.25C8.05109 12.75 7.86033 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86033 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        ></Tooltip>
                                                                        
                                                                    </button>
                                                                    <button class="list_select_arrow_up" alt="알고리즘 순서 위로" onClick={e => {this.onChangeUpSequenceAlgorithm(e, idx)}} tabIndex={-1}> 
                                                                    <Tooltip
                                                                        className={"algorithm_tooltip"}
                                                                        content={"위로"}
                                                                        iconWidth={30}
                                                                        iconHeight={30}
                                                                        hoverSvg={(
                                                                            <svg class="hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M18.8908 5.10938C17.0633 3.28187 14.5847 2.25519 12.0002 2.25519C9.4157 2.25519 6.93707 3.28187 5.10956 5.10938C3.28205 6.93689 2.25537 9.41552 2.25537 12C2.25537 14.5845 3.28205 17.0631 5.10956 18.8906C6.93707 20.7181 9.4157 21.7448 12.0002 21.7448C14.5847 21.7448 17.0633 20.7181 18.8908 18.8906C20.7183 17.0631 21.745 14.5845 21.745 12C21.745 9.41552 20.7183 6.93689 18.8908 5.10938ZM17.8314 17.8313C16.6779 18.9857 15.2079 19.7721 13.6074 20.091C12.0069 20.4099 10.3477 20.2469 8.83984 19.6227C7.33196 18.9984 6.04309 17.941 5.13627 16.5842C4.22945 15.2273 3.74543 13.632 3.74543 12C3.74543 10.368 4.22945 8.7727 5.13627 7.41585C6.04309 6.059 7.33196 5.00158 8.83984 4.37735C10.3477 3.75313 12.0069 3.59014 13.6074 3.90902C15.2079 4.22789 16.6779 5.0143 17.8314 6.16875C18.6024 6.93159 19.2145 7.83974 19.6322 8.84066C20.0499 9.84159 20.265 10.9154 20.265 12C20.265 13.0846 20.0499 14.1584 19.6322 15.1593C19.2145 16.1603 18.6024 17.0684 17.8314 17.8313ZM15.9283 12.9938C16.0625 13.1405 16.133 13.3345 16.1242 13.5331C16.1154 13.7318 16.0281 13.9188 15.8814 14.0531C15.7423 14.1782 15.5623 14.2482 15.3752 14.25C15.271 14.2506 15.1679 14.2291 15.0726 14.1871C14.9773 14.1451 14.8919 14.0835 14.8221 14.0063L12.0002 10.875L9.17831 14.0063C9.04281 14.148 8.85718 14.2311 8.66119 14.2376C8.4652 14.2442 8.27444 14.1737 8.12978 14.0413C7.98512 13.9089 7.89807 13.7251 7.88729 13.5293C7.8765 13.3335 7.94283 13.1412 8.07206 12.9938L11.4471 9.24375C11.5173 9.16702 11.6028 9.10575 11.698 9.06383C11.7932 9.02191 11.8961 9.00026 12.0002 9.00026C12.1042 9.00026 12.2071 9.02191 12.3024 9.06383C12.3976 9.10575 12.483 9.16702 12.5533 9.24375L15.9283 12.9938Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        nonHoverSvg={(
                                                                            <svg class="non_hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M18.8908 5.10938C17.0633 3.28187 14.5847 2.25519 12.0002 2.25519C9.4157 2.25519 6.93707 3.28187 5.10956 5.10938C3.28205 6.93689 2.25537 9.41552 2.25537 12C2.25537 14.5845 3.28205 17.0631 5.10956 18.8906C6.93707 20.7181 9.4157 21.7448 12.0002 21.7448C14.5847 21.7448 17.0633 20.7181 18.8908 18.8906C20.7183 17.0631 21.745 14.5845 21.745 12C21.745 9.41552 20.7183 6.93689 18.8908 5.10938ZM17.8314 17.8313C16.6779 18.9857 15.2079 19.7721 13.6074 20.091C12.0069 20.4099 10.3477 20.2469 8.83984 19.6227C7.33196 18.9984 6.04309 17.941 5.13627 16.5842C4.22945 15.2273 3.74543 13.632 3.74543 12C3.74543 10.368 4.22945 8.7727 5.13627 7.41585C6.04309 6.059 7.33196 5.00158 8.83984 4.37735C10.3477 3.75313 12.0069 3.59014 13.6074 3.90902C15.2079 4.22789 16.6779 5.0143 17.8314 6.16875C18.6024 6.93159 19.2145 7.83974 19.6322 8.84066C20.0499 9.84159 20.265 10.9154 20.265 12C20.265 13.0846 20.0499 14.1584 19.6322 15.1593C19.2145 16.1603 18.6024 17.0684 17.8314 17.8313ZM15.9283 12.9938C16.0625 13.1405 16.133 13.3345 16.1242 13.5331C16.1154 13.7318 16.0281 13.9188 15.8814 14.0531C15.7423 14.1782 15.5623 14.2482 15.3752 14.25C15.271 14.2506 15.1679 14.2291 15.0726 14.1871C14.9773 14.1451 14.8919 14.0835 14.8221 14.0063L12.0002 10.875L9.17831 14.0063C9.04281 14.148 8.85718 14.2311 8.66119 14.2376C8.4652 14.2442 8.27444 14.1737 8.12978 14.0413C7.98512 13.9089 7.89807 13.7251 7.88729 13.5293C7.8765 13.3335 7.94283 13.1412 8.07206 12.9938L11.4471 9.24375C11.5173 9.16702 11.6028 9.10575 11.698 9.06383C11.7932 9.02191 11.8961 9.00026 12.0002 9.00026C12.1042 9.00026 12.2071 9.02191 12.3024 9.06383C12.3976 9.10575 12.483 9.16702 12.5533 9.24375L15.9283 12.9938Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        ></Tooltip>
                                                                        
                                                                    </button>
                                                                    <button class="list_select_arrow_down" alt="알고리즘 순서 아래로" onClick={e => {this.onChangeDownSequenceAlogorithm(e, idx)}} tabIndex={-1}> 
                                                                    <Tooltip
                                                                        className={"algorithm_tooltip"}
                                                                        content={"아래로"}
                                                                        iconWidth={30}
                                                                        iconHeight={30}
                                                                        hoverSvg={(
                                                                            <svg class="hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.10919 18.8906C6.9367 20.7181 9.41533 21.7448 11.9998 21.7448C14.5843 21.7448 17.0629 20.7181 18.8904 18.8906C20.7179 17.0631 21.7446 14.5845 21.7446 12C21.7446 9.41551 20.7179 6.93688 18.8904 5.10937C17.0629 3.28186 14.5843 2.25518 11.9998 2.25518C9.41533 2.25518 6.93669 3.28187 5.10919 5.10937C3.28168 6.93688 2.255 9.41551 2.255 12C2.255 14.5845 3.28168 17.0631 5.10919 18.8906ZM6.16856 6.16875C7.32208 5.01429 8.79207 4.22789 10.3926 3.90901C11.9931 3.59014 13.6523 3.75312 15.1602 4.37735C16.668 5.00157 17.9569 6.05899 18.8637 7.41584C19.7705 8.77269 20.2546 10.368 20.2546 12C20.2546 13.632 19.7706 15.2273 18.8637 16.5842C17.9569 17.941 16.668 18.9984 15.1602 19.6226C13.6523 20.2469 11.9931 20.4099 10.3926 20.091C8.79208 19.7721 7.32208 18.9857 6.16856 17.8312C5.39757 17.0684 4.78551 16.1603 4.36779 15.1593C3.95007 14.1584 3.73499 13.0846 3.73499 12C3.73499 10.9154 3.95007 9.84158 4.36779 8.84066C4.78551 7.83973 5.39757 6.93158 6.16856 6.16875ZM8.07169 11.0062C7.93747 10.8595 7.867 10.6655 7.87579 10.4669C7.88458 10.2682 7.97191 10.0812 8.11856 9.94687C8.25773 9.82183 8.43773 9.75183 8.62481 9.75C8.72896 9.74945 8.83206 9.77088 8.92737 9.81288C9.02268 9.85488 9.10806 9.91651 9.17794 9.99375L11.9998 13.125L14.8217 9.99375C14.9572 9.85199 15.1428 9.76894 15.3388 9.76239C15.5348 9.75585 15.7256 9.82632 15.8702 9.95872C16.0149 10.0911 16.1019 10.2749 16.1127 10.4707C16.1235 10.6665 16.0572 10.8588 15.9279 11.0062L12.5529 14.7562C12.4827 14.833 12.3972 14.8943 12.302 14.9362C12.2068 14.9781 12.1039 14.9997 11.9998 14.9997C11.8958 14.9997 11.7929 14.9781 11.6976 14.9362C11.6024 14.8943 11.517 14.833 11.4467 14.7562L8.07169 11.0062Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        nonHoverSvg={(
                                                                            <svg class="non_hover" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.10919 18.8906C6.9367 20.7181 9.41533 21.7448 11.9998 21.7448C14.5843 21.7448 17.0629 20.7181 18.8904 18.8906C20.7179 17.0631 21.7446 14.5845 21.7446 12C21.7446 9.41551 20.7179 6.93688 18.8904 5.10937C17.0629 3.28186 14.5843 2.25518 11.9998 2.25518C9.41533 2.25518 6.93669 3.28187 5.10919 5.10937C3.28168 6.93688 2.255 9.41551 2.255 12C2.255 14.5845 3.28168 17.0631 5.10919 18.8906ZM6.16856 6.16875C7.32208 5.01429 8.79207 4.22789 10.3926 3.90901C11.9931 3.59014 13.6523 3.75312 15.1602 4.37735C16.668 5.00157 17.9569 6.05899 18.8637 7.41584C19.7705 8.77269 20.2546 10.368 20.2546 12C20.2546 13.632 19.7706 15.2273 18.8637 16.5842C17.9569 17.941 16.668 18.9984 15.1602 19.6226C13.6523 20.2469 11.9931 20.4099 10.3926 20.091C8.79208 19.7721 7.32208 18.9857 6.16856 17.8312C5.39757 17.0684 4.78551 16.1603 4.36779 15.1593C3.95007 14.1584 3.73499 13.0846 3.73499 12C3.73499 10.9154 3.95007 9.84158 4.36779 8.84066C4.78551 7.83973 5.39757 6.93158 6.16856 6.16875ZM8.07169 11.0062C7.93747 10.8595 7.867 10.6655 7.87579 10.4669C7.88458 10.2682 7.97191 10.0812 8.11856 9.94687C8.25773 9.82183 8.43773 9.75183 8.62481 9.75C8.72896 9.74945 8.83206 9.77088 8.92737 9.81288C9.02268 9.85488 9.10806 9.91651 9.17794 9.99375L11.9998 13.125L14.8217 9.99375C14.9572 9.85199 15.1428 9.76894 15.3388 9.76239C15.5348 9.75585 15.7256 9.82632 15.8702 9.95872C16.0149 10.0911 16.1019 10.2749 16.1127 10.4707C16.1235 10.6665 16.0572 10.8588 15.9279 11.0062L12.5529 14.7562C12.4827 14.833 12.3972 14.8943 12.302 14.9362C12.2068 14.9781 12.1039 14.9997 11.9998 14.9997C11.8958 14.9997 11.7929 14.9781 11.6976 14.9362C11.6024 14.8943 11.517 14.833 11.4467 14.7562L8.07169 11.0062Z" fill="#333333"/>
                                                                        </svg>
                                                                        )}
                                                                        ></Tooltip>
                                                                       
                                                                    </button>
                                                                </div>
                                                                <div class="list_select_set" style={{paddingBottom:"5px"}}>
                                                                    <button class="selected_list_btn text_extrasmall hover_white btn_in_algorithm" tabindex="-1" onClick={() => {this.onClickEssentialProductListPopup(obj.uuid)}}>{"고정 상품 설정 ("+(obj.essential_product_no_list != null ? obj.essential_product_no_list.length : 0)+"개)"}</button>
                                                                </div>
                                                                    </label>
                                                                    <div class="list_select_expand_1 rp_algorithm">
                                                                        <div class="list_select_set">
                                                                            {/* 상품 카테고리 */}
                                                                                <h4>상품 카테고리</h4>  
                                                                            <div class="list_select_margin" style={{width:"100%"}}>
                                                                                <div class="list_category_select_box">
                                                                                    <SelectBoxV2
                                                                                        id={"product_rp_category_no_1/"+idx}
                                                                                        tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                        width="100%"
                                                                                        height="36px"
                                                                                        padding="14px 36px 14px 16px"
                                                                                        list= {obj.product_rp_category_list_1}
                                                                                        optionTexttName = "category_name_no_format"
                                                                                        optionValuetName = "category_no"
                                                                                        selectedValue = {obj.product_rp_category_no_1 == "" ? "전체상품" : obj.product_rp_category_no_1}
                                                                                        onClickOption={this.onChangeRpCategory.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    obj.product_rp_category_list_2 != null && obj.product_rp_category_list_2.length > 0
                                                                                    ?<div class="list_category_select_box list_category_select_box_1">
                                                                                        <SelectBoxV2
                                                                                            id={"product_rp_category_no_2/"+idx}
                                                                                            tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                            width="100%"
                                                                                            height="36px"
                                                                                            padding="14px 36px 14px 16px"
                                                                                            list= {obj.product_rp_category_list_2}
                                                                                            optionTexttName = "category_name_no_format"
                                                                                            optionValuetName = "category_no"
                                                                                            selectedValue = {obj.product_rp_category_no_2 == "" ? "전체상품" : obj.product_rp_category_no_2}
                                                                                            onClickOption={this.onChangeRpCategory.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    :   ""
                                                                                }
                                                                                {
                                                                                    obj.product_rp_category_list_3 != null && obj.product_rp_category_list_3.length > 0
                                                                                    ?<div class="list_category_select_box list_category_select_box_1">
                                                                                        <SelectBoxV2
                                                                                            id={"product_rp_category_no_3/"+idx}
                                                                                            tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                            width="100%"
                                                                                            height="36px"
                                                                                            padding="14px 36px 14px 16px"
                                                                                            list= {obj.product_rp_category_list_3}
                                                                                            optionTexttName = "category_name_no_format"
                                                                                            optionValuetName = "category_no"
                                                                                            selectedValue = {obj.product_rp_category_no_3 == "" ? "전체상품" : obj.product_rp_category_no_3}
                                                                                            onClickOption={this.onChangeRpCategory.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    :   ""
                                                                                }
                                                                                {
                                                                                    obj.product_rp_category_list_4 != null && obj.product_rp_category_list_4.length > 0
                                                                                    ?<div class="list_category_select_box list_category_select_box_1">
                                                                                        <SelectBoxV2
                                                                                            id={"product_rp_category_no_4/"+idx}
                                                                                            tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                            width="100%"
                                                                                            height="36px"
                                                                                            padding="14px 36px 14px 16px"
                                                                                            list= {obj.product_rp_category_list_4}
                                                                                            optionTexttName = "category_name_no_format"
                                                                                            optionValuetName = "category_no"
                                                                                            selectedValue = {obj.product_rp_category_no_4 == "" ? "전체상품" : obj.product_rp_category_no_4}
                                                                                            onClickOption={this.onChangeRpCategory.bind(this)}
                                                                                        />
                                                                                    </div>
                                                                                    :   ""
                                                                                }
                                                                            </div>
                                                                            <h4>상품 등록일 기준</h4>
                                                                            <div class="list_select_margin">
                                                                                <SelectBoxV2
                                                                                    id={"eventItemCreatedPeriod_"+idx}
                                                                                    tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list= {this.state.event_item_created_period_list}
                                                                                    optionTexttName = "title"
                                                                                    optionValuetName = "value"
                                                                                    selectedValue = {
                                                                                        obj.event_item_created_period_type == "S"
                                                                                        ? "-999"
                                                                                        : obj.self_select_created_yn == 'Y' ? '-1' : obj.event_item_created_period == 0 ? 0 : obj.event_item_created_period+'/'+obj.event_item_created_period_type
                                                                                    }
                                                                                    selectedText={
                                                                                        obj.event_item_created_period_type == "S"
                                                                                        ? '계절 등록일 기준 적용중'
                                                                                        : obj.self_select_created_yn == 'Y' ? "직접 지정": null}
                                                                                    onClickOption={(text, value, id) => this.onChangeEventItemCreatedPeriod(value, idx)}
                                                                                    disabled = {obj.event_item_created_period_type == "S"}
                                                                                />
                                                                                {obj.self_select_created_yn == 'Y'
                                                                                ?<div class="event_item_created_period">
                                                                                    <div>
                                                                                        <DatePicker name="start_date" id="select_start_date"
                                                                                            selected={obj.event_item_created_start_date}
                                                                                            onChange={(date) => this.onChangeSelectStartDate(date, idx)}
                                                                                            placeholderText="시작일자"
                                                                                            dateFormat="yyyy-MM-dd"
                                                                                            maxDate={new Date()}
                                                                                            locale={ko}
                                                                                            todayButton="오늘"
                                                                                            autoComplete="off"
                                                                                            showYearDropdown
                                                                                            yearDropdownItemNumber={15}
                                                                                            scrollableYearDropdown
                                                                                            tabIndex={obj.self_select_area_checked ? 0 : -1}
                                                                                        />
                                                                                    </div>
                                                                                    <span>~</span>
                                                                                    <div class="event_item_created_end_date">
                                                                                        <DatePicker name="end_date" id="select_end_date"
                                                                                            selected={obj.event_item_created_end_date}
                                                                                            onChange={(date) => this.onChangeSelectEndDate(date, idx)}
                                                                                            placeholderText="종료일자"
                                                                                            dateFormat="yyyy-MM-dd"
                                                                                            minDate={obj.event_item_created_start_date}
                                                                                            maxDate={new Date()}
                                                                                            locale={ko}
                                                                                            todayButton="오늘"
                                                                                            autoComplete="off"
                                                                                            showYearDropdown
                                                                                            yearDropdownItemNumber={15}
                                                                                            scrollableYearDropdown
                                                                                            tabIndex={obj.self_select_area_checked ? 0 : -1}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                :""}
                                                                            </div>
                                                                            <h4>계절 등록일 기준</h4>
                                                                            <div class="list_select_margin">
                                                                                <SelectBoxV2
                                                                                    id={"eventItemCreatedPeriod_"+idx}
                                                                                    tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list= {this.state.event_item_created_period_season_list}
                                                                                    optionTexttName = "title"
                                                                                    optionValuetName = "value"
                                                                                    selectedValue = {
                                                                                        obj.event_item_created_period_type && obj.event_item_created_period_type == "S" 
                                                                                        ? obj.event_item_created_period+'/'+obj.event_item_created_period_type
                                                                                        : "-999"
                                                                                    }
                                                                                    onClickOption={(text, value, id) => this.onChangeEventItemCreatedPeriod(value, idx)}
                                                                                />
                                                                            </div>
                                                                            <div class="list_select_margin">
                                                                            <h4>상품 자동 선정 알고리즘</h4>
                                                                                {
                                                                                    obj["event_item_condition_list"] 
                                                                                    ?   obj["event_item_condition_list"].map((condition_obj, condition_idx) => (
                                                                                            <div style={{marginBottom: "10px"}}>
                                                                                                <SelectBoxV2
                                                                                                    id={"item_condition_list_"+idx+"_"+condition_idx}
                                                                                                    tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                                    width="100%"
                                                                                                    height="36px"
                                                                                                    padding="14px 36px 14px 16px"
                                                                                                    list= {this.state.item_condition_list}
                                                                                                    optionTexttName = "val1"
                                                                                                    optionValuetName = "code_cd"
                                                                                                    selectedValue = {condition_obj}
                                                                                                    onClickOption={this.onChangeItemConditionListCd.bind(this)}
                                                                                                />
                                                                                            </div>
                                                                                        ))
                                                                                    : ""
                                                                                }
                                                                                <SelectBoxV2
                                                                                    id={"item_select_type_select_"+idx}
                                                                                    tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list= {item_select_type_self_list}
                                                                                    optionTexttName = "val1"
                                                                                    optionValuetName = "code_cd"
                                                                                    selectedValue = {obj.cd}
                                                                                    onClickOption={this.onChangeSelfSelectListCd.bind(this)}
                                                                                />
                                                                            </div>
                                                                            <h4>통계수 분석기간</h4>
                                                                            <div class="list_select_margin">
                                                                                <SelectBoxV2
                                                                                    id={"eventItemAnalysisPeriod_"+idx}
                                                                                    tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list= {this.state.event_item_analysis_period_list}
                                                                                    optionTexttName = "title"
                                                                                    optionValuetName = "value"
                                                                                    selectedValue = {obj.event_item_analysis_period}
                                                                                    onClickOption={this.onChangeEventItemAnalysisPeried.bind(this)}
                                                                                />
                                                                            </div>
                                                                            <h4>상품 선정 수</h4>
                                                                            <div class="list_combo_box">
                                                                                <div class="list_combo_select_color">
                                                                                    <SelectBoxV2
                                                                                        id={"EventTotalItemSelectCntSelect_"+idx}
                                                                                        tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                        width="100%"
                                                                                        height="36px"
                                                                                        padding="14px 36px 14px 16px"
                                                                                        list= {this.state.total_item_select_cnt_range}
                                                                                        onlyValue={true}
                                                                                        selectedValue = {obj.cnt}
                                                                                        error={
                                                                                            this.state.total_item_select_cnt_range.length > 0 && this.state.event_total_item_select_cnt > (this.state.total_item_select_cnt_range.indexOf("자동") >= 0 ? this.state.total_item_select_cnt_range.length-2 : this.state.total_item_select_cnt_range.length-1)
                                                                                            || ((obj.essential_product_no_list? obj.essential_product_no_list.length : 0) > 0 && obj.cnt == '자동')
                                                                                            || ((obj.essential_product_no_list? obj.essential_product_no_list.length : 0) > obj.cnt)
                                                                                        }
                                                                                        onClickOption={this.onChangeEventTotalItemSelectCntSelect.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                <input type="text" class="text_14 list_input_disabled" maxlength="3" id={"EventTotalItemSelectCntSelectInput_"+idx} value={obj.cnt} onChange={e => this.onChangeEventTotalItemSelectCntInput(e,idx)}
                                                                                onBlur={e => this.onChangeEventTotalItemSelectCntInput(e,idx,true)} autocomplete="off" tabIndex={-1}/>
                                                                            </div>
                                                                            {this.state.event_total_item_select_cnt > (this.state.total_item_select_cnt_range.indexOf("자동") >= 0 ? this.state.total_item_select_cnt_range.length-2 : this.state.total_item_select_cnt_range.length-1)
                                                                            ?<div class="alert_warning list_select_margin">
                                                                                <span>최대 상품 선정수는 알고리즘 종합 {this.state.total_item_select_cnt_range.indexOf("자동") >= 0 ? this.state.total_item_select_cnt_range.length-2 : this.state.total_item_select_cnt_range.length-1}개입니다.</span>
                                                                            </div>
                                                                            :
                                                                            obj.essential_product_no_list && (obj.essential_product_no_list.length > obj.cnt)
                                                                            ? <div class="alert_warning list_select_margin">
                                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/></svg>                
                                                                                <span>상품 선정수는 고정 상품의 개수보다 커야 합니다.</span>
                                                                            </div>
                                                                            : obj.essential_product_no_list && (obj.essential_product_no_list.length > 0 && obj.cnt == '자동')
                                                                            ? // 필수 상품이 설정되어 있는경우, 상품 선정수는 ‘자동’으로 할 수 없습니다.
                                                                            <div class="alert_warning list_select_margin">
                                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/></svg>                
                                                                                <span>고정 상품이 설정되어 있는경우, 상품 선정수는 ‘자동’으로 할 수 없습니다.</span>
                                                                            </div>
                                                                            : <div class="alert_info list_select_margin">
                                                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1229 7.84575 17.2662 5.78033 15.743 4.25704C14.2197 2.73376 12.1543 1.87707 10 1.875ZM9.84375 5.625C10.0292 5.625 10.2104 5.67998 10.3646 5.783C10.5188 5.88601 10.6389 6.03243 10.7099 6.20373C10.7808 6.37504 10.7994 6.56354 10.7632 6.7454C10.7271 6.92725 10.6378 7.0943 10.5067 7.22541C10.3756 7.35652 10.2085 7.44581 10.0267 7.48199C9.84479 7.51816 9.65629 7.49959 9.48499 7.42864C9.31368 7.35768 9.16726 7.23752 9.06425 7.08335C8.96124 6.92918 8.90625 6.74792 8.90625 6.5625C8.90625 6.31386 9.00503 6.0754 9.18084 5.89959C9.35666 5.72377 9.59511 5.625 9.84375 5.625ZM10.625 14.375H10C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75H10C10.1658 8.75 10.3247 8.81585 10.4419 8.93306C10.5592 9.05027 10.625 9.20924 10.625 9.375V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#ACACAC"/></svg>                
                                                                                <span>최대 상품 선정수는 알고리즘 종합 {this.state.total_item_select_cnt_range.indexOf("자동") >= 0 ? this.state.total_item_select_cnt_range.length-2 : this.state.total_item_select_cnt_range.length-1}개입니다.</span>
                                                                            </div>}
                                                                            <h4>자동선정 상품키워드</h4>
                                                                            <div className={"list_self_select_title_keword_area"}>
                                                                            {obj.product_keyword_list.map((keyword, keyword_idx) => (
                                                                                <div className="list_self_select_title_keword">
                                                                                    <TextInput height={38} value={keyword} onChange={e => this.onChangeEventItemProductKeyword(e, idx, keyword_idx)}/>
                                                                                    <img onClick={() => this.addEventItemProductKeyword(idx,keyword_idx)} src={default_plus_img}/>
                                                                                    {keyword_idx == 0
                                                                                    ?<div className={"empty_area"}></div>
                                                                                    :<img onClick={() => this.delEventItemProductKeyword(idx,keyword_idx)} src={default_minus_img}/>}
                                                                                </div>
                                                                            ))}
                                                                            </div>
                                                                            <h4>자동제외 상품키워드</h4>
                                                                            <div className={"list_self_select_title_keword_area"}>
                                                                            {obj.product_except_keyword_list.map((keyword, keyword_idx) => (
                                                                                <div className="list_self_select_title_keword">
                                                                                    <TextInput height={38} value={keyword} onChange={e => this.onChangeEventItemProductExceptKeyword(e, idx, keyword_idx)}/>
                                                                                    <img onClick={() => this.addEventItemProductExceptKeyword(idx,keyword_idx)} src={default_plus_img}/>
                                                                                    {keyword_idx == 0
                                                                                    ?<div className={"empty_area"}></div>
                                                                                    :<img onClick={() => this.delEventItemProductExceptKeyword(idx,keyword_idx)} src={default_minus_img}/>}
                                                                                </div>
                                                                            ))}
                                                                            </div>
                                                                            {this.state.solution_type_cd == "09001" &&
                                                                            <>
                                                                                <h4>자동선정 상품옵션</h4>
                                                                                <div className="list_self_select_product_color">
                                                                                    <MultiSelect
                                                                                        className={["react-select"].join(' ')}
                                                                                        value={obj.color_list}
                                                                                        closeMenuOnSelect={false}
                                                                                        components={animatedComponents}
                                                                                        isMulti
                                                                                        placeholder={"상품 컬러를 선택할 수 있습니다."}
                                                                                        noOptionsMessage={() => "선택할 컬러가 없습니다."}
                                                                                        getOptionValue={(option) => option.color_seq}
                                                                                        getOptionLabel={(option) => option.color_name}
                                                                                        options={this.state.product_color_list}
                                                                                        onChange={(selectedOption) => this.selectProductColor(idx, selectedOption)}
                                                                                        styles={reactSelectStyles({optionColorName : "color_hex"})}
                                                                                    />
                                                                                </div>
                                                                            </>}
                                                                            <h4>상품 조회수</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_hit_cnt"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_hit_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_hit_cnt"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_hit_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">개</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 장바구니수</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_cart_cnt"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_cart_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_cart_cnt"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_cart_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">개</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 판매가</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_price"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_price)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_price"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_price)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">원</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 판매수</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_order_cnt"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_order_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_order_cnt"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_order_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">개</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 판매액</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_order_price"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_order_price)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_order_price"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_order_price)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">원</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 리뷰수</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_review_cnt"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_review_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_review_cnt"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_review_cnt)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">개</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 리뷰평점</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_review_point"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_review_point)} onChange={e => this.onChangeEventItemPrice(e,idx, 5)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_review_point"} type="text" placeholder="5" value={StringUtil.numberComma(obj.max_review_point)} onChange={e => this.onChangeEventItemPrice(e,idx, 5)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">점</span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>상품 재고수</h4>
                                                                            <div class="list_self_select_price">
                                                                                <div>
                                                                                    <input class="input_self_select" name={"min_quantity"} type="text" placeholder="0" value={StringUtil.numberComma(obj.min_quantity)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">~</span>
                                                                                </div>
                                                                                <div>
                                                                                    <input class="input_self_select" name={"max_quantity"} type="text" placeholder="999,999,999" value={StringUtil.numberComma(obj.max_quantity)} onChange={e => this.onChangeEventItemPrice(e,idx)} autocomplete="off" tabIndex={obj.self_select_area_checked ? 0 : -1}/>
                                                                                    <span class="text_14">개</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="list_select_margin" style={{width:"100%"}}>
                                                                                <h4>상품재고 부분품절 발생시</h4>    
                                                                                <div className='list_admin_side_row_5_radio'>
                                                                                    <RadioButton
                                                                                        value={"Y"}
                                                                                        text={"제외함"}
                                                                                        checked={obj.is_except_option_soldout == "Y" ? true : false}
                                                                                        onChangeCheck={() => this.onChangeIsExceptOptionSoldout(idx, "Y")}
                                                                                    />
                                                                                    <RadioButton
                                                                                        value={"N"}
                                                                                        text={"제외 안 함"}
                                                                                        checked={obj.is_except_option_soldout == "N" ? true : obj.is_except_option_soldout == null ? true : false}
                                                                                        onChangeCheck={() => this.onChangeIsExceptOptionSoldout(idx, "N")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div class="list_select_margin" style={{width:"100%"}}>
                                                                                <h4>상품재고 전체품절 자동제외</h4>    
                                                                                <div className='list_admin_side_row_5_radio'>
                                                                                    <RadioButton
                                                                                        value={"Y"}
                                                                                        text={"품절상품 자동 제외함"}
                                                                                        checked={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div class="list_select_margin" style={{width:"100%"}}>
                                                                                <h4>상품정보에 숨긴 카테고리가 있다면</h4>    
                                                                                <div className='list_admin_side_row_5_radio'>
                                                                                    <RadioButton
                                                                                        value={"Y"}
                                                                                        text={"제외함"}
                                                                                        checked={obj.is_except_invisible_category == "Y" ? true : obj.is_except_invisible_category == null ? true : false}
                                                                                        onChangeCheck={() => this.onChangeIsExceptInvisibleCategory(idx, "Y")}
                                                                                    />
                                                                                    <RadioButton
                                                                                        value={"N"}
                                                                                        text={"제외 안 함"}
                                                                                        checked={obj.is_except_invisible_category == "N" ? true : false}
                                                                                        onChangeCheck={() => this.onChangeIsExceptInvisibleCategory(idx, "N")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button class="list_select_content_add_btn" onClick={() => {this.addListSelfSelect()}}>
                                                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.5 10C2.5 9.83424 2.56585 9.67527 2.68306 9.55806C2.80027 9.44085 2.95924 9.375 3.125 9.375L9.375 9.375L9.375 3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125L10.625 9.375L16.875 9.375C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625L10.625 10.625L10.625 16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875L9.375 10.625L3.125 10.625C2.95924 10.625 2.80027 10.5592 2.68306 10.4419C2.56585 10.3247 2.5 10.1658 2.5 10Z" fill="#333333"/>
                                                        </svg>
                                                        <h4>추가</h4>
                                                    </button>
                                                    <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)", margin:"16px 0px 24px 0px"}}></div>
                                                    <div class="list_select_product_btn_wrap">
                                                        <button class="text_extrasmall hover_white" onClick={() => this.onClickExcludeProductListPopup()} tabIndex={-1}>제외 상품 설정</button>
                                                        <button class="text_extrasmall hover_white" onClick={() => this.onClickExceptSettingPopup()} tabIndex={-1}>진열별 중복제외 설정</button>
                                                    </div>
                                                </div>

                                                <div class="list_admin_side_select list_expand_1" style={{overflow:"visible"}}>
                                                    <div class="list_select_content" style={{paddingBottom: "18px"}}>
                                                        <div class="list_select_wrap">
                                                            <div class="list_select_expand_wrap" style={{overflow:'visible'}}>
                                                                <label class="list_select_expand" id="list_select_expand_force" for="list_select_arrow_force" onClick={e => {this.onClickEventFolderForce()}}>
                                                                    <h3 class="text_16">상품부족시 강제설정</h3>
                                                                    <input class="list_select_expand_check" type="checkbox" id="list_select_arrow_force" name="list_select_arrow"/>
                                                                    <label for="list_select_arrow_force" style={{cursor:"pointer",width:"16px",height:"16px"}}>
                                                                        <svg style={{background:"none",width:"16px",height:"16px", marginBottom: "3px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#333" class="arrow-down" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                        </svg>
                                                                    </label>
                                                                </label>
                                                                <div class="list_select_expand_1 rp_algorithm">
                                                                    <div class="list_select_set">
                                                                        <h4>자동 진열 수량 부족시 강제선정</h4>
                                                                        <div class="list_admin_side_row_5_radio">
                                                                            {this.state.force_use_yn_list.map((obj, idx) => (
                                                                                <RadioButton
                                                                                    id={"force_use_" + obj.value}
                                                                                    name={"list_side_force_radio"}
                                                                                    value={obj.value}
                                                                                    text={obj.text}
                                                                                    checked={this.state.force_use_yn===obj.value}
                                                                                    onChangeCheck={this.onChangeForceUseYn.bind(this)}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div class="list_select_set">
                                                                        <h4>상품 카테고리</h4>
                                                                        {/* 상품 카테고리 */}
                                                                        <div class="list_select_margin" style={{width:"100%"}}>
                                                                            <div class="list_category_select_box">
                                                                                <SelectBoxV2
                                                                                    id={"product_rp_category_no_force/1"}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list={this.state.force_category_list_1}
                                                                                    optionTexttName = "category_name_no_format"
                                                                                    optionValuetName = "category_no"
                                                                                    selectedValue = {this.state.force_category.product_rp_category_no_1 === "" ? "전체상품" : this.state.force_category.product_rp_category_no_1}
                                                                                    onClickOption={this.onChangeForceCategory.bind(this)}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.state.force_category_list_2 != null && this.state.force_category_list_2.length > 0
                                                                                ?<div class="list_category_select_box list_category_select_box_1">
                                                                                    <SelectBoxV2
                                                                                        id={"product_rp_category_no_force/2"}
                                                                                        width="100%"
                                                                                        height="36px"
                                                                                        padding="14px 36px 14px 16px"
                                                                                        list= {this.state.force_category_list_2}
                                                                                        optionTexttName = "category_name_no_format"
                                                                                        optionValuetName = "category_no"
                                                                                        selectedValue = {this.state.force_category.product_rp_category_no_2 === "" ? "전체상품" : this.state.force_category.product_rp_category_no_2}
                                                                                        onClickOption={this.onChangeForceCategory.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                :   ""
                                                                            }
                                                                            {
                                                                                this.state.force_category_list_3 != null && this.state.force_category_list_3.length > 0
                                                                                ?<div class="list_category_select_box list_category_select_box_1">
                                                                                    <SelectBoxV2
                                                                                        id={"product_rp_category_no_force/3"}
                                                                                        width="100%"
                                                                                        height="36px"
                                                                                        padding="14px 36px 14px 16px"
                                                                                        list= {this.state.force_category_list_3}
                                                                                        optionTexttName = "category_name_no_format"
                                                                                        optionValuetName = "category_no"
                                                                                        selectedValue = {this.state.force_category.product_rp_category_no_3 === "" ? "전체상품" : this.state.force_category.product_rp_category_no_3}
                                                                                        onClickOption={this.onChangeForceCategory.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                :   ""
                                                                            }
                                                                            {
                                                                                this.state.force_category_list_4 != null && this.state.force_category_list_4.length > 0
                                                                                ?<div class="list_category_select_box list_category_select_box_1">
                                                                                    <SelectBoxV2
                                                                                        id={"product_rp_category_no_force/4"}
                                                                                        width="100%"
                                                                                        height="36px"
                                                                                        padding="14px 36px 14px 16px"
                                                                                        list= {this.state.force_category_list_4}
                                                                                        optionTexttName = "category_name_no_format"
                                                                                        optionValuetName = "category_no"
                                                                                        selectedValue = {this.state.force_category.product_rp_category_no_4 === "" ? "전체상품" : this.state.force_category.product_rp_category_no_4}
                                                                                        onClickOption={this.onChangeForceCategory.bind(this)}
                                                                                    />
                                                                                </div>
                                                                                :   ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="list_select_set">
                                                                        <div class="list_select_margin" style={{width:"100%"}}>
                                                                        <h4>상품 등록일 기준</h4>
                                                                        <div class="list_select_margin">
                                                                            {/* <SelectBoxV2
                                                                                id={"eventItemCreatedPeriod_force"}
                                                                                width="100%"
                                                                                height="36px"
                                                                                padding="14px 36px 14px 16px"
                                                                                list= {this.state.event_item_created_period_list}
                                                                                optionTexttName = "title"
                                                                                optionValuetName = "value"
                                                                                selectedValue = {this.state.force_select_created_yn === 'Y' ? '-1' : this.state.force_created_period === 0 ? 0 : this.state.force_created_period+'/'+this.state.force_created_period_type}
                                                                                selectedText={this.state.force_select_created_yn === 'Y' ? "직접 지정": null}
                                                                                onClickOption={(text, value, id) => this.onChangeEventItemCreatedPeriodForce(value)}
                                                                            /> */}
                                                                            <SelectBoxV2
                                                                                id={"eventItemCreatedPeriod_force"}
                                                                                width="100%"
                                                                                height="36px"
                                                                                padding="14px 36px 14px 16px"
                                                                                list= {this.state.event_item_created_period_list}
                                                                                optionTexttName = "title"
                                                                                optionValuetName = "value"
                                                                                selectedValue = {
                                                                                    this.state.force_created_period_type == "S"
                                                                                    ? "-999"
                                                                                    : this.state.force_select_created_yn == 'Y' ? '-1' : this.state.force_created_period == 0 ? 0 : this.state.force_created_period+'/'+this.state.force_created_period_type
                                                                                }
                                                                                selectedText={
                                                                                    this.state.force_created_period_type == "S"
                                                                                    ? '계절 등록일 기준 적용중'
                                                                                    : this.state.force_select_created_yn == 'Y' ? "직접 지정": null}
                                                                                onClickOption={(text, value, id) => this.onChangeEventItemCreatedPeriodForce(value)}
                                                                                disabled = {this.state.force_created_period_type == "S"}
                                                                            />
                                                                            {this.state.force_select_created_yn === 'Y'
                                                                            ?<div class="event_item_created_period">
                                                                                <div>
                                                                                    <DatePicker name="start_date" id="select_start_date"
                                                                                        selected={this.state.force_created_start_date}
                                                                                        onChange={(date) => this.onChangeSelectStartDateForce(date)}
                                                                                        placeholderText="시작일자"
                                                                                        dateFormat="yyyy-MM-dd"
                                                                                        maxDate={new Date()}
                                                                                        locale={ko}
                                                                                        todayButton="오늘"
                                                                                        autoComplete="off"
                                                                                        showYearDropdown
                                                                                        yearDropdownItemNumber={15}
                                                                                        scrollableYearDropdown
                                                                                    />
                                                                                </div>
                                                                                <span>~</span>
                                                                                <div class="event_item_created_end_date">
                                                                                    <DatePicker name="end_date" id="select_end_date"
                                                                                        selected={this.state.force_created_end_date}
                                                                                        onChange={(date) => this.onChangeSelectEndDateForce(date)}
                                                                                        placeholderText="종료일자"
                                                                                        dateFormat="yyyy-MM-dd"
                                                                                        minDate={this.state.force_created_start_date}
                                                                                        maxDate={new Date()}
                                                                                        locale={ko}
                                                                                        todayButton="오늘"
                                                                                        autoComplete="off"
                                                                                        showYearDropdown
                                                                                        yearDropdownItemNumber={15}
                                                                                        scrollableYearDropdown
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="list_select_set">
                                                                    <div class="list_select_margin" style={{width:"100%"}}>
                                                                        <h4>계절 등록일 기준</h4>
                                                                        <div class="list_select_margin">
                                                                            <SelectBoxV2
                                                                                id={"eventItemCreatedPeriod_force"}
                                                                                width="100%"
                                                                                height="36px"
                                                                                padding="14px 36px 14px 16px"
                                                                                list= {this.state.event_item_created_period_season_list}
                                                                                optionTexttName = "title"
                                                                                optionValuetName = "value"
                                                                                selectedValue = { 
                                                                                    this.state.force_created_period_type && this.state.force_created_period_type == "S" 
                                                                                    ? this.state.force_created_period+'/'+this.state.force_created_period_type
                                                                                    : "-999"
                                                                                }
                                                                                onClickOption={(text, value, id) => this.onChangeEventItemCreatedPeriodForce(value)}
                                                                            />
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="list_select_set">
                                                                        <div class="list_select_margin" style={{width:"100%"}}>
                                                                        <h4>강제 상품 자동 선정 알고리즘</h4>
                                                                            <SelectBoxV2
                                                                                id={"item_select_type_force"}
                                                                                // tabIndex={obj.self_select_area_checked ? 0 : ""}
                                                                                width="100%"
                                                                                height="36px"
                                                                                padding="14px 36px 14px 16px"
                                                                                list= {item_select_type_self_list}
                                                                                optionTexttName = "val1"
                                                                                optionValuetName = "code_cd"
                                                                                selectedValue = {this.state.force_logic}
                                                                                onClickOption={this.onChangeSelfSelectListCdForce.bind(this)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div class="list_select_set">
                                                                        <div class="list_select_margin" style={{width:"100%"}}>
                                                                            <h4>상품정보에 숨긴 카테고리가 있다면</h4>    
                                                                            <div className='list_admin_side_row_5_radio'>
                                                                                <RadioButton
                                                                                    value={"Y"}
                                                                                    text={"제외함"}
                                                                                    checked={this.state.force_is_except_invisible_category === "Y" ? true : this.state.force_is_except_invisible_category == null ? true : false}
                                                                                    onChangeCheck={() => this.onChangeIsExceptInvisibleCategoryForce("Y")}

                                                                                />
                                                                                <RadioButton
                                                                                    value={"N"}
                                                                                    text={"제외 안 함"}
                                                                                    checked={this.state.force_is_except_invisible_category === "N" ? true : false}
                                                                                    onChangeCheck={() => this.onChangeIsExceptInvisibleCategoryForce("N")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div class="list_select_margin" style={{width:"100%"}}>
                                                                            <h4>통계수 분석기간</h4>
                                                                            <div class="list_select_margin">
                                                                                <SelectBoxV2
                                                                                    id={"eventItemAnalysisPeriod_force"}
                                                                                    width="100%"
                                                                                    height="36px"
                                                                                    padding="14px 36px 14px 16px"
                                                                                    list= {this.state.event_item_analysis_period_list}
                                                                                    optionTexttName = "title"
                                                                                    optionValuetName = "value"
                                                                                    selectedValue = {this.state.force_analysis_period}
                                                                                    onClickOption={this.onChangeEventItemAnalysisPeriedForce.bind(this)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className={this.state.highlightType == "random" ? "list_admin_side_row_5 list_expand_wrap rp_border_highlight" : "list_admin_side_row_5 list_expand_wrap"} ref={highlightRandom}>
                                        <div class="list_expand" id="product_list_order_expand">
                                            <h3 class="text_16">상품 정렬 방식</h3>
                                            <input class="list_expand_check" type="checkbox" id="list_side_title_arrow_3" name="list_side_title_arrow_3"/>
                                            <label for="list_side_title_arrow_3">
                                                <svg class="list_arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0001 14.3751C9.83444 14.3728 9.67561 14.3088 9.55479 14.1954L3.30479 7.94541C3.20512 7.82398 3.15419 7.66981 3.1619 7.5129C3.1696 7.356 3.2354 7.20756 3.34648 7.09648C3.45756 6.9854 3.606 6.9196 3.7629 6.9119C3.91981 6.90419 4.07398 6.95512 4.19541 7.05479L10.0001 12.8673L15.8048 7.05479C15.9262 6.95512 16.0804 6.90419 16.2373 6.9119C16.3942 6.9196 16.5426 6.9854 16.6537 7.09648C16.7648 7.20756 16.8306 7.356 16.8383 7.5129C16.846 7.66981 16.7951 7.82398 16.6954 7.94541L10.4454 14.1954C10.3246 14.3088 10.1658 14.3728 10.0001 14.3751V14.3751Z" fill="#333333"/>
                                                </svg>
                                            </label>
                                        </div>
                                        <div class="list_expand_1">
                                            <div class="list_admin_side_row_5_radio">
                                                {this.state.product_order_type_list.map((obj, idx) => (
                                                    <RadioButton
                                                        id={"product_order_"+obj.code_cd}
                                                        name={"product_order_type"}
                                                        value={obj.code_cd}
                                                        text={obj.code_name}
                                                        // 이전에 N값이 0으로 저장되게 해버려서 0관련 처리 진행
                                                        checked={this.state.product_order_type==obj.code_cd}
                                                        onChangeCheck={this.onChangeProductOrderType.bind(this)}
                                                    />
                                                ))}
                                            </div>
                                            <div class="list_admin_row_4_1a">
                                                <span class="i_notice">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                    </svg>
                                                </span>
                                                <span class="text_12">랜덤의 경우 쇼핑몰을 새로 고침 할 때마다 상품 정렬 순서가 랜덤으로 변경됩니다.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list_save" id="left_setting_save_button_div">
                                        {(this.state.left_is_modified || this.props.excludeProductModified || this.state.except_setting_is_modified) &&
                                            <button class="text_small hover_white" onClick={e => this.onClickResetLeftSetting()} tabIndex={-1}>취소</button>
                                        }
                                        <button id="left_save_btn" class="text_small hover_mint list_save_btn" onClick={e => this.onClickSaveLeftSetting()} tabIndex={-1}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div class="list_admin_main_menu">
                                <div class="list_admin_main_menu_wrap">
                                    <div class="list_admin_main_menu_row_1">
                                        <div class="list_admin_main_menu_row_1_left">
                                            <div className="list_admin_main_menu_row_1_left_sync">
                                                <h2 class="text_16">상품 진열 미리보기 &nbsp;&nbsp;</h2>
                                                <div style={{color: "#878B90"}}>총 <span style={{color: "#00ACB2"}}>{product_list.length}</span>개 &nbsp;&nbsp;</div>
                                                {/* TODO: 실시간 동기화 제거 */}
                                                {/* <div className="sync_data_format">{`상품정보동기화 ${this.state.syncDateFormat}`}</div> */}
                                                {/* {this.state.syncState == "1"
                                                    ?   
                                                        <div className="list_loading" onClick={()=>{this.loadShopSyncStateAndSyncDate(true)}}>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    :  <img className="list_loading" src={arrows_clockwise} onClick={() => {this.loadShopSyncStateAndSyncDate(true)}}/>
                                                } */}
                                            </div>
                                            <h3 class="text_12">
                                                {product_modified_date != "" && product_modified_date != null
                                                ?<>
                                                    {product_is_auto=="Y" && this.state.install_yn=="N"?
                                                        <>{product_modified_date} 에 자동 선정해드린 상품 목록입니다. 설치 후에는 쇼핑몰에 자동 노출됩니다.</>
                                                    : ""}
                                                    {product_is_auto=="Y" && this.state.install_yn=="Y"?
                                                        <>{product_modified_date} 에 자동 선정해드린 상품 목록으로 쇼핑몰에도 노출되어 있습니다.</>
                                                    : ""}
                                                    {product_is_auto=="N" && this.state.install_yn=="N"?
                                                        <>{product_modified_date} 에 직접 수정하신 상품 목록입니다. 설치 후에는 쇼핑몰에 자동 노출됩니다.</>
                                                    : ""}
                                                    {product_is_auto=="N" && this.state.install_yn=="Y"?
                                                        <>{product_modified_date} 에 직접 수정하신 상품 목록으로 쇼핑몰에도 노출되어 있습니다.</>
                                                    : ""}
                                                </>
                                                :""}
                                            </h3>
                                        </div>
                                        <div class="list_admin_main_menu_row_1_right">
                                            {this.state.right_is_modified &&
                                                <button class="text_small hover_white" onClick={e => this.onClickResetRightSetting()} tabIndex={-1}>변경사항 미적용</button>
                                            }
                                            <button class="text_small hover_mint" onClick={e => this.onClickSaveRightSetting()} tabIndex={-1}>미리보기 상품 바로 진열</button>
                                        </div>
                                    </div>
                                    <div class="list_admin_main_board">
                                    {this.state.event_category_select_type=="34001"
                                        ?
                                            <div class="list_main_board_category" id="multi_category_select_div">
                                                <h3 class="text_14" style={{marginRight:"20px"}}>카테고리</h3>
                                                <SelectBoxV2
                                                    id={"multi_category_select"}
                                                    tabIndex={0}
                                                    width="300px"
                                                    height="36px"
                                                    padding="10px 36px 10px 16px"
                                                    list= {show_mulit_category_list}
                                                    optionTexttName = "category_name_no_format"
                                                    optionValuetName = "category_no"
                                                    selectedValue = {this.state.selected_category_no}
                                                    onClickOption={this.onChangeMultiCategorySelect.bind(this)}
                                                />
                                                <span class="list_main_board_category_span"></span>
                                            </div>
                                        : ""
                                    }
                                        <div class="list_main_board_row_1" style={{overflow:"visible", flexWrap:"wrap"}}>
                                            <div style={{borderTop: "0px"}}>
                                                <button class="delete_btn text_extrasmall" onClick={() => this.onClickCheckedRpProductDelete()} tabIndex={-1}>선택 삭제</button>
                                                <div class="up_down_btn">
                                                    <button class="double_down_arrow_btn" onClick={e=>this.onChangeProductOrder(product_list.length)} tabIndex={-1}>
                                                        <svg class="double_down_arrow" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.0341 8.4656C17.1749 8.60779 17.2539 8.79984 17.2539 8.99997C17.2539 9.20011 17.1749 9.39215 17.0341 9.53435L9.5341 17.0343C9.39075 17.173 9.19914 17.2504 8.99973 17.2504C8.80032 17.2504 8.60871 17.173 8.46535 17.0343L0.965352 9.53435C0.845755 9.38862 0.784636 9.20363 0.793884 9.01534C0.803131 8.82705 0.882084 8.64893 1.01539 8.51563C1.14869 8.38233 1.3268 8.30338 1.51509 8.29413C1.70338 8.28488 1.88838 8.346 2.0341 8.4656L8.99973 15.4406L15.9654 8.4656C16.1075 8.32476 16.2996 8.24575 16.4997 8.24575C16.6999 8.24575 16.8919 8.32476 17.0341 8.4656ZM8.46535 9.53435C8.60871 9.67296 8.80032 9.75045 8.99973 9.75045C9.19914 9.75045 9.39075 9.67296 9.5341 9.53435L17.0341 2.03435C17.1537 1.88862 17.2148 1.70363 17.2056 1.51534C17.1963 1.32705 17.1174 1.14893 16.9841 1.01563C16.8508 0.882328 16.6727 0.803375 16.4844 0.794128C16.2961 0.78488 16.1111 0.845999 15.9654 0.965596L8.99973 7.9406L2.0341 0.965596C1.88838 0.845999 1.70338 0.78488 1.51509 0.794128C1.3268 0.803375 1.14869 0.882328 1.01539 1.01563C0.882084 1.14893 0.803131 1.32705 0.793884 1.51534C0.784636 1.70363 0.845755 1.88862 0.965352 2.03435L8.46535 9.53435Z" fill="#333333"/>
                                                        </svg>
                                                    </button>
                                                    <button class="down_arrow_btn" onClick={e=>this.onChangeProductOrder(1)} tabIndex={-1}>
                                                        <svg class="down_arrow" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.99973 9.24997C8.80094 9.24727 8.61034 9.17036 8.46535 9.03435L0.965352 1.53435C0.845755 1.38862 0.784636 1.20363 0.793884 1.01534C0.803131 0.827049 0.882084 0.648931 1.01539 0.51563C1.14869 0.382329 1.3268 0.303375 1.51509 0.294128C1.70338 0.28488 1.88838 0.345999 2.0341 0.465597L8.99973 7.4406L15.9654 0.465597C16.1111 0.345999 16.2961 0.28488 16.4844 0.294128C16.6727 0.303375 16.8508 0.382329 16.9841 0.51563C17.1174 0.648931 17.1963 0.827049 17.2056 1.01534C17.2148 1.20363 17.1537 1.38862 17.0341 1.53435L9.5341 9.03435C9.38911 9.17036 9.19851 9.24727 8.99973 9.24997Z" fill="#333333"/>
                                                        </svg>
                                                    </button>
                                                    <button class="up_arrow_btn" onClick={e=>this.onChangeProductOrder(-1)} tabIndex={-1}>
                                                        <svg class="up_arrow" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.00027 0.750028C9.19906 0.752729 9.38966 0.829637 9.53465 0.965654L17.0346 8.46565C17.1542 8.61138 17.2154 8.79637 17.2061 8.98466C17.1969 9.17295 17.1179 9.35107 16.9846 9.48437C16.8513 9.61767 16.6732 9.69662 16.4849 9.70587C16.2966 9.71512 16.1116 9.654 15.9659 9.5344L9.00027 2.5594L2.03465 9.5344C1.88893 9.654 1.70393 9.71512 1.51564 9.70587C1.32735 9.69662 1.14923 9.61767 1.01593 9.48437C0.882629 9.35107 0.803677 9.17295 0.794428 8.98466C0.785181 8.79637 0.8463 8.61137 0.965899 8.46565L8.4659 0.965654C8.61089 0.829637 8.80149 0.752729 9.00027 0.750028Z" fill="#333333"/>
                                                        </svg>
                                                    </button>
                                                    <button class="double_up_arrow_btn" onClick={e=>this.onChangeProductOrder(0)} tabIndex={-1}>
                                                        <svg class="double_up_arrow" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.965898 9.5344C0.825061 9.39221 0.746053 9.20017 0.746053 9.00003C0.746053 8.79989 0.825061 8.60785 0.965898 8.46566L8.4659 0.965655C8.60925 0.827039 8.80086 0.749553 9.00027 0.749552C9.19968 0.749552 9.39129 0.827038 9.53465 0.965655L17.0346 8.46565C17.1542 8.61138 17.2154 8.79637 17.2061 8.98466C17.1969 9.17295 17.1179 9.35107 16.9846 9.48437C16.8513 9.61767 16.6732 9.69662 16.4849 9.70587C16.2966 9.71512 16.1116 9.654 15.9659 9.5344L9.00027 2.55941L2.03465 9.5344C1.89245 9.67524 1.70041 9.75425 1.50027 9.75425C1.30014 9.75425 1.10809 9.67524 0.965898 9.5344ZM9.53465 8.46566C9.39129 8.32704 9.19968 8.24955 9.00027 8.24955C8.80086 8.24955 8.60925 8.32704 8.4659 8.46566L0.965898 15.9657C0.8463 16.1114 0.785181 16.2964 0.794428 16.4847C0.803677 16.673 0.882629 16.8511 1.01593 16.9844C1.14923 17.1177 1.32735 17.1966 1.51564 17.2059C1.70393 17.2151 1.88893 17.154 2.03465 17.0344L9.00027 10.0594L15.9659 17.0344C16.1116 17.154 16.2966 17.2151 16.4849 17.2059C16.6732 17.1966 16.8513 17.1177 16.9846 16.9844C17.1179 16.8511 17.1969 16.673 17.2061 16.4847C17.2154 16.2964 17.1542 16.1114 17.0346 15.9657L9.53465 8.46566Z" fill="#333333"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="eventItemAnalysisStandardArea">
                                                통계수 분석기간
                                                <div style={{flex: 1, marginLeft:"10px"}}>
                                                    <SelectBoxV2
                                                        style={{width:"100%"}}
                                                        id={"eventItemAnalysisStandard"}
                                                        tabIndex={0}
                                                        width="100%"
                                                        height="36px"
                                                        padding="14px 36px 14px 16px"
                                                        list= {this.state.event_item_analysis_standard_list}
                                                        optionTexttName = "title"
                                                        optionValuetName = "value"
                                                        selectedValue = {this.state.analysis_standard_value}
                                                        onClickOption={this.onChangeEventItemAnalysisStandard.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <button class="add_btn text_extrasmall hover_mint_b" onClick={() => this.onClickAddProductPopup()} tabIndex={-1}>
                                                <img src={plus_img} alt="플러스 아이콘"/>
                                                <div style={{alignSelf:'center'}}>
                                                    상품추가
                                                </div>
                                            </button>
                                        </div>
                                        {/* 상품 목록 테이블 시작 */}
                                        <div class="list_main_board_table_wrap">
                                            <List
                                                values={product_list}
                                                // 드래그를 통한 순서 변경시
                                                onChange={({ oldIndex, newIndex }) =>{
                                                    //  단일 || 다중 카테고리 상태에 따라 순서 변경
                                                    // arrayMove 함수를 통해 자동으로 순서가 변경된다.
                                                   if(this.state.event_category_select_type == "34001"){
                                                        products[selected_category_no]  = [...arrayMove(product_list, oldIndex, newIndex)];
                                                        products[selected_category_no][newIndex].create_type_cd = "22999"; // 수동 선정으로 변경
                                                        this.setState({
                                                            products: products,
                                                            right_is_modified: true,
                                                        }, () => {this.orderIdxReset()});
                                                    } else if(this.state.event_category_select_type == "34002"){
                                                        let drag_product_list = [...arrayMove(product_list, oldIndex, newIndex)];
                                                        drag_product_list[newIndex].create_type_cd = "22999"; // 수동 선정으로 변경
                                                        this.setState({
                                                            rp_product_list: [...drag_product_list],
                                                            right_is_modified: true,
                                                        }, () => {this.orderIdxReset()});
                                                    }
                                                }}
                                                renderList={({ children, props, isDragged  }) => 
                                                    <table class="list_main_board_table rp_list_main_table" onLoad={e => this.onLoadMainTable()}>
                                                        <thead>
                                                            <tr>
                                                                <td class="list_main_td check_box">
                                                                    <CheckBox
                                                                        id={"list_main_board_checkbox_all"}
                                                                        checked={product_list != null && product_list.length > 0 && this.state.rp_check_product_list != null && product_list.length == this.state.rp_check_product_list.length}
                                                                        onChangeCheck={this.onChangeRpProductCheckAll.bind(this)}
                                                                    />
                                                                </td>
                                                                <td class="list_main_td input_table_idx">순서</td>
                                                                <td class="list_main_td table_dnd">순서 변경</td>
                                                                <td class="list_main_td table_image">사진</td>
                                                                <td class="list_main_td product_name">상품명</td>
                                                                <td class="list_main_td reg_date">상품등록일</td>
                                                                <td class="list_main_td hit_cnt">조회수</td>
                                                                <td class="list_main_td cart_cnt">장바구니수</td>
                                                                {/* <td class="list_main_td wish_cnt">찜</td> */}
                                                                <td class="list_main_td price">판매가</td>
                                                                <td class="list_main_td pc_discount_price">할인가</td>
                                                                <td class="list_main_td order_cnt">판매수</td>
                                                                <td class="list_main_td order_price">판매액</td>
                                                                <td class="list_main_td review_cnt">리뷰수</td>
                                                                <td class="list_main_td review_point">리뷰 평점</td>
                                                                <td class="list_main_td quantity">재고수</td>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            !this.state.show_rp_product_list_table_loading_bar
                                                            ?
                                                                <tbody {...props} id="main_table_tbody">{children}</tbody>
                                                            : <tbody style={{position: "relative", height:"200px"}}>  
                                                                <div style={{width:$(".list_main_board_table_wrap").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                                    <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                                </div>
                                                            </tbody>
                                                        }
                                                    </table>
                                                }
                                                renderItem={({ value, props, isDragged, isSelected, index }) => {
                                                    const row = (
                                                        <tr {...props}
                                                        style={{
                                                            ...props.style,
                                                            cursor: isDragged ? 'grabbing' : 'grab',
                                                            height: "600 !important",
                                                            overflowY: 'scroll !important',
                                                            overflowX: 'hidden !important',
                                                        }}
                                                        key={value.product_no}
                                                        tabIndex={-1} >
                                                            <td className={"check_box"} style={isDragged ? {width : "44px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>
                                                                <CheckBox
                                                                    id={"list_main_board_checkbox_"+index}
                                                                    value={index}
                                                                    checked={this.state.rp_check_product_list.find(product => product.product_no == value.product_no) != null}
                                                                    onChangeCheck={this.onChangeRpProductCheck.bind(this)}
                                                                />
                                                            </td>
                                                            <td className={"input_table_idx"} style={isDragged ? {width : "48px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>
                                                                <input class="list_table_number" type="text" value={
                                                                    isDragged ? " " : this.state.main_order_idx[index]
                                                                } name={index} id={"main_order_input_"+index} onChange={e => this.onChangeInput(e)} onKeyPress={e =>this.onChangeOrderIdx(e,false)}
                                                                onBlur={e => this.onChangeOrderIdx(e,true)} style={{padding:'0px', height: "24px"}} autocomplete="off"/>
                                                            </td>
                                                            <td className={"table_dnd"} id={"drag_btn_"+index} data-movable-handle style={isDragged ? {width : "83px", backgroundColor: '#ffffff', cursor: "pointer"} : {backgroundColor: '#ffffff', cursor: "pointer"}} tabIndex={-1}>
                                                                <svg class="drag_btn" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.69529 10.3048C6.81265 10.4233 6.87849 10.5833 6.87849 10.7501C6.87849 10.9169 6.81265 11.0769 6.69529 11.1954L4.19529 13.6954C4.07583 13.8109 3.91615 13.8755 3.74998 13.8755C3.5838 13.8755 3.42413 13.8109 3.30466 13.6954L0.804664 11.1954C0.704999 11.074 0.654067 10.9198 0.661773 10.7629C0.669479 10.606 0.735273 10.4576 0.846358 10.3465C0.957442 10.2354 1.10587 10.1696 1.26278 10.1619C1.41969 10.1542 1.57385 10.2051 1.69529 10.3048L3.12498 11.7423V0.750098C3.12498 0.584338 3.19082 0.425366 3.30803 0.308156C3.42524 0.190946 3.58422 0.125098 3.74998 0.125098C3.91574 0.125098 4.07471 0.190946 4.19192 0.308156C4.30913 0.425366 4.37498 0.584338 4.37498 0.750098V11.7423L5.80466 10.3048C5.92316 10.1874 6.0832 10.1216 6.24998 10.1216C6.41676 10.1216 6.57679 10.1874 6.69529 10.3048ZM14.1953 2.80479L11.6953 0.304785C11.5768 0.187422 11.4168 0.121582 11.25 0.121582C11.0832 0.121582 10.9232 0.187422 10.8047 0.304785L8.30466 2.80479C8.205 2.92622 8.15407 3.08038 8.16177 3.23729C8.16948 3.3942 8.23527 3.54263 8.34636 3.65372C8.45744 3.7648 8.60587 3.83059 8.76278 3.8383C8.91969 3.84601 9.07385 3.79507 9.19529 3.69541L10.625 2.25791V13.2501C10.625 13.4159 10.6908 13.5748 10.808 13.692C10.9252 13.8092 11.0842 13.8751 11.25 13.8751C11.4157 13.8751 11.5747 13.8092 11.6919 13.692C11.8091 13.5748 11.875 13.4159 11.875 13.2501V2.25791L13.3047 3.69541C13.4241 3.81092 13.5838 3.8755 13.75 3.8755C13.9162 3.8755 14.0758 3.81092 14.1953 3.69541C14.3127 3.57691 14.3785 3.41688 14.3785 3.2501C14.3785 3.08332 14.3127 2.92328 14.1953 2.80479Z" fill="#333333"/>
                                                                </svg>
                                                            </td>
                                                            <td className={"table_image"} style={isDragged ? {width : "80px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>
                                                                <img src={
                                                                    this.state.image_type_cd == "45002"
                                                                    ? value.detail_image_url
                                                                    : value.image_url
                                                                }/>
                                                            </td>
                                                            <td className={"product_name"} style={isDragged ? {width : "180px", backgroundColor: '#ffffff', textAlign: "left"} : {backgroundColor: '#ffffff'}}>
                                                                <h4 class="text_extrasmall">
                                                                    {value.product_name}
                                                                </h4>
                                                            </td>
                                                            <td className={"reg_date"} style={isDragged ? {width : "96px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.created_date}</td>
                                                            <td className={"hit_cnt"} style={isDragged ? {width : "84px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.hit_cnt}</td>
															<td className={"cart_cnt"} style={isDragged ? {width : "84px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.cart_cnt}</td>
                                                            <td className={"price"} style={isDragged ? {width : "100px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.price}</td>
                                                            {/* <td className={"wish"} style={isDragged ? {width : "84px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.wish_cnt}</td> */}
                                                            <td className={"pc_discount_price"} style={isDragged ? {width : "100px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.pc_discount_price}</td>
                                                            <td className={"order_cnt"} style={isDragged ? {width : "84px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.order_cnt}</td>
                                                            <td className={"order_price"} style={isDragged ? {width : "128px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.order_price}</td>
                                                            <td className={"review_cnt"} style={isDragged ? {width : "76px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.review_cnt}</td>
                                                            <td className={"review_point"} style={isDragged ? {width : "75px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.review_point}</td>
                                                            <td className={"quantity"} style={isDragged ? {width : "75px", backgroundColor: '#ffffff'} : {backgroundColor: '#ffffff'}}>{value.quantity}</td>
                                                        </tr>
                                                    );
                                                    return isDragged ? (
                                                        <div id="list_table_dragged">
                                                            <div class="list_main_board_table_wrap style_guide_v2">
                                                                <table class="list_main_board_table">
                                                                <tbody>{row}</tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        row
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* 모바일 화면일때 가장 아래에 있는 변경사항 미적용 / [미리보기 상품 바로 진열] 버튼 */}
                                    <div class="list_admin_main_menu_row_1_right mobile">
                                        {this.state.right_is_modified &&
                                            <button class="text_small hover_white" onClick={e => this.onClickResetRightSetting()} tabIndex={-1}>변경사항 미적용</button>
                                        }
                                        <button class="text_small hover_mint" onClick={e => this.onClickSaveRightSetting()} tabIndex={-1}>미리보기 상품 바로 진열</button>
                                    </div>

                                    {/* 제외 상품 목록 팝업 */}
                                    {this.state.show_exclude_product_list_popup && 
                                        <ExcludeProductTableMgmt
                                            iframePageId={this.state.iframe_page_id}
                                            iframeHtmlId={this.state.iframe_html_id}
                                            imageTypeCd={this.state.image_type_cd}
                                            onClose={() => this.setState({
                                                show_exclude_product_list_popup : false,
                                            })}
                                        />
                                    }
                                    {/* 필수 상품 목록 팝업 */}
                                    {this.state.show_essential_product_list_popup && 
                                        <EssentialProductTableMgmt
                                            list_self_select={this.state.list_self_select}
                                            item_select_type_self_list={item_select_type_self_list}
                                            list_self_select_uuid={this.state.essential_list_self_select_uuid}
                                            iframePageId={this.state.iframe_page_id}
                                            iframeHtmlId={this.state.iframe_html_id}
                                            imageTypeCd={this.state.image_type_cd}
                                            onClose={() => this.setState({
                                                show_essential_product_list_popup : false,
                                            })}
                                            onAddProduct={this.addEssentialProduct.bind(this)}
                                        />
                                    }
                                    {/* 상품 추가 팝업 */}
                                    {this.state.show_add_product_popup
                                    ? <SearchProductPopup
                                        title = {"상품 추가"}
                                        selected_product_list = {product_list}
                                        image_type_cd = {this.state.image_type_cd}
                                        onClose = {() => this.closeAddProductPopup()}
                                        onSubmit = {this.onClickPopupCheckedProductApply.bind(this)}
                                        submit_btn_name = {"선택 상품 추가"}
                                    />
                                    : ""}
                                    {/* 대 카테고리에서 선정 팝업 */}
                                    {this.state.show_multi_category_select_popup
                                    ?<div class="add_btn_onclick" style={{alignItems:"center"}}>
                                        <div class="category_list_setting">
                                            <div class="category_list_setting_header">
                                                <div class="add_list_title">
                                                    <h2 class="text_16">카테고리 사용 설정</h2>
                                                    <button onClick={e => this.onCloseBigCategoryListPopup()}>
                                                        <img src={x_img} alt="닫기 버튼"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <h3 class="text_12">
                                                쇼핑몰에 노출 중인 아래의 카테고리 중에서 로보MD 진열을 사용하실 카테고리를 지정해 주세요. 최대 20개까지 지정하실 수 있습니다.   
                                            </h3>
                                            <div class="category_list_setting_table_wrap">
                                                <table class="category_list_setting_table">
                                                    <thead>
                                                        <tr>
                                                            <td class="category_list_setting_td">
                                                                <CheckBox
                                                                    id={"onChangeBigCategoryCheckAll"}
                                                                    checked={this.state.category_list_for_product_detail != null && this.state.category_list_for_product_detail.length > 0 && this.state.big_category_checked_list != null && (this.state.category_list_for_product_detail.length ) == this.state.big_category_checked_list.length}
                                                                    onChangeCheck={this.onChangeBigCategoryCheckAll.bind(this)}
                                                                />
                                                            </td>
                                                            <td class="category_list_setting_td text_extrasmall">
                                                                카테고리명
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.category_list_for_product_detail.map((obj, idx) => (
                                                        obj.category_no != "전체상품"
                                                        ?<>
                                                            <tr key={obj.category_no}>
                                                                <td>
                                                                    <CheckBox
                                                                        id={"big_category_no_"+obj.category_no}
                                                                        value={obj.category_no}
                                                                        checked={this.state.big_category_checked_list.find(category_no => obj.category_no == category_no) != null}
                                                                        onChangeCheck={this.onChangeBigCategoryCheck.bind(this)}
                                                                    />
                                                                </td>
                                                                <td class="text_extrasmall">{obj.category_name_no_format}</td>
                                                            </tr>
                                                        </>
                                                        :""
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="category_list_setting_btn">
                                                {this.state.multi_category_list_is_modified
                                                ?<button class="hover_white" onClick={e => this.onResetBigCategoryList()}>변경사항 미적용</button>
                                                : ""}
                                                <button class="hover_mint" id="selectMultiCategoryUseBtn" onClick={() => this.onClickSelectBigCategoryUse()}>선택한 카테고리 사용</button>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                    {/* 자동 진열된 상품 미리보기 팝업 */}
                                    {this.state.show_event_product_list_popup
                                    ?<PopupFrame 
                                        title={"팝업 미리보기"}
                                        maxWidth={"1150px"}
                                        onClose={this.closeEventListPopup.bind(this)}
                                    >
                                        <div style={{color: "#878B90", marginBottom:"15px"}}>총 <span style={{color: "#00ACB2"}}>{event_rp_product_list.length}</span>개</div>
                                        <div class="list_main_board_row_1 selected_list_table_control" id="selected_list_table_control">
                                            <button class="selected_list_category_refresh_btn text_extrasmall hover_grey" onClick={e => this.onClickReLoadEventProductList()}>상품 새로 선정하기</button>
                                            <div class="selected_list_table_control_row_2">
                                                <SelectBoxV2
                                                    id={"select_event_order_gubun_type"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="26px"
                                                    padding="10px 36px 10px 16px"
                                                    list= {this.state.select_event_order_gubun_type_list}
                                                    optionTexttName = "title"
                                                    optionValuetName = "value"
                                                    selectedValue = {this.state.select_event_order_gubun_type}
                                                    onClickOption={this.onChangeEventOrderGubun.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div class="selected_list_table_wrap">
                                            <table class="selected_list_table list_main_board_table">
                                                <thead>
                                                    <tr>
                                                        <td class="list_main_td">
                                                            <CheckBox
                                                                id={"selected_list_main_board_checkbox_all"}
                                                                checked={(event_rp_product_list != null && event_rp_product_list.length > 0 && this.state.event_product_checked_list != null && event_rp_product_list.length == this.state.event_product_checked_list.length) || (event_rp_product_no_list.length == 0)}
                                                                disabled={event_rp_product_no_list.length == 0}
                                                                onChangeCheck={event_rp_product_no_list.length == 0 ? null : this.onChangeEventProductListCheckAll.bind(this)}
                                                            />
                                                        </td>
                                                        <td class="list_main_td">순서</td>
                                                        <td class="list_main_td">사진</td>
                                                        <td class="list_main_td">상품명</td>
                                                        <td class="list_main_td">상품등록일</td>
                                                        <td class="list_main_td">조회수</td>
														<td class="list_main_td">장바구니수</td>
														{/* <td class="list_main_td">찜</td> */}
                                                        <td class="list_main_td">판매가</td>
                                                        <td class="list_main_td">할인가</td>
                                                        <td class="list_main_td">판매수</td>
                                                        <td class="list_main_td">판매액</td>
                                                        <td class="list_main_td">리뷰수</td>
                                                        <td class="list_main_td">리뷰 평점</td>
                                                        <td class="list_main_td">재고수</td>
                                                    </tr>
                                                </thead>
                                                
                                                {this.state.event_rp_product_list !=null && this.state.event_rp_product_list != ""
                                                ? <tbody id="selected_list_table_tbody">
                                                    {this.state.event_rp_product_list.map((obj, idx) => (
                                                        <tr key={obj.product_no}>
                                                            <td>
                                                                <CheckBox
                                                                    id={"selected_list_main_board_checkbox_"+obj.product_no}
                                                                    value={idx}
                                                                    checked={(this.state.event_product_checked_list.find(product => product.product_no == obj.product_no) != null) || (product_no_list.indexOf(obj.product_no) != -1)}
                                                                    disabled={product_no_list.indexOf(obj.product_no) != -1}
                                                                    onChangeCheck={product_no_list.indexOf(obj.product_no) != -1 ? null : this.onChangeEventProductListCheck.bind(this)}
                                                                />
                                                            </td>
                                                            <td>{idx+1}</td>
                                                            <td>
                                                                <img src={
                                                                    this.state.image_type_cd == "45002"
                                                                    ? obj.detail_image_url
                                                                    : obj.image_url
                                                                }/>
                                                            </td>
                                                            <td>
                                                                <h4 class="text_extrasmall">
                                                                    {obj.product_name}
                                                                </h4>
                                                            </td>
                                                            <td>{obj.created_date}</td>
                                                            <td>{StringUtil.numberComma(obj.orderby_hit_cnt)}</td>
                                                            <td>{StringUtil.numberComma(obj.orderby_cart_cnt)}</td>
                                                            {/* <td>{StringUtil.numberComma(obj.orderby_wish_cnt)}</td> */}
                                                            <td>{obj.price}</td>
                                                            <td>{obj.pc_discount_price}</td>
                                                            <td>{StringUtil.numberComma(obj.orderby_order_cnt)}</td>
                                                            <td>{StringUtil.numberComma(obj.orderby_order_price)+"원"}</td>
                                                            <td>{StringUtil.numberComma(obj.orderby_review_cnt)}</td>
                                                            <td>{obj.orderby_review_point_origin}</td>
                                                            <td>{obj.quantity}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                : this.state.show_event_product_list_table_loading_bar
                                                    ?<tbody style={{position: "relative", height:"200px"}}>
                                                        <div style={{width:$(".selected_list_table_wrap").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                            <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                        </div>
                                                    </tbody>
                                                    : ""
                                                }
                                            </table>
                                        </div>
                                        <div class="selected_list_btn_wrap">
                                            <button class="text_extrasmall hover_mint_b" onClick={() => this.onClickAddSelectEventProductList()}>선택 자동 상품 추가</button>
                                            <button class="text_extrasmall hover_mint" onClick={() => this.onClickRpProductListPaste()}>상품 진열 미리보기</button>
                                        </div>
                                    </PopupFrame>
                                    : ""}
                                    {/* 진열목록별 중복상품제외 팝업 */}
                                    {this.state.show_except_setting_popup
                                    ?<PopupFrame 
                                        title={"진열별 중복제외 설정"}
                                        content={"진열끼리 상품이 중복되지 않도록 하는 기능입니다.<br/>아래에 진열을 추가하면 추가된 진열 속 상품들이 알고리즘 선정에서 제외됩니다."}
                                        maxWidth={"850px"}
                                        onClose={this.closeExceptSettingPopup.bind(this)}
                                    >
                                        <div class="except_setting_table_control">
                                            <div style={{width:"100%"}}>
                                            {this.state.iframe_html_id == null || this.state.iframe_html_id == ""
                                            ?<>
                                                <SelectBoxV2
                                                    id={"except_event_setting_select"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="34px"
                                                    padding="10px 36px 10px 16px"
                                                    list= {this.state.rp_event_setting_list}
                                                    optionTexttName = "name"
                                                    optionValuetName = "id"
                                                    selectedValue = {this.state.select_except_rp_setting}
                                                    disabled = {true}
                                                />
                                                <button class="list_exclude_btn text_extrasmall hover_white disabled" style={{marginRight:"10px"}}>상품보기</button>
                                                <button class="list_exclude_btn text_extrasmall hover_white disabled">추가</button>
                                            </>
                                            :<>
                                                <SelectBoxV2
                                                    id={"except_event_setting_select"}
                                                    tabIndex={0}
                                                    width="100%"
                                                    height="34px"
                                                    padding="10px 36px 10px 16px"
                                                    list= {this.state.rp_event_setting_list}
                                                    optionTexttName = "name"
                                                    optionValuetName = "id"
                                                    selectedValue = {this.state.select_except_rp_setting}
                                                    onClickOption={this.onChangeSelectExceptRpSetting.bind(this)}
                                                    disableOptions = {this.state.except_setting_list.map(obj => obj.id)}
                                                />
                                                <button class="list_exclude_btn text_extrasmall hover_grey" style={{marginRight:"10px"}}
                                                    onClick={() => {
                                                        const iframe_page_id = this.state.select_except_rp_setting.split("/")[0];
                                                        const iframe_html_id = this.state.select_except_rp_setting.split("/")[1];
                                                        this.showExceptSettingProductList(this.state.rp_event_setting_list.filter((obj) => {return obj.iframe_page_id == iframe_page_id && obj.iframe_html_id == iframe_html_id})[0])
                                                    }
                                                }>상품보기</button>
                                                <button class="list_exclude_btn text_extrasmall hover_mint_b" onClick={() => this.addExceptSettingList()}>추가</button>
                                            </>}
                                            </div>
                                        </div>
                                        <div class="selected_list_table_wrap">
                                            <table class="except_setting_table list_main_board_table" style={{width:"800px"}}>
                                                <thead>
                                                    <tr>
                                                        <td class="list_main_td">진열 제목</td>
                                                        <td class="list_main_td">페이지</td>
                                                        <td class="list_main_td">상품 보기</td>
                                                        <td class="list_main_td">삭제</td>
                                                    </tr>
                                                </thead>
                                                
                                                {this.state.except_setting_list !=null && this.state.except_setting_list != ""
                                                ? <tbody>
                                                    {this.state.except_setting_list.map((obj, idx) => (
                                                        <tr>
                                                            <td>{obj.display_name}</td>
                                                            <td>{obj.iframe_page_name}</td>
                                                            <td>
                                                                <button class="list_exclude_btn text_extrasmall hover_white" style={{width:"90%"}} onClick={() => this.showExceptSettingProductList(obj)}>상품보기</button>
                                                            </td>
                                                            <td>
                                                                <button class="close_btn" onClick={e => this.delExceptSetting(idx)}>
                                                                    <img style={{display:"inline", width:"16px", margin:"0px", backgroundColor: "initial"}} src={x_img}/>
                                                                </button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                : ""
                                                }
                                            </table>
                                        </div>
                                        {this.state.except_setting_is_modified
                                        ?<div class="selected_list_btn_wrap exclude_list_btn_wrap">
                                            <button class="text_extrasmall hover_white" style={{border:"1px solid #ACACAC"}} onClick={() => this.clickCancelExceptSettingList()}>취소</button>
                                            <button class="text_extrasmall hover_mint" onClick={() => this.saveExceptSettingList()}>저장</button>
                                        </div>
                                        :""}
                                        {this.state.iframe_html_id == null || this.state.iframe_html_id == ""
                                        ?<div style={{textAlign:"center", padding:"50px", width:"100%"}}>위젯 자동진열 설정 먼저 저장해주세요.</div>
                                        :""}
                                    </PopupFrame>
                                    : ""}
                                    {/* 진열 간 상품 중복 제외 설정 상품 목록 팝업 */}
                                    {this.state.show_except_product_popup
                                    ?<PopupFrame 
                                        title={"진열 상품 목록"}
                                        maxWidth={"1150px"}
                                        onClose={this.closeExceptSettingProductList.bind(this)}
                                    >
                                        {this.state.except_select_category != null && this.state.except_select_category != ""
                                        ?   <div style={{marginBottom:"24px"}}>
                                                <SelectBoxV2
                                                    id={"except_event_product_category_select"}
                                                    tabIndex={0}
                                                    width="200px"
                                                    height="34px"
                                                    padding="10px 36px 10px 16px"
                                                    list= {this.state.except_event_category_list}
                                                    optionTexttName = "category_name_no_format"
                                                    optionValuetName = "category_no"
                                                    selectedValue = {this.state.except_select_category}
                                                    selectedText={this.state.except_products[this.state.except_select_category].category_name_no_format}
                                                    onClickOption={this.onChangeSelectExceptProductCategory.bind(this)}
                                                />
                                            </div>
                                        : ""}
                                        <div class="selected_list_table_wrap" id="except_product_table_wrap">
                                            <table class="selected_list_table list_main_board_table">
                                                <thead>
                                                    <tr>
                                                        <td class="list_main_td" style={{display: "none"}}>
                                                        </td>
                                                        <td class="list_main_td">순서</td>
                                                        <td class="list_main_td">사진</td>
                                                        <td class="list_main_td">상품명</td>
                                                        <td class="list_main_td">상품등록일</td>
                                                        <td class="list_main_td">조회수</td>
														<td class="list_main_td">장바구니수</td>
                                                        {/* <td class="list_main_td">찜</td> */}
                                                        <td class="list_main_td">판매가</td>
                                                        <td class="list_main_td">할인가</td>
                                                        <td class="list_main_td">판매수</td>
                                                        <td class="list_main_td">판매액</td>
                                                        <td class="list_main_td">리뷰수</td>
                                                        <td class="list_main_td">리뷰 평점</td>
                                                        <td class="list_main_td">재고수</td>
                                                    </tr>
                                                </thead>
                                                
                                                {this.state.except_product_list !=null && this.state.except_product_list != ""
                                                ? <tbody id="selected_list_table_tbody">
                                                    {this.state.except_product_list.map((obj, idx) => (
                                                        <tr>
                                                            <td style={{display: "none"}}>
                                                            </td>
                                                            <td>{idx+1}</td>
                                                            <td>
                                                                <img src={
                                                                    this.state.image_type_cd == "45002"
                                                                    ? obj.detail_image_url
                                                                    : obj.image_url
                                                                }/>
                                                            </td>
                                                            <td>
                                                                <h4 class="text_extrasmall">
                                                                    {obj.product_name}
                                                                </h4>
                                                            </td>
                                                            <td>{obj.created_date}</td>
                                                            <td>{StringUtil.numberComma(obj.hit_cnt)}</td>
                                                            <td>{StringUtil.numberComma(obj.cart_cnt)}</td>
                                                            {/* <td>{StringUtil.numberComma(obj.wish_cnt)}</td> */}
                                                            <td>{obj.price}</td>
                                                            <td>{obj.pc_discount_price}</td>
                                                            <td>{StringUtil.numberComma(obj.order_cnt)}</td>
                                                            <td>{StringUtil.numberComma(obj.order_price)}</td>
                                                            <td>{StringUtil.numberComma(obj.review_cnt)}</td>
                                                            <td>{obj.review_point}</td>
                                                            <td>{obj.quantity}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                : this.state.show_except_product_list_table_loading_bar
                                                    ?<tbody style={{position: "relative", height:"200px"}}>
                                                        <div style={{width:$("#except_product_table_wrap").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
                                                            <img src={round_loading_bar} style={{width:"200px"}}></img>
                                                        </div>
                                                    </tbody>
                                                    : ""
                                                }
                                            </table>
                                        </div>
                                    </PopupFrame>
                                    : ""}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* 왼쪽 또는 오른쪽 영역 저장시 나오는 다이얼로그 */
					(this.state.save_dialog_show_state === true)
					? <SaveDialogV2
						content={this.state.save_dialog_content}
						button_list={this.state.save_dialog_button_list}
					/>
					: ''
				}
                {/* 기본 안내 다이얼로그 */
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
					/>
					: ''
				}
                {/* 오른쪽 영역 초기화 다이얼로그*/
					(this.state.title_content_dialog_show_state === true)
					? <TitleContentDialogV2
                        title={this.state.title_content_dialog_title}
						content={this.state.title_content_dialog_content}
						button_list={this.state.title_content_dialog_button_list}
						popup_close={() => {
                            this.setState({title_content_dialog_show_state: false});
                        }}
					/>
					: ''
				}
                {/* 확인 취소 다이얼로그 */
					(this.state.confirm_dialog_show_state === true)
					? <ConfirmDialogV2
						content={this.state.confirm_dialog_content}
                        content2={this.state.confirm_dialog_content2}
						button_list={this.state.confirm_dialog_button_list}
						popup_close={() => {
                            this.setState({
                                confirm_dialog_show_state: false,
                                confirm_dialog_content2 : null,
                            });
                        }}
					/>
					: ''
				}
                {/* 선정된 상품목록 적용하기 : 뒷 스크롤 계속 막아야함 */
					(this.state.paste_confirm_dialog_show_state === true)
					? <ConfirmDialogV2
						content={this.state.confirm_dialog_content}
						button_list={this.state.confirm_dialog_button_list}
						popup_close={() => {
                            this.setState({paste_confirm_dialog_show_state: false});
                        }}
					/>
					: ''
				}
                {/* 상품 연동 다이얼로그 */
					(this.state.refresh_data_show_state === true)
					? <TitleContentDialogV2
                        title={"상품 목록을 새로 불러오시겠습니까?"}
						content={'<p style="word-break:keep-all;">상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.</br>' +
                        "그 동안 다른 기능들은 모두 정상적으로 이용하실 수 있습니다.</p>"}
						button_list={[
                            {
                                title:"네",
                                event_method: () => {
                                    this.fn_refreshDataSync();
                                    this.setState({refresh_data_show_state : false});
                                }
                            },
                            {
                                title: "아니요",
                                event_method: () => {
                                    //팝업 닫기
                                    this.setState({refresh_data_show_state : false});
                                }
                            },
                        ]}
						popup_close={() => {
                            this.setState({refresh_data_show_state: false});
                        }}
					/>
					: ''
				}
                {/* 실시간 관리 신청 다이얼로그 */
					(this.state.real_time_service_regist_show_state === true)
					? <TitleContentDialogV2
                        title={"<img src='https://s3.ap-northeast-2.amazonaws.com/otdeal.file/icn_robomd.png' style='width:100px;height:auto;'/>"}
						content={'<p style="word-break:keep-all;">실시간관리는 실시간 유료서비스를</br>등록하셔야 사용이 가능합니다.</br>실시간 상세 기능 설명을 보러 가시겠습니까?</p>'}
						button_list={[
                            {
                                title:"네",
                                event_method: () => {
                                    window.open("/shop/rp/realTimeService");
                                    this.setState({real_time_service_regist_show_state : false});
                                }
                            },
                            {
                                title: "아니요",
                                event_method: () => {
                                    //팝업 닫기
                                    this.setState({real_time_service_regist_show_state : false});
                                }
                            },
                        ]}
						popup_close={() => {
                            this.setState({real_time_service_regist_show_state: false});
                        }}
					/>
					: ''
				}
            </>
        )
    };
}

// 스토어의 상태를 컴포넌트의 props로 매핑 : useSelector
const mapStateToProps = state => ({
    excludeProductList: state.excludeProductsSlice.list,
    excludeProductModified: state.excludeProductsSlice.modified,
});

// 스토어의 상태를 컴포넌트의 프로퍼티로 매핑 : useDispatch
const mapDispatchToProps = dispatch => ({
    excludeProductLoad : (pageID, htmlID) => dispatch(loadList({pageID : pageID, htmlID : htmlID})),
    excludeProductSave : (data) => dispatch(saveList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RpProductList_install);